import { DexPriceListViewModel, RouteNodeViewModel } from 'models/swap.model'
import React, { createContext, useReducer } from 'react'
import { MultiRouteResponse } from '../models/swap-multiroute.model'

export type RouteDisplayState = 'success' | 'no-route' | 'no-data'

export type ActionType =
  | 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL'
  | 'NOTIFY_EXCHANGE_VIEW_MODEL'
  | 'NOTIFY_CHART_VISIBILITY'
  | 'NOTIFY_PRICE_LOADING'
  | 'NOTIFY_BALANCE_CHANGED'
  | 'NOTIFY_VIEW_MODE_CHANGED'
  | 'NOTIFY_LOADING_STATUS'
  | 'TOPBAR_STATE_CHANGED'
  | 'CHAIN_CHANGED'
  | 'CONNECT_WALLET_BUTTON_CLICK_CHANGED'
  | 'TOUR_BUTTON_CLICK_CHANGED'
  | 'TOUR_INFO_CHANGED'
  | 'NETWORK_CHANGE'
type Action =
  | {
    type: 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL'
    payload: {
      routeDisplayViewModel: RouteNodeViewModel | null
      routeDisplayState: RouteDisplayState
    }
  }
  | {
    type: 'NOTIFY_EXCHANGE_VIEW_MODEL'
    payload: DexPriceListViewModel[] | null
  }
  | {
    type: 'NOTIFY_CHART_VISIBILITY'
    payload: boolean
  }
  | {
    type: 'NOTIFY_PRICE_LOADING'
    payload: boolean
  }
  | {
    type: 'NOTIFY_MULTIROUTE_SUCCESS'
    payload: MultiRouteResponse | null
  }
  | {
    type: 'NOTIFY_LOADING_STATUS'
    payload: React.ReactText
  }
  | {
    type: 'NOTIFY_BALANCE_CHANGED'
    payload: string[]
  }
  | {
    type: 'NOTIFY_VIEW_MODE_CHANGED'
    payload: 'simple' | 'advance'
  }
  | {
    type: 'TOPBAR_STATE_CHANGED'
    payload: boolean
  }
  | {
    type: 'CHAIN_CHANGED'
    payload: number
  }
  | {
    type: 'CONNECT_WALLET_BUTTON_CLICK_CHANGED'
    payload: string
  }
  | {
    type: 'TOUR_BUTTON_CLICK_CHANGED'
    payload: string
  }
  | {
    type: 'TOUR_INFO_CHANGED'
    payload: 'balance' | ''
  }
  | {
    type: 'NETWORK_CHANGE'
    payload: number
  }

type Dispatch = (action: Action) => void
type State = {
  routeDisplayViewModel: RouteNodeViewModel | null
  dexPriceListViewModel: DexPriceListViewModel[] | null
  chartHidden: boolean
  priceLoading: boolean
  routeDisplayState: RouteDisplayState
  multiRouteSuccessResponse: MultiRouteResponse | null
  balances: string[]
  viewMode: 'simple' | 'advance'
  topBarHidden: boolean
  selectedChainId: number
  buttonClickChanged: string
  tourButtonClickChange: string
  tourInfoChanged: 'balance' | ''
  loadingPopupId?: React.ReactText
  networkChanged: number
}

const SwapStateContext = createContext<State | undefined>(undefined)
const SwapDispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL':
      return {
        ...state,
        routeDisplayViewModel: action.payload.routeDisplayViewModel,
        routeDisplayState: action.payload.routeDisplayState,
      }

    case 'NOTIFY_EXCHANGE_VIEW_MODEL':
      return { ...state, dexPriceListViewModel: action.payload }

    case 'NOTIFY_CHART_VISIBILITY':
      return { ...state, chartHidden: action.payload }

    case 'NOTIFY_PRICE_LOADING':
      return { ...state, priceLoading: action.payload }

    case 'NOTIFY_MULTIROUTE_SUCCESS':
      return { ...state, multiRouteSuccessResponse: action.payload }

    case 'NOTIFY_LOADING_STATUS':
      return { ...state, loadingPopupId: action.payload }

    case 'NOTIFY_BALANCE_CHANGED':
      return { ...state, balances: action.payload }

    case 'NOTIFY_VIEW_MODE_CHANGED':
      return { ...state, viewMode: action.payload }

    case 'TOPBAR_STATE_CHANGED':
      return { ...state, topBarHidden: action.payload }

    case 'CHAIN_CHANGED':
      return { ...state, selectedChainId: action.payload }

    case 'CONNECT_WALLET_BUTTON_CLICK_CHANGED':
      return { ...state, buttonClickChanged: action.payload }

    case 'TOUR_BUTTON_CLICK_CHANGED':
      return { ...state, tourButtonClickChange: action.payload }

    case 'TOUR_INFO_CHANGED':
      return { ...state, tourInfoChanged: action.payload }

    case 'NETWORK_CHANGE':
        return { ...state, networkChanged: action.payload }

    default:
      return state
  }
}

const SwapProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, {
    routeDisplayViewModel: null,
    dexPriceListViewModel: [],
    chartHidden: false,
    priceLoading: false,
    routeDisplayState: 'no-data',
    multiRouteSuccessResponse: null,
    balances: [],
    viewMode: 'simple',
    topBarHidden: false,
    selectedChainId: 56,
    buttonClickChanged: '',
    tourButtonClickChange: '',
    tourInfoChanged: '',
    networkChanged: 0,
  })

  return (
    <SwapStateContext.Provider value={state}>
      <SwapDispatchContext.Provider value={dispatch}>
        {children}
      </SwapDispatchContext.Provider>
    </SwapStateContext.Provider>
  )
}

const useSwapGlobalState = (): State => {
  const context = React.useContext(SwapStateContext)
  if (context === undefined) {
    throw new Error('useSwapGlobalState must be used within a SwapProvider')
  }
  return context
}
const useSwapDispatch = (): Dispatch => {
  const context = React.useContext(SwapDispatchContext)
  if (context === undefined) {
    throw new Error('useSwapDispatch must be used within a SwapProvider')
  }
  return context
}

export { SwapProvider, useSwapGlobalState, useSwapDispatch }
