import { Chain } from 'models/chain.model'
import { DexInfoDisplay } from 'models/swap.model'
import React, { createContext, useEffect, useReducer } from 'react'
import { getChains } from 'services/chain.sevice'
import { getDex } from 'services/swap.service'

export type ActionType = 'CHAINS'
type Action =
  | { type: 'CHAINS'; payload: Chain[] }
  | { type: 'DEXS'; payload: DexInfoDisplay[] }
type Dispatch = (action: Action) => void
type State = {
  chains: Chain[]
  dexs: DexInfoDisplay[]
}

const ChainStateContext = createContext<State | undefined>(undefined)
const ChainDispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'CHAINS': {
      return { ...state, chains: action.payload }
    }
    case 'DEXS': {
      return { ...state, dexs: action.payload }
    }
  }
}

const ChainProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, {
    chains: [],
    dexs: [],
  })

  useEffect(() => {
    getChains().then((response) => {
      let filteredChains = response.filter((item: any) => {
        return item?.frontEndData.enabled === true
      })
      dispatch({
        type: 'CHAINS',
        payload: filteredChains,
      })
    })
    getDex().then((response) => {
      dispatch({
        type: 'DEXS',
        payload: response,
      })
    })
  }, [])

  return (
    <ChainStateContext.Provider value={state}>
      <ChainDispatchContext.Provider value={dispatch}>
        {children}
      </ChainDispatchContext.Provider>
    </ChainStateContext.Provider>
  )
}

const useChainState = (): State => {
  const context = React.useContext(ChainStateContext)
  if (context === undefined) {
    throw new Error('useTokenState must be used within a TokenProvider')
  }
  return context
}
const useChainDispatch = (): Dispatch => {
  const context = React.useContext(ChainDispatchContext)
  if (context === undefined) {
    throw new Error('useTokenDispatch must be used within a TokenProvider')
  }
  return context
}

export { ChainProvider, useChainState, useChainDispatch }
