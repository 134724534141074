import Metamask from './icons/Metamask'
// import MathWallet from './icons/MathWallet'
// import TokenPocket from './icons/TokenPocket'
// import WalletConnect from './icons/WalletConnect'
// import BinanceChain from './icons/BinanceChain'
import { Config } from './types'
import { ConnectorNames } from 'hooks/useAuth'

const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
  },
  // {
  //   title: 'Binance Chain Wallet',
  //   icon: BinanceChain,
  //   connectorId: ConnectorNames.BSC,
  // },
  // {
  //   title: 'TrustWallet',
  //   icon: TrustWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'MathWallet',
  //   icon: MathWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'TokenPocket',
  //   icon: TokenPocket,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'SafePal Wallet',
  //   icon: SafePalWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'WalletConnect',
  //   icon: WalletConnect,
  //   connectorId: ConnectorNames.WalletConnect,
  // },
]

export default connectors
export const connectorLocalStorageKey = 'connectorId'
