import { LANG_EN } from 'constants/lang/EN'
import { LANG_TH } from 'constants/lang/TH'
import { useSwapDispatch } from 'contexts/SwapContext'
import { useTranslationDispatch } from 'contexts/TranslationContext'
import { useSessionState } from 'hooks/useStickyState'
import { HermesSwapToken } from 'pages/HermesSwapToken'
import { FC, useEffect, useRef } from 'react'

export const SWAP_EMBBEDDED_ACCESS_KEY = 'ODY3NDgyMTBkMTc5NTIyYTQ3NDZmZTMw'

export const SwapEmbedded: FC = () => {
  const swapDispatch = useSwapDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const chainId = urlParams.get('chainId')
  const compact = urlParams.get('compact')

  const translationDispatch = useTranslationDispatch()
  const lang = urlParams.get('lang')
  const [language, saveLanguage] = useSessionState<'eng' | 'tha'>(
    'eng',
    'config-translation'
  )

  const isValidLANG = (val: string) => {
    if (val === 'eng' || val === 'tha'){
      return true
    }
    return false
  }

  const init = useRef<boolean>(false)
  useEffect(()=>{
    if (init.current === false && language) {
      let currrentLAG = lang ? lang.toLocaleLowerCase() : ""
      currrentLAG = language ? (currrentLAG === 'en' ? "eng" : "tha") : ""
      // var selectedLAG = language
      // console.log("currrentLAG", currrentLAG)
      // console.log("selectedLAG", selectedLAG)
      const isValid = isValidLANG(currrentLAG)
      if (isValid){
        // console.log("isValid", isValid)
        if (isValid){
          const payload = (currrentLAG === 'eng' ? LANG_EN : LANG_TH)
          // console.log("translationDispatch", payload)
          saveLanguage(currrentLAG === 'eng' ? 'eng' : 'tha')
          translationDispatch({
            type: 'LANG_SELECT',
            payload: payload,
          })
          translationDispatch({
            type: 'LANG_CHANGED',
            payload: true,
          })
        }
      }
      init.current = true
    }
  }, [lang, language, saveLanguage, translationDispatch])

  const tokenString = urlParams.get('tokens')
  const key = urlParams.get('k')
  let tokens = undefined
  if (tokenString) {
    tokens = tokenString.split(',')
  }

  useEffect(() => {
    if (key !== SWAP_EMBBEDDED_ACCESS_KEY) {
      swapDispatch({
        type: 'TOPBAR_STATE_CHANGED',
        payload: true,
      })
    }
  }, [key, swapDispatch])

  if (key !== SWAP_EMBBEDDED_ACCESS_KEY) {
    return <div />
  }
  return (
    <HermesSwapToken
      showTopNavigator={false}
      chainId={chainId}
      iTokens={tokens}
      // paddingEnabled={false}
      // flexStyleEnabled={false}
      emebeddedStyle={true}
      compact={compact === 'true'}
      ></HermesSwapToken>
  )
}
