import BigNumber from 'bignumber.js'
import { Chain } from 'models/chain.model'
import React from 'react'
import { swapSingleRoute } from 'services/swap-singleroute.service'
import { useMyWallet } from '../../contexts/MyWalletContext'
import { usePopupContext } from '../../contexts/PopupContext'
import { Token } from '../../models/token.model'
import {
  decimalToUnsignedIntWithTokenDecimal,
  unformatInputNumber,
} from '../../utils/formatter.utils'
import { BIG_NUMBER_ZERO } from './useSwapPrice'

const useSwap = () => {

  const { reload } = useMyWallet()
  const { showTransactionStatePopup } = usePopupContext()
  const bscHashRef = React.useRef('')
  const allowShowStatePopupRef = React.useRef(false)

  const [[rateV1, routeV1], setRateAndRoute] = React.useState<
    [BigNumber, string[]]
  >([BIG_NUMBER_ZERO, []])

  const swapTokens = React.useCallback(
    ({
      tokenFrom,
      tokenTo,
      amountFrom,
      amountTo,
      path,
      slipPage,
      deadLineInMinute,
      chainInfo,
      transactionCommit,
      complete
    }: {
      tokenFrom: Token | undefined
      tokenTo: Token | undefined
      amountFrom: string
      amountTo: string
      path: string[] | undefined
      slipPage: string
      deadLineInMinute: number
      chainInfo: Chain
      transactionCommit: (hash: string, chainInfo: Chain) => void
      complete: () => void
    }) => {

      console.log("swapTokens >>>")

      let convertedAmountToken1 = decimalToUnsignedIntWithTokenDecimal(
        unformatInputNumber(amountFrom),
        parseFloat(tokenFrom?.decimals ?? '0')
      )
      let convertedAmountToken2 = decimalToUnsignedIntWithTokenDecimal(
        unformatInputNumber(amountTo),
        parseFloat(tokenTo?.decimals ?? '0')
      )

      let bscHash = ''
      swapSingleRoute(
        chainInfo,
        tokenFrom?.address ?? '',
        tokenTo?.address ?? '',
        convertedAmountToken1.toString(),
        convertedAmountToken2.toString(),
        true, //disableMultiRoute is true, swap v1 always ignore multiRouteEnabled in chainInfo
        path,
        slipPage,
        deadLineInMinute,
        (response) => {
          if (response.type === 'WAITING') {
            allowShowStatePopupRef.current = true
            showTransactionStatePopup(
              'processing',
              {
                transactionMessage: 'Swapping',
              },
              undefined,
              () => {
                allowShowStatePopupRef.current = false
              }
            )
          } else if (response.type === 'SUBMITTING') {
            if (response.data && 'hash' in response.data) {
              bscHash = response.data.hash
            }
            showTransactionStatePopup(
              'processing',
              {
                transactionTitle: 'Transaction Submitting',
                transactionMessage: '',
              },
              undefined,
              () => {
                allowShowStatePopupRef.current = false
              }
            )
            // if (bscHash) {
            //   setTimeout(()=>{
            //     transactionCommit(bscHash, chainInfo)
            //   }, 5000)
            // }
            console.log("LOADING ___>>")
          } else if (response.type === 'SUBMITED') {
            if (response.data && 'hash' in response.data) {
              bscHash = response.data.hash
            }
            showTransactionStatePopup(
              'submitted',
              {
                bscAddress: bscHash,
              },
              undefined,
              () => {
                allowShowStatePopupRef.current = false
              }
            )
            if (bscHash) {
              setTimeout(()=>{
                transactionCommit(bscHash, chainInfo)
              }, 5000)
            }
            console.log("SUBMIT ___>>", bscHash)
          }
          bscHashRef.current = bscHash
        }
      ).then((response) => {
        if (response.result === 'ERROR') {
          showTransactionStatePopup('error', {
            errorMessage: response.message,
          })
        } else if (response.result === 'FAIL') {
          showTransactionStatePopup('fail')
        } else if (response.result === 'SUCCESS') {
          reload(true)
          if (allowShowStatePopupRef.current) {
            showTransactionStatePopup('successful', {
              bscAddress: bscHash,
            })
          }
        }
        console.log("COMPLETE !!!!", bscHash)
        complete()
      })
    },
    [reload, showTransactionStatePopup]
  )

  return {
    rateV1,
    routeV1,
    setRateAndRoute,
    swapTokens
  }
}

export default useSwap
