import { FC } from 'react'

export const MarketTrade: FC<{}> = () => {
  const sampleData = [
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
    // { price: '0.38415', total: '1000', time: '12.00.01' },
  ]
  return (
    <div
      className='flex text-white flex-col w-64 bg-gray-800'
    >
      <div className='pb-2'>Market Trade</div>
      <div className='flex flex-row text-gray-400' style={{ fontSize: '12px' }}>
        <div className='flex flex-1 justify-end pr-2'>Price (USD)</div>
        <div className='flex flex-1 justify-end pr-2'>Total (USD)</div>
        <div className='flex flex-1 justify-end pr-4'>Time (USD)</div>
      </div>
      <div
        className='flex flex-col'
        style={{ fontSize: '12px', textAlign: 'right' }}>
        {sampleData.map((item, index) => {
          var y = Math.random();
          if (y < 0.5)
            y = 0
          else
            y= 1
          return (
            <div key={index} className='flex flex-1 flex-row text-gray-400'>
              <div className={'flex flex-1 justify-end pr-2' + (y === 0? ' text-red-400' : ' text-green-400')}>{item.price}</div>
              <div className='flex flex-1 justify-end pr-2'>{item.total}</div>
              <div className='flex flex-1 justify-end pr-4'>{item.time}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
