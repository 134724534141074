import { HermesSwapToken } from "pages/HermesSwapToken"
import { FC } from "react"

export const DemoPage: FC = () => {
  return (
    <HermesSwapToken
      showTopNavigator={true}
      emebeddedStyle={false}
      compact={false}
      demo={true}
      ></HermesSwapToken>
  )
}
