import SwapConfirmDialog from 'components/SwapConfirmDialog'
import { RoutingInfoView } from 'pages/HermesSwapToken'
import {
  createContext,
  FC,
  memo,
  ReactElement,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { AlertDialog } from '../components/AlertDialog'
import { SwapConfigPopup } from '../components/SwapConfigPopup'
import TokenListPopup, { FixSizingModel } from '../components/TokenListPopup'
import TransactionStatePopup, {
  TransactionPopupInfo,
  TransactionPopupStatusType,
} from '../components/TransactionStatePopup'
import WithdrawPopup from '../components/WithdrawPopup'
import { MyFarmDetail, Farm } from '../models/farm.model'
import { Token } from '../models/token.model'
import { useTranslationState } from './TranslationContext'

type PopupType =
  | 'alert'
  | 'withdraw'
  | 'transactionstate'
  | 'tokenpopup'
  | 'swap'
  | 'exchangeView'
  | 'routeView'
  | 'confirm'

export type PopupContextType = {
  hidePopup: (type: PopupType) => void
  showSwapConfigPopup: (
    isManualInput: boolean,
    slippage: string,
    deadline: number,
    onSettingChange?: (
      newSlipPage: string,
      newDeadLine: number,
      isManualInput: boolean
    ) => void,
    onClose?: () => void
  ) => void
  showTransactionStatePopup: (
    statusType: TransactionPopupStatusType,
    info?: TransactionPopupInfo,
    onComplete?: () => void,
    onClose?: () => void
  ) => void
  showAlertPopup: (props: {
    title: string
    message: string
    onClose?: () => void
  }) => void
  showWithDrawPopup: (
    withdrawInfo: WithdrawInfo,
    onComplete?: () => void,
    onClose?: () => void
  ) => void
  showSwapTokenList: (props: {
    token: string
    tokenSelectedCallback: (token: Token) => void
    onClose?: () => void
    lockTokens?: (Token | undefined)[]
    size?: FixSizingModel
    limitTokens?: Token[]
  }) => void
  showSwapConfirmDialog: (props: {
    token: string
    tokenSelectedCallback: (token: Token) => void
    onClose?: () => void
    lockTokens?: (Token | undefined)[]
    size?: FixSizingModel
    isBuy?: boolean
  }) => void
  showRouteView: () => void
  showExchangeView: () => void
  // showProgressView: () => void
}

const PopupContext = createContext<PopupContextType>({} as any)

interface PopupInfo {
  type: PopupType
  view: ReactElement
}

export interface WithdrawInfo {
  farmInfo: Farm
  farmDetail: MyFarmDetail
  poolid: number
  totalBalance: string
}

export interface SwapInfo {
  fromToken: string
  toToken: string
  amount: string
}

export const PopupContextProvider: FC = memo(({ children }) => {
  const [popup, setPopup] = useState<PopupInfo[]>([])
  const popRef = useRef(popup)

  const addNewPopup = (popupInfo: PopupInfo) => {
    const list = popRef.current.concat()
    const idx = list.findIndex(({ type }) => type === popupInfo.type)
    if (idx !== -1) {
      list.splice(idx, 1)
    }

    list.push(popupInfo)

    popRef.current = list
    setPopup(popRef.current)
  }

  const hidePopup = useCallback((popupType: PopupType) => {
    console.log('hidePopup', popupType)
    popRef.current = popRef.current.filter((item) => item.type !== popupType)
    setPopup(popRef.current)
  }, [])

  const showTransactionStatePopup = useCallback(
    (
      statusType: TransactionPopupStatusType,
      info?: TransactionPopupInfo,
      onComplete?: () => void,
      onClose?: () => void
    ) => {
      addNewPopup({
        type: 'transactionstate',
        view: (
          <TransactionStatePopup
            key='transactionstate'
            statusType={statusType}
            info={info}
            onComplete={onComplete}
            onClose={() => {
              hidePopup('transactionstate')
              onClose && onClose()
            }}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showSwapConfigPopup = useCallback(
    (
      isManualInput: boolean,
      slipPage: string,
      deadLine: number,
      onSettingChange?: (
        newSlipPage: string,
        newDeadLine: number,
        isManualInput: boolean
      ) => void,
      onClose?: () => void
    ) => {
      addNewPopup({
        type: 'swap',
        view: (
          <SwapConfigPopup
            isManualInput={isManualInput}
            slipPage={slipPage}
            deadLine={deadLine}
            onSettingChange={onSettingChange}
            onClose={() => {
              hidePopup('swap')
              onClose && onClose()
            }}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showWithDrawPopup = useCallback(
    (
      withdrawInfo: WithdrawInfo,
      onComplete?: () => void,
      onClose?: () => void
    ) => {
      addNewPopup({
        type: 'withdraw',
        view: (
          <WithdrawPopup
            key='withdaraw'
            withdrawInfo={withdrawInfo}
            onComplete={onComplete}
            onClose={() => {
              hidePopup('withdraw')
              onClose && onClose()
            }}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showSwapTokenList = useCallback(
    ({
      onClose,
      ...rest
    }: Parameters<PopupContextType['showSwapTokenList']>['0']) => {
      addNewPopup({
        type: 'tokenpopup',
        view: (
          <TokenListPopup
            onClose={() => {
              hidePopup('tokenpopup')
              onClose && onClose()
            }}
            {...rest}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showSwapConfirmDialog = useCallback(
    ({
      onClose,
      ...rest
    }: Parameters<PopupContextType['showSwapTokenList']>['0']) => {
      addNewPopup({
        type: 'confirm',
        view: (
          <SwapConfirmDialog
            onClose={() => {
              hidePopup('confirm')
              onClose && onClose()
            }}
            {...rest}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showAlertPopup = useCallback(
    ({
      message,
      title,
      onClose,
    }: {
      message: string
      title: string
      onClose?: () => void
    }) => {
      addNewPopup({
        type: 'alert',
        view: (
          <AlertDialog
            title={title}
            description={message}
            onClose={() => {
              hidePopup('alert')
              onClose && onClose()
            }}
          />
        ),
      })
    },
    [hidePopup]
  )

  const showRouteView = useCallback(() => {
    addNewPopup({
      type: 'routeView',
      view: <RoutingView hidePopup={hidePopup}></RoutingView>,
    })
  }, [hidePopup])

  const showExchangeView = useCallback(() => {
    addNewPopup({
      type: 'routeView',
      view: <ExchangeView hidePopup={hidePopup}></ExchangeView>,
    })
  }, [hidePopup])

  const generatePopup = () => {
    let mapData = popup.map((obj) => obj.view)
    return mapData
  }

  const sharedState = {
    hidePopup,
    showAlertPopup,
    showSwapConfigPopup,
    showSwapTokenList,
    showSwapConfirmDialog,
    showTransactionStatePopup,
    showWithDrawPopup,
    showRouteView,
    showExchangeView,
    // showProgressView
  }

  return (
    <PopupContext.Provider value={sharedState}>
      {generatePopup()}
      {children}
    </PopupContext.Provider>
  )
})

export const usePopupContext = () => {
  return useContext(PopupContext)
}

export const RoutingView: FC<{
  hidePopup: (type: PopupType) => void
}> = ({ hidePopup }) => {
  //useTranslation
  const { tl } = useTranslationState()
  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen p-0 md:px-4 md:pt-4 md:pb-20 text-center sm:block'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'>
          &#8203;
        </span>
        <div className='absolute w-full h-full top-0 left-0'>
          <div
            className='inline-block w-full max-w-max pt-2 pb-6 px-6 overflow-hidden text-left rounded-lg shadow-xl mb-28 transform transition-all mt-8 sm:mt-32 align-middle text-white border border-gray-600 bg-gradient-to-b from-gray-600 to-gray-800 rounded-xl'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'>
            <div
              style={{
                minHeight: '56px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div className='flex-1 text-center text-2xl'>{tl.routing_view}</div>
              <button
                type='button'
                onClick={() => {
                  hidePopup('routeView')
                }}
                className='farm-price-favorite rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                <span className='sr-only'>Close menu</span>
                <svg
                  style={{ width: '20px', height: '20px' }}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='#40BF77'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            <RoutingInfoView
              themeDisabled={true}
              hideFooterSectionView={true}
              isMobile={false}
              innerWidth={200}
              exchangeOnlyMode={false}
              titleHidden={true}></RoutingInfoView>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ExchangeView: FC<{
  hidePopup: (type: PopupType) => void
}> = ({ hidePopup }) => {
  //useTranslation
  const { tl } = useTranslationState()
  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen p-0 md:px-4 md:pt-4 md:pb-20 text-center sm:block'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'>
          &#8203;
        </span>
        <div className='absolute w-full h-full top-0 left-0'>
          <div
            className='inline-block w-full max-w-max pt-2 pb-6 px-6 overflow-hidden text-left rounded-lg shadow-xl mb-28 transform transition-all mt-8 sm:mt-32 align-middle text-white border border-gray-600 bg-gradient-to-b from-gray-600 to-gray-800 rounded-xl'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'>
            <div
              style={{
                minHeight: '56px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div className='flex-1 text-center text-2xl'>
                {tl.exchange_rate}
              </div>
              <button
                type='button'
                onClick={() => {
                  hidePopup('routeView')
                }}
                className='farm-price-favorite rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                <span className='sr-only'>Close menu</span>
                <svg
                  style={{ width: '20px', height: '20px' }}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='#40BF77'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            <RoutingInfoView
              themeDisabled={true}
              hideRouteView={true}
              isMobile={false}
              exchangeOnlyMode={true}
              innerWidth={200}></RoutingInfoView>
          </div>
        </div>
      </div>
    </div>
  )
}
