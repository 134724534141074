import { getChainById } from 'hooks/Chain/useChain'
import { Token } from 'models/token.model'
import React, { createContext, useEffect, useReducer } from 'react'
import { useSessionState } from '../hooks/useStickyState'
import useToken from '../hooks/useToken'
import { useChainState } from './ChainContext'
import { useSwapGlobalState } from './SwapContext'
import { useTokenState } from './TokenContext'

export type PriceModel = {
  time: number
  low: number
  high: number
  open: number
  close: number
}
export type ActionType = 'TOKENS' | 'TOKEN_PRICES' | 'TOKEN' | 'SWAP_TOKEN'
type Action =
  | { type: 'TOKEN'; payload: Token }
  | { type: 'SWAP_TOKEN'; payload: (Token | undefined | null)[] }
  | { type: 'TOKENS'; payload: Token[] }
  // | { type: 'TOKEN_PRICES'; payload: TokenPrice[] }
  | { type: 'LAST_CHART_PRICE'; payload: PriceModel }

type Dispatch = (action: Action) => void
type State = {
  token: Token | null
  token2: Token | null
  tokens: Token[]
  // tokenPrices: TokenPrice[]
  lastChartPrice: PriceModel
}

const FarmPriceStateContext = createContext<State | undefined>(undefined)
const FarmPriceDispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'TOKENS': {
      return { ...state, tokens: action.payload }
    }
    // case 'TOKEN_PRICES': {
    //   return { ...state, tokenPrices: action.payload }
    // }
    case 'TOKEN': {
      if (action.payload?.symbol === state.token2?.symbol) {
        return { ...state, token: action.payload, token2: state.token }
      } else {
        return { ...state, token: action.payload }
      }
    }
    case 'SWAP_TOKEN': {
      sessionStorage.setItem(
        'pair-tokens',
        JSON.stringify(action.payload.map((p) => p && p.address))
      )
      return {
        ...state,
        token: action.payload[0] || null,
        token2: action.payload[1] || null,
      }
    }
    case 'LAST_CHART_PRICE': {
      return { ...state, lastChartPrice: action.payload }
    }
  }
}

const FarmPriceProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const { tokens } = useTokenState()
  const { chains } = useChainState()
  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const { getTokenByAddress } = useToken()
  const [state, dispatch] = useReducer(reducer, {
    tokens: [],
    // tokenPrices: [],
    token: null,
    token2: null,
    lastChartPrice: {
      time: 0,
      low: 0,
      high: 0,
      open: 0,
      close: 0,
    },
  })

  const [pairTokens] = useSessionState<string[]>(
    //['BTCB', 'BUSD'], for BSC
    // [
    //   '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    //   '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    // ],
    // ['BTCB', 'BUSD'], for JIBCHAIN
    [
      '0xFD8Ef75c1cB00A594D02df48ADdc27414Bd07F8a',
      '0x99999999990FC47611b74827486218f3398A4abD',
    ],
    'pair-tokens'
  )

  useEffect(() => {
    const selectedPairTokens =
      pairTokens &&
      pairTokens.map((token) => {
        return getTokenByAddress(tokens, token, selectedChain?.chainId ?? -1)
      })
    dispatch({
      type: 'SWAP_TOKEN',
      payload: selectedPairTokens,
    })
  }, [pairTokens, getTokenByAddress, tokens, selectedChain])

  return (
    <FarmPriceStateContext.Provider value={state}>
      <FarmPriceDispatchContext.Provider value={dispatch}>
        {children}
      </FarmPriceDispatchContext.Provider>
    </FarmPriceStateContext.Provider>
  )
}

const useFarmPriceState = (): State => {
  const context = React.useContext(FarmPriceStateContext)
  if (context === undefined) {
    throw new Error('useFarmPriceState must be used within a FarmPriceProvider')
  }
  return context
}
const useFarmPriceDispatch = (): Dispatch => {
  const context = React.useContext(FarmPriceDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useFarmPriceDispatch must be used within a FarmPriceProvider'
    )
  }
  return context
}

export { FarmPriceProvider, useFarmPriceState, useFarmPriceDispatch }
