import { Body, Container, ContainerBody } from './style'
import React, { useEffect, useState } from 'react'

// import SideBar from './SideBar'
import SideBarMobile from './SideBarMobile'
import TopBar from './TopBar'
import TopBarMobile from './TopBarMobile'
// import { getSczPrice } from '../../services/scz-price.service'
import { useSelectTheme } from '../../contexts/SelectThemeContext'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../../GlobalStyles'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

declare let window: any

export type Page = {
  name: string
  icon: string
  path: string
}

const pages = [
  // {
  //   name: 'My Farm',
  //   icon: '/images/icons/icon_myfarm.svg',
  //   path: '/',
  // },
  // {
  //   name: 'Farm Price',
  //   icon: '/images/icons/icon_farm_price.svg',
  //   path: '/farm-price',
  // },
  // SWAP_TOKEN_ENABLED
  //   ? {
  //       name: 'Swap token',
  //       icon: '/images/icons/icon_switch.svg',
  //       path: '/swap-token',
  //     }
  //   : null,
  // {
  //   name: 'Support Protocols',
  //   icon: '/images/icons/icon_support_ptotocol.svg',
  //   path: '/support-protocol',
  // },
  // {
  //   name: 'Allowance',
  //   icon: '/images/icons/icon_allowance.svg',
  //   path: '/allowance',
  // },
  // {
  //   name: 'Portfolio',
  //   icon: '/images/icons/icon_profile.svg',
  //   path: '/portfolio',
  // },
  // {
  //   name: 'Market Place',
  //   icon: '/images/icons/icon_market.svg',
  //   path: '/marketplace',
  // },
].filter((p) => p) as Page[]

const NavLayout: React.FC = (props) => {
  const [windowDimension, setWindowDimension] = useState(0)
  const [showSideBar, setShowSideBar] = useState<boolean>(false)
  const handleShowSideBar = () => setShowSideBar(true)
  const handleHideSideBar = () => setShowSideBar(false)
  // const [sczPrice, setSczPrice] = useState<string>()
  // const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // const loadSczPrice = async () => {
    //   setLoading(true)
    //   const response = await getSczPrice()
    //   if (response && response.data && response.data.price) {
    //     setSczPrice(response.data.price)
    //   }
    //   setLoading(false)
    // }
    // loadSczPrice()
  }, []) //sczPrice

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 640
  const { theme } = useSelectTheme()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          margin: 0,
          padding: 0,
        }}>
        {isMobile ? (
          <TopBarMobile handleShowSideBar={handleShowSideBar} />
        ) : (
          <TopBar />
        )}
        <ContainerBody>
          {isMobile ? (
            <SideBarMobile
              pages={pages}
              handleHideSideBar={handleHideSideBar}
              showSideBar={showSideBar}
              // sczPrice={sczPrice}
              // loading={loading}
            />
          ) : (
            <div></div>
            // <SideBar pages={pages} sczPrice={sczPrice} loading={loading} />
          )}
          <Body>
            {/* <ContainerChildren>{props.children}</ContainerChildren> */}
            {props.children}
          </Body>
        </ContainerBody>
      </Container>
      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
    </ThemeProvider>
  )
}

export default NavLayout
