import TokenSymbolImg from 'components/TokenSymbolImg'
import { FC } from 'react'
import {
  RouteNodeViewModel,
  RouterViewModel,
} from 'models/swap.model'
import { Token } from '../../models/token.model'

type Props = {
  nodes: RouteNodeViewModel[]
  fromToken?: Token
  toToken?: Token
  isRoot: boolean
  isParentBeRoot?: boolean
  currentIndex: string
  isMobile?: boolean
  innerWidth: number
}

const PathRoutingView: FC<Props> = ({
  nodes,
  fromToken,
  toToken,
  isRoot,
  isParentBeRoot,
  currentIndex,
  isMobile,
  innerWidth,
}) => {
  let toNode = toToken
    ? {
        ...toToken,
        index: 0,
        level: 0,
        percent: 0,
        weight: 0,
        nodes: [],
        routers: [],
      }
    : null

  function makeNodeView(tk: RouteNodeViewModel) {
    const makeLabelWithSymbolDisplay = () => {
      return (
        <div
          className='flex flex-1 items-center'
          style={{ paddingLeft: '5px' }}>
          <TokenSymbolImg token={tk} style={{ marginTop: '2px' }} />
          <div className='mx-1'>{tk.symbol}</div>
        </div>
      )
    }
    const makeLabelWithPercentDisplay = (
      router: RouterViewModel,
      index: number
    ) => {
      return (
        <div className='flex' key={currentIndex + '_' + index + 'route'}>
          <div
            className='flex flex-1 text-gray-300 bg-gray-800 px-2 py text-sm'
            style={{
              margin: '4px',
              paddingLeft: '8px',
              borderRadius: '8px',
            }}>
            {router.name + ' ' + router.percent + '%'}
          </div>
        </div>
      )
    }

    if (isRoot) {
      return (
        <div style={{ minWidth: '100px' }}>
          {makeLabelWithSymbolDisplay()}
          {tk.routers.map((router, index) =>
            makeLabelWithPercentDisplay(router, index)
          )}
        </div>
      )
    } else {
      return (
        <div
          className='flex-1 flex-col text-green-500 border border-gray-600 bg-gradient-to-tr from-gray-800 to-gray-900'
          style={{
            // minWidth: '80px',
            borderRadius: '8px',
            margin: '5px',
            padding: '2px',
          }}>
          {makeLabelWithSymbolDisplay()}
          {tk.routers.map((router, index) =>
            makeLabelWithPercentDisplay(router, index)
          )}
        </div>
      )
    }
  }

  let darkTheme = '' //'text-white bg-gradient-to-b from-gray-600 to-gray-900 border-4 border border-green-500 border-4 border border-green-500 rounded-3xl'
  function makeView() {
    const makeChildNode = (tk: RouteNodeViewModel, index: number) => {
      return (
        <PathRoutingView
          nodes={tk.nodes ?? []}
          isRoot={false}
          isParentBeRoot={isRoot}
          currentIndex={currentIndex + '_' + index}
          innerWidth={innerWidth}
        />
      )
    }

    const makeToTokenNode = () => {
      if (toNode) {
        return (
          <div className='flex'>
            <div className='flex mx-5'>
              <TokenSymbolImg token={toNode} />
              <div className='mx-2'>{toNode?.symbol ?? ''}</div>
            </div>
          </div>
        )
      }
      return null
    }

    const makeLabelWithPercent = (label: string) => {
      return (
        <div
          className='flex text-gray-100'
          style={{
            display: isParentBeRoot ? 'block' : 'none',
            marginLeft: '16px',
            // marginRight: '5px',
            minWidth: '38px',
          }}>
          {label + '%'}
        </div>
      )
    }

    const makeArrow = (minWith: number = 30) => {
      return (
        <div
          className='flex text-gray-100'
          style={{
            display: isRoot ? 'none' : 'block',
            minWidth: minWith + 'px',
            textAlign: 'center',
          }}>
          {'>'}
        </div>
      )
    }

    if (isRoot) {
      if (!nodes.length) {
        ;<div className='inline-flex items-center justify-center'>
          {makeToTokenNode()}
        </div>
      }

      let padding = 90
      let nodeLeftAndNodeRight = 200
      let maxWidth = innerWidth - (nodeLeftAndNodeRight + padding)
      return (
        <div
          className={
            'route-section flex inline-flex items-center justify-center sm:text-sm text-xs my-auto ' +
            darkTheme
          }
          // style={{minHeight: '200px'}}
        >
          {nodes.map((tk, index) => {
            return (
              <div
                key={currentIndex + '_' + index}
                className='flex items-center border-r border-gray-500'>
                {
                  <div className={'flex items-center'}>
                    <div className='flex items-center divide-x divide-gray-500'>
                      {makeNodeView(tk)}
                      {makeArrow(30)}
                      <div
                        className='overflow-x-auto'
                        style={{
                          maxWidth: maxWidth + 'px',
                        }}>
                        {makeChildNode(tk, index)}
                      </div>
                    </div>
                  </div>
                }
              </div>
            )
          })}
          {makeToTokenNode()}
        </div>
      )
    } else {
      return (
        <div>
          {nodes.map((tk, index) => {
            return (
              <div
                key={currentIndex + '_' + index}
                className='flex items-center'>
                {
                  <div className={'flex flex-1 items-center'}>
                    {makeLabelWithPercent(tk.weight + '')}
                    {makeArrow()}
                    <div className='flex flex-1 items-center'>
                      {makeNodeView(tk)}
                      {tk.nodes.length === 0 ? makeArrow(40) : <div />}
                      {makeChildNode(tk, index)}
                    </div>
                  </div>
                }
              </div>
            )
          })}
          {makeToTokenNode()}
        </div>
      )
    }
  }
  return makeView()
}

export default PathRoutingView
