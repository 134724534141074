import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { ModalProvider } from './components/Modal'
import { MyWalletProvider } from './contexts/MyWalletContext'
import { PopupContextProvider } from './contexts/PopupContext'
import { UserProvider } from './contexts/UserContext'
import { FarmPriceProvider } from './contexts/FarmPriceContext'
import { SwapProvider } from './contexts/SwapContext'
import { SelectThemeProvider } from './contexts/SelectThemeContext'
import { getEthersProvider } from './services/ethers.service'
import { TokenProvider } from 'contexts/TokenContext'
import { ChainProvider } from 'contexts/ChainContext'
import { TranslationProvider } from 'contexts/TranslationContext'

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getEthersProvider}>
      <SelectThemeProvider>
        <TranslationProvider>
          <UserProvider>
            <ChainProvider>
              <TokenProvider>
                <SwapProvider>
                  <MyWalletProvider>
                    <FarmPriceProvider>
                      <PopupContextProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </PopupContextProvider>
                    </FarmPriceProvider>
                  </MyWalletProvider>
                </SwapProvider>
              </TokenProvider>
            </ChainProvider>
          </UserProvider>
        </TranslationProvider>
      </SelectThemeProvider>
    </Web3ReactProvider>
  )
}

export default Providers
