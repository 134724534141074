import React, { memo, useState } from 'react'
import { DefaultTheme } from 'styled-components'
import * as themes from '../utils/theme.json'

type ThemeContextProps = {
  theme: DefaultTheme
  changeMode: (mode: 'sun' | 'moon') => void
}

const SelectThemeContext = React.createContext<ThemeContextProps>({
  theme: {
    id: 'T_001',
    name: 'Light',
    colors: {
      bodyLight: '#f0faf4',
      card: '#FFFFFF',
      body: '#FFFFFF',
      text: '#000000',
      textHighlight: '#97a3a6',
      iconColor: 120,
      border: 'rgba(229, 231, 235, var(--tw-border-opacity))',
      backgroundButtonMoreWallet: 'rgba(236, 253, 245, var(--tw-bg-opacity))',
      button: {
        text: '#40bf77',
        background: '#000000',
        border: '#40bf77',
      },
      link: {
        text: 'teal',
        opacity: 1,
      },
    },
    font: 'Sarabun',
  },
  changeMode: () => void 0,
})

const SelectThemeProvider: React.FC = memo(({ children }) => {
  const [theme, setTheme] = useState(themes.data.light)
  const changeMode = (mode: 'sun' | 'moon') => {
    if (mode === 'moon') {
      setTheme(themes.data.dark)
    } else {
      setTheme(themes.data.light)
    }
  }

  return (
    <SelectThemeContext.Provider value={{ theme, changeMode }}>
      {children}
    </SelectThemeContext.Provider>
  )
})

const useSelectTheme = () => React.useContext(SelectThemeContext)
export { SelectThemeProvider, useSelectTheme }
