import { CoinInfo } from 'components/MarketPrice'
import { MenuDropDown } from 'components/MenuDropDown'
import { FC, useEffect, useState } from 'react'

export const TreeMap: FC<{
  coinsInfo: CoinInfo[]
}> = ({ coinsInfo }) => {
  let google = Object(window).google
  google.charts.load('current', { packages: ['treemap'] })
  google.charts.setOnLoadCallback(drawChart)

  const [blockType, setBlockSizeType] = useState('marketcap')
  const [itemInMap, setItemInMap] = useState('10')
  const [windowDimension, setWindowDimension] = useState(0)

  function convertData() {
    let header = [
      'Location',
      'Parent',
      'Market Cap',
      'Market Change',
    ]
    if(blockType === 'volume'){
      header = [
        'Location',
        'Parent',
        'Volume',
        'Volume Change',
      ]
    }
    let rootName = (blockType === 'volume'? 'Volume': 'Market Cap')
    let root = [rootName, null, 0, 0]
    let mapData = coinsInfo.map((coin) => {
      let percent = coin.price_change_percentage_24h
      // let percentColor = 0
      // if (percent > 0) {
      //   percentColor = 0.5 + percent / 50
      // } else {
      //   percentColor = -percent / 50
      // }
      return [
        coin.name + ' ' + percent.toFixed(2) + '%',
        rootName,
        blockType === 'marketcap' ? coin.market_cap : coin.total_volume,
        percent,
      ]
    })
    let result = [header, root, ...mapData.splice(0, parseInt(itemInMap))]
    console.log('result', result)
    return result
  }

  function drawChart() {
    let covertedData = convertData()
    var data = google.visualization.arrayToDataTable(covertedData)

    let tree = new google.visualization.TreeMap(
      document.getElementById('chart_div')
    )

    const showFullTooltip = (row: number, size: number, value: string) => {
      // return <div className='flex w-64 bg-gray-400 border border-gray-300'>
      //     Test { data.getValue(row, 0)}
      //   </div>
      return (
        '<div style="background:#fd9; padding:10px; border-style:solid">' +
        // '<span style="font-family:Courier">'+
        '<b>' +
        data.getValue(row, 0) +
        '<br>' +
        data.getValue(row, 1) +
        ': '+
        data.getValue(row, 2) +
        '</b>' +
        ' </div>'
      )
    }

    tree.draw(data, {
      minColor: '#f00',
      midColor: '#ddd',
      maxColor: '#0d0',
      headerHeight: 15,
      fontColor: 'black',
      showScale: true,
      generateTooltip: showFullTooltip,
    })
  }

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  console.log('render TreeMap')

  const blockSizeItems = [
    { value: 'marketcap', label: 'Market Cap' },
    { value: 'volume', label: 'Volume' },
  ]

  return (
    <div className='flex flex-1 relative flex-col'>
      <div className='flex justify-center'>
        <MenuDropDown
          title={
            'Block Size: ' +
            blockSizeItems.filter((item) => {
              return item.value === blockType
            })[0].label
          }
          items={blockSizeItems}
          isItalicFont={false}
          onChange={(item) => {
            setBlockSizeType(item)
          }}></MenuDropDown>
        <MenuDropDown
          title={'Item in map: ' + itemInMap}
          items={[
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]}
          isItalicFont={false}
          onChange={(item) => {
            setItemInMap(item)
          }}></MenuDropDown>
      </div>
      <div
        id='chart_div'
        style={{
          width: windowDimension - 10 + 'px',
          maxWidth: windowDimension * 0.7,
          minHeight: '400px',
        }}></div>
    </div>
  )
}
