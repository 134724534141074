import { HomePage } from 'components/HomePage'
import { TradeHistory } from 'components/TradeHistory'
import { SwapResult } from 'components/SwapResult'
import { HermesSwapToken } from 'pages/HermesSwapToken'
import { AboutPage } from 'components/AboutPage'
import { SwapEmbedded } from 'components/SwapEmbedded'
import { SimpleSwap } from 'components/SimpleSwap'
import { MarketPrice } from 'components/MarketPrice'
import { DemoPage } from 'components/DemoPage'
export class ApplicationRoutes {
  static HOME = {
    path: '/',
    component: HomePage,
  }
  static HomePage = {
    path: '/swap',
    component: HermesSwapToken,
  }
  static TradeHistory = {
    path: '/tradehistory',
    component: TradeHistory,
  }
  static SwapResult = {
    path: '/swapresult',
    component: SwapResult,
  }
  static AboutPage = {
    path: '/about',
    component: AboutPage,
  }
  static SwapEmbedded = {
    path: '/swapembedded',
    component: SwapEmbedded,
  }
  static SimpleSwap = {
    path: '/swap/simple',
    component: SimpleSwap,
  }
  static MarketPrice = {
    path: '/marketprice',
    component: MarketPrice,
  }
  static DemoPage = {
    path: '/demo',
    component: DemoPage,
  }
}
