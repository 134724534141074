import React from 'react'
import { Login } from './types'
import { Modal } from '../../Modal'
import config from './config'
import styled from 'styled-components'
import { useChainState } from 'contexts/ChainContext'

interface Props {
  login: Login
  onDismiss?: () => void
}

const WalletCard = styled.div.attrs({
  className:
    'flex flex-row justify-between mx-4 my-4 hover:text-white hover:bg-gray-900 px-3.5 py-2 rounded-md cursor-pointer bg-gray-800 text-white',
})``

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {

  //useTranslation
  const { chains } = useChainState()

  return (
    <Modal title='Connect to a wallet'>
      {config.map(({ title, icon: Icon, connectorId }, index) => (
        <WalletCard
          key={index}
          onClick={() => {
            login(connectorId, false, chains)
            onDismiss()
          }}>
          {title}
          <Icon width='32px'></Icon>
        </WalletCard>
      ))}
    </Modal>
  )
}

export default ConnectModal
