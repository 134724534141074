import { MultiRouteSwapTokenCard } from 'components/MultiRouteSwapTokenCard'
import PathRoutingView from 'components/PathRoutingView'

import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useFarmPriceDispatch,
  useFarmPriceState,
} from '../contexts/FarmPriceContext'
import { useSwapDispatch, useSwapGlobalState } from '../contexts/SwapContext'
import ChartHeaderView, { GroupFarmPriceContent } from '../components/ChartHeaderView'
import { Tooltip } from 'components/Tooltip'
import { useSessionState } from 'hooks/useStickyState'
import {
  Card,
  NoRouteCard,
} from 'components/MultiRouteSwapTokenCard/Components/SwapCard'
import usedChain, { getChainById } from 'hooks/Chain/useChain'
import Joyride, {
  ACTIONS,
  CallBackProps,
  LIFECYCLE,
  STATUS,
} from 'react-joyride'
import useTopbar from 'hooks/useTopbar'
import useToken from 'hooks/useToken'
import { MarketTrade } from 'components/MarketTrade'
import TradingViewChartContainer from 'components/TVChartContainer'
import { FixSizingModel } from 'components/SwapConfirmDialog'
import { FooterTabView } from 'components/FooterTabView'
import { useTokenState } from 'contexts/TokenContext'
import { useChainState } from 'contexts/ChainContext'
import { resolveInput } from 'services/swap-multiroute.service'
import { tutorial1, tutorial2 } from 'constants/joyride.constant'
import { CHART_VISIBILITY, CONFIG_VIEW_MODE } from 'constants/session.constant'
import { useTranslationState } from 'contexts/TranslationContext'

type ViewModeType = 'simple' | 'advance'

export const HermesSwapToken: FC<{
  showTopNavigator: boolean
  chainId?: string | null
  iTokens?: string[]
  emebeddedStyle: boolean
  simpleMode?: boolean
  size?: FixSizingModel
  compact: boolean
  demo?: boolean
  fixViewMode?: ViewModeType
}> = ({
  showTopNavigator = true,
  chainId,
  iTokens,
  emebeddedStyle = false,
  simpleMode = false,
  size,
  compact,
  demo = false,
  fixViewMode,
}) => {

  //Local State
  const [init, setInit] = useState(false)
  const [loaded, setLoaded] = useState(false)

  //Global State
  const { chains } = useChainState()
  const { tokens: allTokens } = useTokenState()
  const { token, token2 } = useFarmPriceState()
  const { selectedChain: localSavedChain } = usedChain()
  const { getTokenByAddress } = useToken()
  const {
    viewMode,
    topBarHidden,
    selectedChainId,
    tourButtonClickChange,
    multiRouteSuccessResponse,
  } = useSwapGlobalState()

  //Update Global State
  const dispatch = useFarmPriceDispatch()
  const swapDispatch = useSwapDispatch()

  const userClick = React.useRef(false)
  let tokens = useMemo(() => {
    return [token ?? undefined, token2 ?? undefined]
  }, [token, token2])

  if (chainId) {
    if (iTokens && userClick.current === false) {
      tokens = iTokens.map((item) => {
        return getTokenByAddress(allTokens, item, +chainId)
      })
    } else {
      tokens.forEach((token) => {
        if (token?.chainId !== +chainId) {
          let newChain = getChainById(chains, +chainId)
          let detaultTokens = newChain?.frontEndData.defaultTokens.split(',')
          if (detaultTokens) {
            tokens = detaultTokens.map((item) => {
              return getTokenByAddress(allTokens, item, +chainId)
            })
          }
        }
      })
    }
  }

  const tokenRef = useRef(tokens)
  tokenRef.current = tokens
  const [windowDimension, setWindowDimension] = useState(0)
  const isMobile = windowDimension <= 1166

  //config
  const [viewModeState, setViewModeState] = useSessionState<ViewModeType>(
    'simple',
    CONFIG_VIEW_MODE
  )
  const [chartVisible, setChartVisible] = useSessionState(
    true,
    CHART_VISIBILITY
  )

  const selectedViewMode = React.useMemo(() => {
    if (emebeddedStyle) {
      return 'simple'
    }
    return viewMode
  }, [viewMode, emebeddedStyle])

  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  useEffect(() => {
    //load chain
    if (init && !loaded) {
      setLoaded(true)
      if (chainId) {
        swapDispatch({
          type: 'CHAIN_CHANGED',
          payload: +chainId,
        })
        dispatch({
          type: 'SWAP_TOKEN',
          payload: tokens,
        })
      } else if (localSavedChain) {
        swapDispatch({
          type: 'CHAIN_CHANGED',
          payload: localSavedChain,
        })
      }
    }
  }, [chainId, dispatch, init, loaded, localSavedChain, swapDispatch, tokens])

  useEffect(() => {
    if (
      selectedChain?.frontEndData.displayType === 'simpleModeOnly' &&
      selectedViewMode !== 'simple'
    ) {
      swapDispatch({
        type: 'NOTIFY_VIEW_MODE_CHANGED',
        payload: 'simple',
      })
      setViewModeState('simple')
    }
  }, [
    selectedChainId,
    selectedViewMode,
    setViewModeState,
    selectedChain,
    swapDispatch,
  ])

  useEffect(() => {
    swapDispatch({
      type: 'NOTIFY_VIEW_MODE_CHANGED',
      payload: viewModeState,
    })
  }, [viewModeState, swapDispatch])

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!init) {
    console.log('setInit')
    setInit(true)
  }

  let darkTheme = ' text-white bg-gray-700 border border-gray-900 rounded-xl'

  function makeMultiRouteSwapTokenCard() {
    return (
      <MultiRouteSwapTokenCard
        setTokens={(tokens) => {
          userClick.current = true
          dispatch({
            type: 'SWAP_TOKEN',
            payload: tokens,
          })
        }}
        tokens={tokens}
        simpleMode={simpleMode}
        size={size}
        compact={compact}
        chartVisible={chartVisible}
        setChartVisible={setChartVisible}
        emebeddedStyle={emebeddedStyle}
      />
    )
  }

  if (topBarHidden === showTopNavigator) {
    swapDispatch({
      type: 'TOPBAR_STATE_CHANGED',
      payload: !showTopNavigator,
    })
  }

  const { run, setRun } = useTopbar()
  const [stepIndex, setStepIndex] = useState(0)
  const [group1End, setGroup1End] = useState(false)
  const [isNewRoute, setIsNewRoute] = useState(false)

  useEffect(() => {
    if (tourButtonClickChange === 'user') {
      swapDispatch({
        type: 'TOUR_BUTTON_CLICK_CHANGED',
        payload: '',
      })
      setStepIndex(0)
      setGroup1End(false)
      setRun(true)
    }
  }, [loaded, run, setRun, swapDispatch, tourButtonClickChange])

  useEffect(() => {
    if (multiRouteSuccessResponse) {
      setIsNewRoute(true)
    }
  }, [multiRouteSuccessResponse])

  useEffect(() => {
    if (group1End === true && isNewRoute) {
      setRun(true)
      setGroup1End(false)
      setStepIndex(3)
    }
  }, [group1End, isNewRoute, setRun])

  useEffect(() => {
    if (group1End === true && run === false) {
      swapDispatch({
        type: 'TOUR_INFO_CHANGED',
        payload: 'balance',
      })
    }
  }, [group1End, run, swapDispatch])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index, lifecycle } = data
    if (action === ACTIONS.RESET && status === STATUS.READY) {
      setRun(false)
      setGroup1End(false)
      setStepIndex(0)
      console.log('CLEAR')
    } else if (index === 2 && lifecycle === LIFECYCLE.COMPLETE) {
      setIsNewRoute(false)
      setRun(false)
      setGroup1End(true)
      swapDispatch({
        type: 'TOUR_INFO_CHANGED',
        payload: 'balance',
      })
    } else if (action === ACTIONS.NEXT && lifecycle === LIFECYCLE.COMPLETE) {
      setStepIndex(index + 1)
    } else if (action === ACTIONS.NEXT && status === STATUS.FINISHED) {
      setRun(false)
      setIsNewRoute(false)
      setGroup1End(false)
      setStepIndex(0)
    }
  }

  let frontEndDisplayType = selectedChain?.frontEndData.displayType === 'full'
  let isFarmVisible = frontEndDisplayType ? '' : ' hidden'
  let divStyle = frontEndDisplayType ? '' : ''
  let styleExtension =
    selectedViewMode === 'simple' ? ' items-center justify-center' : ''

  let multiRouteDisplayEnabled = selectedChain?.frontEndData.multiRouteEnabled
  let v1VIewStyle = !multiRouteDisplayEnabled && selectedViewMode === 'advance'
  let emebeddedStyleAtt = emebeddedStyle
    ? ''
    : 'bg-gray-800 flex flex-1 flex-row pl-6 pr-6 pb-6 pt-6'

  let enabledAdvanceMode = simpleMode === false && !size

  return (
    <div
      className={
        'flex relative flex-1 justify-center' +
        (emebeddedStyle ? '' : ' h-full')
      }>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={
          selectedViewMode === 'advance' && enabledAdvanceMode ? tutorial1 : tutorial2
        }
        hideBackButton
        hideCloseButton
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#40BE77',
          },
        }}></Joyride>
      <div className={emebeddedStyleAtt}>
        <div
          className={
            'flex flex-1' +
            styleExtension +
            (isMobile
              ? ' flex-col-reverse'
              : ' flex-row' + (emebeddedStyle ? '' : ' space-x-4'))
          }>
          <div
            className={
              'relative flex flex-1 flex-col' +
              divStyle +
              (v1VIewStyle ? '' : '')
            }
            style={{
              display:
                selectedViewMode === 'advance' && enabledAdvanceMode
                  ? 'flex'
                  : 'none',
            }}>
            <Card
              className={
                'chart-section' +
                (simpleMode ? '' : ' min-w-430') +
                (isMobile ? '' : ' w-full') +
                darkTheme +
                isFarmVisible
              }>
              <ChartHeaderView isChartVisible={chartVisible} />
            </Card>
            <div className='flex flex-row flex-1 divide-x divide-gray-600 space-x-4 pt-4'>
              {demo && <MarketTrade />}

              <div
                className={
                  'flex flex-1 flex-col flex-row space-y-4' +
                  (demo ? ' pl-4' : '')
                }>
                {chartVisible && (
                  <div className='flex'>
                    <GroupFarmPriceContent>
                      {token && token2 && (
                        <TradingViewChartContainer
                          tokenFrom={token}
                          tokenTo={token2}
                          symbol={token.symbol}
                        />
                      )}
                    </GroupFarmPriceContent>
                  </div>
                )}
                <RoutingInfoView
                  isMobile={isMobile}
                  innerWidth={windowDimension}
                  demo={demo}
                />
              </div>
            </div>
            =
          </div>
          <Card
            className={'relative min-w-430' + (isMobile ? ' mb-6' : '')}
            style={{
              // minWidth: simpleMode ? 'auto' : '380px',
              // height: v1VIewStyle ? '570px' : 'auto',
              // height: v1VIewStyle ? '570px' : 'auto',
            }}>
            {makeMultiRouteSwapTokenCard()}
          </Card>
        </div>
      </div>
    </div>
  )
}

export const RoutingInfoView: FC<{
  isMobile?: boolean
  innerWidth: number
  hideRouteView?: boolean
  hideFooterSectionView?: boolean
  exchangeOnlyMode?: boolean
  themeDisabled?: boolean
  titleHidden?: boolean
  demo?: boolean
}> = ({
  isMobile,
  innerWidth,
  hideRouteView,
  hideFooterSectionView,
  exchangeOnlyMode,
  themeDisabled,
  titleHidden,
  demo = false,
}) => {
  const { token, token2 } = useFarmPriceState()
  const tokens = [token ?? undefined, token2 ?? undefined]
  const {
    routeDisplayViewModel,
    routeDisplayState,
    multiRouteSuccessResponse,
  } = useSwapGlobalState()
  const [mouseOverTooltip, setMouseOverTooltip] = React.useState(false)
  const [isMouseDown, setIsMouseDown] = React.useState(false)

  const { selectedChainId } = useSwapGlobalState()
  const { chains } = useChainState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  function makeRouteDisplayWithErrorOrNoDataState() {
    if (routeDisplayState === 'no-route') {
      return (
        <NoRouteCard className={isMobile ? '' : ''}>
          <div
            className='flex text-2xl text-gray-500'
            style={{
              width: '100px',
            }}>
            No Route
          </div>
        </NoRouteCard>
      )
    }
    return <></>
  }

  const copyMultiRouteInfoAsJson = useCallback(async () => {
    if (!token) {
      navigator.clipboard.writeText('no token from')
      return
    }
    if (!token2) {
      navigator.clipboard.writeText('no token to')
      return
    }
    if (!multiRouteSuccessResponse || !routeDisplayViewModel) {
      navigator.clipboard.writeText('no route')
      return
    }

    //return payload when click copy
    let data = multiRouteSuccessResponse.payload
    let minAmountOut = multiRouteSuccessResponse.minAmountOut
    let v2 = resolveInput(data, minAmountOut, token, token2)
    if(v2)
      navigator.clipboard.writeText(JSON.stringify(v2, null, 2))
    else
      navigator.clipboard.writeText("ERROR: Payload was not found.")
  }, [token, token2, multiRouteSuccessResponse, routeDisplayViewModel])

  let darkTheme = themeDisabled ? '' : ' text-white' //border border-gray-600 bg-gray-700 rounded-xl
  let routeEnabled = routeDisplayViewModel && routeDisplayState === 'success'
  let exchangeEnabled = selectedChain?.frontEndData.exchangeEnabled

  const { tl } = useTranslationState()
  return (
    <Card className={'flex-1' + (isMobile ? ' p-4' : '') + darkTheme}>
      {selectedChain?.frontEndData.multiRouteEnabled && !routeEnabled ? (
        <div className='mb-8'>{makeRouteDisplayWithErrorOrNoDataState()}</div>
      ) : (
        <div
          className={
            'flex mb-8 justify-center' +
            (routeDisplayViewModel && !hideRouteView ? ' ' : ' hidden')
          }>
          <div className='flex flex-1 flex-col'>
            <div className='flex relative items-start justify-between'>
              {titleHidden ? (
                <div></div>
              ) : (
                <div className='text-xl pl-2'>{tl.routing}</div>
              )}
              <div className='relative'>
                <img
                  data-tooltip-target='tooltip-default'
                  onMouseOver={() => {
                    setMouseOverTooltip(true)
                  }}
                  onMouseOut={() => {
                    setMouseOverTooltip(false)
                    setIsMouseDown(false)
                  }}
                  onMouseDown={() => {
                    setIsMouseDown(true)
                  }}
                  onMouseUp={() => {
                    copyMultiRouteInfoAsJson()
                  }}
                  draggable='false'
                  className='flex cursor-pointer opacity-30 hover:opacity-100 down:opacity-50'
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src={process.env.PUBLIC_URL + 'images/icon_copy.svg'}
                  alt=''></img>
                <Tooltip
                  visible={mouseOverTooltip}
                  label='Click to Copy'
                  mouseDownLabel='Copied'
                  isMouseDown={isMouseDown}
                  top='-40px'
                  left='-70px'
                />
              </div>
            </div>
            {routeDisplayViewModel ? (
              <PathRoutingView
                nodes={[routeDisplayViewModel]}
                fromToken={tokens[0]}
                toToken={tokens[1]}
                isRoot={true}
                currentIndex={'root'}
                isMobile={isMobile}
                innerWidth={innerWidth}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {/* {!hideFooterSectionView && ( */}
        <div>
          <FooterTabView
            exchangeEnabled={exchangeEnabled ?? false}
            selectedChain={selectedChain}
            token2={token2}
            //exchangeOnlyMode={!demo ? true : exchangeOnlyMode ?? false}
            exchangeOnlyMode={false}
            demo={demo}></FooterTabView>
          </div>
      {/* )} */}
    </Card>
  )
}
