import { useMemo, useState } from "react"

const useTopbar = () => {

  const [run, setRun] = useState(false)

  return useMemo(
    () => (
      {
      run,
      setRun,
    }),[run, setRun])
}

export default useTopbar
