import React from 'react'
import styled from 'styled-components'
import { usePopupContext } from '../../contexts/PopupContext'
import { useTranslationState } from 'contexts/TranslationContext'

const InputBox = styled.input.attrs(
  (props: React.InputHTMLAttributes<HTMLInputElement>) => ({
    autoComplete: 'off',
    name: 'amount',
    placeholder: '0.0',
    style: {
      display: 'flex',
      flex: 1,
      textAlign: 'left',
      fontStyle: 'italic',
      color: 'white',
      paddingRight: '4px',
      borderRadius: '4px',
      height: '42px',
      backgroundColor: 'transparent',
    },
    type: 'number',
    pattern: '[0-9]*',
    inputmode: 'numeric',
    className: 'focus:outline-none text-lg pl-3 pr-3',
    ...props,
  })
)``

const SlippageTooltip: React.FC<{
  visible: boolean
}> = ({ visible }) => {
  //useTranslation
  const { tl } = useTranslationState()

  return (
    <div>
      <div
        style={{
          padding: '9px',
          display: visible ? 'block' : 'none',

          top: '0px',
          left: '20px',
          zIndex: 1000,
          borderRadius: '4px',
          border: '1px solid #40BF77',
          background: '#FFFFFF',
          position: 'absolute',
          width: '180px',
        }}>
        <div
          className='text-sm pb-2'
          style={{
            color: '#000000',
          }}>
          {tl.swap_what_is_slippage_title}
        </div>
        <div
          className='text-xs italic'
          style={{
            color: '#97A3A6',
          }}>
          {tl.swap_what_is_slippage_messge}
        </div>
      </div>
    </div>
  )
}

export const SwapConfigPopup = ({
  isManualInput,
  slipPage,
  deadLine,
  onSettingChange,
  onClose,
}: {
  isManualInput: boolean
  slipPage: string
  deadLine: number
  onSettingChange?: (
    newSlipPage: string,
    newDeadLine: number,
    isManualInput: boolean
  ) => void
  onClose?: () => void
}) => {
  //useTranslation
  const { tl } = useTranslationState()
  const [currentIsManualInput, setCurrentIsManualInput] =
    React.useState(isManualInput)
  const [currentSlipPage, setCurrentSlipPage] = React.useState(slipPage)
  const [currentDeadLine,] = React.useState(deadLine)
  const [mouseOverTooltip, setMouseOverTooltip] = React.useState(false)
  const { hidePopup } = usePopupContext()

  function generatePredefineButton() {
    return (
      <div className='flex flex-row justify-between'>
        <button
          onClick={() => {
            setCurrentIsManualInput(false)
            setCurrentSlipPage('0.1')
          }}
          type='button'
          className='w-full text-sm'
          data-testid='withdraw-submit'
          style={{
            fontWeight: 'bold',
            width: '104px',
            height: '42px',
            borderRadius: '4px',
            color:
              currentSlipPage === '0.1'
                ? '#FFFFFF'
                : currentIsManualInput
                ? '#97A3A6'
                : '#40BF77',
            border:
              currentSlipPage === '0.1'
                ? ''
                : currentIsManualInput
                ? '1px solid #97A3A6'
                : '1px solid #40BF77',
            background:
              currentSlipPage === '0.1'
                ? currentIsManualInput
                  ? '#97A3A6'
                  : '#40BF77'
                : '',
          }}>
          0.1 %
        </button>
        <button
          onClick={() => {
            setCurrentIsManualInput(false)
            setCurrentSlipPage('0.5')
          }}
          type='button'
          className='w-full text-sm'
          data-testid='withdraw-submit'
          style={{
            fontWeight: 'bold',
            width: '104px',
            height: '42px',
            borderRadius: '4px',
            color:
              currentSlipPage === '0.5'
                ? '#FFFFFF'
                : currentIsManualInput
                ? '#97A3A6'
                : '#40BF77',
            border:
              currentSlipPage === '0.5'
                ? ''
                : currentIsManualInput
                ? '1px solid #97A3A6'
                : '1px solid #40BF77',
            background:
              currentSlipPage === '0.5'
                ? currentIsManualInput
                  ? '#97A3A6'
                  : '#40BF77'
                : '',
          }}>
          0.5 %
        </button>
        <button
          onClick={() => {
            setCurrentIsManualInput(false)
            setCurrentSlipPage('1')
          }}
          type='button'
          className='w-full text-sm'
          data-testid='withdraw-submit'
          style={{
            fontWeight: 'bold',
            width: '104px',
            height: '42px',
            borderRadius: '4px',
            color:
              currentSlipPage === '1'
                ? '#FFFFFF'
                : currentIsManualInput
                ? '#97A3A6'
                : '#40BF77',
            border:
              currentSlipPage === '1'
                ? ''
                : currentIsManualInput
                ? '1px solid #97A3A6'
                : '1px solid #40BF77',
            background:
              currentSlipPage === '1'
                ? currentIsManualInput
                  ? '#97A3A6'
                  : '#40BF77'
                : '',
          }}>
          1 %
        </button>
      </div>
    )
  }

  const closeButton = () => {
    return (
      <button
        type='button'
        onClick={() => {
          hidePopup('swap')
          if (onClose) onClose()
        }}
        className='farm-price-favorite rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
        <span className='sr-only'>Close menu</span>
        <svg
          style={{ width: '20px', height: '20px' }}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='#40BF77'
          aria-hidden='true'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>
    )
  }

  const saveButton = () => {
    return (
      <div className='flex flex-col' style={{ marginBottom: '24px' }}>
        <div className='flex flex-row justify-center'>
          <button
            onClick={() => {
              if (currentSlipPage && currentDeadLine) {
                if (onSettingChange)
                  onSettingChange(
                    currentSlipPage,
                    currentDeadLine,
                    currentIsManualInput
                  )
                hidePopup('swap')
              }
            }}
            className='w-full text-lg bg-gradient-to-r from-cyan-700 to-green-500'
            data-testid='withdraw-submit'
            type='submit'
            style={{
              fontWeight: 'bold',
              color: 'white',
              // width: '180px',
              height: '42px',
              borderRadius: '8px',
              // background: '#40BF77',
            }}>
            {tl.swap_save_button}
          </button>
        </div>
      </div>
    )
  }

  const input = () => {
    return (
      <div className='flex flex-col' style={{ paddingBottom: '22px' }}>
        <div>
          <div className='flex justify-between items-center pb-1'>
            <div
              className='flex flex-row items-center select-none'
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setCurrentIsManualInput(!currentIsManualInput)
              }}>
              <div
                style={{
                  paddingRight: '8px',
                }}>
                <div
                  style={{
                    marginBottom: '4px',
                    width: '20px',
                    height: '20px',
                    border: '1px solid #40BF77',
                    borderRadius: '2px',
                  }}>
                  <img
                    draggable='false'
                    className='flex'
                    style={{
                      width: '20px',
                      height: '20px',
                      display: currentIsManualInput ? 'block' : 'none',
                    }}
                    src={process.env.PUBLIC_URL + 'images/swaptoken/check.png'}
                    alt=''></img>
                </div>
              </div>
              <div className='text-sm italic' style={{ color: '#97A3A6' }}>
                {tl.swap_manual_input}
              </div>
            </div>
          </div>
          <div className='flex items-center mb-1'>
            <div className='relative flex flex-row items-center flex-1 max-w-lg border border-safegreen rounded'>
              <InputBox
                value={currentSlipPage}
                onChange={(event) => {
                  setCurrentIsManualInput(true)
                  setCurrentSlipPage(event.target.value)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const slipPageView = () => {
    return (
      <div className='flex flex-col' style={{ paddingBottom: '24px' }}>
        <div>
          <div
            className='flex justify-between items-center'
            style={{ paddingBottom: '10px' }}>
            <div className='flex' style={{ flex: 1 }}>
              <div
                data-testid='withdraw-token-name'
                className='text-sm'
                style={{
                  paddingRight: '4px',
                }}>
                {tl.swap_slippage_tolerance}
              </div>

              <div style={{ position: 'relative' }}>
                <button
                  style={{ cursor: 'default' }}
                  onMouseOver={() => {
                    setMouseOverTooltip(true)
                  }}
                  onMouseOut={() => {
                    setMouseOverTooltip(false)
                  }}
                  type='button'
                  onClick={() => {}}>
                  <img
                    draggable='false'
                    className='flex'
                    style={{ width: '16px', height: '16px' }}
                    src={process.env.PUBLIC_URL + 'images/swaptoken/info.png'}
                    alt=''></img>
                </button>
                <SlippageTooltip visible={mouseOverTooltip}></SlippageTooltip>
              </div>
            </div>
          </div>
          {generatePredefineButton()}
        </div>
      </div>
    )
  }

  let popupDarkTheme =
    ' text-white border border-gray-500 bg-gradient-to-b from-gray-600 to-gray-800'

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen p-0 text-center'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <div className='absolute w-full h-full top-0 left-0'>
          <div
            className={
              'inline-block w-full sm:w-min pt-2 overflow-hidden text-left bg-white rounded-lg shadow-xl mb-28 transform transition-all mt-8 sm:mt-32 align-middle' +
              popupDarkTheme
            }
            style={{
              width: '374px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'>
            <div
              style={{
                minHeight: '56px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                style={{
                  marginLeft: '24px',
                  fontSize: '18px',
                  textAlign: 'center',
                  paddingBottom: '10px',
                  flex: '1',
                }}>
                {tl.swap_transaction_setting}
              </div>
              {closeButton()}
            </div>
            <div
              style={{
                display: 'flex',
                height: '2px',
                flex: 1,
                background: '#40BF77',
                marginBottom: '24px',
              }}></div>
            {slipPageView()}
            {input()}
            {saveButton()}
          </div>
        </div>
      </div>
    </div>
  )
}
