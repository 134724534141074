import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import useToken from 'hooks/useToken'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createChart } from 'lightweight-charts'
import { ASSETS_BASE_URL } from 'constants/url.constant'
import { getTokenPrice, getTokenPriceGraph } from 'services/token.service'
import { calPercentTokenPrice, commaStrDecimal } from 'utils/formatter.utils'
import { ChartBar, Token } from 'models/token.model'
import { usePopupContext } from 'contexts/PopupContext'
import { useFarmPriceDispatch } from 'contexts/FarmPriceContext'
import { SWAP_EMBBEDDED_ACCESS_KEY } from 'components/SwapEmbedded'
import { useTokenState } from 'contexts/TokenContext'
import { useChainState } from 'contexts/ChainContext'
import { getChainById } from 'hooks/Chain/useChain'

export const SimpleSwap: FC = () => {
  const DEFAULT_WIDTH = 400
  const DEFAULT_HEIGHT = 440
  const BUTTON_HEIGHT = 42

  const [loaded, setLoaded] = React.useState(false)
  const [init, setInit] = React.useState(false)

  const { selectedChainId, topBarHidden } = useSwapGlobalState()

  const { tokens: allTokens } = useTokenState()
  const { getTokenByAddress } = useToken()
  const [selectedFromToken, setSelectedFromToken] = React.useState<
    Token | undefined
  >()
  const [selectedToToken, setSelectedToToken] = React.useState<
    Token | undefined
  >()

  const { showSwapTokenList, showSwapConfirmDialog } = usePopupContext()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const tokenString = urlParams.get('tokens')

  //Check Selected Chain
  const selectedChainRef = useRef('' + selectedChainId)
  const key = urlParams.get('k')
  let chainId = urlParams.get('chainId')
  if (chainId) {
    //override chain
    selectedChainRef.current = '' + chainId
  }
  const { chains } = useChainState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, +selectedChainRef.current ?? 96)
  }, [chains])

  const dispatch = useFarmPriceDispatch()
  const swapDispatch = useSwapDispatch()
  const selectedTokenRef = useRef<string[] | undefined>()
  const [windowDimension, setWindowDimension] = useState({width: 0, height: 0})

  useEffect(() => {
    setWindowDimension({width: window.innerWidth, height: window.innerHeight})
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension({width: window.innerWidth, height: window.innerHeight})
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    //load chain
    if (init && !loaded && selectedChain && allTokens.length) {
      setLoaded(true)
      //console.log('init and load >>> ', selectedChain)
      if (selectedChain.chainId) {
        swapDispatch({
          type: 'CHAIN_CHANGED',
          payload: +selectedChain.chainId,
        })
      }
      //Check Selected Token
      let iTokens = undefined
      if (tokenString) {
        iTokens = tokenString.split(',')
        if (!selectedTokenRef.current) {
          selectedTokenRef.current = iTokens
          if (selectedTokenRef.current[0]) {
            let token1 = getTokenByAddress(
              allTokens,
              selectedTokenRef.current[0],
              selectedChain.chainId
            )
            setSelectedFromToken(token1)
          }
          if (selectedTokenRef.current[1]) {
            let token2 = getTokenByAddress(
              allTokens,
              selectedTokenRef.current[1],
              selectedChain.chainId
            )
            setSelectedToToken(token2)
          }
        }
      }
    }
  }, [
    allTokens,
    allTokens.length,
    chainId,
    dispatch,
    getTokenByAddress,
    init,
    loaded,
    selectedChain,
    swapDispatch,
    tokenString,
  ])

  let showTopNavigator = false
  if (topBarHidden === showTopNavigator) {
    swapDispatch({
      type: 'TOPBAR_STATE_CHANGED',
      payload: !showTopNavigator,
    })
  }

  let allowToClick = true
  function makeButton(buttonLabel: string, theme: string) {
    return (
      <div className={`flex flex-row flex-1 justify-center`}>
        <button
          className={
            `flex items-center justify-center text-lg rounded-md text-gray-300 font-semibold` +
            theme
          }
          type='submit'
          style={{
            width: '100%',
            height: BUTTON_HEIGHT + 'px',
            opacity: allowToClick ? 1 : 0.5,
            pointerEvents: allowToClick ? 'auto' : 'none',
          }}>
          <div className='flex'>{buttonLabel}</div>
        </button>
      </div>
    )
  }

  const [price, setPrice] = React.useState('-')
  const [percent, setPercent] = React.useState('-')
  const [chartBars, setChartBars] = React.useState<ChartBar[]>([])
  const [priceMovement, setPriceMovement] = React.useState('up')
  const [tokenFrom, setTokenFrom] = React.useState('')
  const [tokenTo, setTokenTo] = React.useState('')

  function convert(str: string) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('-')
  }

  const validateToken = useCallback(
    (tokenAddress: string | undefined, chainId: string) => {
      if (tokenAddress?.toLowerCase() === 'ether') {
        let item = getChainById(chains, +chainId)
        return item?.wrapTokenAddress
      }
      return tokenAddress
    },
    [chains]
  )

  React.useEffect(() => {
    let lightWeightData = chartBars.map((item) => {
      // console.log('item.timestamp', item.timestamp)
      let date = convert(item.timestamp)
      return { time: date, value: item.open + '' }
    })
    let div = document.getElementById('light_chart_div')
    if (div && chartBars) {
      if (div.firstElementChild) div.removeChild(div.firstElementChild)
      var chart = createChart(div, {
        width: windowDimension.width,
        height: windowDimension.height - 250,
        layout: {
          textColor: '#d1d4dc',
          backgroundColor: '#2c375b',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        rightPriceScale: {
          visible: false,
        },
      })
      var areaSeries = chart.addAreaSeries({
        topColor: 'rgba(38, 198, 218, 0.56)',
        bottomColor: 'rgba(38, 198, 218, 0.04)',
        lineColor: 'rgba(38, 198, 218, 1)',
        lineWidth: 2,
        // crossHairMarkerVisible: false,
      })
      areaSeries.setData(lightWeightData)
      chart.timeScale().fitContent()
    }
  }, [chartBars, windowDimension])

  React.useEffect(() => {
    let tokenFromString = selectedFromToken
      ? selectedFromToken.address
      : selectedChain?.frontEndData.defaultSimpleTokens.split(',')[0]
    let tokenToString = selectedToToken
      ? selectedToToken.address
      : selectedChain?.frontEndData.defaultSimpleTokens.split(',')[1]
    if (tokenFromString && tokenToString && price === '-') {
      let validatedFromToken =
        validateToken(tokenFromString, selectedChainRef.current) ?? ''
      let validatedToToken =
        validateToken(tokenToString, selectedChainRef.current) ?? ''

      getTokenPrice(validatedFromToken, validatedToToken).then((data) => {
        let value = data.data.value
        let change = data.data.change
        let priceMovement = data.data.priceMovement
        const percent = calPercentTokenPrice(
          value + '',
          change + '',
          priceMovement
        )
        setPercent(percent)
        setPriceMovement(priceMovement)
        setPrice(commaStrDecimal(`${value}`))
      })
      setTokenFrom(tokenFromString)
      setTokenTo(tokenToString)

      //https://dev.hermesswap.com/api/1/price/token-price-graph/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x55d398326f99059ff775485246999027b3197955?from=2021-04-26T00:00:00.000Z&to=2022-07-19T00:00:00.000Z&minuteTimeFrame=1D&symbol=BTCB
      let fromDate = new Date()
      fromDate.setDate(fromDate.getDate() - 90)
      let toDate = new Date()
      let token1 = getTokenByAddress(
        allTokens,
        tokenFrom ?? '',
        selectedChain?.chainId ?? -1
      )

      getTokenPriceGraph(
        validatedFromToken,
        validatedToToken,
        fromDate.toISOString(),
        toDate.toISOString(),
        '1D',
        token1?.symbol ?? ''
      ).then((data) => {
        let chartData = data.data.chartBars
        setChartBars(chartData)
      })
    }
  }, [
    allTokens,
    getTokenByAddress,
    init,
    loaded,
    price,
    selectedChain,
    selectedFromToken,
    selectedToToken,
    tokenFrom,
    validateToken,
  ])

  if (!init) {
    setInit(true)
  }

  const token1 = getTokenByAddress(
    allTokens,
    tokenFrom ?? '',
    selectedChain?.chainId ?? -1
  )

  const token2 = getTokenByAddress(
    allTokens,
    tokenTo ?? '',
    selectedChain?.chainId ?? -1
  )

  if (token1 === undefined || token2 === undefined) {
    return <></>
  }

  if (key !== SWAP_EMBBEDDED_ACCESS_KEY) {
    return <div />
  }

  const width = Math.max(window.innerWidth, DEFAULT_WIDTH)
  const height = Math.max(window.innerHeight, DEFAULT_HEIGHT)

  let textColor = priceMovement === 'up' ? ' text-green-400' : ' text-red-400'
  const NO_ICON_SOURCE = `${ASSETS_BASE_URL}/assets/images/noimg.png`
  return (
    <div
      className='py-4 text-white'
      style={{
        background: '#2c375b',
        width: width + 'px',
        height: height + 'px'
      }}>
      <div className='px-4 flex flex-row justify-start items-center'>
        <div
          className='px-4 flex flex-row items-center flex-1 hover:bg-cyan-800 rounded-lg cursor-pointer'
          onClick={() => {
            if (token1) {
              showSwapTokenList({
                token: token1.address || '',
                tokenSelectedCallback: (token) => {
                  setPrice('-')
                  setSelectedFromToken(token)
                },
                lockTokens: [],
                size: { width, height },
              })
            }
          }}>
          <img
            className='flex mr-4'
            style={{ width: '52px', height: '52px' }}
            src={token1?.icon ? ASSETS_BASE_URL + token1.icon : NO_ICON_SOURCE}
            alt='logo'
          />
          <div className='flex flex-col pb-2'>
            <div
              style={{
                fontSize: '24px',
              }}>
              {token1.symbol}
            </div>
            <div
              className='bg-cyan-600 rounded-lg'
              style={{
                paddingLeft: '12px',
                paddingRight: '12px',
                fontSize: '12px',
              }}>
              {token1.name}
            </div>
          </div>
        </div>
      </div>
      <div
        className='px-8 flex flex-row justify-center items-end'
        style={{
          fontSize: '40px',
        }}>
        <div className='flex pr-4'>{price}</div>
        <div
          className={'flex pb-2' + textColor}
          style={{
            fontSize: '18px',
          }}>
          {/* +20% */}
          {(priceMovement === 'up' ? '+' : '-') + percent + '%'}
        </div>
      </div>
      <div className='py-4' id='light_chart_div'></div>
      <div className='px-8 flex flex-1 flex-row space-x-8'>
        <div
          className='flex flex-1'
          onClick={() => {
            if (token1 && token2) {
              showSwapConfirmDialog({
                token: token1.address || '',
                tokenSelectedCallback: (token) => {
                  setPrice('-')
                  setSelectedFromToken(token)
                },
                lockTokens: [token2, token1],
                size: { width, height },
                isBuy: true,
              })
            }
          }}>
          {makeButton('Buy', ' bg-gradient-to-r from-cyan-700 to-green-500')}
        </div>
        <div
          className='flex flex-1'
          onClick={() => {
            if (token1 && token2) {
              showSwapConfirmDialog({
                token: token1.address || '',
                tokenSelectedCallback: (token) => {
                  setPrice('-')
                  setSelectedFromToken(token)
                },
                lockTokens: [token1, token2],
                size: { width, height },
                isBuy: false,
              })
            }
          }}>
          {makeButton('Sell', ' bg-gradient-to-r from-pink-500 to-red-500')}
        </div>
      </div>
    </div>
  )
}
