import { Token, TokenPrice, TokenPriceData } from 'models/token.model'
import { TokenImgWithIcon } from '../TokenImg'
import styled from 'styled-components'
import { useMemo } from 'react'

interface Props {
  token: Token
  token2: Token
  tokenPrices: TokenPrice[]
}

interface ClassName {
  className?: string
}

const Header = styled.div.attrs({
  className: 'w-full rounded-sm items-center',
})``

const HeaderInfo = styled.div.attrs({
  className: 'flex font-bold',
})``

// const ColBody = styled.div.attrs<ClassName>({
//   className: 'text-xs',
// })``

// const ColTitle = styled.div.attrs<ClassName>({
//   className: 'text-gray-400 text-xs',
// })``

const TokenSymbol = styled.div.attrs({
  className:
    'text-opacity-100 text-h-color items-end xl:text-2xl lg:text-xl md:text-xl text-md',
})``

// const TokenName = styled.div.attrs({
//   className:
//     'text-opacity-100 text-h-color xl:text-xl lg:text-lg md:text-base text-sm',
// })``

// const Col = styled.div.attrs<ClassName>({
//   className: 'flex-col items-start space-y-1 mx-4',
// })``

export const ChartHeader = (props: Props) => {
  // const { tokenPrices } = useFarmPriceState()

  const tokenData = useMemo<TokenPriceData>(() => {
    const price = props.tokenPrices.find((p) => p.symbol === props.token.symbol)
    return {
      id: props.token.id,
      name: props.token.name,
      address: props.token.address,
      symbol: props.token.symbol,
      isFavorite: false,
      value: price?.value ?? '-',
      priceMovement: price?.priceMovement ?? 'down',
      volume: price?.volume ?? '-',
      low: price?.low ?? '-',
      high: price?.high ?? '-',
      change: price?.change ?? '-',
    }
  }, [props.token.address, props.token.id, props.token.name, props.token.symbol, props.tokenPrices])

  // const priceChange = useMemo(() => {
  //   let value = Number(tokenData.value) ?? 0
  //   let change = Number(tokenData.change) ?? 0
  //   let priceMovement = tokenData.priceMovement

  //   if (!Number.isNaN(change) && !Number.isNaN(value) && change > 0) {
  //     const percent = calPercentTokenPrice(
  //       value + '',
  //       change + '',
  //       priceMovement
  //     )
  //     switch (priceMovement) {
  //       case 'up': {
  //         const prevValue = commaStrDecimal(`${value - change}`)
  //         return `${prevValue} + ${percent}%`
  //       }
  //       case 'down': {
  //         const prevValue = commaStrDecimal(`${value + change}`)
  //         return `${prevValue} - ${percent}%`
  //       }
  //     }
  //   }
  //   return '-'
  // }, [tokenData])

  // const classPriceChange = useMemo(() => {
  //   if (priceChange) {
  //     switch (tokenData.priceMovement) {
  //       case 'up': {
  //         return 'text-primary-green'
  //       }
  //       case 'down': {
  //         return 'text-red-500'
  //       }
  //     }
  //   }
  //   return ''
  // }, [priceChange, tokenData.priceMovement])

  // function getVolumeDisplay() {
  //   if (tokenData.value !== '-' && tokenData.volume !== '-') {
  //     return (+tokenData.volume).toLocaleString()
  //   }
  //   return '-'
  // }

  function getSymBolLabel() {
    return tokenData.symbol + "/" + props.token2.symbol
  }

  return (
    <Header>
      <HeaderInfo>
        {/* <div className='flex space-x-2 items-center pr-8 border-r-2 border-grey-500'> */}
        <div className='flex space-x-2 items-center pr-8 border-grey-500'>
          <div>
            <div style={{marginLeft: '32px'}}>
              <TokenImgWithIcon width='42' height='42' tokenSymbol={props.token2.icon} />
            </div>
            <div style={{marginTop: '-42px'}}>
              <TokenImgWithIcon width='42' height='42' tokenSymbol={props.token.icon} />
            </div>
          </div>
          <div className=''>
            <TokenSymbol>{getSymBolLabel()}</TokenSymbol>
          </div>
        </div>
{/*
        <Col className='flex justify-center'>
          <div className='flex text-lg'>
            {commaStrDecimal(tokenData.value, 12, true)}
          </div>
          <div className={`${classPriceChange} text-sm`}>{priceChange}</div>
        </Col> */}

        {/* <Col className='hidden md:flex'>
          <ColTitle>24H High</ColTitle>
          <ColBody>{commaStrDecimal(tokenData.high)}</ColBody>
        </Col>

        <Col className='hidden md:flex'>
          <ColTitle>24H Low</ColTitle>
          <ColBody>{commaStrDecimal(tokenData.low)}</ColBody>
        </Col>

        <Col className='hidden md:flex'>
          <ColTitle>24H Volume (USD)</ColTitle>
          <ColBody>{getVolumeDisplay()}</ColBody>
        </Col> */}

      </HeaderInfo>
    </Header>
  )
}
export default ChartHeader
