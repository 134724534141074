import axios, { AxiosResponse } from "axios"
import { BACKEND_NODE_BASE_URL } from "constants/url.constant"
import { HashInfo } from "contexts/MyWalletContext";

const HISTORY_PATH = BACKEND_NODE_BASE_URL + '/history'

export const addHistory = async (
  fromAddress: string,
  chainId: number,
  hash: string,
): Promise<AxiosResponse<{ status: string ; message?: any }>> => {
  // console.log("addHistory >>>")
  // console.log("fromAddress", fromAddress)
  // console.log("chainId", chainId)
  // console.log("hash", hash)
  return await axios.post(
    `${HISTORY_PATH}/add`,
    {
      fromAddress: fromAddress,
      chainId: chainId,
      hash: hash
    }
    // {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // }
  )
}

export const getHistory = async (
  fromAddress: string,
  chainId: number
): Promise<AxiosResponse<HashInfo[]>> => {
  return await axios.get(
    `${HISTORY_PATH}/?fromAddress=${fromAddress}&chainId=${''+chainId}`
  )
}
