import React from 'react'
import styled from 'styled-components'

const StyledModal = styled.div.attrs({
  className: 'text-lg rounded-lg bg-gray-700 text-white border border-gray-500',
})`
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  z-index: 40;
  overflow-y: auto;
  width: auto;
  min-width: 360px;
  max-width: 100%;
`

const ModalHeader = styled.div.attrs({
  className: 'border-b border-gray-500',
})`
  display: flex;
  align-items: center;
  padding: 12px 24px;
`

interface Props {
  title?: string
}

const Modal: React.FC<Props> = ({ children, title }) => {
  return (
    <StyledModal>
      {title && <ModalHeader>{title}</ModalHeader>}
      {children}
    </StyledModal>
  )
}

export default Modal
