import { ASSETS_BASE_URL } from '../../constants/url.constant'
import React from 'react'
import styled from 'styled-components'
import { Token } from '../../models/token.model'

const NO_ICON_SOURCE = 'assets/icons/token/noimg.png'

const SymbolImage = styled.img`
  background: url(${NO_ICON_SOURCE}) no-repeat scroll 0 0;
`

const TokenSymbolImg = ({
  token,
  size = 20,
  style,
  ...rest
}: {
  token: Token | undefined | null
  size?: number
} & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [source, setSource] = React.useState(
    token?.icon ? ASSETS_BASE_URL + token.icon : NO_ICON_SOURCE
  )

  React.useEffect(() => {
    setSource(token?.icon ? ASSETS_BASE_URL + token.icon : NO_ICON_SOURCE)
  }, [token])

  return (
    <SymbolImage
      src={source}
      onError={() => setSource(NO_ICON_SOURCE)}
      style={{ ...style, width: size, height: size }}
      {...rest}
    />
  )
}

export default TokenSymbolImg
