import { useState, useEffect } from 'react'

export function useStickyState<S = any>(defaultValue: S, key: string) {
  const [value, setValue] = useState<S>(() => {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue === null ? defaultValue : JSON.parse(stickyValue)
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue] as const
}

export function useSessionState<S = any>(defaultValue: S, key: string) {
  const [value, setValue] = useState<S>(() => {
    const stickyValue = window.sessionStorage.getItem(key)
    return stickyValue === null ? defaultValue : JSON.parse(stickyValue)
  })

  useEffect(() => {
    key && window.sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue] as const
}
