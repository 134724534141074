import TokenSymbolImg from "components/TokenSymbolImg"
import { Token } from "models/token.model"

export const TokenSymbolComboBox: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    token: Token | undefined
    enabled: boolean
  }
> = ({ token, enabled, ...props }) => {
  return (
    <div className='flex items-center'>
      <TokenSymbolImg className='mx-2 mr-1' token={token} />
      <button
        type='button'
        className='text-left'
        style={{
          cursor: enabled ? 'pointer' : 'default',
          // color: enabled ? '#40BF77' : '#484848',
          fontWeight: 'bold',
        }}
        {...props}>
        <div style={{ display: 'flex' }}>
          {token?.symbol || ''}
          <img
            alt=''
            className='flex w-4 h-4 m-auto'
            style={{ display: enabled ? 'block' : 'none' }}
            src={
              process.env.PUBLIC_URL + '/images/swaptoken/arrow_down.png'
            }></img>
        </div>
      </button>
    </div>
  )
}
