import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { connectorLocalStorageKey } from 'constants/local-storage-key.constant'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { Chain } from 'models/chain.model'
import React, { useCallback } from 'react'
import { connectorsByName } from 'utils/connector.utils'
import { getChainById } from './Chain/useChain'
import { ethers } from 'ethers'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const { selectedChainId } = useSwapGlobalState()

  //https://binance-wallet.gitbook.io/binance-chain-wallet/dev/get-started
  const handleBSCWallet = useCallback(
    async (
      chainId: number,
      chains: Chain[],
      callbackWith?: (result: boolean) => void
    ) => {
      const bscConnector = connectorsByName[ConnectorNames.BSC]
      bscConnector.activate()
      let bscProvider = await bscConnector.getProvider()
      let provider = new ethers.providers.Web3Provider(bscProvider)
      console.log('chainId', chainId)

      if (provider) {
        console.log('provider', provider)
        try {

          // await Object(provider).request({
          //   method: 'wallet_switchEthereumChain',
          //   params: [{ chainId: `0x${chainId.toString(16)}` }],
          // })

          // bscProvider.request({method: "eth_sign", params: ["address", "message"]})
          // await provider.send('wallet_switchEthereumChain', [{ chainId: `0x${chainId.toString(16)}` }]);

          // let connectChainId = `0x${chainId.toString(16)}`
          // // console.log("connectChainId", connectChainId)
          // // await bscConnector.changeChainId(chainId)
          // await bscConnector.initChain(chainId)
          // await bscConnector.connect()
          // console.log('Switched to network with chain ID:', chainId)
          // You can perform further operations on the switched network

          await Object(bscProvider).request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          })

          if (callbackWith) {
            callbackWith(true)
          }

          return true
        } catch (switchError) {

          console.log("switchError", switchError)

          console.log(
            'useAuth setupNetwork => ERROR CODE = ',
            (switchError as any)?.code
          )

          if ((switchError as any)?.code === 4902) {
            console.error(
              'useAuth ERROR there is no selected chain in Metamask'
            )

            let chainName = ''
            let nativeCurrency
            let rpcUrls: string[] = []
            let blockExplorerUrls: string[] = []
            let chain = getChainById(chains, chainId)

            if (!chain) {
              if (callbackWith) {
                callbackWith(false)
              }
              return
            }

            if (chain) {
              chainName = chain.name
              rpcUrls = chain.rpcUrls
              blockExplorerUrls = chain.frontEndData.blockExplorerUrls
              nativeCurrency = chain.frontEndData.nativeCurrency
            }

            try {
              console.log('setupNetwork added', chainId)
              await Object(provider).request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: `0x${chainId.toString(16)}`,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrls,
                    blockExplorerUrls: blockExplorerUrls,
                  },
                ],
              })

              if (callbackWith) {
                console.log(
                  'useAuth setupNetwork added chainId',
                  chainId,
                  ' successfully'
                )
                callbackWith(true)
              }
              return
            } catch (error) {
              if (callbackWith) {
                callbackWith(false)
              }
              console.error(
                'useAuth ERROR setup the network in Metamask',
                error
              )
              return
            }
          }
          if (callbackWith) {
            callbackWith(false)
          }
        }
      } else {
        console.error(
          "useAuth Can't setup the network on metamask because window.ethereum is undefined"
        )
        if (callbackWith) {
          callbackWith(false)
        }
      }
    },
    []
  )

  const handleMetaMask = useCallback(
    async (
      chainId: number,
      chains: Chain[],
      callbackWith?: (result: boolean) => void
    ) => {
      const provider = window.ethereum

      if (provider) {
        console.log('setupNetwork to chainId', chainId)
        try {
          await Object(provider).request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          })

          if (callbackWith) {
            callbackWith(true)
          }

          return true
        } catch (switchError) {
          console.log(
            'useAuth setupNetwork => ERROR CODE = ',
            (switchError as any)?.code
          )

          if ((switchError as any)?.code === 4902) {
            console.error(
              'useAuth ERROR there is no selected chain in Metamask'
            )

            let chainName = ''
            let nativeCurrency
            let rpcUrls: string[] = []
            let blockExplorerUrls: string[] = []
            let chain = getChainById(chains, chainId)

            if (!chain) {
              if (callbackWith) {
                callbackWith(false)
              }
              return
            }

            if (chain) {
              chainName = chain.name
              rpcUrls = chain.rpcUrls
              blockExplorerUrls = chain.frontEndData.blockExplorerUrls
              nativeCurrency = chain.frontEndData.nativeCurrency
            }

            try {
              console.log('setupNetwork added', chainId)
              await Object(provider).request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: `0x${chainId.toString(16)}`,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrls,
                    blockExplorerUrls: blockExplorerUrls,
                  },
                ],
              })

              if (callbackWith) {
                console.log(
                  'useAuth setupNetwork added chainId',
                  chainId,
                  ' successfully'
                )
                callbackWith(true)
              }
              return
            } catch (error) {
              if (callbackWith) {
                callbackWith(false)
              }
              console.error(
                'useAuth ERROR setup the network in Metamask',
                error
              )
              return
            }
          }
          if (callbackWith) {
            callbackWith(false)
          }
        }
      } else {
        console.error(
          "useAuth Can't setup the network on metamask because window.ethereum is undefined"
        )
        if (callbackWith) {
          callbackWith(false)
        }
      }
    },
    []
  )

  const setupNetwork = useCallback(
    async (
      chainId: number,
      chains: Chain[],
      callbackWith?: (result: boolean) => void
    ) => {
      //metamask
      const connectorID = window.localStorage.getItem('loginType')
      if (connectorID === ConnectorNames.Injected) {
        console.log('CALL handleMetaMask')
        handleMetaMask(chainId, chains, callbackWith)
      } else if (connectorID === ConnectorNames.BSC) {
        console.log('CALL handleBSCWallet')
        handleBSCWallet(chainId, chains, callbackWith)
      } else if (connectorID === ConnectorNames.WalletConnect) {
        console.log('CALL handleBSCWallet')
        handleMetaMask(chainId, chains, callbackWith)
      }
    },
    [handleBSCWallet, handleMetaMask]
  )

  const swapDispatch = useSwapDispatch()
  const setEventListener = useCallback(async (connectorID: ConnectorNames) => {
    if (connectorID === ConnectorNames.Injected) {
      const provider = Object(window).ethereum
      if (provider) {
        provider.on('chainChanged', (_chainId: string) => {
          setChaiID(+parseInt('' + _chainId, 16))
          console.log("NETWORK_CHANGE ConnectorNames.Injected:", _chainId)
          swapDispatch({
            type: 'NETWORK_CHANGE',
            payload:  new Date().getTime(),
          })
        });
      }
    } else if (connectorID === ConnectorNames.BSC) {
      const bscConnector = connectorsByName[ConnectorNames.BSC]
      const provider = await bscConnector.getProvider()
      if (provider){
        provider.on('chainChanged', (_chainId: string) => {
          setChaiID(+parseInt('' + _chainId, 16))
          swapDispatch({
            type: 'NETWORK_CHANGE',
            payload:  new Date().getTime(),
          })
        });
      }
    } else if (connectorID === ConnectorNames.WalletConnect) {
      const provider = Object(window).ethereum
      if (provider) {
        provider.on('chainChanged', (_chainId: string) => {
          setChaiID(+parseInt('' + _chainId, 16))
          swapDispatch({
            type: 'NETWORK_CHANGE',
            payload:  new Date().getTime(),
          })
        });
      }
    }
  }, [swapDispatch])

  const login = useCallback(
    (
      connectorID: ConnectorNames,
      fromLocalSaved: boolean,
      chains: Chain[],
      retry: boolean = false
    ) => {
      console.log('CALL login')
      const connector = connectorsByName[connectorID]
      console.log('connectorID', connectorID)
      window.localStorage.setItem('loginType', connectorID)
      console.log('connector', connector)

      if (connector) {
        console.log("CALL Activate")
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            if (!fromLocalSaved && !retry) {
              setupNetwork(selectedChainId, chains, function (success) {
                if (success) {
                  login(connectorID, false, chains, true)
                }
              })
            }
          } else {
            //OTHER ERROR
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (
              error instanceof NoEthereumProviderError ||
              error instanceof NoBscProviderError
            ) {
              console.warn('Provider Error, No provider was found')
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = null
                console.warn(
                  'Authorization Error Please authorize to access your account'
                )
              } else {
                console.warn(error.name, error.message)
              }
            }
          }
        }).then(async () => {
          console.log('useAuth login SUCCESS', "connectorID: ", connectorID)
          window.localStorage.setItem(connectorLocalStorageKey, connectorID)
          if (connectorID === ConnectorNames.Injected) {

            let chainId = +Object(window?.ethereum).networkVersion
            console.log('CASE ConnectorNames.Injected', ", connectorID: ", chainId)
            setChaiID(chainId)
          } else if (connectorID === ConnectorNames.BSC) {

            const bscConnector = connectorsByName[ConnectorNames.BSC]
            const provider = await bscConnector.getProvider()
            if (provider) {
              bscConnector.getChainId().then((chainId: any) => {
                console.log("bscConnector.getChainById", chainId)
                setChaiID(+parseInt('' + chainId, 16))
              })
            }
          } else if (connectorID === ConnectorNames.WalletConnect) {
            let chainId = +Object(window?.ethereum).networkVersion
            setChaiID(chainId)
          }

          setEventListener(connectorID)
        })
      } else {
        console.warn('Unsupported connector')
      }
    },
    [activate, selectedChainId, setEventListener, setupNetwork]
  )

  const setChaiID = (chainId?: number) => {
    if (chainId) window.localStorage.setItem('chainId', '' + chainId)
  }

  const logout = useCallback(() => {
    deactivate()
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close()
      connectorsByName.walletconnect.walletConnectProvider = null
    }
    window.localStorage.removeItem(connectorLocalStorageKey)
  }, [deactivate])

  return React.useMemo(
    () => ({
      login,
      logout,
      setupNetwork,
    }),
    [login, logout, setupNetwork]
  )
}

export default useAuth
