import axios from 'axios'
import { getEthersProvider } from './ethers.service'

import { BACKEND_PYTHON_BASE_URL } from 'constants/url.constant'
import {
  MultiRouteErrorResponse,
  MultiRouteResponse,
} from 'models/swap-multiroute.model'
import { Chain } from '../models/chain.model'
import { callContractProgress } from '../models/contract.model'
import {
  callContractMethod,
  makeCallContractResponse,
} from './contract.service'
import HERMES_ABI from 'constants/abi/reduced-hermes-router-abi.json'
import {
  EncodeFunctionArgs,
  RouteInfoV2,
  SmartContactInputV2,
} from 'models/swap.model'
import { DexOutPutReturnType } from 'hooks/Swap/DexOutPutReturnType'
import { Bytes, Interface } from 'ethers/lib/utils'
import { ethers } from 'ethers'
import { Token } from 'models/token.model'
import { alert_message_user_wallet } from 'contexts/TranslationContext'

export const getMultiRouteInfo = async (
  userAddress: string | null | undefined,
  fromTokenAddress: string,
  toTokenAddresss: string,
  amountFrom: string,
  slippage: string,
  lastAmountOut: string | null,
  dexOutPutReturnType: DexOutPutReturnType,
  chainId?: string | null
): Promise<MultiRouteResponse | MultiRouteErrorResponse> => {
  if (!fromTokenAddress || !toTokenAddresss) {
    return Promise.reject()
  }
  try {
    let extraParam = lastAmountOut ? '&lastAmountOut=' + lastAmountOut : ''
    extraParam += chainId ? '&chainId=' + chainId : ''
    extraParam += slippage ? '&slippage=' + +slippage / 100 : ''
    extraParam +=
      '&optimizeLowestGas=' + (dexOutPutReturnType === 'max-return' ? 0 : 1)
    const { data } = await axios.get<MultiRouteResponse>(
      `${BACKEND_PYTHON_BASE_URL}/dex-aggregator?address=${
        userAddress ?? ''
      }&fromToken=${fromTokenAddress}&toToken=${toTokenAddresss}&amountIn=${amountFrom}${extraParam}`
    )
    //console.log("getOptimalMultiRouteSwapInfo : ", data)
    return data
  } catch (e) {
    return JSON.parse(JSON.stringify(e)) as MultiRouteErrorResponse
  }
}

export const swapMultiRoute = async (
  chainInfo: Chain,
  minAmountOut: number,
  fromToken: Token,
  toToken: Token,
  data: RouteInfoV2,
  progressFunction?: (response: callContractProgress) => void
) => {
  console.log("swapMultiRoute >>>")
  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  let input = resolveInput(data, minAmountOut, fromToken, toToken)
  let routerAddress = chainInfo.dexAggregatorContract
  let routerABI = HERMES_ABI

  return callContractMethod(
    routerAddress,
    routerABI,
    'execute(program)',
    {
      program: input,
    },
    progressFunction
  )
}

const toBytes = (text: string): Bytes[] => {
  const buffer = Buffer.from(text, 'utf8')
  const result = Array(buffer.length)
  for (let i = 0; i < buffer.length; ++i) {
    result[i] = buffer[i]
  }
  return result
}

export const resolveInput = (
  data_in: RouteInfoV2,
  minAmountOut: number,
  fromToken: Token,
  toToken: Token
  ) => {
  let data = data_in
  // let data = test_data.payload
  console.log('data_in', data)
  let steps: EncodeFunctionArgs[] = data.steps.map((step) => {
    //step.abi.inputs = step.abi.inputs.splice(0, 4)
    //step.funcArgs = Object(step.funcArgs).splice(0, 4)

    let ABI = [step.abi]
    const iface = new Interface(ABI)
    //check bytes[] format
    let index = 0
    let convertedfuncArgs: any[] = []

    // step.part = Math.round(step.part * 100)
    let stepPart = Math.round(step.part * 100)
    step.abi.inputs.forEach((element: any) => {
      if (element.type === 'bytes[]') {
        let str = step.funcArgs[index]
        convertedfuncArgs.push(toBytes(str))
      } else {
        convertedfuncArgs.push(step.funcArgs[index])
      }
      index++
    })

    console.log('convertedfuncArgs', convertedfuncArgs)

    const data = iface.encodeFunctionData(step.functionName, convertedfuncArgs)
    console.log('data', data)
    return {
      part: convertToEther(''+stepPart),
      target: step.target,
      fromTokenAddress: step.fromTokenAddress,
      data: data,
    }
  })

  console.log('data.amount', data.amount)
  let amountBigNumber = convertToEther('' + numberToNonExpo(data.amount), fromToken.decimals)
  console.log('amount', amountBigNumber)

  let expectAmountOutInEther = numberToNonExpo(minAmountOut * Math.pow(10, +toToken.decimals))
  let expectAmountOutBigNumber = convertToEther('' + expectAmountOutInEther, toToken.decimals)
  console.log('expectAmountOut', minAmountOut)

  let result: SmartContactInputV2 = {
    ...data,
    steps: steps,
    amount: amountBigNumber,
    expectAmountOut: expectAmountOutBigNumber,
  }
  console.log('SmartContactInputV2 data', result)
  return result
}

const numberToNonExpo = (val: number) => {
  return Math.ceil(val).toLocaleString('fullwide', { useGrouping: false })
}

const convertToEther = (value: string, decimal?: string) => {
  console.log("\n\n==convertToEther==")
  console.log("value", value)
  console.log("decimal", decimal)

  return ethers.BigNumber.from(value)
}
