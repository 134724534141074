import BigNumber from 'bignumber.js'
import { isArray } from 'lodash'
import React from 'react'
import { usePopupContext, WithdrawInfo } from '../../contexts/PopupContext'
import useWithdraw from '../../hooks/useWithdraw'
import {
  formatBalance,
  formatInputNumber,
  unformatInputNumber,
} from '../../utils/formatter.utils'
import NumberSlider from '../NumberSlider'
const WithdrawPopup = ({
  withdrawInfo,
  onComplete,
  onClose,
}: {
  withdrawInfo: WithdrawInfo
  onComplete?: () => void
  onClose?: () => void
}) => {
  const [amount, setAmount] = React.useState('')
  const { hidePopup } = usePopupContext()

  const callWithdraw = useWithdraw()

  function onAmountChange(newValue: string) {
    const amount = unformatInputNumber(newValue)
    const totalBalance = new BigNumber(withdrawInfo.totalBalance)
    if (amount.isGreaterThan(totalBalance)) {
      newValue = withdrawInfo.totalBalance
    } else if (amount.isLessThan(0)) {
      newValue = '0'
    }
    setAmount(newValue)
  }

  // const amountNum = +amount || 0
  const balanceNumber = +withdrawInfo.totalBalance || 0

  // show action not fill amount
  const onInvalidAmount = (state: boolean) => {
    let elemAmount = document.getElementById('withdraw-amount')
    if (elemAmount !== null) {
      if (state) {
        elemAmount.classList.remove('border-gray-400')
        elemAmount.classList.add('border-red-500')
      } else {
        elemAmount.classList.remove('border-red-500')
        elemAmount.classList.add('border-gray-400')
      }
    }
  }

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'>
          &#8203;
        </span>
        <div
          className='inline-block w-full overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl mb-28 transform transition-all sm:my-8 sm:align-middle md:max-w-md md:w-full'
          style={{ width: '479px', height: '215px' }}
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (onClose) onClose()

              const val = unformatInputNumber(amount)
              callWithdraw(
                withdrawInfo.farmInfo,
                withdrawInfo.farmDetail,
                withdrawInfo.poolid,
                val.toString()
              ).then(onComplete)
            }}
            className='flex flex-col'
            style={{
              paddingLeft: '32px',
              paddingRight: '16px',
              paddingTop: '10px',
            }}>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row items-center'>
                <p data-testid='withdraw-title' style={{ fontSize: '18px' }}>
                  Withdraw
                </p>
              </div>
              <button
                type='button'
                onClick={() => {
                  hidePopup('withdraw')
                  if (onClose) onClose()
                }}
                className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                <span className='sr-only'>Close menu</span>
                <svg
                  style={{ width: '20px', height: '20px' }}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='#40BF77'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            <div
              className='flex flex-col'
              style={{ paddingRight: '16px', paddingTop: '5px' }}>
              <div className='flex flex-col'>
                <div
                  className='flex justify-between items-center'
                  style={{ paddingBottom: '3px' }}>
                  <div className='flex'>
                    <div
                      data-testid='withdraw-token-name'
                      className='font-bold'
                      style={{ fontSize: '14px', color: '#40BF77' }}>
                      {' '}
                      {withdrawInfo.farmInfo.tokenName}{' '}
                    </div>
                  </div>
                  <div
                    data-testid='withdraw-balance'
                    style={{
                      fontSize: '12px',
                      fontStyle: 'italic',
                      color: '#808080',
                    }}>
                    Balance : {formatBalance(withdrawInfo.totalBalance + '')}{' '}
                  </div>
                </div>

                <div className='relative flex flex-row items-center'>
                  <input
                    autoComplete='off'
                    type='number'
                    id='withdraw-amount'
                    name='amount'
                    placeholder='Amount'
                    onInvalid={() => {
                      onInvalidAmount(true)
                    }}
                    required={true}
                    className='flex flex-row w-full focus:outline-none border-2 border-gray-400'
                    style={{
                      fontSize: '14px',
                      paddingLeft: '12px',
                      paddingRight: '50px',
                      height: '36px',
                      borderRadius: '4PX',
                    }}
                    value={formatInputNumber(amount)}
                    onChange={({ currentTarget: { value: newValue } }) => {
                      onAmountChange(newValue)
                      onInvalidAmount(false)
                    }}></input>
                  <button
                    type='button'
                    className='absolute font-bold focus:outline-none'
                    style={{
                      fontSize: '14px',
                      right: '12px',
                      color: '#40BF77',
                    }}
                    onClick={() => {
                      setAmount(withdrawInfo.totalBalance)
                    }}>
                    MAX
                  </button>
                </div>
              </div>

              <div style={{ paddingLeft: '7px', paddingRight: '4px' }}>
                <NumberSlider
                  aria-label='Amount %'
                  value={new BigNumber(
                    balanceNumber > 0
                      ? ((+amount || 0) / balanceNumber) * 100
                      : 0
                  )
                    .dp(2)
                    .toNumber()}
                  onChange={(_, newValue) => {
                    const val = isArray(newValue) ? newValue[0] : newValue
                    const newAmount = new BigNumber(val)
                      .dividedBy(100)
                      .multipliedBy(withdrawInfo.totalBalance)
                    onAmountChange(newAmount.toString())
                    onInvalidAmount(false)
                  }}></NumberSlider>
              </div>

              <div className='flex flex-row justify-center'>
                <button
                  data-testid='withdraw-submit'
                  type='submit'
                  style={{
                    fontSize: '14px',
                    color: 'white',
                    width: '112px',
                    height: '26px',
                    borderRadius: '8px',
                    background: '#40BF77',
                  }}>
                  Withdraw
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default WithdrawPopup
