import _ from 'lodash'
import { getTokens } from 'services/token.service'
import { getTokenPriceGraph } from '../../services/token.service'
import { ChartBar, Token } from 'models/token.model'
import { Chain } from 'models/chain.model'
import { PriceModel } from 'contexts/FarmPriceContext'

export function parseFullSymbol(fullSymbol: any) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return null
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  }
}

export const configurationData = {
  supports_search: false,
  supports_group_request: false,
  // supported_resolutions: ['15', '30', '60', '1D', '1M', '1W'],
  // supported_resolutions: ['30', '60', '1D', '1M', '1W'],
  supported_resolutions: ['1D', '1M', '1W'],
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
  has_intraday: true,
}

export const getChartBy = () => {}

export const getDataFeed = (
  setSelectedTimeFrame: any,
  selectedChain: Chain | undefined,
  tokenFrom: Token,
  tokenTo: Token,
  syncTime: (price: PriceModel) => void,
  setUpdateRealTimeChart: (updateRealTimeFunction: () => void) => void,
  updateChartPriceFormatter: (isLessThenZero: boolean) => void,
  onChartLoad: (
    tokenFrom: Token,
    tokenTo: Token,
    date: string,
    count: number
  ) => void
) => {
  return {
    onReady: (callback: any) => {
      console.log('[onReady]: Method call')
      setTimeout(() => callback(configurationData))
    },
    searchSymbols: (
      userInput: any,
      exchange: any,
      symbolType: any,
      onResultReadyCallback: any
    ) => {
      console.log('[searchSymbols]: Method call')
      onResultReadyCallback([])
    },
    resolveSymbol: (
      symbolName: any,
      onSymbolResolvedCallback: any,
      onResolveErrorCallback: any
    ) => {
      const symbolInfo = {
        name: symbolName,
        has_daily: true,
        ticker: '6',
        has_weekly_and_monthly: true,
        minmov2: 0,
        timezone: 'Asia/Bangkok',
        // session: '0001-2330', //ทำให้ chart แสดงผลเริ่มที่ 00.01 แทน 00.00
        has_intraday: true, //ดูช่วงเวลาใน 1 วันได้ ทำให้ 15mins 30mins ทำงานถูกต้อง
        description: symbolName, //แสดง symbol มุมซ้าย Chart
        supported_resolutions: configurationData.supported_resolutions,
        type: 'stock',
        'exchange-listed': '6',
        pointvalue: 1,
        'exchange-traded': '6',
        minmov: 1,
        pricescale: 100,
        has_no_volume: false, //ไม่แสดง config volume
      }
      console.log('[resolveSymbol]: Method call', symbolName)
      onSymbolResolvedCallback(symbolInfo)
    },
    getBars: async (
      symbolInfo: any,
      resolution: any,
      periodParams: any,
      onHistoryCallback: any,
      onErrorCallback: any
    ) => {
      const { from, to } = periodParams
      setSelectedTimeFrame(resolution)

      var currentDateUTC = new Date()
      currentDateUTC.setMinutes(
        currentDateUTC.getMinutes() - currentDateUTC.getTimezoneOffset()
      )

      getPriceChart(
        from,
        to,
        symbolInfo,
        resolution,
        tokenFrom,
        tokenTo,
        selectedChain,
        (
          status: 'success' | 'error',
          bars: any[],
          props: { noData: boolean },
          error: any
        ) => {
          if (status === 'success') {
            let lasttime = bars[bars.length - 1]
            onHistoryCallback(bars, props)

            if (lasttime >= currentDateUTC.getTime()) {
              // console.log("lasttime >>", lasttime)
              // console.log("syncTime >>")
              syncTime(lasttime)
            }
          } else {
            onErrorCallback(error)
          }
        },
        false,
        updateChartPriceFormatter,
        onChartLoad
      )
    },
    subscribeBars: (
      symbolInfo: any,
      resolution: any,
      onRealtimeCallback: any,
      subscribeUID: any,
      onResetCacheNeededCallback: any
    ) => {
      const getPrice = () => {
        var date1 = new Date()
        date1.setMinutes(date1.getMinutes() - date1.getTimezoneOffset())
        date1.setDate(date1.getDate() - 7)

        var date2 = new Date()
        date2.setMinutes(date2.getMinutes() - date2.getTimezoneOffset())

        var from = date1.getTime() / 1000
        var to = date2.getTime() / 1000

        getPriceChart(
          from,
          to,
          symbolInfo,
          resolution,
          tokenFrom,
          tokenTo,
          selectedChain,
          (
            status: 'success' | 'error',
            bars: any[],
            props: { noData: boolean },
            error: any
          ) => {
            if (status === 'success') {
              if (bars.length) {
                let lasttime = bars[bars.length - 1]
                lasttime.time = to * 1000
                // console.log("lasttime >>", lasttime)
                // console.log("syncTime >>")
                syncTime(lasttime)
                onRealtimeCallback(lasttime)
              }
            }
          },
          true,
          updateChartPriceFormatter,
          onChartLoad
        )
      }
      setUpdateRealTimeChart(getPrice)
    },
    unsubscribeBars: (subscriberUID: any) => {
      console.log(
        '[unsubscribeBars]: Method call with subscriberUID:',
        subscriberUID
      )
    },
  }
}

export const getPriceChart = async (
  from: number,
  to: number,
  symbolInfo: any,
  resolution: any,
  tokenFrom: Token,
  tokenTo: Token,
  selectedChain: Chain | undefined,
  callback: (
    status: 'success' | 'error',
    bars: any[],
    props: { noData: boolean },
    error?: any
  ) => void,
  isRealTime: boolean,
  updateChartPriceFormatter: (isLessThenZero: boolean) => void,
  onChartLoad: (
    tokenFrom: Token,
    tokenTo: Token,
    date: string,
    count: number
  ) => void
) => {
  const { name: symbol } = symbolInfo

  let tokenFromAddress = tokenFrom.address
  let tokenToAddress = tokenTo.address
  if (tokenFromAddress.toLowerCase() === 'ether' && selectedChain) {
    tokenFromAddress = selectedChain.wrapTokenAddress.toLowerCase()
  }
  if (tokenToAddress.toLowerCase() === 'ether' && selectedChain) {
    tokenToAddress = selectedChain.wrapTokenAddress.toLowerCase()
  }

  var date1 = new Date(from * 1000)
  var date2 = new Date(to * 1000)
  var dateInput1 = date1.toISOString()
  var dateInput2 = date2.toISOString()

  try {
    const allTokens = await getTokens()
    const token = allTokens.find((t) => t.symbol === symbol)
    if (!token) {
      onChartLoad(tokenFrom, tokenTo, dateInput1 + '_' + dateInput2, 0)
      callback('success', [], { noData: true })
      return
    }

    const price = await getTokenPriceGraph(
      tokenFromAddress,
      tokenToAddress,
      dateInput1,
      dateInput2,
      resolution,
      symbol
    )

    let chartBars = price.data.chartBars
    const data: any[] = chartBars ?? []
    _.uniqBy(data, 'time')
    // console.log("\n\n\nfrom >>>", from)
    // console.log("to >>>", to)
    // console.log("dateInput1 >>>", dateInput1)
    // console.log("dateInput2 >>>", dateInput2)
    // console.log("\n\n\nisRealTime >>>", isRealTime)
    // console.log("chartBars >>>", chartBars)

    if (data.length === 0) {
      // console.log('getPriceChart >> noData')
      onChartLoad(tokenFrom, tokenTo, dateInput1 + '_' + dateInput2, 0)
      callback('success', [], { noData: true })
      return
    }

    //Prepare Data
    let bars: any[] = []
    let isLessThanZero = false
    if (isRealTime) {
      var latestBar = null
      if (data.length > 0) {
        latestBar = data[data.length - 1]
      }
      console.log('Real time update >>>', latestBar)
      if (latestBar) {
        bars = [
          ...bars,
          {
            time: +latestBar.timestamp,
            low: latestBar.low,
            high: latestBar.high,
            open: latestBar.open,
            close: latestBar.close,
          },
        ]
      }
    } else {
      data.forEach(function (bar: ChartBar) {
        const time = +bar.timestamp / 1000
        if (time >= from && time < to) {
          isLessThanZero = +bar.open < 1
          isLessThanZero = isLessThanZero && +bar.close < 1

          // let low = bar.low
          // if (isLessThanZero) {

          // }
          // console.log("bar.low", (+bar.low).toFixed(10))
          // console.log("bar.high", (+bar.high).toFixed(10))
          // console.log("bar.open", (+bar.open).toFixed(10))
          // console.log("bar.close", (+bar.close).toFixed(10))

          bars = [
            ...bars,
            {
              time: +bar.timestamp,
              // low: (+bar.low).toFixed(10),
              // high: (+bar.high).toFixed(10),
              // open: (+bar.open).toFixed(10),
              // close: (+bar.close).toFixed(10),
              // low: 0.00002222,
              // high: 0.00002222,
              // open: 0.00002222,
              // close: 0.00002222,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            },
          ]
        }
      })
      // console.log("getPriceChart >>>", bars)
    }

    updateChartPriceFormatter(isLessThanZero)
    // console.log('getPriceChart >>', bars.length, bars)
    onChartLoad(tokenFrom, tokenTo, dateInput1 + '_' + dateInput2, bars.length)
    callback('success', bars, { noData: false })
  } catch (error) {
    // console.log('getPriceChart >> error')
    onChartLoad(tokenFrom, tokenTo, dateInput1 + '_' + dateInput2, 0)
    callback('success', [], { noData: true })
    //callback('error', [], {noData: false}, error)
  }
}
