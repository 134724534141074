/* eslint-disable jsx-a11y/alt-text */
import { Input, Tooltip } from '@material-ui/core'
import axios from 'axios'
import { MenuDropDown } from 'components/MenuDropDown'
import { TreeMap } from 'components/TreeMap'
import { BACKEND_NODE_BASE_URL } from 'constants/url.constant'
import { useChainState } from 'contexts/ChainContext'
import { usePopupContext } from 'contexts/PopupContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import usedChain, { getChainById } from 'hooks/Chain/useChain'
import { useSessionState } from 'hooks/useStickyState'
import useToken from 'hooks/useToken'
import { createChart } from 'lightweight-charts'
import { Chain } from 'models/chain.model'
import { Token } from 'models/token.model'
import moment from 'moment'
import React, { FC, useCallback, useMemo, useState } from 'react'

export type CoinInfo = {
  id: string
  image: string
  symbol: string
  name: string
  price_change_24h: number
  price_change_percentage_24h: number
  price_change_percentage_7d: number
  price_change_percentage_30d: number
  market_cap_change_percentage_7d: number
  market_cap_change_percentage_24h: number
  market_cap_change_percentage_30d: number
  volume_change_percentage_1d: number
  volume_change_percentage_7d: number
  volume_change_percentage_30d: number
  current_price: number
  market_cap: number
  total_volume: number
  price_graph: number[]
  market_cap_graph: number[]
  volume_graph: number[]
  cdc_v3: CDC_V3[]
}

type CDC_V3 = {
  signal: string
  date: string
  price: number
}

type ExchangeInfo = {
  image: string
  name: string
  quote: QuoteUSD
  rank: number
  exchange_score: number
  // year_established: string
  // trust_score: number
  // trust_score_rank: number
  // trade_volume_24h_btc: number
  // url: string
}

type QuoteUSD = {
  USD: Quote
}

type Quote = {
  volume_24h: number
  volume_24h_adjusted: number
  volume_7d: number
  volume_30d: number
  percent_change_volume_24h: number
  percent_change_volume_7d: number
  percent_change_volume_30d: number
  effective_liquidity_24h: number
  derivative_volume_usd: number
  spot_volume_usd: number
}

// type GlobalInfo = {
//   data: GlobalMarketData
// }

// type GlobalMarketData = {
//   total_market_cap: { [index: string]: number }
//   total_volume: { [index: string]: number }
//   market_cap_percentage: { [index: string]: number }
// }

export const MarketPrice: FC<{}> = () => {
  const [cryptoSelectedIndex, setCryptoSelectedIndex] = useState(-1)
  const [favoriteCoins, setFavoriteCoins] = useSessionState('', 'favorite-coin')

  const [BTCPrice, setBTCPrice] = useState(0)

  const [menu, setMenu] = useState('crypto')
  const [searchText, setSearchText] = useState('')
  const [chainMenu, setChainMenu] = useState('56')

  const { tokens } = useTokenState()
  const { chains } = useChainState()
  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const [coins, setCoins] = useState<CoinInfo[]>([])
  const [exchanges, setExchanges] = useState<ExchangeInfo[]>([])
  // const [globalData, setGlobalData] = useState<GlobalInfo>()
  const [pageIndex, setPageIndex] = useState(0)

  const favouriteList = React.useMemo(() => {
    return favoriteCoins.split(',')
  }, [favoriteCoins])

  const isFavorite = React.useCallback(
    (symbol: string) => {
      return favouriteList.indexOf(symbol.toLowerCase()) >= 0
    },
    [favouriteList]
  )

  const PAGE_COUNT = 50
  const getCoinsList = useCallback(
    async (startIndex: number, searchText: string): Promise<CoinInfo[]> => {
      console.log('getCoinsList', startIndex)
      try {
        console.log('BF startIndex', startIndex)
        startIndex += 1
        console.log('AT startIndex', startIndex)
        let URL =
          BACKEND_NODE_BASE_URL +
          '/stats/market-caps?page=' +
          startIndex +
          '&limit=' +
          PAGE_COUNT
        if (searchText) {
          URL += '&search=' + searchText
        }
        return (await axios.get(URL)).data
      } catch (e) {
        console.log('ERROR ', e)
        throw e
      }
    },
    []
  )

  const getExchangeList = async (): Promise<ExchangeInfo[]> => {
    try {
      const URL = 'https://dev.hermesswap.com/api/1/stats/exchange/listings'
      let result = (await axios.get(URL)).data
      console.log('result', result.data)
      return result.data
    } catch (e) {
      console.log('ERROR ', e)
      throw e
    }
  }

  React.useEffect(() => {
    getCoinsList(pageIndex, searchText).then((response) => {
      setCoins(response)
      console.log('response', response)
      if (response.length) {
        let btc = response.filter((item) => {
          return item.symbol === 'btc'
        })
        if (btc.length) setBTCPrice(btc[0].current_price)
      }
    })
  }, [getCoinsList, pageIndex, searchText])

  React.useEffect(() => {
    if (menu === 'exchange') {
      getExchangeList().then((response) => {
        setExchanges(response)
      })
    }
  }, [menu])

  const mapToken = React.useMemo(() => {
    // _.mapKeys(tokens, 'symbol')
    let mapping: { [index: string]: boolean } = { btc: true }
    tokens.map((item) => {
      mapping[item.symbol.toLowerCase()] = true
      return item
    })
    return mapping
  }, [tokens])

  const numFormatter = (num: number) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'
    } else if (num > 1000000000) {
      return (num / 1000000000).toFixed(1) + 'B'
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'
    } else if (num < 900) {
      return num
    }
  }

  const labelWithSymobol = (label: number) => {
    if (label === null) {
      label = 0
    }
    if (label < 0) {
      return (
        <div
          className='flex bg-red-900 rounded text-red-400 justify-center bg-opacity-40'
          style={{ width: '60px' }}>
          {label.toFixed(2) + '%'}
        </div>
      )
    } else if (label > 0) {
      return (
        <div
          className='flex bg-green-900 rounded text-green-400 justify-center bg-opacity-40'
          style={{ width: '60px' }}>
          {label.toFixed(2) + '%'}
        </div>
      )
    }
    return (
      <div
        className='flex bg-gray-600 rounded text-gray-500 justify-center bg-opacity-40'
        style={{ width: '60px' }}>
        {label.toFixed(2) + '%'}
      </div>
    )
  }

  const makeReadAblePrice = (price: number) => {
    if (price < 0.1) {
      return price.toFixed(8)
    }
    return price
  }

  const { setChainId } = usedChain()
  const { getTokenByAddress } = useToken()

  const { showSwapConfirmDialog } = usePopupContext()

  const openSwapPage = useCallback(
    (symbol: string, isBuy: boolean = false) => {
      setChainId(56, tokens, chains, (success) => {
        if (success) {
          let symbolToTokenAddress = tokens.filter((token) => {
            if (
              token.chainId === 56 &&
              token.symbol.toLowerCase() === symbol.toLowerCase()
            ) {
              return true
            } else if (
              symbol === 'btc' &&
              token.symbol.toLowerCase() === 'btcb'
            ) {
              return true
            }
            return false
          })

          if (!symbolToTokenAddress.length) return

          let usdT = getTokenByAddress(
            tokens,
            '0x55d398326f99059ff775485246999027b3197955',
            selectedChain?.chainId ?? -1
          )
          if (usdT) {
            let token = symbolToTokenAddress[0]
            let selectedTokens: Token[] = [token, usdT]
            if (isBuy) {
              selectedTokens = [usdT, token]
            }

            showSwapConfirmDialog({
              token: selectedTokens[0].address || '',
              tokenSelectedCallback: (token) => {
                // setPrice('-')
                // setSelectedFromToken(token)
              },
              lockTokens: selectedTokens,
              isBuy: isBuy,
            })
          }
        }
      })
    },
    [
      setChainId,
      tokens,
      chains,
      getTokenByAddress,
      selectedChain?.chainId,
      showSwapConfirmDialog,
    ]
  )

  const makeChartSelection = (label?: string) => {
    return (
      <MenuDropDown
        title={label ?? '7D chart'}
        items={[
          {
            value: '7day',
            label: '7 days',
          },
          {
            value: '30day',
            label: '30 days',
          },
        ]}
        fontSize={10}
        arrowHidden={true}
        onChange={(item) => {}}></MenuDropDown>
    )
  }

  const makeCoinRow = (
    coin: CoinInfo,
    index: number,
    selectedIndex: number
  ) => {
    let buttonStyle = ' pointer-events-none opacity-40'
    if (mapToken[coin.symbol]) {
      buttonStyle = ' cursor-pointer pointer-events-auto'
    }
    let isSelected = index === selectedIndex
    return (
      <div
        id='coinRow'
        className={
          'flex flex-1 flex-row text-gray-300 py-3 border-b border-gray-700 hover:bg-gray-900 cursor-pointer pb-4' +
          (isSelected ? ' bg-gray-900' : '')
        }
        onClick={(e) => {
          console.log('e.currentTarget' + e.currentTarget.id)
          console.log('e.target' + Object(e.target).id)
          if (Object(e.target).id !== 'coinRow') return
          if (index === selectedIndex) {
            setCryptoSelectedIndex(-1)
          } else {
            setCryptoSelectedIndex(index)
          }
        }}
        style={{ minHeight: '55px' }}>
        <div className='flex w-16 justify-end pr-8 items-center'>{index}</div>
        <div className='flex w-64 justify-start items-center pointer-events-none'>
          <img
            className='mr-2'
            style={{ width: '26px', height: '26px' }}
            src={coin.image}></img>
          <div>{coin.name}</div>
          <div className='text-gray-500 px-2'>{'•'}</div>
          <div className='text-gray-500'>{coin.symbol.toLocaleUpperCase()}</div>
        </div>
        <div className='flex w-64 justify-start items-center pr-2 pointer-events-none'>
          <div className='flex flex-col'>
            <div className='flex flex-col pt-2'>
              {'$' + makeReadAblePrice(coin.current_price).toLocaleString()}
              <div className='text-xs text-gray-500'>
                {(coin.current_price / BTCPrice).toFixed(10).toLocaleString() +
                  ' BTC'}
              </div>
            </div>
            <div
              className='flex text-xs flex-row pt-2 justify-around'
              style={{ fontSize: 10 }}>
              <div className='flex'>{'% 24H'}</div>
              <div className='flex'>{'% 7D'}</div>
              <div className='flex'>{'% 30D'}</div>
            </div>
            <div className='flex flex-row pt-2'>
              <div className='pr-2'>
                {labelWithSymobol(coin.price_change_percentage_24h)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.price_change_percentage_7d)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.price_change_percentage_30d)}
              </div>
            </div>
            {isSelected && (
              <div className='flex pt-2 flex-col'>
                <div
                  className='relative flex flex-1 justify-center pointer-events-auto'
                  style={{ fontSize: 10 }}>
                  {makeChartSelection('Price Graph (7D)')}
                </div>
                <div className='flex m-auto'>
                  <ChartCell
                    coinId={coin.id}
                    chartType='price'
                    prices={coin.price_graph}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='flex w-64 justify-start items-center pr-2 pointer-events-none'>
          <div className='flex flex-col'>
            <div className='flex flex-col pt-2'>
              {'$' + numFormatter(coin.market_cap)}
              <div className='text-xs text-gray-500'>{'-'}</div>
            </div>
            <div
              className='flex text-xs flex-row pt-2 justify-around'
              style={{ fontSize: 10 }}>
              <div className='flex'>{'% 24H'}</div>
              <div className='flex'>{'% 7D'}</div>
              <div className='flex'>{'% 30D'}</div>
            </div>
            <div className='flex flex-row pt-2'>
              <div className='pr-2'>
                {labelWithSymobol(coin.market_cap_change_percentage_24h)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.market_cap_change_percentage_7d)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.market_cap_change_percentage_30d)}
              </div>
            </div>
            {isSelected && (
              <div className='flex pt-2 flex-col'>
                <div
                  className='relative flex flex-1 justify-center pointer-events-auto'
                  style={{ fontSize: 10 }}>
                  {makeChartSelection('Market Cap Graph (7D)')}
                </div>
                <div className='flex m-auto'>
                  <ChartCell
                    coinId={coin.id}
                    chartType='market_cap'
                    prices={coin.market_cap_graph}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='flex w-64 justify-start items-center pointer-events-none'>
          <div className='flex flex-col'>
            <div className='flex flex-col pt-2'>
              {'$' + numFormatter(coin.total_volume)}
              <div className='text-xs text-gray-500'>{'-'}</div>
            </div>
            <div
              className='flex text-xs flex-row pt-2 justify-around'
              style={{ fontSize: 10 }}>
              <div className='flex'>{'% 24H'}</div>
              <div className='flex'>{'% 7D'}</div>
              <div className='flex'>{'% 30D'}</div>
            </div>
            <div className='flex flex-row pt-2'>
              <div className='pr-2'>
                {labelWithSymobol(coin.volume_change_percentage_1d)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.volume_change_percentage_7d)}
              </div>
              <div className='pr-2'>
                {labelWithSymobol(coin.volume_change_percentage_30d)}
              </div>
            </div>
            {isSelected && (
              <div className='flex pt-2 flex-col'>
                <div
                  className='relative flex flex-1 justify-center pointer-events-auto'
                  style={{ fontSize: 10 }}>
                  {makeChartSelection('Volume Graph (7D)')}
                </div>
                <div className='flex m-auto'>
                  <ChartCell
                    coinId={coin.id}
                    chartType='total_volume'
                    prices={coin.volume_graph}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='flex w-64 justify-start items-center pointer-events-none'>
          { makeCDCV3View(coin) }
        </div>

        <div className='flex w-48 text-gray-400 justify-end items-center'>
          <div className='relative flex text-gray-400 justify-end items-center space-x-2'>
            {makeButton(
              'Buy',
              ' bg-gradient-to-r from-cyan-700 to-green-500' + buttonStyle,
              coin.symbol.toLowerCase(),
              openSwapPage,
              true
            )}
            {makeButton(
              'Sell',
              ' bg-gradient-to-r from-pink-500 to-red-500' + buttonStyle,
              coin.symbol.toLowerCase(),
              openSwapPage
            )}
            <Tooltip
              title={
                isFavorite(coin.symbol)
                  ? 'Remove from favourites'
                  : 'Add to favourites'
              }>
              <div
                className={
                  'pr-4' +
                  (isFavorite(coin.symbol)
                    ? ' text-red-300 hover:text-red-600'
                    : ' text-white hover:text-green-300')
                }
                onClick={() => {
                  let newValue: string[] = [coin.symbol]
                  if (favoriteCoins.length) {
                    let oldArr = favoriteCoins.split(',')
                    let index = oldArr.indexOf(coin.symbol.toLowerCase())
                    if (index === -1) {
                      newValue = oldArr.concat(coin.symbol.toLowerCase())
                    } else {
                      oldArr.splice(index, 1)
                      newValue = oldArr
                    }
                  }
                  console.log('newValue', newValue)
                  setFavoriteCoins(newValue.toString())
                }}>
                {!isFavorite(coin.symbol) && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                )}

                {isFavorite(coin.symbol) && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  const BUTTON_HEIGHT = 28
  const makeButton = (
    buttonLabel: string,
    theme: string,
    key: string,
    callback: (symobl: string, isBuy: boolean) => void,
    isBuy: boolean = false
  ) => {
    return (
      <div className={`flex flex-row flex-1 justify-center`}>
        <button
          onClick={(e) => {
            e.preventDefault()
            callback(key, isBuy)
          }}
          className={
            `flex items-center justify-center text-sm rounded-md text-gray-300 font-semibold px-2` +
            theme
          }
          type='submit'
          style={{
            width: '100%',
            height: BUTTON_HEIGHT + 'px',
          }}>
          <div className='flex'>{buttonLabel}</div>
        </button>
      </div>
    )
  }

  const makeCDCV3View = (coin: CoinInfo) => {
    const cdcv3 = coin.cdc_v3[coin.cdc_v3.length - 1]
    if (cdcv3) {
      let splitedDate = cdcv3.date.split("-")
      let date = new Date()
      //"2023-01-24"
      date.setDate(+splitedDate[2])
      date.setFullYear(+splitedDate[0])
      date.setMonth((+splitedDate[1])-1)
      let formattedDate = (moment(date)).format('DD MMM YYYY')
      return (
        <div className='flex flex-col flex-1 items-center justify-center'>
          {cdcv3.signal.toLowerCase() === 'buy' && (
            <div className='flex p-3 rounded-xl text-green-400 bg-gray-900 text-2xl'>BUY</div>
          )}
          {cdcv3.signal.toLowerCase() === 'sell' && (
            <div className='flex p-3 rounded-xl text-red-400 bg-gray-900 text-2xl'>SELL</div>
          )}
          <div className='flex rounded-xl text-gray-400'>Signal started on</div>
          <div className='flex rounded-xl text-gray-400'>{formattedDate}</div>
          <div className='flex rounded-xl'>{"at price $" + makeReadAblePrice(cdcv3.price).toLocaleString()}</div>
        </div>
      )
    }
    return (
      <div className='flex flex-col flex-1 items-center justify-center'>
        <div>No Signal</div>
      </div>
    )
  }

  const makeExchangeRow = (exchange: ExchangeInfo, index: number) => {
    console.log('exchange', exchange)
    return (
      <div className='flex flex-1 flex-row text-gray-300 py-3 border-b border-gray-700'>
        <div className='flex w-16 justify-end pr-8'>{index}</div>
        <div className='flex w-64 justify-start'>
          <img
            className='pr-4'
            src={exchange.image}
            style={{ maxHeight: '24px', width: 'auto' }}></img>
          <div>{exchange.name}</div>
        </div>
        <div className='flex w-48 text-gray-400 justify-start'>
          {
            <div className='flex flex-col'>
              <div className='flex flex-col pt-2'>
                {'$' + numFormatter(exchange.quote.USD.volume_24h)}
                <div className='text-xs text-gray-500'>{'-'}</div>
              </div>
              <div
                className='flex text-xs flex-row pt-2 justify-around'
                style={{ fontSize: 10 }}>
                <div className='flex'>{'% 24H'}</div>
                <div className='flex'>{'% 7D'}</div>
                <div className='flex'>{'% 30D'}</div>
              </div>
              <div className='flex flex-row pt-2'>
                <div className='pr-2'>
                  {labelWithSymobol(
                    exchange.quote.USD.percent_change_volume_24h
                  )}
                </div>
                <div className='pr-2'>
                  {labelWithSymobol(
                    exchange.quote.USD.percent_change_volume_7d
                  )}
                </div>
                <div className='pr-2'>
                  {labelWithSymobol(
                    exchange.quote.USD.percent_change_volume_30d
                  )}
                </div>
              </div>
            </div>
          }
        </div>
        <div className='flex w-32 text-gray-400 justify-end'>
          {'$' + numFormatter(exchange.quote.USD.volume_7d)}
        </div>
        <div className='flex w-32 text-gray-400 justify-end'>
          {'$' + numFormatter(exchange.quote.USD.volume_30d)}
        </div>
        <div className='flex w-32 text-gray-400 justify-end'>{'-'}</div>
        <div className='flex w-32 text-gray-400 justify-end'>{'-'}</div>
        <div className='flex w-32 text-gray-400 justify-end'>
          {exchange.exchange_score.toFixed(2)}
        </div>
        {/* <div className='flex w-32 text-gray-400 justify-end'>{''}</div> */}
      </div>
    )
  }

  const chainToMenuModel = (chains: Chain[]): ChainMenuButtonModel[] => {
    return chains
      .filter((chain) => {
        return chain.name.toLowerCase().indexOf('test') === -1
      })
      .map((chain) => {
        return {
          value: chain.chainId + '',
          label: chain.name,
          icon: chain.frontEndData.icon,
          enabled: true,
        }
      })
      .filter((item) => {
        return item.value === '56'
      })
  }

  const [indexScroll, setIndexScroll] = React.useState(0)
  // const cointMaxCount = 250
  const cointMaxCount = useMemo(() => {
    // return coins.length
    if (coins.length) {
      //TODO: need this value from API
      return 250
    }
    return 0
  }, [coins])

  function makeNavigatorButton() {
    let pageCount = Math.ceil(cointMaxCount / PAGE_COUNT)
    let numButtuon = []
    let isSelected = false
    let cursor = ' cursor-pointer hover:bg-gray-900'
    let hoverState = ' items-center justify-center'
    let startIndex = indexScroll
    let endIndex = startIndex + pageCount
    for (var i = startIndex; i < endIndex; i++) {
      isSelected = i === +pageIndex
      let index = i
      let button = (
        <div
          id={'navigate_' + index}
          className={
            'flex w-8' +
            (isSelected ? ' text-blue-400 border border-blue-400' : '') +
            hoverState +
            cursor
          }
          // eslint-disable-next-line no-loop-func
          onClick={(button) => {
            let id = Object(button).target.id.replace('navigate_', '')
            setPageIndex(+id)
          }}>
          {i + 1}
        </div>
      )
      numButtuon.push(button)
    }
    let leftArrowEnabled = startIndex !== 0
    let rightArrowEnabled = endIndex < pageCount
    return (
      <div className='flex flex-1'>
        <div
          className={
            'flex w-8' +
            hoverState +
            (leftArrowEnabled ? cursor : ' select-none opacity-30')
          }
          onClick={() => {
            setIndexScroll(Math.max(0, indexScroll - 10))
          }}>
          {'<<'}
        </div>
        {numButtuon}
        <div
          className={
            'flex w-8' +
            hoverState +
            (rightArrowEnabled ? cursor : ' select-none opacity-30')
          }
          onClick={() => {
            setIndexScroll(Math.min(pageCount - 10, indexScroll + 10))
          }}>
          {'>>'}
        </div>
      </div>
    )
  }

  const makeHeaderAndContent = (
    menu: string,
    selectedIndex: number,
    chainMenu: string,
    searchText: string,
    setSearchText: (val: string) => void
  ) => {
    return [
      menu === 'crypto' && (
        <>
          <div className='flex flex-row justify-between'>
            <div className='flex pb-4'>
              <ChainMenuButtonBar
                items={chainToMenuModel(chains)}
                selectedValue={chainMenu}
                onChange={(value) => {
                  console.log('exchange', value)
                  setCryptoSelectedIndex(-1)
                  setChainMenu(value)
                }}></ChainMenuButtonBar>
            </div>
            <div className=''>
              <Input
                value={searchText}
                className='outline-none border text-white border-gray-800 bg-gray-600 rounded-full px-2 mx-2'
                style={{
                  color: 'white',
                }}
                onChange={({ target: { value } }) => {
                  setSearchText(value)
                }}></Input>
            </div>
          </div>
          <div
            className='flex flex-row text-gray-400 py-3'
            style={{ height: '45px' }}>
            <ContentCell
              label='#'
              widthStyle='w-16'
              otherStyle='pr-8'></ContentCell>
            <ContentCell
              label='Name'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Price'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Market Cap'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Volume 24h'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='CDC V3'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell label='' widthStyle='w-48'></ContentCell>
          </div>
          <div>
            {chainMenu === '56' &&
              coins.map((item, index) => {
                return makeCoinRow(item, index + 1, selectedIndex)
              })}
          </div>
          {!searchText && (
            <div className='flex fle-1 px-8 font-bold justify-between text-white pt-4 pb-8'>
              <div>
                {+pageIndex * PAGE_COUNT +
                  1 +
                  ' - ' +
                  Math.min(cointMaxCount, (+pageIndex + 1) * PAGE_COUNT) +
                  ' From ' +
                  cointMaxCount +
                  ' Record '}
              </div>
              <div>{makeNavigatorButton()}</div>
            </div>
          )}
        </>
      ),
      menu === 'exchange' && (
        <>
          <div
            className='flex flex-row text-gray-400 py-3'
            style={{ height: '45px' }}>
            <ContentCell
              label='#'
              widthStyle='w-16'
              otherStyle='pr-8'></ContentCell>
            <ContentCell
              label='Name'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Volume 24h'
              widthStyle='w-48'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Volume 7d'
              alignStyle='justify-end'></ContentCell>
            <ContentCell
              label='Volume 30d'
              alignStyle='justify-end'></ContentCell>
            <ContentCell
              label='Weekly Visits'
              alignStyle='justify-end'></ContentCell>
            <ContentCell label='# Coins' alignStyle='justify-end'></ContentCell>
            <ContentCell
              label='Exchange Score'
              alignStyle='justify-end'></ContentCell>
          </div>
          <div>
            {exchanges.map((item, index) => {
              return makeExchangeRow(item, index + 1)
            })}
          </div>
        </>
      ),
      menu === 'favorites' && (
        <>
          <div
            className='flex flex-row text-gray-400 py-3'
            style={{ height: '45px' }}>
            <ContentCell
              label='#'
              widthStyle='w-16'
              otherStyle='pr-8'></ContentCell>
            <ContentCell
              label='Name'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Price'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Market Cap'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell
              label='Volume 24h'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            <ContentCell label='' widthStyle='w-48'></ContentCell>
          </div>
          <div>
            {coins
              .map((item, index) => {
                if (favoriteCoins.indexOf(item.symbol.toLowerCase()) >= 0) {
                  return makeCoinRow(item, index + 1, selectedIndex)
                }
                return null
              })
              .filter((coin) => {
                return coin
              })}
          </div>
        </>
      ),
      menu === 'defi' && (
        <>
          <div
            className='flex flex-row text-gray-400 py-3'
            style={{ height: '45px' }}>
            <ContentCell
              label='#'
              widthStyle='w-16'
              otherStyle='pr-8'></ContentCell>
            <ContentCell
              label='Name'
              widthStyle='w-64'
              alignStyle='justify-start'></ContentCell>
            {/* <ContentCell label='1h Change'></ContentCell> */}
            <ContentCell label='Change (24h)'></ContentCell>
            <ContentCell label='Price'></ContentCell>
            <ContentCell label='Price in BTC' widthStyle='w-48'></ContentCell>
            <ContentCell label='Market Cap'></ContentCell>
            <ContentCell label='Volume 24h'></ContentCell>
            <ContentCell
              label='Price Graph (7D)'
              widthStyle='w-48'></ContentCell>
          </div>
          <div></div>
        </>
      ),
      menu === 'heatmap' && (
        <>
          <TreeMap coinsInfo={coins}></TreeMap>
        </>
      ),
    ]
  }

  function makeView() {
    return (
      <div className='flex text-sm flex-col text-white flex-col justify-center'>
        <div className='flex text-xl text-center justify-center'>
          {'Best Coin Price Tracker in the Market'}
        </div>

        <div className='flex text-gray-400 pt-4 justify-center'>
          {
            'With Market Price, you can manage all crypto assets from one interface.'
          }
        </div>
        {/* <div className='flex text-gray-400 justify-center'>
          {
            'The global crypto market cap is $1.1T a 4.85% increase over the last day.'
          }
        </div> */}

        {/* <div className='flex justify-center p-4 space-x-4'>
          <DashboardCell
            header='Market Cap'
            label={'$' + dashBoardData.market}
            percentChange={dashBoardData.market_percent}></DashboardCell>
          <DashboardCell
            header='Volume 24h'
            label={'$' + dashBoardData.volumn24h}
            percentChange={dashBoardData.volumn24h_percent}></DashboardCell>
          <DashboardCell
            header='BTC Dominance'
            label={dashBoardData.btc_dominance + '%'}
            percentChange={dashBoardData.btc_dominance_percent}></DashboardCell>
        </div> */}

        <div className='flex pt-8 pb-0 flex-col justify-center'>
          <div className='flex mx-auto flex-col'>
            <div className='flex pl-8'>
              <MenuButtonBar
                items={[
                  { value: 'crypto', label: 'Cryptocurrencies', enabled: true },
                  // { value: 'exchange', label: 'Exchanges', enabled: true },
                  { value: 'favorites', label: 'Favorites', enabled: true },
                  // { value: 'defi', label: 'DeFi', enabled: true },
                  { value: 'heatmap', label: 'Heatmap', enabled: true },
                ]}
                selectedValue={menu}
                onChange={(value) => {
                  console.log('exchange', value)
                  setCryptoSelectedIndex(-1)
                  setMenu(value)
                }}></MenuButtonBar>
            </div>
            <div
              className='flex flex-col mx-auto py-4 px-8 mt-4 justify-center border border-gray-500 rounded-xl'
              style={{ maxWidth: '1300px' }}>
              {makeHeaderAndContent(
                menu,
                cryptoSelectedIndex,
                chainMenu,
                searchText,
                setSearchText
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div className='w-full h-full p-6 bg-gray-800'>{makeView()}</div>
}

// type ChartData = {
//   prices: number[][]
// }
const ChartCell: FC<{
  coinId: string
  chartType: string
  simulate?: boolean
  prices: number[]
}> = ({ coinId, chartType, simulate, prices }) => {
  // const getCoinChart = async (): Promise<ChartData> => {
  //   try {
  //     const URL =
  //       'https://api.coingecko.com/api/v3/coins/' +
  //       coinId +
  //       '/market_chart?vs_currency=usd&days=14&interval=30'
  //     return (await axios.get(URL)).data
  //   } catch (e) {
  //     console.log('ERROR ', e)
  //     throw e
  //   }
  // }

  const [chartBars] = React.useState<number[][]>([])

  const getFormattedDate = (timeStamp: number) => {
    var date = new Date(timeStamp),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return (
      date.getFullYear().toString() +
      '-' +
      mnth.toString() +
      '-' +
      day.toString()
    )
  }

  const getDayBefore = (inDate: Date) => {
    var yesterday = new Date(inDate.getTime())
    yesterday.setDate(inDate.getDate() - 1)
    return yesterday
  }

  React.useEffect(() => {
    console.timeLog('chartBars length = ', chartBars.length)
    let dateMapping: { [index: string]: Boolean } = {}
    let lightWeightData: LineChartData[] = chartBars
      .map((item) => {
        let timeStamp = item.length > 1 ? item[0] : 0
        let value = item.length > 1 ? item[1] : 0
        let formattedData = getFormattedDate(timeStamp)
        let time = dateMapping[formattedData] ? '' : formattedData
        dateMapping[formattedData] = true
        let result = { time: time, value: value }
        return result
      })
      .filter((item) => {
        return item.time !== ''
      })

    let today = new Date()
    let reversedPrice = prices.reverse()
    lightWeightData = reversedPrice.map((element) => {
      let date = today
      let formatData = getFormattedDate(date.getTime())
      today = getDayBefore(today)
      return { time: formatData, value: element }
    })

    lightWeightData = lightWeightData.reverse()

    if (!lightWeightData.length) return

    let div = document.getElementById('light_chart_div_' + coinId + chartType)
    if (div && chartBars) {
      if (div.firstElementChild) div.removeChild(div.firstElementChild)
      var chart = createChart(div, {
        width: 190,
        height: 40,
        layout: {
          textColor: '#d1d4dc',
          backgroundColor: 'rgba(100, 100, 100, 0)',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        rightPriceScale: {
          visible: false,
        },
        crosshair: {
          vertLine: {
            visible: false,
            labelVisible: false,
          },
          horzLine: {
            visible: false,
            labelVisible: false,
          },
        },
        handleScroll: {
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        timeScale: {
          visible: false,
        },
      })
      var areaSeries = chart.addLineSeries({
        color: simulate ? '#202937' : 'rgba(38, 198, 218, 1)',
        lineWidth: 1,
        crosshairMarkerVisible: false,
        lastValueVisible: false,
        priceLineVisible: false,
        baseLineVisible: false,
      })
      areaSeries.setData(lightWeightData)
      chart.timeScale().fitContent()
    }
  }, [chartBars, chartType, coinId, simulate, prices])

  return (
    <div className={'flex border border-gray-800'}>
      <div id={'light_chart_div_' + coinId + chartType}></div>
    </div>
  )
}

type LineChartData = {
  time: string
  value: number
}

////////////////////////////////////////////////////////////////////////////////

type ContentCellProps = {
  label: string
  widthStyle?: string
  alignStyle?: string
  otherStyle?: string
}
const ContentCell: FC<ContentCellProps> = ({
  label,
  widthStyle,
  alignStyle,
  otherStyle,
}) => {
  const w = widthStyle ? ' ' + widthStyle : ' w-32'
  const align = alignStyle ? ' ' + alignStyle : ' justify-end'
  return (
    <div
      className={
        'flex' + w + align + (otherStyle ? ' ' + otherStyle : '')
      }>
      {label}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////

type MenuButtonModel = {
  value: string
  label: string
  enabled: boolean
}
const MenuButtonBar: FC<{
  items: MenuButtonModel[]
  selectedValue: string
  onChange: (value: string) => void
}> = ({ items, selectedValue, onChange }) => {
  return (
    <div className='flex flex-row space-x-8'>
      {items.map((item) => {
        const isSelected = selectedValue === item.value
        return (
          <div
            key={item.value}
            className={
              'flex' +
              (isSelected
                ? ' border-b-4 border-blue-400 pb-1'
                : '' +
                  (item.enabled
                    ? ' cursor-pointer'
                    : ' text-gray-500 pointer-events-none'))
            }
            onClick={() => {
              onChange(item.value)
            }}>
            {item.label}
          </div>
        )
      })}
    </div>
  )
}

type ChainMenuButtonModel = {
  value: string
  label: string
  enabled: boolean
  icon: string
}
const ChainMenuButtonBar: FC<{
  items: ChainMenuButtonModel[]
  selectedValue: string
  onChange: (value: string) => void
}> = ({ items, selectedValue, onChange }) => {
  return (
    <div className='flex flex-row space-x-2'>
      {items.map((item) => {
        const isSelected = selectedValue === item.value
        return (
          <div
            key={item.value}
            className={
              'flex rounded-full px-4 py-1' +
              (isSelected
                ? ' bg-gray-900'
                : '' +
                  (item.enabled
                    ? ' border border-gray-700 cursor-pointer'
                    : ' text-gray-500 pointer-events-none'))
            }
            onClick={() => {
              onChange(item.value)
            }}>
            <img
              className='mr-2'
              style={{ width: '20px', height: '20px' }}
              src={item.icon}></img>
            {item.label}
          </div>
        )
      })}
    </div>
  )
}
