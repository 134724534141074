import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Providers from './Providers'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SENTRY_DNS, SENTRY_ENABLED, SENTRY_ENV } from './constants/sentry.constant'

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [ new BrowserTracing() ],
    tracesSampleRate: 0.3,
    environment: SENTRY_ENV
  })
}


const Index = () => {
  return <App />
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Index />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
