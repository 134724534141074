import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useChainState } from 'contexts/ChainContext'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { useTranslationState } from 'contexts/TranslationContext'
import { getChainById } from 'hooks/Chain/useChain'
import useAuth from 'hooks/useAuth'
import { Chain } from 'models/chain.model'
import { Token } from 'models/token.model'
import React, { FC, useCallback, useEffect } from 'react'
import { unformatInputNumber } from 'utils/formatter.utils'

export const SwapButton: FC<{
  token1: Token | undefined,
  token2: Token | undefined,
  approved: boolean,
  approveToken: () => void,
  is2TokenSelected: Token | undefined,
  balanceFrom: string,
  maxExchangeFrom: BigNumber,
  priceImpactIsHigh: boolean,
  submitButtonVisible: boolean,
  chainInfo: Chain | undefined,
  isLowLP: boolean
}> = ({
  token1,
  token2,
  approved,
  approveToken,
  is2TokenSelected,
  balanceFrom,
  maxExchangeFrom,
  priceImpactIsHigh,
  submitButtonVisible,
  chainInfo,
  isLowLP
}) => {
  const { tl } = useTranslationState()
  const { chains } = useChainState()

  const { active } = useWeb3React()
  const swapDispatch = useSwapDispatch()
  const { priceLoading, selectedChainId, networkChanged } = useSwapGlobalState()
  const { setupNetwork } = useAuth()
  const buttonHeight = 42;
  const connectWallet = useCallback(()=>{
    setupNetwork(selectedChainId, chains, (success) => {
      if (success) {
        swapDispatch({
          type: 'CONNECT_WALLET_BUTTON_CLICK_CHANGED',
          payload: 'userClick',
        })
      }
    })
  }, [setupNetwork, selectedChainId, chains, swapDispatch])

  useEffect(()=>{
    console.log("networkChanged", networkChanged)
  }, [networkChanged])

  const switchNetwork = useCallback(()=>{
    setupNetwork(selectedChainId, chains)
  }, [setupNetwork, selectedChainId, chains])

  const selectedChain = React.useMemo(() => {
    return getChainById(chains,  selectedChainId)
  }, [chains, selectedChainId])

  if (isLowLP) {
    return (
      <div
        className={`flex flex-row justify-center'
          }`}>
        <button
          onClick={connectWallet}
          type='button'
          className='w-full text-lg bg-gradient-to-r from-cyan-700 to-cyan-700'
          style={{
            fontWeight: 'bold',
            width: '100%',
            color: 'white',
            height: buttonHeight+'px',
            borderRadius: '8px',
            pointerEvents: 'none',
            opacity: 0.3,
          }}>
          Insufficient liquidity for this trade
        </button>
      </div>
    )
  }

  if(!selectedChain?.frontEndData.swapEnabled){
    return (
      <div
        className={`flex flex-row justify-center'
          }`}>
        <button
          onClick={connectWallet}
          type='button'
          className='w-full text-lg bg-gradient-to-r from-cyan-500 to-blue-500'
          style={{
            fontWeight: 'bold',
            width: '100%',
            color: 'white',
            height: buttonHeight+'px',
            borderRadius: '8px',
            pointerEvents: 'none',
            opacity: 0.3,
          }}>
          Coming Soon..
        </button>
      </div>
    )
  }

  if (!active) {
    return (
      <div
        className={`flex flex-row justify-center'
          }`}>
        <button
          onClick={connectWallet}
          type='button'
          className='w-full text-lg bg-gradient-to-r from-cyan-500 to-blue-500'
          style={{
            // fontSize: '14px',
            fontWeight: 'bold',
            width: '100%',
            // color: '#40BF77',
            color: 'white',
            height: buttonHeight+'px',
            borderRadius: '8px',
            // border: '1px solid #40BF77',
            // border: '1px solid gray',
            pointerEvents: 'auto',
          }}>
          {tl.button_connect_wallat}
        </button>
      </div>
    )
  }

  let chainId = -1
  let _chainId = window.localStorage.getItem("chainId")
  if (_chainId){
    chainId = +_chainId
  }
  if (selectedChainId !== chainId) {
    return (
      <div
        className={`flex flex-row justify-center'
          }`}>
        <button
          onClick={switchNetwork}
          type='button'
          className='w-full text-lg bg-gradient-to-r from-pink-500 to-red-500'
          style={{
            // fontSize: '14px',
            fontWeight: 'bold',
            width: '100%',
            // color: '#40BF77',
            color: 'white',
            height: buttonHeight+'px',
            borderRadius: '8px',
            // border: '1px solid #40BF77',
            pointerEvents: 'auto',
          }}>
          {tl.button_connect_network}
        </button>
      </div>
    )
  }

  if (!approved) {
    return (
      <div className={`flex flex-row justify-center`}>
        <button
          onClick={approveToken}
          type='button'
          className='w-full text-lg bg-gradient-to-r from-cyan-700 to-green-500'
          style={{
            // fontSize: '14px',
            fontWeight: 'bold',
            width: '100%',
            // color: '#40BF77',
            color: 'white',
            height: buttonHeight+'px',
            borderRadius: '8px',
            // border: '1px solid #40BF77',
            opacity: is2TokenSelected ? 1 : 0.3,
            pointerEvents: is2TokenSelected ? 'auto' : 'none',
          }}>
          {tl.button_approve}
        </button>
      </div>
    )
  }

  let balanceFromNum = unformatInputNumber(balanceFrom).toNumber()
  let isBalanceSufficient = balanceFromNum <= maxExchangeFrom.toNumber()
  let balanceFromContainValue = balanceFromNum > 0
  var allowToClick =
    is2TokenSelected &&
    !priceLoading &&
    !priceImpactIsHigh &&
    balanceFromContainValue &&
    isBalanceSufficient

  let swapTokenLabel = tl.button_swap
  if(token1?.address.toLowerCase() === "ETHER".toLowerCase() && token2?.address.toLowerCase() === chainInfo?.wrapTokenAddress.toLowerCase()) {
    swapTokenLabel = tl.button_wrap
  } else if(token2?.address.toLowerCase() === "ETHER".toLowerCase() && token1?.address.toLowerCase() === chainInfo?.wrapTokenAddress.toLowerCase()){
    swapTokenLabel = tl.button_unwrap
  }

  let buttonLabel = priceImpactIsHigh
    ? tl.button_insufficient_lp
    : swapTokenLabel
  if (!isBalanceSufficient) {
    buttonLabel = tl.button_insufficient
  }

  return (
    <div
      className={`flex flex-row justify-center ${
        submitButtonVisible ? 'block' : 'hidden'
      }`}>
      <button
        className='flex items-center justify-center text-lg rounded-md text-gray-300 font-semibold bg-gradient-to-r from-cyan-700 to-green-500'
        type='submit'
        style={{
          width: '100%',
          height: buttonHeight+'px',
          opacity: allowToClick ? 1 : 0.5,
          pointerEvents: allowToClick ? 'auto' : 'none',
        }}>
        <div className='flex'>{buttonLabel}</div>
      </button>
    </div>
  )
}
