import { useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import { Token } from 'models/token.model'
import { HermesSwapToken } from 'pages/HermesSwapToken'
import React, { memo } from 'react'
import { PopupContextType, usePopupContext } from '../../contexts/PopupContext'

export type FixSizingModel = {
  width: number,
  height: number
}

const SwapConfirmDialog = memo(
  ({
    token,
    tokenSelectedCallback,
    onClose,
    lockTokens,
    size,
    isBuy
  }: Parameters<PopupContextType['showSwapConfirmDialog']>['0']) => {
    const { hidePopup } = usePopupContext()
    const { selectedChainId } = useSwapGlobalState()
    //const { tokens } = useToken()
    const { tokens } = useTokenState()
    let selectedTokens: (Token | undefined)[] = tokens
    if(lockTokens){
      selectedTokens = lockTokens.filter((item)=>{
        if (item){
          return true
        }
        return false
      })
    }

    let isFixedSize = size ? true: false
    console.log("size", size)
    console.log("isFixedSize", isFixedSize)
    return (
      <div className={ isFixedSize ? 'absolute inset-0 z-50' : 'fixed inset-0 z-50 overflow-y-auto'}
      style={{
        width: isFixedSize && size? size.width : 'auto',
        height: isFixedSize && size? size.height : 'auto'
      }}
      >
        <div className={ isFixedSize ? 'w-full h-full p-4' : 'flex items-end justify-center min-h-screen p-0 md:px-4 md:pt-4 md:pb-20 text-center sm:block' } >
          <div className={ isFixedSize ? 'bg-gray-500 opacity-75' : 'fixed inset-0 transition-opacity'} aria-hidden='true'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </div>
          <span
            className={ isFixedSize ? 'hidden' : 'hidden sm:inline-block sm:align-middle sm:h-screen' }
            aria-hidden='true'>
            &#8203;
          </span>
          <div className={ isFixedSize ? 'flex h-full': 'absolute w-full h-full top-0 left-0' }>
            <div
              className={ (isFixedSize ? 'flex flex-1 flex-col m-2': 'w-full max-w-sm mb-28 mt-8 sm:mt-32')
              + ' text-white border border-gray-500 bg-gradient-to-b from-gray-600 to-gray-800 inline-block pt-2 pb-6 px-6 overflow-hidden text-left rounded-lg shadow-xl transform transition-all align-middle'
            }
              role='dialog'
              aria-modal='true'
              aria-labelledby='modal-headline'>
              <div
                style={{
                  minHeight: '56px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    fontSize: '18px',
                    paddingBottom: '0px',
                    flex: '1',
                  }}>
                  { 'Confirm' + (isBuy? ' Buy': ' Sell') }
                </div>
                <button
                  type='button'
                  onClick={() => {
                    hidePopup('withdraw')
                    if (onClose) onClose()
                  }}
                  className='farm-price-favorite rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                  <span className='sr-only'>Close menu</span>
                  <svg
                    style={{ width: '20px', height: '20px' }}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#40BF77'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              <div className='flex flex-1'>
                <HermesSwapToken
                  iTokens={ selectedTokens.map((item)=>{ return item ? item.address: '' })}
                  emebeddedStyle={true}
                  chainId={''+selectedChainId}
                  showTopNavigator={false}
                  // paddingEnabled={false}
                  // flexStyleEnabled={false}
                  simpleMode={true}
                  size={size}
                  compact={false}
                ></HermesSwapToken>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default SwapConfirmDialog
