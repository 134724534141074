import _ from 'lodash'
import React from 'react'
// import { useMyFarm } from '../contexts/MyFarmContext'
import { useMyWallet } from '../contexts/MyWalletContext'
import { usePopupContext } from '../contexts/PopupContext'
import {
  callContractProgress,
  callContractResponse,
} from '../models/contract.model'
import { MyFarmDetail, Farm } from '../models/farm.model'
import {
  getPoolInfoWithFarmDetails,
  poolSos,
  sos,
} from '../services/farm.service'

const useSos = () => {
  const { showTransactionStatePopup } = usePopupContext()
  const { reload: reloadWallet } = useMyWallet()
  // const { reloadAndWatch: reloadFarm, lastUpdate } = useMyFarm()
  const bscHashRef = React.useRef('')
  const allowShowStatePopupRef = React.useRef(false)

  const watchFarmBeforeSuccessRef = React.useRef<Record<string, string> | null>(
    null
  )

  // success popup
  React.useEffect(() => {
    const current = watchFarmBeforeSuccessRef.current
    // if (!current || !lastUpdate) return

    // for (const farmId in current) {
    //   if (current[farmId] === lastUpdate[farmId]) continue
    //   delete current[farmId]
    // }

    if (_.isEmpty(current)) {
      watchFarmBeforeSuccessRef.current = null
      if (allowShowStatePopupRef.current) {
        showTransactionStatePopup('successful', {
          bscAddress: bscHashRef.current,
        })
      }
    }
  }, [showTransactionStatePopup]) //[lastUpdate, showTransactionStatePopup])

  const sosFunc = React.useCallback(
    (farmInfo: Farm, farmDetail: MyFarmDetail, poolId: number) => {
      var farmMethodProgressCallback = (response: callContractProgress) => {
        let bscHash = ''
        if (response.type === 'WAITING') {
          allowShowStatePopupRef.current = true
          showTransactionStatePopup(
            'processing',
            {
              transactionMessage: 'SOS',
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITTING') {
          if (response.data && 'hash' in response.data) {
            bscHash = response.data.hash
          }
          showTransactionStatePopup(
            'processing',
            {
              transactionTitle: 'Transaction Submitting',
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITED') {
          if (response.data && 'hash' in response.data) {
            bscHash = response.data.hash
          }
          showTransactionStatePopup(
            'submitted',
            {
              bscAddress: bscHash,
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        }
        bscHashRef.current = bscHash
      }

      var farmMethodResponseCallback = (response: callContractResponse) => {
        if (response.result === 'ERROR') {
          showTransactionStatePopup('error', {
            errorMessage: response.message,
          })
        } else if (response.result === 'FAIL') {
          showTransactionStatePopup('fail')
        } else if (response.result === 'SUCCESS') {
          // const farmId = farmInfo.id ?? ''

          reloadWallet(true, true)
          // reloadFarm([farmId], true)

          // if (!watchFarmBeforeSuccessRef.current)
          //   watchFarmBeforeSuccessRef.current = {}
          // watchFarmBeforeSuccessRef.current[farmId] = lastUpdate
          //   ? lastUpdate[farmId]
          //   : ''
        }
        return true
      }

      let poolInfo = getPoolInfoWithFarmDetails(farmInfo, farmDetail)
      if (poolInfo) {
        return poolSos(poolInfo, poolId, farmMethodProgressCallback).then(
          farmMethodResponseCallback
        )
      }
      return sos(farmInfo, poolId, farmMethodProgressCallback).then(
        farmMethodResponseCallback
      )
    },
    [showTransactionStatePopup, reloadWallet] //, reloadFarm, lastUpdate]
  )

  return sosFunc
}

export default useSos
