import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'


const POLLING_INTERVAL = 12000

export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? '96'
)

export const SUPPORT_NETWORK: number[] = [56, 96, 97, 25925, 3501, 8899]

const injected = new InjectedConnector({
  supportedChainIds: SUPPORT_NETWORK,
})

enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

const bscConnector = new BscConnector({ supportedChainIds: SUPPORT_NETWORK })

const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: 'https://bsc-dataseed.binance.org/' },
  bridge: 'https://pancakeswap.bridge.walletconnect.org/',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}
