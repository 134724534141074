import { Box, CircularProgress } from "@material-ui/core"
import React, { FC } from "react"

export const CircularProgressWithLabel: FC<{
  isLoading: boolean
}> = ({ isLoading }) => {
  const [progress, setProgress] = React.useState(100)

  React.useEffect(() => {
    if (isLoading) setProgress(0)
    else setProgress(100)
  }, [isLoading])

  /// CIRCLE PROGRESS FOR MULTIROUTE ///
  const progressTimer = React.useRef(0)
  React.useEffect(() => {
    if (progress >= 100) {
      progressTimer.current = -1
      setProgress(100)
      clearInterval(progressTimer.current)
      return
    }
    progressTimer.current = +setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 100 / 6
      )
    }, 1000)
    return () => {
      clearInterval(progressTimer.current)
    }
  }, [progress])

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        size={20}
        color='inherit'
        value={progress}
        variant='determinate'
      />
    </Box>
  )
}
