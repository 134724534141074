import { useEffect } from 'react'
import { connectorLocalStorageKey } from 'constants/local-storage-key.constant'
import useAuth, { ConnectorNames } from './useAuth'
import { useChainState } from 'contexts/ChainContext'

export default function useEagerConnect() {
  const { chains } = useChainState()
  const { login } = useAuth()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(
      connectorLocalStorageKey
    ) as ConnectorNames
    if (connectorId) {
      login(connectorId, true, chains)
    }
  }, [chains, login])
}
