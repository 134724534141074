import styled from 'styled-components'

export const Spinner = styled.div`
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 10px;
`

export const Rect1 = styled.div`
  background-color: #4db86d;
  border-radius: 50%;
  height: 100%;
  width: 15px;
  margin-left: 5px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;

  @-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`

export const Rect2 = styled(Rect1)`
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`

export const Rect3 = styled(Rect1)`
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
`

export const Rect4 = styled(Rect1)`
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
`
