import { Step } from "react-joyride"

export const tutorial1: Step[] = [
  {
    content: <h2>5 steps to use multiroute swap on Hermes Swap</h2>,
    locale: { skip: <strong aria-label='skip'>S-K-I-P</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: 'For swapping on Hermes Swap, we will start from this section.',
    placement: 'left',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.swap-section',
    title: '1) First step',
  },
  {
    content:
      'Type in the amount to swap here. For example, we will fill in 0.0001 for you. Please press next.',
    placement: 'left',
    styles: {
      options: {
        width: 400,
      },
    },
    target: '.amount-in-section',
    title: '2) Second step',
  },

  {
    content: (
      <div>
        <div>
          The amount out will be show here then you should be able to swap
          now.
        </div>
        <br></br>
        Apart from this, next will be the information that we provide for
        user.
      </div>
    ),
    placement: 'left',
    styles: {
      options: {
        width: 350,
      },
    },
    target: '.amount-out-section',
    title: '3) Third step',
  },
  {
    content: (
      <div>
        <div className='text-green-700 text-xl'>Route View</div>'This section
        will show your about what are the routes that use to swap.
      </div>
    ),
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.route-section',
    title: '4) Fourth step',
  },
  {
    content: (
      <div>
        <div className='text-green-700 text-xl'>Exchange View</div>This
        section will show you a comparison between our result and other DApps
        in the market.
      </div>
    ),
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.exchange-section',
    title: '5) Last step',
  },
]

export const tutorial2: Step[] = [
  {
    content: <h2>5 steps to use multiroute swap on Hermes Swap</h2>,
    locale: { skip: <strong aria-label='skip'>S-K-I-P</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: 'For swapping on Hermes Swap, we will start from this section.',
    placement: 'left',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.swap-section',
    title: '1) First step',
  },
  {
    content:
      'Type in the amount to swap here. For example, we will fill in 0.0001 for you. Please press next.',
    placement: 'left',
    styles: {
      options: {
        width: 400,
      },
    },
    target: '.amount-in-section',
    title: '2) Second step',
  },

  {
    content: (
      <div>
        <div>
          The amount out will be show here then you should be able to swap
          now.
        </div>
        <br></br>
        Apart from this, next will be the information that we provide for
        user.
      </div>
    ),
    placement: 'left',
    styles: {
      options: {
        width: 350,
      },
    },
    target: '.amount-out-section',
    title: '3) Third step',
  },
  {
    content: (
      <div>
        <div className='text-green-700 text-xl'>Exchange View</div>This
        section will show you a comparison between our result and other DApps
        in the market.
      </div>
    ),
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.exchange-simple-section',
    title: '4) Fourth step',
  },
  {
    content: (
      <div>
        <div className='text-green-700 text-xl'>Route View</div>'This section
        will show your about what are the routes that use to swap.
      </div>
    ),
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.route-simple-section',
    title: '5) Last step',
  },
]
