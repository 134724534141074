import { useWeb3React } from '@web3-react/core'
import { MenuDropDown } from 'components/MenuDropDown'
import { useChainState } from 'contexts/ChainContext'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import usedChain, { getChainById } from 'hooks/Chain/useChain'
import { useSessionState } from 'hooks/useStickyState'
import { Chain } from 'models/chain.model'
import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useWalletModal from '../WalletModal/useWalletModal'
import { AddressButton, ConnectWalletButton, LogoutButton } from './style'
import {
  useTranslationDispatch,
  useTranslationState,
} from 'contexts/TranslationContext'
import { LANG_EN } from 'constants/lang/EN'
import { LANG_TH } from 'constants/lang/TH'

const TopBar = () => {
  const { tokens } = useTokenState()
  const { chains } = useChainState()

  const { login, logout } = useAuth()
  const { account, active } = useWeb3React()
  const { onPresentConnectModal } = useWalletModal(login)

  const swapDispatch = useSwapDispatch()
  const translationDispatch = useTranslationDispatch()
  const { buttonClickChanged, topBarHidden } = useSwapGlobalState()

  React.useEffect(() => {
    if (buttonClickChanged === 'userClick') {
      onPresentConnectModal()
      swapDispatch({
        type: 'CONNECT_WALLET_BUTTON_CLICK_CHANGED',
        payload: '',
      })
    }
  }, [buttonClickChanged, onPresentConnectModal, swapDispatch])

  const [viewMode, setViewModeState] = useSessionState<'simple' | 'advance'>(
    'simple',
    'config-viewmode'
  )

  const { tl, change: language_change } = useTranslationState()
  const { selectedChainId } = useSwapGlobalState()
  const [language, saveLanguage] = useSessionState<'eng' | 'tha'>(
    'eng',
    'config-translation'
  )

  const init = useRef<boolean>(false)
  useEffect(() => {
    // console.log(">> language", language)
    // console.log(">> language_change", language_change)
    if (init.current === false && language_change === false) {
      console.log(">> useEffect")
      if (language === 'tha') {
        translationDispatch({
          type: 'LANG_SELECT',
          payload: LANG_TH,
        })
      } else if(language === 'eng') {
        translationDispatch({
          type: 'LANG_SELECT',
          payload: LANG_EN,
        })
      }
      init.current = true
    }
  }, [language, translationDispatch, language_change])

  // useEffect(() => {
  //   console.log(">> tl changed")
  // },[tl])

  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const history = useHistory()

  const isMobile = false
  const [windowDimension, setWindowDimension] = useState(0)

  useEffect(() => {}, [selectedChainId])

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  let isCompactView = windowDimension < 900

  const { setChainId } = usedChain()

  const getChainData = (chains: Chain[]) => {
    chains = chains.sort((n1, n2) => n1.chainId - n2.chainId)
    chains = chains.sort((n1, n2) => {
      if (
        n1.name.toLowerCase().indexOf('testnet') >= 0 &&
        n2.name.toLowerCase().indexOf('testnet') >= 0
      ) {
        return 0
      } else if (n1.name.toLowerCase().indexOf('testnet') >= 0) {
        return 1
      } else if (n2.name.toLowerCase().indexOf('testnet') >= 0) {
        return -1
      }
      return 0
    })
    return chains.map((chain) => {
      return {
        value: '' + chain.chainId,
        label: chain.name,
        icon: chain.frontEndData.icon,
      }
    })
  }

  return (
    <div
      className={
        'relative flex justify-between items-center pt-4 bg-gradient-to-b from-gray-900 to-gray-800 bg-opacity-100' +
        (isMobile ? ' px-4' : ' space-x-12 px-6') +
        (topBarHidden ? ' hidden' : '')
      }>
      <div
        className={'flex space-x-2 items-center' + (isMobile ? ' flex-1' : '')}>
        <div className='flex flex-col relative items-center'>
          <img
            alt='hermes logo'
            className='h-14 cursor-pointer'
            src='./images/logo-horizontal.png'
            onClick={() => {
              history.push('/')
            }}
          />
          <div
            style={{
              fontSize: 12,
              textAlign: 'center',
            }}
            className='flex border text-white px-2 rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500'>
            {tl.logo_description}
          </div>
        </div>
      </div>

      <div
        className={
          'relative flex flex-row flex-1 space-x-6 items-center mt-2' +
          (isMobile ? ' hidden' : '') +
          (isCompactView ? ' hidden' : '')
        }>
        <MenuDropDown
          title={tl.menu_trade}
          items={[
            { value: 'simple-mode', label: tl.menu_trade_simple_swap },
            { value: 'simple-swap', label: tl.menu_trade_advance_swap },
          ]}
          onChange={(item) => {
            if (item === 'simple-mode') {
              history.push('/swap')
              swapDispatch({
                type: 'NOTIFY_VIEW_MODE_CHANGED',
                payload: 'simple',
              })
              if (viewMode !== 'simple') setViewModeState('simple')
            } else {
              history.push('/swap')
              swapDispatch({
                type: 'NOTIFY_VIEW_MODE_CHANGED',
                payload: 'advance',
              })
              if (viewMode !== 'advance') setViewModeState('advance')
            }
          }}></MenuDropDown>
        <MenuDropDown
          title={tl.menu_more}
          items={[
            { value: 'market', label: tl.menu_more_market_price },
            process.env.REACT_APP_MODE_DEV === 'true'
              ? { value: 'history', label: tl.menu_more_history }
              : null,
          ].filter((item) => {
            return item !== null
          })}
          onChange={(item) => {
            switch (item) {
              case 'swap': {
                history.push('/swap')
                break
              }
              case 'market': {
                history.push('/marketprice')
                break
              }
              case 'history': {
                history.push('/tradehistory')
                break
              }
            }
          }}></MenuDropDown>
        <MenuDropDown
          hidden={process.env.REACT_APP_MODE_DEV !== 'true'}
          title='Developer'
          items={[
            { value: 'simple-swap', label: 'Buy-Sell Embedded' },
            { value: 'swap-emebedded', label: 'Swap Card Embedded' },
            { value: 'tour', label: 'Tour' },
            {
              value: 'advance-mode-demo',
              label: 'Advanced mode (For Designer)',
            },
            { value: 'simple-mode-demo', label: 'Simple mode (For Designer)' },
          ]}
          onChange={(item) => {
            switch (item) {
              case 'swap-emebedded': {
                window.open(
                  '/swapembedded?k=ODY3NDgyMTBkMTc5NTIyYTQ3NDZmZTMw&compact=true',
                  '_blank'
                )
                break
              }
              case 'simple-swap': {
                window.open(
                  '/swap/simple?k=ODY3NDgyMTBkMTc5NTIyYTQ3NDZmZTMw',
                  '_blank'
                )
                break
              }
              case 'swap-result': {
                history.push('/swapresult')
                break
              }
              case 'tour': {
                history.push('/swap')
                swapDispatch({
                  type: 'TOUR_BUTTON_CLICK_CHANGED',
                  payload: 'user',
                })
                break
              }
              case 'advance-mode-demo': {
                history.push('/demo')
                swapDispatch({
                  type: 'NOTIFY_VIEW_MODE_CHANGED',
                  payload: 'advance',
                })
                if (viewMode !== 'advance') setViewModeState('advance')
                break
              }
              case 'simple-mode-demo': {
                history.push('/demo')
                swapDispatch({
                  type: 'NOTIFY_VIEW_MODE_CHANGED',
                  payload: 'simple',
                })
                if (viewMode !== 'simple') setViewModeState('simple')
                break
              }
            }
          }}></MenuDropDown>
      </div>

      <div className='relative flex justify-end items-center gap-2 mr-2'>
        <MenuDropDown
          isItalicFont={false}
          icon={selectedChain?.frontEndData.icon}
          tintColor={selectedChain?.frontEndData.tint}
          title={selectedChain?.name ?? ''}
          items={getChainData(chains)}
          onChange={(item) => {
            setChainId(parseInt(item), tokens, chains, (success) => {
              if (success) {
                swapDispatch({
                  type: 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL',
                  payload: {
                    routeDisplayViewModel: null,
                    routeDisplayState: 'success',
                  },
                })
              }
            })
          }}></MenuDropDown>

        {!active ? (
          <ConnectWalletButton
            onClick={() => {
              onPresentConnectModal()
            }}>
            {tl.button_connect_wallat}
          </ConnectWalletButton>
        ) : (
          <>
            <AddressButton>
              <div className='overflow-ellipsis overflow-hidden w-32'>
                {account}
              </div>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(account || '')
                }}>
                <img
                  className='max-h-5 ml-2'
                  src='/images/icons/icon_copy.svg'
                  alt='copy-clipboard-icon'
                />
              </div>
            </AddressButton>
            <LogoutButton onClick={() => logout()}>
              {tl.sign_out}
              <img
                className='ml-2'
                src='/images/icons/icon_logout.svg'
                alt='sign-out-icon'
              />
            </LogoutButton>
          </>
        )}

        <MenuDropDown
          // isItalicFont={false}
          // icon={selectedChain?.frontEndData.icon}
          // tintColor={selectedChain?.frontEndData.tint}
          title={tl === LANG_EN ? 'EN' : 'TH'}
          items={[
            { value: 'LANG_EN', label: 'EN' },
            { value: 'LANG_TH', label: 'TH' },
          ]}
          onChange={(item) => {
            translationDispatch({
              type: 'LANG_SELECT',
              payload: item === 'LANG_EN' ? LANG_EN : LANG_TH,
            })
            saveLanguage(item === 'LANG_EN' ? 'eng' : 'tha')
          }}></MenuDropDown>
      </div>
    </div>
  )
}

export default TopBar
