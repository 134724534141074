import useToken from '../useToken'
import {
  DexInfo,
  RouterViewModel,
  RouteNodeViewModel,
  TransactionInfo,
  DexPriceListViewModel,
  RoutePath,
  RouteInfo,
  DexInfoDisplay,
} from 'models/swap.model'
import React from 'react'
import { getMultiRouteInfo, swapMultiRoute } from 'services/swap-multiroute.service'
import { Token } from '../../models/token.model'
import BigNumber from 'bignumber.js'
import { Chain } from 'models/chain.model'
import { decimalToUnsignedIntWithTokenDecimal, unformatInputNumber } from 'utils/formatter.utils'
import { MultiRouteResponse } from '../../models/swap-multiroute.model'
import { BIG_NUMBER_ZERO } from './useSwapPrice'
import { usePopupContext } from 'contexts/PopupContext'
import { useMyWallet } from 'contexts/MyWalletContext'
import { DexOutPutReturnType } from "./DexOutPutReturnType"
import { callContractProgressType } from 'models/contract.model'
import { useTranslationState } from 'contexts/TranslationContext'

const useMultiRouteSwap = () => {
  //useTranslation
  const { tl } = useTranslationState()

  const [rateV2, setRateV2] = React.useState<BigNumber>(BIG_NUMBER_ZERO)
  const { getTokenByAddress } = useToken()

  const [multiRouteNode, setMultiRouteNode] =
    React.useState<RouteNodeViewModel | null>()

  const [multiRouteResponse, setMultiRouteResponse] =
    React.useState<MultiRouteResponse | null>()

  const [dexPriceListViewModel, setDexPriceListViewModel] = React.useState<
    DexPriceListViewModel[]
  >([])

  function stepCount(
    node: RouteNodeViewModel,
    mapCount: { [index: string]: number }
  ) {
    if (node) {
      mapCount['count'] += node.routers.length
      node.nodes.forEach((element) => {
        stepCount(element, mapCount)
      })
    }
  }

  function getRouteLabelTraversal(
    node: RouteNodeViewModel,
    prefix: string
  ): string {
    if (node) {
      return prefix + ' > ' + getRouteLabelTraversal(node.nodes[0], node.symbol)
    }
    return prefix
  }

  function getRouteText(routeDisplayViewModel: RouteNodeViewModel | null) {
    if (routeDisplayViewModel) {
      if (routeDisplayViewModel.nodes.length === 1) {
        return getRouteLabelTraversal(
          routeDisplayViewModel.nodes[0],
          routeDisplayViewModel.symbol
        )
      } else {
        let mapping = { count: 0 }
        stepCount(routeDisplayViewModel, mapping)
        return mapping['count'] + ' ' + tl.swap_step_suffix
      }
    }
    return ''
  }

  const getMultiRouteSwapInfo = React.useCallback(
    async ({
      userAddress,
      tokenFrom,
      tokenTo,
      amountFrom,
      lastAmountOut = null,
      chainId,
      slippage = '0.1',
      deadLineInMinute = 20,
      chainInfo,
      dexOutPutReturnType = 'max-return',
      tokens
    }: {
      userAddress: string | null | undefined
      tokenFrom: Token | undefined
      tokenTo: Token | undefined
      lastAmountOut?: string | null
      chainId: string | null
      amountFrom: string
      slippage?: string
      deadLineInMinute?: number
      chainInfo: Chain | undefined
      dexOutPutReturnType?: DexOutPutReturnType
      tokens: Token[]
    }) => {
      let tokenFromAddress = tokenFrom?.address
      if(tokenFromAddress === "ETHER"){
        tokenFromAddress = chainInfo?.wrapTokenAddress
      }
      let tokenToAddress = tokenTo?.address
      if(tokenToAddress === "ETHER"){
        tokenToAddress = chainInfo?.wrapTokenAddress
      }
      if (tokenFrom && tokenTo && tokenFromAddress && tokenToAddress) {
        const response = await getMultiRouteInfo(
          userAddress,
          tokenFromAddress,
          tokenToAddress,
          amountFrom,
          slippage,
          lastAmountOut,
          dexOutPutReturnType,
          chainId,
        )

        if (response.status !== 'success') {
          console.log("ERROR RESPONSE getMultiRouteSwapInfo")
          return {
            viewModel: null,
            transactionInfo: null,
            response: response,
          }
        }

        let successResponse = response as MultiRouteResponse
        const viewModel = getMultiRouteSwapViewModel(
          successResponse,
          tokenFrom,
          tokenTo,
          tokens,
          chainId ? +chainId : -1,
          getTokenByAddress
        )

        const transactionInfo = getMultiRouteSwapTransactionInfo(
          successResponse,
          tokenFrom,
          tokenTo,
        )

        return {
          viewModel: viewModel,
          transactionInfo: transactionInfo,
          response: response,
        }
      }
      return null
    },
    [getTokenByAddress]
  )

  async function getRouteInfo(
    tokenFrom: Token,
    tokenTo: Token,
    multiRouteSuccessResponse: MultiRouteResponse,
    balances: string[],
    routeDisplayViewModel: RouteNodeViewModel,
    dexs: DexInfoDisplay[]
    ) {

    let token0Address = tokenFrom.address
    let multiRouteModel: MultiRouteResponse = multiRouteSuccessResponse
    var dexIdToDexInfo: { [index: string]: DexInfo } = {}
    var dexInfoMapping = dexs//await getDexInfos()
    console.log("dexInfoMapping", dexInfoMapping)

    dexInfoMapping.forEach((element: any) => {
      dexIdToDexInfo[element.dexId] = element
    })

    let amountFrom = balances[0]
    let amountTo = multiRouteModel.amountOut
    let convertedAmountToken1 = decimalToUnsignedIntWithTokenDecimal(
      unformatInputNumber(amountFrom),
      parseFloat(tokenFrom?.decimals ?? '0')
    )
    let convertedAmountToken2 = decimalToUnsignedIntWithTokenDecimal(
      unformatInputNumber(amountTo),
      parseFloat(tokenTo?.decimals ?? '0')
    )

    let travelTree = function travelsalNode(
      fromTokenAddress: string,
      node: RouteNodeViewModel,
      arr: RoutePath[][]
    ) {
      let routerPath: RoutePath[] = []
      arr.push(routerPath)
      node.routers.forEach((router) => {
        console.log("router.id", router)
        routerPath.push({
          name: router.name,
          part: '' + router.percent,
          fromTokenAddress: fromTokenAddress,
          toTokenAddress: node.address,
          routerAddress: dexIdToDexInfo[router.id].routerAddress,
          pairAddress: node.pairAddress,
          amountIn: '',
          amountOut: '',
          interface: ''
        })
      })
      node.nodes.forEach((element) => {
        travelTree(node.address, element, arr)
      })
    }

    let ratio: number[] = []
    let arr: RoutePath[][][] = []
    routeDisplayViewModel?.nodes.forEach((element) => {
      let route: RoutePath[][] = []
      arr.push(route)
      ratio.push(element.weight)
      travelTree(token0Address, element, route)
    })

    let newArry: RoutePath[]  = []
    const collectData = function collectDataInfo(route: any[], newArr: RoutePath[]) {
      route.forEach(element => {
        if('pairAddress' in element){
          newArr.push(element)
        } else {
          collectDataInfo(element, newArr)
        }
      });
    }

    //traversale to create 1 level arry
    collectData(arr, newArry)

    let jsonFormat: RouteInfo = {
      amount: '' + convertedAmountToken1,
      expectAmountOut: '' + convertedAmountToken2,
      ratio,
      route: newArry,
    }

    return jsonFormat;
  }

  const { reload } = useMyWallet()
  const { showTransactionStatePopup } = usePopupContext()
  const allowShowStatePopupRef = React.useRef(false)
  const bscHashRef = React.useRef('')

  async function swapUsingDexAggregator(
    chain: Chain,
    tokenFrom: Token,
    tokenTo: Token,
    multiRouteSuccessResponse: MultiRouteResponse,
    balances: string[],
    routeDisplayViewModel: RouteNodeViewModel,
    progressTypeCallback: (progressType: callContractProgressType) => void,
    dexs: DexInfoDisplay[]
  ){
    let routeInfo = multiRouteSuccessResponse.payload
    let minAmountOut = multiRouteSuccessResponse.minAmountOut
    console.log("routeInfo", routeInfo)
    let bscHash = ''
    swapMultiRoute(
      chain,
      minAmountOut,
      tokenFrom,
      tokenTo,
      routeInfo,
      (response) => {
        progressTypeCallback(response.type)
        if (response.type === 'WAITING') {
          allowShowStatePopupRef.current = true
          showTransactionStatePopup(
            'processing',
            {
              transactionMessage: 'Swapping',
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITTING') {
          if (response.data && 'hash' in response.data) {
            bscHash = response.data.hash
          }
          showTransactionStatePopup(
            'processing',
            {
              transactionTitle: 'Transaction Submitting',
              transactionMessage: '',
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITED') {
          if (response.data && 'hash' in response.data) {
            bscHash = response.data.hash
          }
          showTransactionStatePopup(
            'submitted',
            {
              bscAddress: bscHash,
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        }
        bscHashRef.current = bscHash
      }
    ).then((response) => {
      if (response.result === 'ERROR') {
        showTransactionStatePopup('error', {
          errorMessage: response.message,
        })
      } else if (response.result === 'FAIL') {
        showTransactionStatePopup('fail')
      } else if (response.result === 'SUCCESS') {
        reload(true)
        if (allowShowStatePopupRef.current) {
          showTransactionStatePopup('successful', {
            bscAddress: bscHash,
          })
        }
      }
    })
  }

  return {
    rateV2,
    multiRouteNode,
    multiRouteResponse,
    dexPriceListViewModel,
    setRateV2,
    getMultiRouteSwapInfo,
    getRouteText,
    getRouteInfo,
    setMultiRouteNode,
    setMultiRouteResponse,
    setDexPriceListViewModel,
    swapUsingDexAggregator
  }
}

const getMultiRouteSwapTransactionInfo = (
  response: MultiRouteResponse,
  tokenFrom: Token,
  tokenTo: Token,
) => {
  var swapNodeInfos: TransactionInfo[] = []
  return swapNodeInfos
}

const getMultiRouteSwapViewModel = (
  { selectedRoutes, weightToRoute, dexInfos }: MultiRouteResponse,
  tokenFrom: Token,
  tokenTo: Token,
  tokens: Token[],
  chainId: number,
  getTokenByAddress: (tokens: Token[], address: string, chainId: number) => Token | undefined
) => {
  let idToName: { [index: string]: string } = {}
  idToName['00071'] = 'Pancake Swap V2'
  idToName['0007'] = 'Pancake Swa'
  idToName['0002'] = 'ApeSwap'
  idToName['0020'] = 'MDEX'
  idToName['0038'] = 'Wault Swap'
  idToName['0053'] = 'Biswap'
  idToName['00572'] = 'DODO V2'

  const createNewNode = (token: Token): RouteNodeViewModel => {
    return {
      ...token,
      index: 0,
      level: 0,
      percent: 0,
      weight: 0,
      pairAddress: '',
      nodes: [],
      routers: [],
    }
  }

  const createRoute = (): RouterViewModel => {
    return {
      id: '',
      name: '',
      percent: 0,
      nodeWeight: 0,
    }
  }

  const isMathRoute = (
    node1: RouteNodeViewModel,
    node2: RouteNodeViewModel
  ): boolean => {
    const getPathLabel = (n: RouteNodeViewModel): string => {
      return n.name + ':' + (n.nodes.length ? getPathLabel(n.nodes[0]) : '')
    }
    if (getPathLabel(node1) === getPathLabel(node2)) {
      return true
    }
    return false
  }

  let dexInfoMap: { [index: string]: DexInfo } = {}
  for (let dexId in dexInfos) {
    dexInfoMap[dexId] = dexInfos[dexId]
  }

  let rootNode: RouteNodeViewModel = createNewNode(tokenFrom)
  let previousNode = rootNode
  let route = selectedRoutes
  let token1: Token

  let routeIndex = 0
  route.forEach((element) => {
    previousNode = rootNode
    // token1 = tokenFrom
    let level = 0
    element.forEach((pairInfo) => {
      let node = createNewNode(token1)
      previousNode.nodes.push(node)
      let isSameOrder = pairInfo.sameOrder
      let tokenTo = getTokenByAddress(tokens, pairInfo.tokens[isSameOrder ? 1 : 0], chainId)

      node.icon = tokenTo?.icon ?? '-'
      node.level = level
      level++
      node.symbol = tokenTo?.symbol ?? '-'
      node.name = tokenTo?.name ?? '-'
      node.address = tokenTo?.address ?? '-'
      node.percent = weightToRoute[routeIndex]
      node.weight = weightToRoute[routeIndex]
      node.pairAddress = pairInfo.pairAddress

      let route = createRoute()
      let dexInfo = dexInfoMap[pairInfo.DEX]
      route.id = dexInfo.id
      route.name = idToName[dexInfo.id] ?? dexInfo.farmName
      route.percent = 100
      route.nodeWeight = node.weight
      node.routers.push(route)
      previousNode = node
    })
    routeIndex++
  })

  ///Merge Route
  let nodes = rootNode.nodes.concat()
  let newNodes: RouteNodeViewModel[] = []

  const mergeNode = (
    node1: RouteNodeViewModel,
    node2: RouteNodeViewModel
  ): RouteNodeViewModel => {
    node1.weight = node1.weight + node2.weight
    const travelSalMergeNode = (
      n: RouteNodeViewModel,
      n2: RouteNodeViewModel
    ): void => {
      if (n === undefined && n2 === undefined) {
        return
      }

      n.routers = n.routers.concat(n2.routers)
      let totalWeight = 0
      let router
      for (router of n.routers) {
        totalWeight += router.nodeWeight
      }
      for (router of n.routers) {
        router.percent = Math.round((router.nodeWeight / totalWeight) * 100)
      }

      //merge route
      let tmpRoutes = n.routers.concat()
      let newRoutes = []
      for (let tempRoute of tmpRoutes) {
        if (newRoutes.length === 0) {
          newRoutes.push(tempRoute)
        } else {
          let isMatch = false
          for (let newRoute of newRoutes) {
            if (newRoute.id === tempRoute.id) {
              newRoute.percent = newRoute.percent + tempRoute.percent
              isMatch = true
              break
            }
          }
          if (!isMatch) {
            newRoutes.push(tempRoute)
          }
        }
      }
      newRoutes.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      n.routers = newRoutes

      if (n.nodes.length && n2.nodes.length)
        travelSalMergeNode(n.nodes[0], n2.nodes[0])
    }
    travelSalMergeNode(node1, node2)
    return node1
  }

  for (let node of nodes) {
    if (newNodes.length === 0) {
      newNodes.push(node)
    } else {
      let isMatch = false
      for (let newNode of newNodes) {
        if (isMathRoute(newNode, node)) {
          mergeNode(newNode, node)
          isMatch = true
          break
        }
      }
      if (!isMatch) {
        newNodes.push(node)
      }
    }
  }
  rootNode.nodes = newNodes

  return rootNode
}

export default useMultiRouteSwap
