import { useWeb3React } from '@web3-react/core'
import React, { memo, useCallback, useRef, useState } from 'react'
import { getUserBalance } from '../services/user.service'
import { User, UserResponse } from '../models/user.model'
import { useSwapGlobalState } from './SwapContext'
import { useTokenState } from './TokenContext'
import { BigNumber } from 'ethers'
import { getEthersProvider } from 'services/ethers.service'
import { addHistory, getHistory } from 'services/history.service'

export type BlockTransaction = {
  transactionHash: string
  timestamp: number
  blockNumber: number
  status: number | undefined
  type: number
  cumulativeGasUsed: BigNumber
  effectiveGasPrice: BigNumber
  gasUsed: BigNumber
  logs: TransactionLog[]
}

type TransactionLog = {
  address: string
  data: string
}

export type HashInfo = {
  hash: string
  chainId: number
}

type MyWalletContextProps = {
  wallets: User[]
  total: number
  loading: boolean
  showLoading: boolean
  reload: (isFetch: boolean, disableShowLoading?: boolean) => void
  transactions: BlockTransaction[]
  addTransaction: (fromAddress: string, hash: string, chainId: number) => void
  reloadTransaction: () => void
}

const MyWalletContext = React.createContext<MyWalletContextProps>({
  total: 0,
  wallets: [],
  loading: false,
  showLoading: false,
  reload: () => void 0,
  transactions: [],
  addTransaction: () => void 0,
  reloadTransaction: () => void 0,
})

const MyWalletProvider: React.FC = memo(({ children }) => {
  const { account } = useWeb3React()
  const { tokens } = useTokenState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showLoading, setShowLoading] = React.useState<boolean>(true)
  const [{ wallet: wallets, total }, setMyWallet] =
    React.useState<UserResponse>({ wallet: [], total: 0 })

  const { selectedChainId } = useSwapGlobalState()

  const reloadWallet = React.useCallback(
    async (isFetch?: boolean) => {
      console.log(
        'MyWalletProvider useCallback ,reloadWallet:',
        account,
        ',isFetch:',
        isFetch
      )
      if (!account) return
      setLoading(true)
      let wallets = null
      const filteredTokens = tokens.filter(
        (tk) => tk.swappable && selectedChainId === tk.chainId
      )
      try {
        wallets = await getUserBalance(account, isFetch, filteredTokens)
        console.log('YES', wallets)
      } catch (e) {
        console.error(e)
        console.log('NO', e)
      }
      wallets && setMyWallet(wallets)
      setLoading(false)
    },
    [account, tokens, selectedChainId]
  )

  const reload = React.useCallback(
    (isFetch: boolean, disableShowLoading) => {
      setShowLoading(!disableShowLoading)
      reloadWallet(isFetch)
    },
    [reloadWallet]
  )

  const [transactions, setTransactions] = useState<BlockTransaction[]>([])
  const loadTransaction = useCallback(
    async (hashes: HashInfo[]) => {
      const ethereum = (window as any).ethereum
      if (ethereum == null || ethereum === undefined) return
      let trasactions: BlockTransaction[] = []

      // console.log('loadTransaction >>', hashes)
      // hashes = [
      //   {
      //     chainId: 8899,
      //     hash: "0x90767d1ddd3a2ddf1bab7db9c40376f4d8f893d5f97b08cb50f2b9d4c5b465c2"
      //   }
      // ]

      // hashes = [
      //   {
      //     chainId: 3501,
      //     hash: "0x03b60c40f64c1770019ad08d16e51312bf18f5a31c2e1da8946cf18dca1f9b53"
      //   }
      // ]

      // hashes = [
      //   {
      //     chainId: 96,
      //     hash: '0x2a6b7af82164ad5f447b5bcef1f725d447ed6990280c53b8d0e3a1bd345a894d',
      //   },
      // ]

      // hashes = [
      //   {
      //     chainId: 96,
      //     hash: '0xc3afe85e14750d0758120c4bcb934786258a29f8890b261c668de75fa87b3c39',
      //   },
      // ]

      if (hashes.length) {
        const provider = await getEthersProvider()
        if (provider) {
          let trasactions2 = hashes.map(async (hash) => {
            let receipt = await provider.getTransactionReceipt(hash.hash)
            if (receipt) {
              let timestamp = (await provider.getBlock(receipt.blockNumber))
                .timestamp
              // console.log('receipt >>', receipt)
              let item = {
                transactionHash: receipt.transactionHash,
                blockNumber: receipt.blockNumber,
                cumulativeGasUsed: receipt.cumulativeGasUsed,
                timestamp: timestamp,
                effectiveGasPrice: receipt.effectiveGasPrice,
                gasUsed: receipt.gasUsed,
                status: receipt.status,
                type: receipt.type,
                logs: receipt.logs,
              }
              trasactions.push(item)
              return await item
            }
          })
          if (trasactions2.length > 0) {
            await Promise.all(trasactions2).then((val) => {
              //sort by date
              const sortedItems = trasactions.sort((item1, items2) => {
                return items2.timestamp - item1.timestamp
              })
              setTransactions(sortedItems)
            })
          }
        }
      } else {
        setTransactions([])
      }
    },
    [setTransactions]
  )

  const reloadTransaction = useCallback(async () => {
    if (account) {
      let hashInfo = await getHistory(account, selectedChainId)
      const filteredData = hashInfo.data.filter((item) => {
        return item.chainId === selectedChainId
      })
      loadTransaction(filteredData)
    }
  }, [account, loadTransaction, selectedChainId])

  const addTransaction = useCallback(
    async (fromAddress: string, hash: string, chainId: number) => {
      await addHistory(fromAddress, chainId, hash)
      reloadTransaction()
    },
    [reloadTransaction]
  )

  const initInfo = useRef({ init: false, chain: 0 })
  React.useEffect(() => {
    reloadWallet()
    if (initInfo.current.init === false && account) {
      initInfo.current.init = true
      initInfo.current.chain = selectedChainId
      reloadTransaction()
    }
    if (initInfo.current.init && initInfo.current.chain !== selectedChainId) {
      initInfo.current.chain = selectedChainId
      reloadTransaction()
    }
  }, [
    account,
    loadTransaction,
    reloadTransaction,
    reloadWallet,
    selectedChainId,
  ])

  return (
    <MyWalletContext.Provider
      value={{
        loading,
        showLoading: loading && showLoading,
        wallets,
        total,
        reload,
        transactions,
        addTransaction,
        reloadTransaction
      }}>
      {children}
    </MyWalletContext.Provider>
  )
})
const useMyWallet = () => React.useContext(MyWalletContext)

export { MyWalletProvider, useMyWallet }
