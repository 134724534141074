/* eslint-disable jsx-a11y/alt-text */
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { ASSETS_BASE_URL } from 'constants/url.constant'
import { useChainState } from 'contexts/ChainContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { getChainById } from 'hooks/Chain/useChain'
import { Token } from 'models/token.model'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import tokenJson from './temp_supportedtokens.json'
import { TradeHistoryModel } from 'models/display.model'
import { getTransaction } from 'services/contract.service'
import { get, set } from 'lodash'
import { ethers } from 'ethers'
import HERMES_ABI from 'constants/abi/reduced-hermes-router-abi.json'
import moment from 'moment'

export const TradeHistory: FC<{
  isCompact?: boolean
  isOnlyMadeByHermes: boolean
}> = ({ isCompact, isOnlyMadeByHermes }) => {
  const visibleDataRef = useRef<TradeHistoryModel[]>([])
  const { chains } = useChainState()
  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    let chain = getChainById(chains, selectedChainId)
    return chain
  }, [chains, selectedChainId])

  const [transactionList, setList] = React.useState<TradeHistoryModel[]>([])
  const [visibledDataList, setVisibledDataList] = React.useState<
    TradeHistoryModel[] | undefined
  >(undefined)
  const { account } = useWeb3React()

  const [init, setInit] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [maxPerPage] = React.useState(10)
  const [indexScroll, setIndexScroll] = React.useState(0)

  const loadedData = useRef<{ [index: string]: boolean }>({})

  const updateDataList = useCallback(
    (transactionList: TradeHistoryModel[]) => {
      let tempList: TradeHistoryModel[] = []
      let startIndex = pageIndex * maxPerPage
      let lastIndex = (+pageIndex + 1) * maxPerPage
      for (let i = startIndex; i < lastIndex; i++) {
        let item = transactionList[i]
        if (item === undefined) break
        tempList.push(transactionList[i])
      }

      if (tempList.length > 0) {
        console.log('setVisibledDataList 2', tempList)
        visibleDataRef.current = tempList
        setVisibledDataList(tempList)
      }
    },
    [maxPerPage, pageIndex]
  )

  const isCompactValue = useMemo(() => {
    if (isCompact !== undefined) {
      return isCompact
    }
    return false
  }, [isCompact])

  const BSC_API_KEY = 'AED2BBXYWPSJVQYN451IKI2RXCUPC4D2NP'
  const transactionAPI = useMemo(() => {
    if (selectedChain) {
      switch (selectedChain?.chainId) {
        case 56:
          return (
            'https://api.bscscan.com/api?module=account&action=txlist&address=' +
            account +
            '&sort=desc&apikey=' +
            BSC_API_KEY
          )
      }
    }
    return ''
  }, [account, selectedChain])

  const loadTransaction = useCallback(async () => {
    try {
      let URL = transactionAPI
      if (!URL) {
        setList([])
        updateDataList([])
      }

      console.log('URL', URL)
      let history = (await axios.get(URL)).data

      let result = history.result
      let tempList: TradeHistoryModel[] = []
      result.forEach((element: any) => {
        let item: Object = Object(element)
        let timeStamp = Object(item).timeStamp * 1000
        let fee =
          (+Object(item).gasPrice * +Object(item).gasUsed) / Math.pow(10, 18)
        let date = new Date(timeStamp)
        tempList.push({
          date: date,
          icon: 'string',
          pair: 'string',
          to: Object(item).to,
          value: Object(item).value,
          price: 'string',
          action: Object(item).input,
          excuted: 'string',
          fee: fee.toFixed(10),
          total: 'string',
          transactionId: Object(item).hash,
          txreceipt_status: Object(item).txreceipt_status,
          detail: undefined,
        })
      })
      if (isOnlyMadeByHermes) {
        tempList = tempList.filter((item) => {
          let dexAggregatorContract =
            selectedChain?.dexAggregatorContract.toLowerCase()
          console.log('dexAggregatorContract >>', dexAggregatorContract)
          if (
            item.to.toLowerCase() ===
            selectedChain?.dexAggregatorContract.toLowerCase()
          ) {
            return true
          }
          return false
        })
      }
      setList(tempList)
      updateDataList(tempList)
    } catch (e) {
      return
    }
  }, [
    transactionAPI,
    isOnlyMadeByHermes,
    updateDataList,
    selectedChain?.dexAggregatorContract,
  ])

  const tokenMapping = React.useRef<{ [index: string]: Token }>({})
  React.useEffect(() => {
    if (init && !loaded) {
      console.log('setVisibledDataList 1 undefined')
      setVisibledDataList(undefined)
      setLoaded(true)
      loadTransaction()
      tokenJson.forEach((element) => {
        tokenMapping.current[element.address.toLowerCase()] = element
      })
    }
  }, [init, loadTransaction, loaded])

  React.useEffect(() => {
    if (transactionList) updateDataList(transactionList)
  }, [pageIndex, transactionList, updateDataList])

  function DateToYYYYMMDD(Date: Date): string {
    let DS: string =
      Date.getFullYear() +
      '/' +
      ('0' + (Date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + Date.getDate()).slice(-2) +
      ' ' +
      Date.getHours() +
      ':' +
      Date.getMinutes() +
      ':' +
      Date.getSeconds()
    return DS
  }

  const NO_ICON_SOURCE = 'assets/icons/token/noimg.png'
  function getPairLabel(item: TradeHistoryModel) {
    let to = item.detail?.tokenIn ?? ''
    let out = item.detail?.tokenOut ?? ''
    let tokenIn = tokenMapping.current[to.toLowerCase()]
    let tokenOUt = tokenMapping.current[out.toLowerCase()]
    if (tokenIn && tokenOUt) {
      return (
        <div className='flex flex-row items-center space-x-2'>
          <div>
            <img
              className='w-6'
              src={
                tokenIn?.icon ? ASSETS_BASE_URL + tokenIn.icon : NO_ICON_SOURCE
              }
            />
          </div>
          <div>{tokenIn.name}</div>
          <div className='p-2'>{'->'}</div>
          <div>
            <img
              className='w-6'
              src={
                tokenOUt?.icon
                  ? ASSETS_BASE_URL + tokenOUt.icon
                  : NO_ICON_SOURCE
              }
            />
          </div>
          <div>{tokenOUt.name}</div>
        </div>
      )
    }
    return '-'
  }

  function getAmount(item: TradeHistoryModel) {
    let to = item.detail?.tokenIn ?? ''
    let out = item.detail?.tokenOut ?? ''
    let tokenIn = tokenMapping.current[to.toLowerCase()]
    let tokenOut = tokenMapping.current[out.toLowerCase()]
    let amountIn = item.detail?.amountIn ?? 0
    let amountOut = item.detail?.amountOut ?? 0
    if (tokenIn && tokenOut) {
      amountIn = amountIn / Math.pow(10, +tokenIn.decimals)
      amountOut = amountOut / Math.pow(10, +tokenOut.decimals)
      return (
        <div className='flex justify-start flex-col'>
          <div className='flex'>
            {'-' + amountIn + ' ' + tokenIn.symbol.toUpperCase()}
          </div>
          <div className='flex text-green-400'>
            {'+' + amountOut + ' ' + tokenOut.symbol.toUpperCase()}
          </div>
        </div>
      )
    }
    return '-'
  }

  function getExecuted(item: TradeHistoryModel) {
    var covertAmountFrom = new BigNumber(item.value).dividedBy(Math.pow(10, 18))
    let label = covertAmountFrom.toString()
    let action = getActionType(item)
    if (action === 'Receive') {
      return <div className='text-green-300'>{'+' + label}</div>
    }
    return label
  }

  function getStatus(item: TradeHistoryModel) {
    if (item.txreceipt_status === '1') {
      return (
        <div className='flex items-center text-green-200 bg-green-900 px-2 h-8 rounded-xl'>
          Success
        </div>
      )
    }
    return (
      <div className='flex items-center text-red-200 bg-red-900 px-2 h-8 rounded-xl'>
        Fail
      </div>
    )
  }

  function getActionType(item: TradeHistoryModel) {
    let sliceInput = item.action.slice(0, 10)
    if (sliceInput === '0x095ea7b3') {
      return 'Approve'
    } else if (sliceInput === '0x1058d281') {
      return 'Leave Staking'
    } else if (sliceInput === '0xa9059cbb') {
      return 'Transfer'
    } else if (sliceInput === '0x441a3e70') {
      return 'Withdraw'
    } else if (sliceInput === '0xa22cb465') {
      return 'Set Approval For All'
    } else if (sliceInput === '0x38ed1739') {
      return 'Swap Exact Tokens for Tokens'
    } else {
      if (item.to.toLowerCase() === account?.toLowerCase()) {
        return 'Receive'
      }
    }
    return '-'
  }

  function makeNavigatorButton() {
    let pageCount = Math.ceil(transactionList.length / maxPerPage)
    let numButtuon = []
    let isSelected = false
    let cursor = ' cursor-pointer hover:bg-gray-900'
    let hoverState = ' items-center justify-center'
    let startIndex = indexScroll
    let endIndex = startIndex + pageCount - 1
    for (var i = startIndex; i < endIndex; i++) {
      isSelected = i === +pageIndex
      let index = i
      let button = (
        <div
          id={'navigate_' + index}
          className={
            'flex w-8' +
            (isSelected ? ' text-blue-400 border border-blue-400' : '') +
            hoverState +
            cursor
          }
          // eslint-disable-next-line no-loop-func
          onClick={(button) => {
            let id = Object(button).target.id.replace('navigate_', '')
            setPageIndex(id)
          }}>
          {i + 1}
        </div>
      )
      numButtuon.push(button)
    }
    let leftArrowEnabled = startIndex !== 0
    let rightArrowEnabled = endIndex < pageCount
    return (
      <div className='flex flex-1'>
        <div
          className={
            'flex w-8' +
            hoverState +
            (leftArrowEnabled ? cursor : ' select-none opacity-30') +
            (pageCount > 0 ? ' hidden' : '')
          }
          onClick={() => {
            setIndexScroll(Math.max(0, indexScroll - 10))
          }}>
          {'<<'}
        </div>
        {numButtuon}
        <div
          className={
            'flex w-8' +
            hoverState +
            (rightArrowEnabled ? cursor : ' select-none opacity-30') +
            (pageCount > 0 ? ' hidden' : '')
          }
          onClick={() => {
            setIndexScroll(Math.min(pageCount - 10, indexScroll + 10))
          }}>
          {'>>'}
        </div>
      </div>
    )
  }

  const BNB_PRICE_URL = "https://api.coingecko.com/api/v3/coins/binancecoin/history?date="
  const loadTranscationWithHash = useCallback(
    async (hash: string) => {
      let data = await getTransaction(hash)
      console.log("loadTranscationWithHash data >>>", data)
      let items = visibleDataRef.current
      let filters = visibleDataRef.current.filter((item) => {
        return item.transactionId.toLowerCase() === hash.toLowerCase()
      })

      if (filters && filters.length > 0) {
        let item = filters[0]

        //https://api.coingecko.com/api/v3/coins/binancecoin/history?date=30-04-2023
        const inter = new ethers.utils.Interface(HERMES_ABI)
        const decodedInput = inter.parseTransaction({
          data: get(data, 'data'),
          value: get(data, 'value'),
        })

        let URL = BNB_PRICE_URL + (moment(item.date)).format('DD-MM-YYYY')
        console.log("URL", URL)
        let gasPrice = (await axios.get(URL)).data
        console.log("gasPrice", gasPrice.market_data.current_price.usd)
        let bnbPriceInUSD = gasPrice.market_data.current_price.usd
        item.fee = (+(item.fee) * bnbPriceInUSD).toPrecision(2)

        console.log("decodedInput", decodedInput)
        let srcTokens = get(decodedInput, 'args.0.srcTokens')
        let dstTokens = get(decodedInput, 'args.0.dstTokens')
        let amount = get(decodedInput, 'args.program.amount')
        let amountOut = get(decodedInput, 'args.program.expectAmountOut')
        let balance = new BigNumber(amount._hex)

        let detail = {
          tokenIn: srcTokens[0],
          tokenOut: dstTokens[0],
          amountIn: balance.toNumber(),
          amountOut: amountOut,
        }
        set(item, 'detail', detail)

        visibleDataRef.current = items

        let noMore = visibleDataRef.current.filter((item) => {
          return !item.detail
        })
        if (noMore.length === 0) {
          // let aa = setInterval(() => {
          //   let newItems = items.concat()
          //   console.log("setVisibledDataList 3", newItems)
          //   setVisibledDataList(items)
          //   clearInterval(aa)
          // }, 2000)
          let newItems = items.concat()
          setVisibledDataList(newItems)
        }
      }
    },
    [setVisibledDataList]
  )

  const getCampactRow = useCallback(
    (item: TradeHistoryModel) => {
      let hash = item.transactionId
      if (!loadedData.current[hash]) {
        loadedData.current[hash] = true
        loadTranscationWithHash(hash)
      }
      if (!item.detail) {
        return <></>
      }
      return (
        <div
          className='flex flex-1 flex-row text-gray-300 py-3 border-b border-gray-600'
          style={{ minHeight: '50px' }}>
          <div className='flex w-48 flex-col'>
            <div>{DateToYYYYMMDD(item.date)}</div>
            <div
              className='flex flex-1 w-40 cursor-pointer text-blue-300'
              style={{ overflow: 'clip' }}
              onClick={() => {
                window.open('https://bscscan.com/tx/' + item.transactionId)
              }}>
              {item.transactionId.substring(
                item.transactionId.length - 10,
                item.transactionId.length
              )}
            </div>
          </div>
          <div className='flex w-64'>{getPairLabel(item)}</div>
          <div className='flex w-48'>{getAmount(item)}</div>
          <div className='flex w-24 justify-end pr-4'>{item.fee}</div>
          <div className='flex w-24 justify-end pr-4' style={{ color: '#40BF77' }}>
            {'0'}
          </div>
          <div className='flex w-24 justify-end'>{getStatus(item)}</div>
        </div>
      )
    },
    [loadTranscationWithHash]
  )

  // const isReady = useMemo(() => {
  //   let ready = false
  //   if (visibledDataList) {
  //     const noMore = visibleDataRef.current.filter((item) => {
  //       return !item.detail
  //     })
  //     ready = noMore.length === 0
  //   } else {
  //     ready = false
  //   }
  //   return ready
  // }, [visibledDataList])

  function makeCampactView() {
    return (
      <div
        className='flex flex-1 text-sm flex-col border border-gray-600'
        style={{}}>
        <div className='flex flex-col px-10 pt-8 pb'>
          <div
            className='flex flex-1 flex-row text-gray-400 py-3 border-b border-gray-600'
            style={{ height: '45px' }}>
            <div className='flex w-48'>Date</div>
            <div className='flex w-64'>Pair / Token</div>
            <div className='flex w-48'></div>
            <div className='flex w-24 justify-end'>Tx Cost​ (USD)</div>
            <div className='flex w-24 justify-end' style={{ color: '#40BF77' }}>
              HERMES fee
            </div>
            <div className='flex w-24 justify-end'>Status</div>
          </div>
          <div>
            {visibledDataList &&
              visibledDataList.map((item) => {
                return getCampactRow(item)
              })}
            <div
              className={'animate-pulse' + (visibledDataList ? ' hidden' : '')}>
              {[1, 2, 3, 4 ].map(() => {
                return (
                  <div
                    className='flex flex-1 flex-row text-gray-300 bg-gray-600 my-4 rounded-xl '
                    style={{ height: '50px' }}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className='flex fle-1 px-8 font-bold justify-between text-white pt-4 pb-8'>
          <div>
            {+pageIndex * maxPerPage +
              1 +
              ' - ' +
              Math.min(transactionList.length, (+pageIndex + 1) * maxPerPage) +
              ' From ' +
              transactionList.length +
              ' Record '}
          </div>
          <div>{makeNavigatorButton()}</div>
        </div>
      </div>
    )
  }

  function makeView() {
    return (
      <div
        className='flex flex-1 text-sm flex-col border border-gray-600 bg-gray-700 rounded-xl'
        style={{}}>
        <div className='flex flex-col px-10 pt-8 pb'>
          <div
            className='flex flex-1 flex-row text-gray-400 py-3 border-b border-gray-600'
            style={{ height: '45px' }}>
            <div className='flex w-48'>Date</div>
            <div className='flex w-64'>Pair / Token</div>
            <div className='flex w-32'>Price</div>
            <div className='flex w-32'>Executed</div>
            <div className='flex w-60'>Action</div>
            <div className='flex w-32'>Fee</div>
            <div className='flex w-20'>Txn Hash</div>
          </div>
          <div>
            {visibledDataList &&
              visibledDataList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='flex flex-1 flex-row text-gray-300 py-3 border-b border-gray-600'
                    style={{ height: '50px' }}>
                    <div className='flex w-48'>{DateToYYYYMMDD(item.date)}</div>
                    <div className='flex w-64'>{getPairLabel(item)}</div>
                    <div className='flex w-32'>{getAmount(item)}</div>
                    <div className='flex w-32'>{getExecuted(item)}</div>
                    <div className='flex w-60'>{getActionType(item)}</div>
                    <div className='flex w-32'>{item.fee}</div>
                    <div
                      className='flex flex-1 w-20 cursor-pointer text-blue-300'
                      style={{ overflow: 'clip' }}
                      onClick={() => {
                        window.open(
                          'https://bscscan.com/tx/' + item.transactionId
                        )
                      }}>
                      {item.transactionId.slice(0, 30) + '...'}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className='flex fle-1 px-8 font-bold justify-between text-white pt-4 pb-8'>
          <div>
            {+pageIndex * maxPerPage +
              1 +
              ' - ' +
              Math.min(transactionList.length, (+pageIndex + 1) * maxPerPage) +
              ' From ' +
              transactionList.length +
              ' Record '}
          </div>
          <div>{makeNavigatorButton()}</div>
        </div>
      </div>
    )
  }

  if (!init) setInit(true)

  return (
    <div
      className={'w-full h-full p-6' + (isCompactValue ? '' : ' bg-gray-800')}>
      {isCompactValue && makeCampactView()}
      {!isCompactValue && makeView()}
    </div>
  )
}
