import styled from 'styled-components'
export const ActivePath = styled.div.attrs({
  className: ' bg-green-500 absolute h-10 rounded-r-lg left-0',
})`
  width: 4px;
`

export const Title = styled.h1.attrs({
  className: 'text-xl',
})`
  > * {
    &:first-child {
      color: #faa819;
    }
  }
`
export const Descriptions = styled.p.attrs({
  className: 'leading-3 overflow-ellipsis overflow-hidden',
})`
  font-size: 0.5rem;
  color: rgba(92, 92, 92, 1);
`

export const Container = styled.div.attrs({
  className:
    'fixed z-50 bg-white  top-0 right-0 bottom-0 w-72 h-screen overflow-scroll card',
})`
  ${({ isShow = false }: { isShow: boolean }) => !isShow && 'display: none'}
`
