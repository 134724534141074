import { LoadingComponent } from "components/LoadingComponent";
import styled from "styled-components";

export const SwapLoading = styled(LoadingComponent).attrs((props) => ({
  ...props,
  loading: true,
}))`
  transform: scale(0.4);
  width: 82px;
`
