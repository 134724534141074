import React from 'react'
import { withStyles } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'

const ValueLabelComponent: React.FC<{ value: number }> = ({ value }) => {
  return <div style={{ fontSize: '12px' }}>{value}</div>
}

const PriceSlider = withStyles({
  root: {
    color: '#C8CED0',
    height: 8,
  },
  thumb: {
    height: 9,
    width: 9,
    backgroundColor: '#40BF77',
    borderRadius: '50%',
    marginTop: -4,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 2,
    borderRadius: 2,
    backgroundColor: '#40BF77',
  },
  valueLabel: {
    left: 'calc(-50% - 5px)',
  },
  rail: {
    height: 2,
    borderRadius: 2,
    backgroundColor: '#000000',
  },
  mark: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    marginTop: -4,
    marginLeft: -6,
  },
  markLabel: {
    color: '#97A3A6',
    fontSize: '12px',
    // fontWeight: '500',
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#40BF77',
    border: '1px solid #C8CED0',
  },
})(Slider)

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

function NumberSlider(props: typeof PriceSlider.defaultProps) {
  return (
    <PriceSlider
      marks={marks}
      min={0}
      max={100}
      valueLabelDisplay='auto'
      valueLabelFormat={(value) => <ValueLabelComponent value={value} />}
      {...props}
    />
  )
}

export default NumberSlider
