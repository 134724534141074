import styled from 'styled-components'

export const ConnectWalletButton = styled.button.attrs({
  className:
    'text-white rounded-lg py-2 px-4 mx-1 focus:outline-none bg-gray-700 border-gray-600 ring-green-400 hover:ring-2 hover:bg-gray-700',
})``

export const AddressButton = styled.button.attrs({
  className:
    'text-white flex items-center rounded-lg text-sm border border-2 bg-gray-700 border-gray-600 p-2 mx-1 ring-blue-400 hover:ring-2 truncate focus:outline-none',
})``

export const ConfigButton = styled.button.attrs({
  className:
    'text-white flex items-center rounded-lg text-sm border bg-gray-700 border-gray-600 p-2 mx-1 ring-blue-400 hover:ring-2 truncate focus:outline-none',
})``

export const LogoutButton = styled.button.attrs({
  className:
    'text-white flex items-center  rounded-lg text-sm border bg-gray-700 border-gray-600 p-2 mx-1 ring-red-400 hover:ring-2 truncate focus:outline-none',
})``
