import { createContext, useReducer } from 'react'
import { PremiumUserResponse } from '../models/user.model'

export const UserContext = createContext<PremiumUserResponse>({
  level: 'FREE',
  msczBalance: 0,
  rate: 0,
  sczBalance: 0,
  value: 0,
} as PremiumUserResponse)

export const userReducer = (
  state: PremiumUserResponse,
  action: PremiumUserResponse
) => {
  return action
}

export const UserProvider: React.FC = ({ children }) => {
  const [user] = useReducer(userReducer, {
    level: 'FREE',
    msczBalance: 0,
    rate: 0,
    sczBalance: 0,
    value: 0,
  })

  // useEffect(() => {
  //   if (account) {
  //     const checkProWallet = async () => {
  //       const premium = await getUserIsPremium(account, false)
  //       setUserispatch(premium)
  //     }
  //     checkProWallet()
  //   }
  // }, [account])

  return (
    <UserContext.Provider value={{ ...user }}>{children}</UserContext.Provider>
  )
}
