import axios from 'axios'
import {
  User,
  UserResponse,
  PremiumUserResponse,
  TelegramProfileRequest,
} from 'models/user.model'
import { BACKEND_BASE_URL } from '../constants/url.constant'
import ERC20_ABI from '../constants/abi/erc20-abi.json'
import { getEthersProvider } from './ethers.service'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { Token } from 'models/token.model'
import { ConnectorNames } from 'hooks/useAuth'
import { connectorsByName } from 'utils/connector.utils'

// export const registerUser = async (
//   request: LoginRequest
// ): Promise<LoginResponse> => {
//   return Promise.reject()
// }

// export const getUserById = async (usersId: string): Promise<UserResponse> => {
//   return Promise.reject()
// }

export const getUserBalance = async (
  walletAddress: string,
  isFetch: boolean = false,
  swapTokenList: Token[]
): Promise<UserResponse> => {
  if (!walletAddress) {
    return Promise.reject()
  }

  return fetchUserBalance(walletAddress, swapTokenList)
  // try {
  //   return fetchUserBalance(walletAddress, swapTokenList)
  // } catch (e) {
  //   console.log("ERROR : getUserBalance", JSON.stringify(e))
  //   throw e
  // }
}

async function fetchUserBalance(
  walletAddress: string,
  swapTokenList: Token[]
): Promise<UserResponse> {
  let provider: any

  const connectorID = window.localStorage.getItem('loginType')
  // console.log('user.service fetchUserBalance', ', connectorID:', connectorID)
  if (connectorID === ConnectorNames.Injected) {
    provider = await getEthersProvider()
  } else if (connectorID === ConnectorNames.BSC) {
    const bscConnector = connectorsByName[ConnectorNames.BSC]
    let bscProvider = await bscConnector.getProvider()
    provider = new ethers.providers.Web3Provider(bscProvider)
  } else if (connectorID === ConnectorNames.WalletConnect) {
  }

  // console.log('user.service fetchUserBalance provider', provider)
  if (!provider) return Promise.reject()

  var signer = provider.getSigner()
  var total: number = 0
  var wallets: User[] = []
  var index = 0
  // console.log('signer', signer)

  await Promise.all(
    swapTokenList.map(async (b) => {
      let balance = new BigNumber(0)

      let address = b.address
      // console.log('address DEBUG', address)
      if (address === 'ETHER') {
        const userBalance = await provider.getBalance(walletAddress)
        balance = new BigNumber(userBalance._hex)
        // console.log('CASE 1 balance', balance, ' for:', address)
      } else {
        try {
          let contract = new ethers.Contract(
            address,
            ERC20_ABI,
            signer.connectUnchecked()
          )
          let balanceData = await contract.balanceOf(walletAddress)
          balance = new BigNumber(balanceData._hex)
          // console.log('CASE 2 balance', balance, ' for:', address)
        } catch (error) {}
      }
      total = total + balance.toNumber()

      wallets.push({
        name: b.name,
        imageUrl: b.icon,
        balance: balance + '',
        rawBalance: balance + '',
        price: '',
        value: '',
        order: index,
        isCustomToken: true,
        tokenAddress: address,
        decimals: b.decimals,
      })

      index++
    })
  )

  // console.log('total >>', total)
  // console.log('wallets >>', wallets)

  return new Promise((resolve, reject) => {
    resolve({
      total: total,
      wallet: wallets,
    })
  })
}

export const getUserIsPremium = async (
  walletAddress: string,
  isSync: boolean
): Promise<PremiumUserResponse> => {
  return Promise.reject()
  // if (!walletAddress) {
  //   return Promise.reject()
  // }

  // const instance = axios.create()
  // try {
  //   const { data } = await instance.get<PremiumUserResponse>(
  //     `${BACKEND_BASE_URL}/proAccount?account=${walletAddress}&sync=${isSync}`
  //   )
  //   return data
  // } catch (e) {
  //   console.log('getCheckPremium : ', JSON.stringify(e))
  //   throw e
  // }
}

export const addTelegramProfile = async (request: TelegramProfileRequest) => {
  try {
    return await axios.post(`${BACKEND_BASE_URL}/telegram-profile`, request)
  } catch (e) {
    throw e
  }
}
