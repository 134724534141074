import styled from 'styled-components'

export type OverlayProps = {
  show: boolean
  zIndex?: number
}

const Overlay = styled.div.attrs({
  role: 'presentation',
  className: 'bg-gray-500 bg-opacity-75 transition-opacity',
})<OverlayProps>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: ${({ show }) => (show ? 'initial' : 'none')};
`

Overlay.defaultProps = {
  show: false,
  zIndex: 10,
}

export default Overlay
