import _ from 'lodash'
import React from 'react'
// import { useMyFarm } from '../contexts/MyFarmContext'
import { useMyWallet } from '../contexts/MyWalletContext'
import { usePopupContext } from '../contexts/PopupContext'
import {
  callContractProgress,
  callContractResponse,
} from '../models/contract.model'
import { MyFarmDetail, Farm } from '../models/farm.model'

import {
  getPoolInfoWithFarmDetails,
  poolWithdraw,
  withdraw,
} from '../services/farm.service'
import {
  decimalToUnsignedIntWithTokenDecimal,
  formatBalance,
  unformatInputNumber,
} from '../utils/formatter.utils'

const useWithdraw = () => {
  const { reload: reloadWallet } = useMyWallet()
  // const { reloadAndWatch: reloadFarm, lastUpdate } = useMyFarm()
  const bscHashRef = React.useRef('')
  const allowShowStatePopupRef = React.useRef(false)
  const watchFarmBeforeSuccessRef = React.useRef<Record<string, string> | null>(
    null
  )
  const { showTransactionStatePopup } = usePopupContext()

  // success popup
  React.useEffect(() => {
    const current = watchFarmBeforeSuccessRef.current
    // if (!current || !lastUpdate) return

    // for (const farmId in current) {
    //   if (current[farmId] === lastUpdate[farmId]) continue
    //   delete current[farmId]
    // }

    if (_.isEmpty(current)) {
      watchFarmBeforeSuccessRef.current = null
      if (allowShowStatePopupRef.current) {
        showTransactionStatePopup('successful', {
          bscAddress: bscHashRef.current,
        })
      }
    }
  }, [showTransactionStatePopup]) //[lastUpdate, showTransactionStatePopup])

  const withdrawFunc = React.useCallback(
    (
      farmInfo: Farm,
      farmDetail: MyFarmDetail,
      poolid: number,
      amount: string
    ) => {
      let decimals = farmInfo.tokenDecimals
      let convertedAmount = decimalToUnsignedIntWithTokenDecimal(
        unformatInputNumber(formatBalance(amount)),
        decimals
      )

      var responseFunction = (response: callContractProgress) => {
        if (response.type === 'WAITING') {
          allowShowStatePopupRef.current = true
          showTransactionStatePopup(
            'processing',
            {
              transactionMessage:
                'Withdrawing ' + amount + ' ' + farmInfo.tokenSymbol,
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITTING') {
          if (response.data && 'hash' in response.data) {
            bscHashRef.current = response.data.hash
          }
          showTransactionStatePopup(
            'processing',
            {
              transactionTitle: 'Transaction Submitting',
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        } else if (response.type === 'SUBMITED') {
          if (response.data && 'hash' in response.data) {
            bscHashRef.current = response.data.hash
          }
          showTransactionStatePopup(
            'submitted',
            {
              bscAddress: bscHashRef.current,
            },
            undefined,
            () => {
              allowShowStatePopupRef.current = false
            }
          )
        }
      }

      var farmMethodResponseFunction = (response: callContractResponse) => {
        if (response.result === 'ERROR') {
          showTransactionStatePopup('error', {
            errorMessage: response.message,
          })
        } else if (response.result === 'FAIL') {
          showTransactionStatePopup('fail')
        } else if (response.result === 'SUCCESS') {
          // const farmId = farmInfo?.id ?? ''

          reloadWallet(true, true)
          // reloadFarm([farmId], true)

          // if (!watchFarmBeforeSuccessRef.current)
          //   watchFarmBeforeSuccessRef.current = {}
          // watchFarmBeforeSuccessRef.current[farmId] = lastUpdate
          //   ? lastUpdate[farmId]
          //   : ''
        }
        return true
      }

      let poolInfo = getPoolInfoWithFarmDetails(farmInfo, farmDetail)
      if (poolInfo) {
        return poolWithdraw(
          poolInfo,
          poolid,
          convertedAmount.toString(),
          responseFunction
        ).then(farmMethodResponseFunction)
      }
      return withdraw(
        farmInfo,
        poolid,
        convertedAmount.toString(),
        responseFunction
      ).then(farmMethodResponseFunction)
    },
    [reloadWallet, showTransactionStatePopup] //[reloadFarm, reloadWallet, showTransactionStatePopup, lastUpdate]
  )

  return withdrawFunc
}

export default useWithdraw
