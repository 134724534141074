import { useChainState } from 'contexts/ChainContext'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { getChainById } from 'hooks/Chain/useChain'
import React, { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import useSos from '../../hooks/useSos'
import { Farm, MyFarmDetail } from '../../models/farm.model'
export type TransactionPopupStatusType =
  | 'processing'
  | 'submitted'
  | 'successful'
  | 'error'
  | 'fail'
  | 'sos'

export type TransactionPopupInfo = {
  errorMessage?: string
  transactionTitle?: string
  transactionMessage?: string

  selectedFarmInfo?: Farm
  selectedFarmDetail?: MyFarmDetail
  selectedPoolId?: number
  bscAddress?: string
}

const USE_TOAST = true

const TransactionStatePopup = ({
  statusType,
  info,
  onComplete,
  onClose,
}: {
  statusType: TransactionPopupStatusType
  info?: TransactionPopupInfo
  onComplete?: () => void
  onClose?: () => void
}) => {
  const { chains } = useChainState()
  const { selectedChainId, loadingPopupId } = useSwapGlobalState()
  const swapDispatch = useSwapDispatch()
  const callSOS = useSos()

  const selectedChain = React.useMemo(
    () => getChainById(chains, selectedChainId),
    [chains, selectedChainId]
  )

  function getViewTransactionLabel() {
    return 'View on ' + selectedChain?.frontEndData.blockExplorerName
  }

  function openBSCLink() {
    let blockExploreURL = selectedChain?.frontEndData.blockExplorerUrls[0]
    window.open(blockExploreURL + '/tx/' + info?.bscAddress, '_blank')
  }

  const loadingIdRef = useRef(loadingPopupId)
  loadingIdRef.current = loadingPopupId

  const transactionTitle = info?.transactionTitle

  useEffect(() => {
    if (!USE_TOAST || statusType === 'sos') return

    if (loadingIdRef.current) {
      toast.dismiss(loadingIdRef.current)
      swapDispatch({
        type: 'NOTIFY_LOADING_STATUS',
        payload: '',
      })
    }

    switch (statusType) {
      case 'processing':
        const payload = toast.loading(
          transactionTitle ? transactionTitle : 'Waiting For Confirmation'
        )
        swapDispatch({
          type: 'NOTIFY_LOADING_STATUS',
          payload,
        })
        break

      case 'submitted':
        toast.info('Transaction Submitted')
        break

      case 'successful':
        toast.success('Transaction Successful')
        break

      case 'fail':
        toast.error('Transaction fail')
        break

      case 'error':
        toast.error(
          <div>
            <div>Transaction error</div>
            <textarea
              data-testid='error-textarea'
              defaultValue={info?.errorMessage ? info.errorMessage : ''}
              style={{
                width: '100%',
                border: '1px solid #FD5151',
                marginTop: '10px',
                padding: '10px',
                minHeight: '86px',
                borderRadius: '8px',
              }}
            />
          </div>,
          {
            closeOnClick: false,
          }
        )
        break

      default:
        break
    }
  }, [transactionTitle, statusType, swapDispatch, info?.errorMessage])

  if (USE_TOAST) {
    return <></>
  }

  return (
    <div className='fixed z-50 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'>
          &#8203;
        </span>
        <div
          className='inline-block mb-28 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
          style={{ minHeight: '317px' }}>
          <div className='text-right px-3 pt-2'>
            <button
              type='button'
              onClick={() => {
                if (onClose) onClose()
              }}
              className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
              <span className='sr-only'>Close menu</span>
              <svg
                style={{ width: '20px', height: '20px' }}
                xmlns='http://www.w3.org/2000/svg'
                fill='#C8CED0'
                viewBox='0 0 24 24'
                stroke='#40BF77'
                aria-hidden='true'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>

          {/* When waiting for conformation  */}
          <div
            className='flex-col text-center justify-center item-center w-100 max-w-md'
            style={{ paddingBottom: '25px', paddingTop: '1px' }}>
            <div className='mx-12 sm:mx-16'>
              <div
                style={{
                  display: statusType === 'processing' ? '' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ marginTop: '1px' }}>
                  <svg
                    className='animate-spin -ml-1 text-green'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    style={{ width: '86px', height: '86px' }}>
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='#2ecc71'
                      strokeWidth='4'
                    />
                    <path
                      className='opacity-75'
                      fill='#2ecc71'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    />
                  </svg>
                </div>
                <div
                  data-testid='waiting-title'
                  className='font-bold'
                  style={{ fontSize: '24px', paddingTop: '14px' }}>
                  {transactionTitle
                    ? transactionTitle
                    : 'Waiting For Confirmation'}
                </div>
                <div
                  data-testid='waiting-message'
                  className=''
                  style={{ fontSize: '18px', paddingTop: '7px' }}>
                  {info?.transactionMessage ? info.transactionMessage : ''}
                </div>
                <div className=' font-bold'>
                  <div
                    data-testid='waiting-message-confirm'
                    style={{ paddingTop: '20px', fontSize: '15px' }}>
                    confirm this transaction in your wallet
                  </div>
                </div>
              </div>

              {/* When transaction submitted */}
              <div
                style={{
                  display: statusType === 'submitted' ? '' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ paddingTop: '2px' }}>
                  {' '}
                  <img
                    className='flex'
                    style={{ width: '86px', height: '86px' }}
                    src={
                      process.env.PUBLIC_URL +
                      'images/widthdraw/transaction-submitted.png'
                    }
                    alt=''
                  />
                </div>
                <div
                  data-testid='submitted-title'
                  className='font-5 font-bold'
                  style={{ fontSize: '24px', paddingTop: '12px' }}>
                  Transaction Submitted
                </div>
                <div
                  style={{
                    paddingTop: '13px',
                    color: '#40BF77',
                    fontSize: '18px',
                  }}>
                  <button
                    className='underline'
                    data-testid='submitted-open-bsc'
                    onClick={openBSCLink}>
                    {getViewTransactionLabel()}
                  </button>
                </div>
                <div style={{ paddingTop: '48px', paddingBottom: '0px' }}>
                  <button
                    data-testid='submitted-close'
                    style={{
                      fontSize: '14px',
                      width: '120px',
                      height: '24px',
                      background: '#4DB86D',
                    }}
                    onClick={() => {
                      if (onClose) onClose()
                    }}
                    className='hover:bg-green-700 text-white rounded'>
                    Close
                  </button>
                </div>
              </div>

              {/* When transaction successful */}
              <div
                style={{
                  display: statusType === 'successful' ? '' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ paddingTop: '2px' }}>
                  {' '}
                  <img
                    className='flex'
                    style={{ width: '86px', height: '86px' }}
                    src={
                      process.env.PUBLIC_URL +
                      'images/widthdraw/transaction-successful.png'
                    }
                    alt=''
                  />
                </div>
                <div
                  className='font-5 font-bold'
                  style={{ fontSize: '24px', paddingTop: '12px' }}>
                  {'Transaction Successful'}
                </div>
                <div
                  style={{
                    paddingTop: '13px',
                    color: '#40BF77',
                    fontSize: '18px',
                  }}>
                  <button className='underline' onClick={openBSCLink}>
                    {getViewTransactionLabel()}
                  </button>
                </div>
                <div style={{ paddingTop: '48px', paddingBottom: '0px' }}>
                  <button
                    style={{
                      fontSize: '14px',
                      width: '120px',
                      height: '24px',
                      background: '#4DB86D',
                    }}
                    onClick={() => {
                      if (onClose) onClose()
                    }}
                    className='hover:bg-green-700 text-white rounded'>
                    Close
                  </button>
                </div>
              </div>

              {/* When transaction error */}
              <div
                style={{
                  display: statusType === 'error' ? '' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ paddingTop: '2px' }}>
                  {' '}
                  <img
                    className='flex'
                    style={{ width: '86px', height: '86px' }}
                    src={
                      process.env.PUBLIC_URL +
                      'images/widthdraw/transaction-error.png'
                    }
                    alt=''
                  />
                </div>
                <div
                  data-testid='error-title'
                  className='font-bold'
                  style={{ fontSize: '24px', paddingTop: '12px' }}>
                  Transaction error
                </div>
                <textarea
                  data-testid='error-textarea'
                  defaultValue={info?.errorMessage ? info.errorMessage : ''}
                  style={{
                    border: '1px solid #FD5151',
                    marginTop: '22px',
                    paddingTop: '13px',
                    paddingBottom: '13px',
                    paddingLeft: '18px',
                    paddingRight: '18px',
                    fontSize: '14px',
                    minWidth: '250px',
                    maxWidth: '300px',
                    minHeight: '100px',
                    borderRadius: '8px',
                  }}
                />
                <div style={{ paddingTop: '16px', paddingBottom: '0px' }}>
                  <button
                    data-testid='error-close'
                    style={{
                      fontSize: '14px',
                      width: '120px',
                      height: '24px',
                      background: '#4DB86D',
                    }}
                    onClick={() => {
                      if (onClose) onClose()
                    }}
                    className='hover:bg-green-700 text-white rounded'>
                    Close
                  </button>
                </div>
              </div>

              {/* When transaction fail */}
              <div
                className='container'
                style={{
                  display: statusType === 'fail' ? '' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ paddingTop: '2px' }}>
                  {' '}
                  <img
                    className='flex'
                    style={{ width: '86px', height: '86px' }}
                    src={
                      process.env.PUBLIC_URL +
                      'images/widthdraw/transaction-error.png'
                    }
                    alt=''
                  />
                </div>
                <div
                  data-testid='fail-title'
                  className='font-5 font-bold'
                  style={{ fontSize: '24px', paddingTop: '12px' }}>
                  Transaction fail
                </div>
                <div style={{ paddingTop: '85px', paddingBottom: '0px' }}>
                  <button
                    data-testid='fail-close'
                    style={{
                      fontSize: '14px',
                      width: '120px',
                      height: '24px',
                      background: '#4DB86D',
                    }}
                    onClick={() => {
                      if (onClose) onClose()
                    }}
                    className='hover:bg-green-700 text-white rounded'>
                    Close
                  </button>
                </div>
              </div>

              {/* State - Emergency Withdraw */}
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  width: '384px',
                  display: statusType === 'sos' ? 'flex' : 'none',
                }}>
                <div
                  className='flex justify-center'
                  style={{ marginTop: '-10px' }}>
                  <img
                    className='flex'
                    style={{ width: '289px', height: '289px' }}
                    src={process.env.PUBLIC_URL + 'images/widthdraw/sos.png'}
                    alt=''
                  />
                </div>
                <div
                  data-testid='emergency-withdraw-title'
                  style={{ fontSize: '18px', paddingTop: '0px' }}>
                  SOS
                </div>
                <div
                  data-testid='emergency-withdraw-messenge'
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    lineHeight: '16px',
                    marginTop: '20px',
                    fontSize: '14px',
                    width: '320px',
                    minHeight: '90px',
                    textAlign: 'initial',
                  }}>
                  SOS Button:
                  <br />
                  User can get LP back from the farm using this Button , in case
                  you cannot withdraw from the website, this function is working
                  even the website is down.
                </div>
                <div className=' font-bold'>
                  <div
                    data-testid='emergency-withdraw-caution'
                    style={{
                      paddingTop: '0px',
                      fontStyle: 'italic',
                      fontSize: '11px',
                    }}>
                    Caution : User will lose all pending rewards when use SOS
                    button.
                  </div>
                </div>
                <div style={{ paddingTop: '22px', paddingBottom: '0px' }}>
                  <button
                    data-testid='emergency-withdraw-button-confirm'
                    style={{
                      fontSize: '14px',
                      width: '120px',
                      height: '26px',
                      background: '#FD5151',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      if (onClose) onClose()

                      if (
                        info &&
                        info.selectedFarmInfo &&
                        info.selectedPoolId != null &&
                        info.selectedFarmDetail
                      ) {
                        callSOS(
                          info.selectedFarmInfo,
                          info.selectedFarmDetail,
                          info.selectedPoolId
                        ).then(onComplete)
                      }
                    }}
                    className='hover:bg-green-700 text-white rounded'>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default TransactionStatePopup
