import styled from "styled-components"

export const Card = styled.div.attrs(({ className }) => ({
  className: 'flex flex-col' + (className ? ' ' + className : ''),
  style: {
    // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
  },
}))``

export const NoRouteCard = styled.div.attrs(({ className }) => ({
  className:
    'flex rounded-lg justify-center items-center border-dashed border-2 border-gray-600 h-32' +
    (className ? ' ' + className : ''),
  style: {

  },
}))``

export const EmptyDesktopCard = styled.div.attrs(({ className }) => ({
  className:
    'flex flex-1 rounded-lg justify-center align-center border-dashed border-2 border-green-700' +
    (className ? ' ' + className : ''),
  style: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))``
