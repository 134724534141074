import axios, { AxiosResponse } from 'axios'
import BigNumber from 'bignumber.js'
import {
  ASSETS_SUPPORT_CHAIN_TOKEN_URL,
  ASSETS_SUPPORT_TOKEN_URL,
  BACKEND_NODE_BASE_URL,
} from 'constants/url.constant'
import { ethers } from 'ethers'
import {
  decimalToUnsignedIntWithTokenDecimal,
  unformatInputNumber,
} from 'utils/formatter.utils'
import ERC20_ABI from '../constants/abi/erc20-abi.json'
import { defaultDEXContractInfo } from '../constants/swap.constant'
import { Chain } from '../models/chain.model'
import { callContractProgress } from '../models/contract.model'
import { Token, TokenPrice, TokenPriceGraph } from '../models/token.model'
import {
  callContractMethod,
  makeCallContractResponse,
} from './contract.service'
import { getEthersProvider } from './ethers.service'
import { alert_message_user_wallet } from 'contexts/TranslationContext'

const PRICE_API_PATH = BACKEND_NODE_BASE_URL + '/price'

export const getTokenPriceGraph = async (
  fromTokenAddress: string,
  toTokenAddress: string,
  fromTimeString: string,
  toTimeString: string,
  minuteTimeFrame: string,
  symbol: string
): Promise<AxiosResponse<TokenPriceGraph>> => {
  let param = {
        from: fromTimeString,
        to: toTimeString,
        minuteTimeFrame,
        symbol,
      }
  return await axios.get(
    `${PRICE_API_PATH}/token-price-graph/${fromTokenAddress}/${toTokenAddress}`,
    {
      params: param,
    }
  )
}

export const getTokenPrice = async (
  fromTokenAddress: string,
  toTokenAddress: string
): Promise<AxiosResponse<TokenPrice>> => {
  return Promise.resolve({
    data: {
      symbol: "",
      value: "",
      change: "",
      priceMovement: 'up',
      volume: "",
      low: "",
      high: "",
    },
    status: 200,
    statusText: '',
    config: {},
    headers: {}
  })
  // return await axios.get(
  //   `${PRICE_API_PATH}/token-basic-stats/${fromTokenAddress.toLowerCase()}/${toTokenAddress.toLowerCase()}`
  // )
}

export const getTokensWithPrice = async (
  allowTokens: string[],
  chainId: number
): Promise<Token[]> => {
  let tokens: Token[] = (await axios.get(ASSETS_SUPPORT_TOKEN_URL)).data
  let resultTokens: Token[] = []
  for (const token of tokens) {
    if (allowTokens.indexOf(token.symbol) === -1 || token.chainId !== chainId)
      continue
    const tokenPrice = (
      await getTokenPrice(
        token.address,
        //TODO: should change depends on chainId
        '0xe9e7cea3dedca5984780bafc599bd69add087d56'
      )
    ).data.value
    token.price = +tokenPrice
    resultTokens.push(token)
  }
  // console.log('getTokensWithPrice, tokens:', resultTokens)
  return resultTokens
}

export const getTokens = async (): Promise<Token[]> => {
  try {
    let tokenFromServer: any[] = (
      await axios.get(`${BACKEND_NODE_BASE_URL}/token/`)
    ).data
    let chainCoinToken: any[] = (
      await axios.get(ASSETS_SUPPORT_CHAIN_TOKEN_URL)
    ).data
    return tokenFromServer.concat(chainCoinToken)
  } catch (e) {
    console.log('ERROR ', e)
    throw e
  }
}

var _tokenNameMap: { [key: string]: string }
export const getTokensNameMap = async (): Promise<{
  [key: string]: string
}> => {
  if (!_tokenNameMap) {
    _tokenNameMap = {}
    let tokens = await getTokens()
    for (let token of tokens) {
      _tokenNameMap[token.address] = token.name
    }
  }
  return _tokenNameMap
}

export const genTokenKey = (token1: Token, token2: Token) => {
  return token1.address + '::' + token2.address
}

export const toBigNumber = (rate: number, token: Token) => {
  return new BigNumber(rate).dividedBy(new BigNumber(10).pow(token.decimals))
}

export const toWei = (amount: BigNumber, token: Token) => {
  return amount.multipliedBy(10 ** +token.decimals)
}

export const getCovertedAmountInString = (balance: string, token: Token) => {
  let unformatAmount = unformatInputNumber(balance).toString()
  let convertedAmount = decimalToUnsignedIntWithTokenDecimal(
    unformatInputNumber(unformatAmount),
    parseFloat(token?.decimals ?? '0')
  )
  return convertedAmount.toString()
}

export const isSpendingApproved = async (
  tokenAddress: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  if (tokenAddress === 'ETHER') {
    return {
      approved: true,
      token: tokenAddress,
      allowanceAmount: Infinity
    }
  }

  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  let account
  try {
    var signer = provider.getSigner()
    account = await signer.getAddress()
  } catch (error) {
    return makeCallContractResponse('ERROR', alert_message_user_wallet)
  }

  let tokenABI = defaultDEXContractInfo(chainInfo, disableMultiRoute).tokenABI
  const contract = new ethers.Contract(
    tokenAddress,
    tokenABI,
    signer.connectUnchecked()
  )

  var allowanceAmount = 0
  try {
    let allowanceAmountHex = await getSpendingAmount(
      contract,
      account.toLowerCase(),
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      chainInfo,
      tokenAddress,
      disableMultiRoute
    )
    console.log("allowanceAmountHex >>>", allowanceAmountHex)
    allowanceAmount = +allowanceAmountHex._hex
    console.log("allowanceAmount", allowanceAmount)

  } catch (e) {
    console.log('isApproved -> error: ', e)
  }

  return {
    approved: allowanceAmount !== 0,
    token: tokenAddress,
    allowanceAmount: allowanceAmount
  }
}

export const approveSpending = async (
  tokenAddress: string,
  chainInfo: Chain,
  disableMultiRoute: boolean,
  progressFunction?: (response: callContractProgress) => void
) => {
  let functionString = 'approve(spender, amount)'
  console.log('functionString', functionString)
  return callContractMethod(
    tokenAddress,
    ERC20_ABI,
    functionString,
    {
      spender: defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      amount: ethers.constants.MaxUint256,
    },
    progressFunction
  )
}

const getSpendingAmount = async (
  contract: ethers.Contract,
  account: string,
  contractAddress: string,
  chainInfo: Chain,
  tokenAddress: string,
  disableMultiRoute: boolean
) => {
  let contractInfo = defaultDEXContractInfo(chainInfo, disableMultiRoute)
  let allowanceFunctionList = contractInfo?.allowance.split(',')
  let allowanceTokenMap: { [index: string]: string } = {}

  for (let allowFunction of allowanceFunctionList) {
    let tokenAndFunction = allowFunction.split(':')
    if (tokenAndFunction.length === 1) {
      allowanceTokenMap[''] = tokenAndFunction[0]
    } else if (tokenAndFunction.length > 1) {
      allowanceTokenMap[tokenAndFunction[0]] = tokenAndFunction[1]
    }
  }

  let allowanceFunctionName = allowanceTokenMap['']
  for (let key in allowanceTokenMap) {
    if (key.split('|').indexOf(tokenAddress) >= 0) {
      allowanceFunctionName = allowanceTokenMap[key]
    }
  }

  console.log("\ngetSpendingAmount >>>")
  console.log("account", account)
  console.log("contractAddress", contractAddress)
  console.log("allowanceFunctionName", allowanceFunctionName)

  switch (allowanceFunctionName) {
    case 'allowances': {
      return await contract.allowances(account.toLowerCase(), contractAddress)
    }
    default:
      return await contract.allowance(account.toLowerCase(), contractAddress)
  }
}

/*
const getTokenAllowanceFunction = (chainInfo: Chain, tokenAddress: string) => {
  let contractInfo = defaultDEXContractInfo(chainInfo)
  var allowanceTokenMap = contractInfo.allowance
    .split(',')
    .reduce(function (map: { [index: string]: string }, value: string) {
      if (value.indexOf(':') !== -1) {
        let values = value.split(':')
        map[values[0]] = values[1]
      } else {
        map[''] = value
      }
      return map
    }, {})
  return allowanceTokenMap[tokenAddress] ?? allowanceTokenMap['']
}
*/
