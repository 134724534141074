import {
  callContractProgress,
  callContractResponse,
  callContractResponseType,
} from '../models/contract.model'
import { SmartContactInputV2, SwapRoute } from '../models/swap.model'
import { BigNumber, ContractReceipt, ContractTransaction, ethers } from 'ethers'
import { isUndefined } from 'lodash'
import { getEthersProvider } from '../services/ethers.service'
import { alert_message_user_wallet } from 'contexts/TranslationContext'

export const makeCallContractResponse = (
  status: callContractResponseType,
  msg: string,
  data?: any
): callContractResponse => {
  return { result: status, message: msg }
}

export const getTransaction = async (hash: string) => {
  const provider = await getEthersProvider()
  console.log("provider", provider)
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)
  return await provider.getTransaction(hash)
}

export const callContractMethod = async (
  contractAddress: string,
  contractABI: any,
  functionString: string,
  params:
    | { program: SmartContactInputV2 }
    | { xs: SwapRoute[]; selector: string; test: boolean }
    | { amount: string; poolId: number; poolIds?: number[] }
    | { value: { value: string } }
    | { amountIn: string }
    | {
        amountOutMin: string
        path: string[]
        to: string
        deadline: number
        value: { value: string }
      }
    | { amountOut: string; path: string[]; to: string; deadline: number }
    | {
        amountIn: string
        amountOutMin: string
        path: string[]
        to: string
        deadline: number
      }
    | { spender: string; amount: BigNumber }
    | {},
  progressFunction?: (response: callContractProgress) => void
): Promise<callContractResponse> => {
  if ('poolId' in params) params['poolIds'] = [params['poolId']]

  if('program' in params)
    console.log("program", params['program'])

  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  var signer = provider.getSigner()

  // We have to use `account` in `eval` statement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const account = await signer.getAddress()

  // let poolInfo = (poolIndex && farmInfo.pools)? farmInfo.pools[poolIndex] : null
  // let contractAddress = (poolInfo)? poolInfo.poolAddress : farmInfo?.chefAddress
  if (!contractAddress) {
    return makeCallContractResponse(
      'ERROR',
      'Harvest, farmInfo:' +
        contractAddress +
        ', ERROR! contractAddress is not available!'
    )
  }

  console.log("contractAddress", contractAddress)
  console.log("contractABI", contractABI)

  // We have to use `contract` in `eval` statement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const contract = new ethers.Contract(
    contractAddress,
    contractABI,
    signer.connectUnchecked()
  )

  var newFunctionString = ''

  try {
    //validate the function call string
    functionString = functionString.trim()
    if (functionString === '') {
      return makeCallContractResponse('ERROR', 'Not supported')
    }

    let startParam = functionString.indexOf('(')
    if (startParam === 0) {
      return makeCallContractResponse('ERROR', 'Function name is missing!')
    }
    if (startParam === -1) {
      return makeCallContractResponse(
        'ERROR',
        'Invalid function format "' + functionString + '".'
      )
    }
    let stopParam = functionString.lastIndexOf(')')
    if (stopParam === -1) {
      return makeCallContractResponse(
        'ERROR',
        'Invalid function format "' + functionString + '".'
      )
    }
    let functionName = functionString.substr(0, startParam).trim()
    const fnreg = new RegExp('^[a-zA-Z_][a-zA-Z0-9_]*$')
    if (!fnreg.test(functionName)) {
      return makeCallContractResponse(
        'ERROR',
        'Invalid function name "' + functionName + '".'
      )
    }
    let callingFunction = eval('contract.' + functionName)
    if (isUndefined(callingFunction)) {
      return makeCallContractResponse(
        'ERROR',
        'No such contract function name "' + functionName + '".'
      )
    }
    newFunctionString = functionName + '('

    let parameters = functionString
      .substring(startParam + 1, stopParam)
      .split(',')
    for (var i in parameters) {
      if (+i > 0) {
        newFunctionString += ','
      }
      var paramstr = ''
      var tmpParamstr = parameters[i].trim()
      if (tmpParamstr in params) {
        paramstr = 'params["' + tmpParamstr + '"]'
      } else {
        paramstr = tmpParamstr
      }
      if (paramstr && isUndefined(eval(paramstr))) {
        return makeCallContractResponse(
          'ERROR',
          parameters[i] + ' is not defined' //actually no needed
        )
      }
      newFunctionString += paramstr
    }

    newFunctionString += ')'
  } catch (e: any) {
    return makeCallContractResponse('ERROR', e.message, e)
  }

  try {
    console.log('Transaction: Calling method: ', newFunctionString)

    progressFunction?.apply(this, [
      { type: 'WAITING', message: 'Waiting for confirmation' },
    ])
    console.log('Transaction: Waiting for confirmation')

    let tx: ContractTransaction = await eval('contract.' + newFunctionString)

    progressFunction?.apply(this, [
      { type: 'SUBMITTING', message: 'Submitting transaction', data: tx },
    ])
    console.log('Transaction: Submitting, data:', tx)

    let blockHandler = async () => {
      provider.removeListener('block', blockHandler)

      let receipt: ContractReceipt = await provider.getTransactionReceipt(
        tx.hash
      )
      progressFunction?.apply(this, [
        { type: 'SUBMITED', message: 'Transaction submitted', data: receipt },
      ])
    }
    provider.on('block', blockHandler)

    const receipt: ContractReceipt = await tx.wait(3)

    console.log('Transaction: Confirmed, data:', receipt)
    return makeCallContractResponse('SUCCESS', 'Transaction Confirmed', receipt)
  } catch (e: any) {
    console.log('Transaction: Error, data:', e)
    switch (e.code) {
      case 4001:
      case 3:
        return makeCallContractResponse('FAIL', e.message, e)
      case -32603:
        return makeCallContractResponse('ERROR', e.message, e)
    }
    return makeCallContractResponse('ERROR', e.message, e)
  }
}
