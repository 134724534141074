// import axios from 'axios'
import axios from 'axios'
import { BACKEND_NODE_BASE_URL } from 'constants/url.constant'
import { WebStats } from 'models/user.model'
// import { BACKEND_BASE_URL } from '../constants/url.constant'

export const getChartStats = async (): Promise<any> => {
  try {
    return (await axios.get(`${BACKEND_NODE_BASE_URL}/stats/savings`)).data
  } catch (e) {
    throw e
  }
  // return new Promise((resolve, reject) => {
  //   resolve([
  //     ['2019', 5000, 4000],
  //     ['2020', 6000, 5000],
  //     ['2021', 12000, 10000],
  //     ['2022', 24325, 20000],
  //   ])
  // })
}

export const getStats = async (): Promise<WebStats> => {
  try {
    return (await axios.get(`${BACKEND_NODE_BASE_URL}/stats/home`)).data
  } catch (e) {
    console.log('ERROR ', e)
    throw e
  }
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     liquidity: 15000000000,
  //     totalvoloume: 15000000,
  //     totalwallet: 10000000,
  //     totaltrades: 12000000,
  //   })
  // })
}
