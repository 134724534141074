import { useSessionState } from "hooks/useStickyState"
import { DexOutPutReturnType } from "./DexOutPutReturnType"

export const useSwapConfig = () => {
  const [slipPage, setSlipPage] = useSessionState('0.1', 'config-slipPage')
  const [maxReturn, setMaxReturn] = useSessionState<DexOutPutReturnType>('max-return', 'config-maxReturn')
  const [deadLine, setDeadLine] = useSessionState(20, 'config-deadLine')
  const [isManualInput, setIsManualInput] = useSessionState(
    false,
    'config-isManualInput'
  )

  return {
    slipPage,
    deadLine,
    isManualInput,
    maxReturn,
    setSlipPage,
    setDeadLine,
    setIsManualInput,
    setMaxReturn
  }
}
