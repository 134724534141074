/* eslint-disable jsx-a11y/alt-text */
import { useWeb3React } from '@web3-react/core'
import { ASSETS_BASE_URL } from 'constants/url.constant'
import { useChainState } from 'contexts/ChainContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { getChainById } from 'hooks/Chain/useChain'
import { Token } from 'models/token.model'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { TradeHistoryModel } from 'models/display.model'
import { ethers } from 'ethers'
import { BlockTransaction, useMyWallet } from 'contexts/MyWalletContext'
import { useTokenState } from 'contexts/TokenContext'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Tooltip } from '@material-ui/core'
import { getBestConversionRateMultiCall } from 'services/swap.service'
import useToken from 'hooks/useToken'
import {
  decimalToUnsignedIntWithTokenDecimal,
  unformatInputNumber,
} from 'utils/formatter.utils'
import { toBigNumber } from 'services/token.service'

export const UserTradeHistory: FC<{
  isCompact?: boolean
  isOnlyMadeByHermes: boolean
}> = ({ isCompact, isOnlyMadeByHermes }) => {
  const visibleDataRef = useRef<TradeHistoryModel[]>([])
  const { chains } = useChainState()
  const { tokens } = useTokenState()
  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    let chain = getChainById(chains, selectedChainId)
    return chain
  }, [chains, selectedChainId])

  const [transactionList, setList] = React.useState<TradeHistoryModel[]>([])
  const [visibledDataList, setVisibledDataList] = React.useState<
    TradeHistoryModel[] | undefined
  >(undefined)
  const { account } = useWeb3React()

  const [init, setInit] = React.useState(false)
  const [loaded] = React.useState(false)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [maxPerPage] = React.useState(5)
  const [indexScroll, setIndexScroll] = React.useState(0)

  const updateDataList = useCallback(
    (transactionList: TradeHistoryModel[]) => {
      // console.log('updateDataList transactionList', transactionList)

      let tempList: TradeHistoryModel[] = []
      let startIndex = pageIndex * maxPerPage
      let lastIndex = (+pageIndex + 1) * maxPerPage
      for (let i = startIndex; i < lastIndex; i++) {
        let item = transactionList[i]
        if (item === undefined) break
        tempList.push(transactionList[i])
      }

      visibleDataRef.current = tempList
      setVisibledDataList(tempList)
    },
    [maxPerPage, pageIndex]
  )

  const { getTokenByAddress } = useToken()
  const [weiInUSD, setWeiInUSD] = useState<number | undefined>(undefined)
  const updataWeiToUSD = useCallback(() => {
    const amountIn = 1
    if (!selectedChain || !account) return
    switch (selectedChain.chainId) {
      case 8899:
        const WJBC = getTokenByAddress(
          tokens,
          '0x99999999990fc47611b74827486218f3398a4abd', //WJBC
          selectedChain.chainId
        )
        const JBC_USDT = getTokenByAddress(
          tokens,
          '0xfd8ef75c1cb00a594d02df48addc27414bd07f8a', //USDT
          selectedChain.chainId
        )
        if (WJBC !== undefined && JBC_USDT !== undefined) {
          const convertedAmountIn = decimalToUnsignedIntWithTokenDecimal(
            unformatInputNumber(amountIn),
            parseFloat(WJBC.decimals)
          ).toString()
          return getBestConversionRateMultiCall(
            WJBC.address,
            +WJBC.decimals,
            JBC_USDT.address,
            convertedAmountIn,
            chains,
            selectedChain,
            true
          ).then((resp) => {
            if (resp === undefined) return null
            const amountOut = unformatInputNumber(amountIn)
              .multipliedBy(toBigNumber(resp.rate, WJBC))
              .toNumber()
            console.log('amountOut >>>', amountOut)
            setWeiInUSD(amountOut)
            return { amountOut }
          })
        }
        break
      case 96:
        const KUB = getTokenByAddress(
          tokens,
          '0x67ebd850304c70d983b2d1b93ea79c7cd6c3f6b5', //WJBC
          selectedChain.chainId
        )
        const KUSDT = getTokenByAddress(
          tokens,
          '0x7d984c24d2499d840eb3b7016077164e15e5faa6', //USDT
          selectedChain.chainId
        )
        if (KUB !== undefined && KUSDT !== undefined) {
          const convertedAmountIn = decimalToUnsignedIntWithTokenDecimal(
            unformatInputNumber(amountIn),
            parseFloat(KUB.decimals)
          ).toString()
          return getBestConversionRateMultiCall(
            KUB.address,
            +KUB.decimals,
            KUSDT.address,
            convertedAmountIn,
            chains,
            selectedChain,
            true
          ).then((resp) => {
            if (resp === undefined) return null
            const amountOut = unformatInputNumber(amountIn)
              .multipliedBy(toBigNumber(resp.rate, KUB))
              .toNumber()
            console.log('amountOut >>>', amountOut)
            setWeiInUSD(amountOut)
            return { amountOut }
          })
        }
        break
      case 3501:
        const WJFIN = getTokenByAddress(
          tokens,
          '0x1112eb329121F5513177C07729459A3Cf601f76d', //WJBC
          selectedChain.chainId
        )
        const JFUSDT = getTokenByAddress(
          tokens,
          '0x1Cfc7EE9f50C651759a1EfFCd058D84F42E26967', //USDT
          selectedChain.chainId
        )
        if (WJFIN !== undefined && JFUSDT !== undefined) {
          const convertedAmountIn = decimalToUnsignedIntWithTokenDecimal(
            unformatInputNumber(amountIn),
            parseFloat(WJFIN.decimals)
          ).toString()
          return getBestConversionRateMultiCall(
            WJFIN.address,
            +WJFIN.decimals,
            JFUSDT.address,
            convertedAmountIn,
            chains,
            selectedChain,
            true
          ).then((resp) => {
            if (resp === undefined) return null
            const amountOut = unformatInputNumber(amountIn)
              .multipliedBy(toBigNumber(resp.rate, WJFIN))
              .toNumber()
            console.log('amountOut >>>', amountOut)
            setWeiInUSD(amountOut)
            return { amountOut }
          })
        }
    }
  }, [account, chains, getTokenByAddress, selectedChain, tokens])

  const isCompactValue = useMemo(() => {
    if (isCompact !== undefined) {
      return isCompact
    }
    return false
  }, [isCompact])

  const { transactions } = useMyWallet()
  const getFee = (value: ethers.BigNumber) => {
    // return parseInt('0x' + Number(value).toString(16),16)
    return (value.toNumber() / Math.pow(10, 18)).toFixed(8)
  }

  const getTransactionDetail = (
    log: { address: string; data: string; topics: string[] }[],
    selectedAccount: string | null | undefined,
    tokens: Token[]
  ) => {
    const userAddress = selectedAccount ?? ''

    let token1 = log[0]
    let token2 = log[1]
    let i = 0
    let userSubAddress = userAddress
      .substring(userAddress.length - 5, userAddress.length)
      .toLowerCase()

    console.log('log >>', log)

    if (log.length === 1) {
      console.log('CASE 1', log)
      const firstLog = log[0]
      if (
        firstLog.address.toLowerCase() ===
        selectedChain?.wrapTokenAddress.toLowerCase()
      ) {
        if (
          firstLog.topics[firstLog.topics.length - 1] //last
            .toLowerCase()
            .indexOf(userSubAddress) >= 0 &&
          firstLog.topics[0] ===
            '0x7fcf532c15f0a6db0bd6d0e038bea71d30d808c7d98cb3bf7268a95bf5081b65'
        ) {
          //WRAP ON JBC
          token1 = firstLog
          token2 = {
            data: firstLog.data,
            address: 'ether',
            topics: firstLog.topics,
          }
        } else {
          //UNWRAP ON JBC
          token1 = {
            data: firstLog.data,
            address: 'ether',
            topics: firstLog.topics,
          }
          token2 = firstLog
        }
      }
    } else {
      let isWrapping = false
      if (log.length === 2) {
        const firstLog = log[0]
        const secondLog = log[1]
        const mainTokenRef =
          '0x0000000000000000000000000000000000000000000000000000000000000000'
        if (
          firstLog.address.toLowerCase() ===
            selectedChain?.wrapTokenAddress.toLowerCase() &&
          secondLog.topics[1] === mainTokenRef
        ) {
          //WRAP ON BITKUB
          token1 = {
            data: firstLog.data,
            address: 'ether',
            topics: firstLog.topics,
          }
          token2 = firstLog
          isWrapping = true
        } else if (
          firstLog.address.toLowerCase() ===
            selectedChain?.wrapTokenAddress.toLowerCase() &&
          secondLog.topics[2] === mainTokenRef
        ) {
          //UNWRAP ON BITKUB
          token1 = firstLog
          token2 = {
            data: firstLog.data,
            address: 'ether',
            topics: firstLog.topics,
          }
          isWrapping = true
        }
      }

      console.log('CASE 2', log)
      if (!isWrapping) {
        for (i = 0; i < log.length; i++) {
          let item = log[i]
          if (
            item.topics.length > 1 &&
            item.topics[1].toLowerCase().indexOf(userSubAddress) >= 0
          ) {
            token1 = item
            break
          }
        }
        for (i = 0; i < log.length; i++) {
          let item = log[i]
          if (
            item.topics[item.topics.length - 1]
              .toLowerCase()
              .indexOf(userSubAddress) >= 0
          ) {
            token2 = item
            break
          }
        }
      }
    }

    // console.log("token1 ???", token1)
    // console.log("token2 ???", token2)

    return {
      amountIn: token1.data,
      amountOut: token2.data,
      tokenIn: token1.address,
      tokenOut: token2.address,
    }
  }

  const loadTransaction = useCallback(async () => {
    try {
      // console.log('transactions.length >>', transactions.length)
      // console.log('transactions >>', transactions)

      let tempList: TradeHistoryModel[] = []
      transactions.forEach((element: BlockTransaction) => {
        let date = new Date(element.timestamp * 1000)
        tempList.push({
          // date: date,
          date: date,
          icon: 'string',
          pair: 'string',
          to: '',
          value: '',
          // to: Object(item).to,
          // value: Object(item).value,
          price: 'string',
          // action: Object(item).input,
          action: '',
          excuted: 'string',
          fee: '' + getFee(element.gasUsed.mul(element.effectiveGasPrice)),
          total: 'string',
          // transactionId: Object(item).hash,
          transactionId: '',
          txreceipt_status: '' + Object(element).status,
          url:
            selectedChain?.frontEndData.blockExplorerUrls +
            '/tx/' +
            element.transactionHash,
          detail: getTransactionDetail(Object(element).logs, account, tokens),
        })
      })
      setList(tempList)
      updateDataList(tempList)
    } catch (e) {
      console.log('ERROR >>>', e)
      return
    }
  }, [
    transactions,
    updateDataList,
    selectedChain?.frontEndData.blockExplorerUrls,
    account,
    tokens,
  ])

  React.useEffect(() => {
    loadTransaction()
    updataWeiToUSD()
  }, [init, loadTransaction, loaded, transactions, updataWeiToUSD])

  React.useEffect(() => {
    if (transactionList) updateDataList(transactionList)
  }, [pageIndex, transactionList, updateDataList])

  function shortTime(Date: Date): string {
    let DS: string =
      Date.getHours() + ':' + Date.getMinutes() + ':' + Date.getSeconds()
    return DS
  }

  function shortDateToYYYYMMDD(Date: Date): string {
    let DS: string =
      Date.getFullYear() +
      '/' +
      ('0' + (Date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + Date.getDate()).slice(-2)
    return DS
  }

  const NO_ICON_SOURCE = 'assets/icons/token/noimg.png'

  const getPairLabel2Lines = (
    item: TradeHistoryModel,
    tokens: Token[],
    selectedChainId: number
  ) => {
    let to = item.detail?.tokenIn ?? ''
    let out = item.detail?.tokenOut ?? ''

    let tokenIn = tokens.filter(
      (t) =>
        t.address.toLowerCase() === to.toLowerCase() &&
        selectedChainId === t.chainId
    )[0]
    let tokenOut = tokens.filter(
      (t) =>
        t.address.toLowerCase() === out.toLowerCase() &&
        selectedChainId === t.chainId
    )[0]
    if (tokenIn && tokenOut) {
      return (
        <div className='flex items-center pr-4 border-grey-500'>
          <div style={{}}>
            <Tooltip title={tokenIn.name}>
              <img
                className='w-6'
                src={
                  tokenIn?.icon
                    ? ASSETS_BASE_URL + tokenIn.icon
                    : NO_ICON_SOURCE
                }
                alt={tokenIn.name}
              />
            </Tooltip>
          </div>
          <div style={{}}>
            <Tooltip title={tokenOut.name}>
              <img
                className='w-6'
                src={
                  tokenOut?.icon
                    ? ASSETS_BASE_URL + tokenOut.icon
                    : NO_ICON_SOURCE
                }
              />
            </Tooltip>
          </div>
        </div>
      )
    }
    return '-'
  }

  const getPairLabel = (
    item: TradeHistoryModel,
    tokens: Token[],
    selectedChainId: number
  ) => {
    let to = item.detail?.tokenIn ?? ''
    let out = item.detail?.tokenOut ?? ''

    let tokenIn = tokens.filter(
      (t) =>
        t.address.toLowerCase() === to.toLowerCase() &&
        selectedChainId === t.chainId
    )[0]
    let tokenOut = tokens.filter(
      (t) =>
        t.address.toLowerCase() === out.toLowerCase() &&
        selectedChainId === t.chainId
    )[0]
    if (tokenIn && tokenOut) {
      return (
        <div className='flex flex-row items-center space-x-2'>
          <div>
            <img
              className='w-6'
              src={
                tokenIn?.icon ? ASSETS_BASE_URL + tokenIn.icon : NO_ICON_SOURCE
              }
            />
          </div>
          <div>{tokenIn.name}</div>
          <div className='p-2'>{'>'}</div>
          <div>
            <img
              className='w-6'
              src={
                tokenOut?.icon
                  ? ASSETS_BASE_URL + tokenOut.icon
                  : NO_ICON_SOURCE
              }
            />
          </div>
          <div>{tokenOut.name}</div>
        </div>
      )
    }
    return '-'
  }

  function getAmount(item: TradeHistoryModel, tokens: Token[]) {
    let to = item.detail?.tokenIn ?? ''
    let out = item.detail?.tokenOut ?? ''

    let tokenIn = tokens.filter(
      (t) => t.address.toLowerCase() === to.toLowerCase()
    )[0]
    let tokenOut = tokens.filter(
      (t) => t.address.toLowerCase() === out.toLowerCase()
    )[0]

    let amountIn = item.detail?.amountIn ?? 0
    let amountOut = item.detail?.amountOut ?? 0

    if (tokenIn && tokenOut) {
      amountIn = +amountIn / Math.pow(10, +tokenIn.decimals)
      amountOut = +amountOut / Math.pow(10, +tokenOut.decimals)
      return (
        <div className='flex flex-col'>
          <div className='flex justify-end'>
            {'-' +
              amountIn.toLocaleString() +
              ' ' +
              tokenIn.symbol.toUpperCase()}
          </div>
          <div className='flex text-green-400 justify-end'>
            {'+' +
              amountOut.toLocaleString() +
              ' ' +
              tokenOut.symbol.toUpperCase()}
          </div>
        </div>
      )
    }
    return '-'
  }

  function getStatus(item: TradeHistoryModel) {
    if (item.txreceipt_status === '1') {
      return (
        <div className='flex items-center text-green-200 bg-green-900 px-2 h-8 rounded-xl'>
          Success
        </div>
      )
    }
    return (
      <div className='flex items-center text-red-200 bg-red-900 px-2 h-8 rounded-xl'>
        Fail
      </div>
    )
  }

  function makeNavigatorButton() {
    let pageCount = Math.ceil(transactionList.length / maxPerPage)
    let numButtuon = []
    let isSelected = false
    let cursor = ' cursor-pointer hover:bg-gray-900'
    let hoverState = ' items-center justify-center'
    let startIndex = indexScroll
    let endIndex = startIndex + pageCount
    for (var i = startIndex; i < endIndex; i++) {
      isSelected = i === +pageIndex
      let index = i
      let button = (
        <div
          key={'nav' + i}
          id={'navigate_' + index}
          className={
            'flex w-8' +
            (isSelected ? ' text-blue-400 border border-blue-400' : '') +
            hoverState +
            cursor
          }
          // eslint-disable-next-line no-loop-func
          onClick={(button) => {
            let id = Object(button).target.id.replace('navigate_', '')
            setPageIndex(id)
          }}>
          {i + 1}
        </div>
      )
      numButtuon.push(button)
    }
    let leftArrowEnabled = startIndex !== 0
    let rightArrowEnabled = endIndex < pageCount
    return (
      <div className='flex flex-1'>
        <div
          className={
            'flex w-8' +
            hoverState +
            (leftArrowEnabled ? cursor : ' select-none opacity-30') +
            (pageCount > 0 ? ' hidden' : '')
          }
          onClick={() => {
            setIndexScroll(Math.max(0, indexScroll - 10))
          }}>
          {'<<'}
        </div>
        {numButtuon}
        <div
          className={
            'flex w-8' +
            hoverState +
            (rightArrowEnabled ? cursor : ' select-none opacity-30') +
            (pageCount > 0 ? ' hidden' : '')
          }
          onClick={() => {
            setIndexScroll(Math.min(pageCount - 10, indexScroll + 10))
          }}>
          {'>>'}
        </div>
      </div>
    )
  }

  const getFeeInWei = useCallback(
    (item: TradeHistoryModel) => {
      if (weiInUSD) {
        const fee = (+item.fee * weiInUSD).toFixed(8)
        return <div>{fee}</div>
      }
      return <></>
      // return (
      //   <div className={'animate-pulse' + (visibledDataList ? ' hidden' : '')}>
      //     <div
      //       className='flex flex-1 flex-row text-gray-300 bg-gray-600 my-4 rounded-xl '
      //       style={{ height: '10px' }}
      //     />
      //   </div>
      // )
    },
    [weiInUSD]
  )
  const getCampactRow = useCallback(
    (item: TradeHistoryModel) => {
      return (
        <div
          className='flex flex-1 flex-row text-gray-300 py-3 border-b border-gray-600 items-center justify-center'
          style={{ minHeight: '50px' }}>
          <div className='flex w-24 flex-col'>
            <div>{shortDateToYYYYMMDD(item.date)}</div>
            <div>{shortTime(item.date)}</div>
          </div>
          {/* <div className='flex w-24 flex-col flex md:hidden'>
            <div>{shortDateToYYYYMMDD(item.date)}</div>
          </div> */}
          <div className='flex flex-1 pr-4 flex md:hidden'>
            {getPairLabel2Lines(item, tokens, selectedChainId)}
          </div>
          <div className='flex flex-1 pr-4 hidden md:flex'>
            {getPairLabel(item, tokens, selectedChainId)}
          </div>
          <div className='flex w-36 justify-end'>{getAmount(item, tokens)}</div>
          <div className='flex w-24 justify-end hidden md:flex'>
            {getFeeInWei(item)}
          </div>
          <div
            className='flex w-24 justify-end hidden md:flex'
            style={{ color: '#40BF77' }}>
            {'0'}
          </div>
          <div className='flex w-24 justify-center hidden md:flex'>
            {getStatus(item)}
          </div>
          <div className='flex w-16 justify-center'>
            {' '}
            <Box
              onClick={() => {
                window.open(item.url, 'blank')
              }}
              sx={{
                paddingX: 2,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <LaunchIcon
                color='info'
                sx={{ cursor: 'pointer', width: 20, height: 20 }}
              />
            </Box>
          </div>
        </div>
      )
    },
    [getFeeInWei, selectedChainId, tokens]
  )

  // console.log('visibledDataList >>', visibledDataList)
  // console.log('isCompactValue >>', isCompactValue)

  function makeCampactView() {
    return (
      <div className='flex flex-1 text-sm flex-col border border-gray-700'>
        <div className='flex flex-col px-4 pt-8 pb'>
          <div
            className='flex flex-1 flex-row text-gray-400 py-3 border-b border-gray-600'
            style={{ height: '45px' }}>
            <div className='flex w-24'>Date</div>
            <div className='flex flex-1 pr-4'>Pair / Token</div>
            <div className='flex w-36 justify-end'>Amount</div>
            <div className='flex w-24 justify-end hidden md:flex'>
              Tx Cost​ (USD)
            </div>
            <div
              className='flex w-24 justify-end hidden md:flex'
              style={{ color: '#40BF77' }}>
              HERMES fee
            </div>
            <div className='flex w-24 justify-center hidden md:flex'>
              Status
            </div>
            <div className='flex w-16 justify-center'></div>
          </div>
          <div>
            {visibledDataList &&
              visibledDataList.map((item, index) => {
                return <div key={'visible' + index}>{getCampactRow(item)}</div>
              })}
            <div
              className={'animate-pulse' + (visibledDataList ? ' hidden' : '')}>
              {[1, 2, 3, 4].map((_, index) => {
                return (
                  <div
                    key={index}
                    className='flex flex-1 flex-row text-gray-300 bg-gray-600 my-4 rounded-xl '
                    style={{ height: '50px' }}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className='flex fle-1 px-8 font-bold justify-between text-white pt-4 pb-8'>
          <div>
            {+pageIndex * maxPerPage +
              1 +
              ' - ' +
              Math.min(transactionList.length, (+pageIndex + 1) * maxPerPage) +
              ' From ' +
              transactionList.length +
              ' Record '}
          </div>
          <div>{makeNavigatorButton()}</div>
        </div>
      </div>
    )
  }

  if (!init) setInit(true)

  return (
    <div className={'w-full h-full' + (isCompactValue ? '' : ' bg-gray-800')}>
      {makeCampactView()}
    </div>
  )
}
