import styled from 'styled-components'

export const Body = styled.nav.attrs({
  className: 'flex-1',
  // className: 'flex-1 p-6 container-body'
  // 'flex flex-col items-start justify-start flex-1 pl-10 pt-6 pr-10 lg:w-4/5 container-body',
})``

export const Container = styled.div.attrs({
  className: 'flex flex-1', //'flex flex-col',
})``

export const ContainerBody = styled.div.attrs({
  className: 'flex flex-1', //'relative flex flex-cols-1 md:flex-cols-1',
})``

export const ContainerChildren = styled.div.attrs({
  className: 'flex flex-1 h-full', //'min-h-full min-w-full max-w-full',
})``
