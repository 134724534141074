import { LANG } from "constants/translation";

export const LANG_EN: LANG = {
  title_exchange: "Exchange",
  swap_from: 'From',
  swap_to: "To",
  swap_price: "price",
  swap_price_impact: "Price impact",
  swap_tx_cost: "Tx Cost",
  swap_fee: "HERMES fee",
  swap_route: "Route",
  swap_step_suffix: "steps in the route",
  swap_tooltip_auto_refresh: "Auto Refresh Timer",
  swap_open_setting: "Open Setting",
  swap_show_more: "show more",
  swap_transaction_setting: "Transaction Setting",
  swap_slippage_tolerance: "Slippage Tolerance (%)",
  swap_manual_input: "Manual input Slippage Tolerance",
  swap_what_is_slippage_title: "What is Slippage Tolerance",
  swap_what_is_slippage_messge: "Setting a high slippage tolerance can help transactions succeed, but\n you may not get such a good price. Use with caution.",
  swap_is_slippage: "string",
  swap_save_button: "Save",
  swap_show_chart: "Show Chart",
  swap_hide_chart: "Hide Chart",
  button_insufficient: "Insufficient balance",
  button_insufficient_lp: "",
  button_swap: "Swap token",
  button_wrap: "Wrap",
  button_unwrap: "Unwrap",
  button_approve: "Approve",
  button_connect_wallat: "Connect Wallet",
  button_connect_network: "Switch Network",
  max_return: "Max return",
  lowest_gas: "Lowest gas",
  balance: "Balance",
  menu_trade: "Trade",
  menu_trade_simple_swap: "Simple mode",
  menu_trade_advance_swap: "Advanced mode",
  menu_more: "More",
  menu_more_market_price: "Market Price",
  menu_more_history: "History",
  logo_description: "With 0% trading fees",
  exchange_rate: "Exchange rates",
  trade_history: "Trade History",
  routing: "Routing",
  sign_out: "Sign Out",
  select_token: "Select a Token",
  routing_view: "Routing View",
  exchange_title: "Name",
  exchange_amount: "Amount",
  exchange_diff: "Diff",
  max_button: "MAX",
  connect_to_wallet_title: "Connect to a wallet"
}

