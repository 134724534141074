import BigNumber from 'bignumber.js'
import { getEthersProvider } from './ethers.service'

import { Chain } from 'models/chain.model'
import { callContractProgress } from '../models/contract.model'
import {
  callContractMethod,
  makeCallContractResponse,
} from './contract.service'
import { defaultDEXContractInfo } from 'constants/swap.constant'
import { alert_message_user_wallet } from 'contexts/TranslationContext'

export const swapSingleRoute = async (
  chainInfo: Chain,
  fromToken: string,
  toToken: string,
  amountIn: string,
  amountOut: string,
  disableMultiRoute: boolean,
  path?: string[],
  slippage = '0.005',
  deadline = 1200,
  progressFunction?: (response: callContractProgress) => void
) => {

  console.log("swapSingleRoute >>> disableMultiRoute:", disableMultiRoute)

  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  var signer = provider.getSigner()
  const account = await signer.getAddress()

  const blockNumber = await provider.getBlockNumber()
  const block = await provider.getBlock(blockNumber - 1)
  var deadlineTimestamp = block.timestamp + deadline

  let amountOutMin = new BigNumber(amountOut)
    .multipliedBy(new BigNumber(1).minus(+slippage/100))
    .dp(0, BigNumber.ROUND_DOWN)
    .toString()

  console.log("amountOutMin", amountOutMin)

  let chainCoinAddress = 'ETHER'
  let wrapCoinAddress = chainInfo.wrapTokenAddress

  fromToken = fromToken.toLowerCase()
  toToken = toToken.toLowerCase()
  chainCoinAddress = chainCoinAddress.toLowerCase()
  wrapCoinAddress = wrapCoinAddress.toLowerCase()

  if (fromToken === chainCoinAddress && toToken !== wrapCoinAddress) {
    console.log('CASE 1')
    // Swap BNB to XXX
    return callContractMethod(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI,
      'swapExactETHForTokens(amountOutMin, path, to, deadline, value)',
      {
        amountOutMin: amountOutMin,
        path: path ? path : [wrapCoinAddress, toToken.toLowerCase()],
        to: account,
        deadline: deadlineTimestamp,
        value: { value: amountIn },
      },
      progressFunction
    )
  } else if (fromToken !== wrapCoinAddress && toToken === chainCoinAddress) {
    console.log('CASE 2')
    // Swap XXX to BNB
    return callContractMethod(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI,
      'swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline)',
      {
        amountIn: amountIn,
        amountOutMin: amountOutMin,
        path: path ? path : [fromToken.toLowerCase(), wrapCoinAddress],
        to: account,
        deadline: deadlineTimestamp,
      },
      progressFunction
    )
  } else if (fromToken === wrapCoinAddress && toToken === chainCoinAddress) {
    console.log('CASE 3')
    // Swap WBNB to BNB
    return callContractMethod(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).tokenChefAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).tokenABI,
      'withdraw(amountIn)',
      {
        amountIn: amountIn,
      },
      progressFunction
    )
  } else if (fromToken === chainCoinAddress && toToken === wrapCoinAddress) {
    console.log('CASE 4')
    // Swap BNB to WBNB
    return callContractMethod(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).tokenChefAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).tokenABI,
      'deposit(value)',
      {
        value: { value: amountIn },
      },
      progressFunction
    )
  } else {
    console.log('CASE others')
    // Swap XXX to WBNB
    // Swap WBNB to XXX
    // Swap XXX to YYY
    console.log('fromToken', fromToken)
    console.log('toToken', toToken)
    return callContractMethod(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI,
      'swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline)',
      {
        amountIn: amountIn,
        amountOutMin: amountOutMin,
        path: path ? path : [fromToken.toLowerCase(), toToken.toLowerCase()],
        to: account,
        deadline: deadlineTimestamp,
      },
      progressFunction
    )
  }
}
