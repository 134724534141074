export const ASSETS_TOKEN_URL = `/assets/icons/`
export const ASSETS_SUPPORT_TOKEN_URL = `/json/supported-tokens.json`
export const ASSETS_SUPPORT_CHAIN_TOKEN_URL = `/json/supported-tokens-chain-coin.json`
export const ASSETS_SUPPORT_CHAIN_URL = `/json/supported-chains.json`
export const ASSETS_SUPPORT_FARM_URL = `/json/supported-farm.json`
export const ASSETS_BASE_URL = ''
export const BACKEND_BASE_URL = process.env.REACT_APP_API_URL
export const BACKEND_NODE_BASE_URL = process.env.REACT_APP_NODE_API_URL
export const BACKEND_PYTHON_BASE_URL = process.env.REACT_APP_PYTHON_API_URL

// export const ALL_AVAILABLE_BSC_RPC_URLS = [
//   'https://bsc-dataseed.binance.org',
//   'https://bsc-dataseed1.defibit.io',
//   'https://bsc-dataseed1.ninicoin.io',
//   'https://bsc-dataseed2.defibit.io',
//   'https://bsc-dataseed3.defibit.io',
//   'https://bsc-dataseed4.defibit.io',
//   'https://bsc-dataseed2.ninicoin.io',
//   'https://bsc-dataseed3.ninicoin.io',
//   'https://bsc-dataseed4.ninicoin.io',
//   'https://bsc-dataseed1.binance.org',
//   'https://bsc-dataseed2.binance.org',
//   'https://bsc-dataseed3.binance.org',
//   'https://bsc-dataseed4.binance.org',
//   'https://binance.ankr.com',
// ]

// export const BITKUB_RPC = "https://rpc.bitkubchain.io/"
