import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
  }

  .container-body {
    background: ${({ theme }) => theme.colors.bodyLight};
    color: ${({ theme }) => theme.colors.text};
  }

  .card {
    background: ${({ theme }) => theme.colors.card};
    color: ${({ theme }) => theme.colors.text};
  }

  .text-h-color {
    color: ${({ theme }) => theme.colors.textHighlight};
  }

  .border-default-color {
    border-color: ${({ theme }) => theme.colors.border};
  }

  .border-green {
    border-color: rgba(16, 185, 129);
  }

  .bg-more-wallet {
    background: ${({ theme }) => theme.colors.backgroundButtonMoreWallet};
    &:hover {
      background: rgba(16, 185, 129);
    }
  }

  .farm-price-favorite {
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundButtonMoreWallet};
    }
  }

  .button-border-green {
    background: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
    border: 1px solid #40bf77;
  }

  .icon-color {
    filter: ${({ theme }) =>
      `brightness(0.8) sepia(1) hue-rotate(${theme.colors.iconColor}deg) saturate(5)`};
  }

  .input {
    background: ${({ theme }) => theme.colors.body};
  }

`
