import BigNumber from 'bignumber.js'
import { useChainState } from 'contexts/ChainContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { getChainById } from 'hooks/Chain/useChain'
import { useSessionState } from 'hooks/useStickyState'
import React from 'react'
import { unformatInputNumber } from 'utils/formatter.utils'

export const useSwapViewInput = () => {
  const { chains } = useChainState()

  const [balances, setBalances] = useSessionState<string[]>(['1', ''], 'user-input')
  const { selectedChainId } = useSwapGlobalState()

  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const setBalanceFrom = (
    value: string,
    rateV1: BigNumber,
    rateV2: BigNumber,
    ) => {
    if (!selectedChain?.frontEndData.multiRouteEnabled) {
      setBalances([
        value,
        unformatInputNumber(value).dividedBy(rateV1).toString(),
      ])
    }
    setBalances([
      value,
      unformatInputNumber(value).dividedBy(rateV2).toString(),
    ])
  }

  return {
    balances,
    setBalances,
    setBalanceFrom,
  }
}
