import { LANG_EN } from 'constants/lang/EN'
import { LANG } from 'constants/translation'
import React, { createContext, useEffect, useReducer } from 'react'

type Action =
  | {
    type: 'LANG_SELECT'; payload: LANG
  }
  | {
    type: 'LANG_CHANGED'; payload: boolean
  }
type Dispatch = (action: Action) => void
type State = {
  tl: LANG
  change: boolean
}

const TranslationStateContext = createContext<State | undefined>(undefined)
const TranslationDispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'LANG_SELECT': {
      return { ...state, tl: action.payload }
    }
    case 'LANG_CHANGED': {
      return { ...state, change: action.payload }
    }
  }
}

const TranslationProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {

  const [state, dispatch] = useReducer(reducer, {
    tl: LANG_EN,
    change: false
  })

  useEffect(() => {
    // getChains().then((response) => {
    //   dispatch({
    //     type: 'LANG_SELECT',
    //     payload: response,
    //   })
    // })
  }, [])

  return (
    <TranslationStateContext.Provider value={state}>
      <TranslationDispatchContext.Provider value={dispatch}>
        {children}
      </TranslationDispatchContext.Provider>
    </TranslationStateContext.Provider>
  )
}

const useTranslationState = (): State => {
  const context = React.useContext(TranslationStateContext)
  if (context === undefined) {
    throw new Error('useTokenState must be used within a TokenProvider')
  }
  return context
}
const useTranslationDispatch = (): Dispatch => {
  const context = React.useContext(TranslationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useTokenDispatch must be used within a TokenProvider'
    )
  }
  return context
}

export { TranslationProvider, useTranslationState, useTranslationDispatch }

//temp
export const alert_message_user_wallet = "Cannot connect to user wallet"
