import BigNumber from 'bignumber.js'
import { FETCH_PRICE_DATA_DURATION } from 'components/MultiRouteSwapTokenCard'
import _, { isArray } from 'lodash'
import { Chain } from 'models/chain.model'
import {
  DexInfoDisplay,
  DexPriceListViewModel,
  RouteNodeViewModel,
} from 'models/swap.model'
import { Token } from 'models/token.model'
import React, { Dispatch } from 'react'
import { genTokenKey, toBigNumber, toWei } from 'services/token.service'
import {
  decimalToUnsignedIntWithTokenDecimal,
  formatInputNumber,
  unformatInputNumber,
} from 'utils/formatter.utils'
import { callContractResponse } from '../../models/contract.model'
import {
  MultiRouteErrorResponse,
  MultiRouteResponse,
} from '../../models/swap-multiroute.model'
import {
  getBestConversionRate,
  getBestConversionRateMultiCall,
  getRoutePriceImpact,
} from '../../services/swap.service'
import { DexOutPutReturnType } from './DexOutPutReturnType'
import { useSwapLabel } from './useSwapLabel'

export const BIG_NUMBER_ZERO = new BigNumber(0)

export const getPriceV1 = (
  token1: Token | undefined,
  token2: Token | undefined,
  balanceFrom: string,
  chains: Chain[],
  setRateAndRoute: (value: React.SetStateAction<[BigNumber, string[]]>) => void,
  setIsLoadingPriceData: (isPriceLoading: boolean) => void,
  setDisplayState: (displayState: string) => void,
  setBalances: (value: React.SetStateAction<string[]>) => void,
  tokenKeyRef: React.MutableRefObject<string>,
  balanceRef: React.MutableRefObject<string[]>,
  chainInfo: Chain | undefined,
  setPriceImpact: (val: string) => void,
  setPriceImpactVal: (val: number) => void,
  setPriceImpactIsHigh: (val: boolean) => void,
  setIsLowLP: (val: boolean) => void
) => {
  if (!token1 || !token2) return
  if (!chainInfo) return

  tokenKeyRef.current = token1.address + '::' + token2.address

  setRateAndRoute([BIG_NUMBER_ZERO, []])
  setIsLoadingPriceData(true)

  //getBestConversionRate(token1.address, token2.address, selectedChainId)
  let tokenFromDecimal = parseFloat(token1?.decimals ?? '0')
  let amountFrom = unformatInputNumber(balanceFrom).toString()
  let convertedAmountToken1 = decimalToUnsignedIntWithTokenDecimal(
    unformatInputNumber(amountFrom),
    tokenFromDecimal
  )

  if (chainInfo.multiCallContractAddress.length > 0) {
    getBestConversionRateMultiCall(
      token1.address,
      +token1.decimals,
      token2.address,
      convertedAmountToken1.toString(),
      chains,
      chainInfo,
      true //disableMultiRoute = true, because this is swap V1
    )
      .then(
        (
          rateResponse:
            | callContractResponse
            | { rate: number; route: string[] }
            | undefined
        ) => {
          console.log('tokenKeyRef', tokenKeyRef)
          if (tokenKeyRef.current !== genTokenKey(token1, token2)) {
            return
          }
          if (rateResponse === undefined) {
            return
          }
          console.log('rateResponse V1', rateResponse)

          //check lp
          if ('rate' in rateResponse && rateResponse.rate === 0) {
            setIsLowLP(true)
          } else {
            setIsLowLP(false)
          }

          const invertedRate =
            'rate' in rateResponse
              ? toBigNumber(rateResponse.rate, token1)
              : new BigNumber(0)

          console.log("invertedRate >>>", invertedRate)

          const rate = new BigNumber(1).dividedBy(invertedRate)

          let route = 'route' in rateResponse ? rateResponse.route : []
          console.log("getBestConversionRateMultiCall rate",rate)
          setRateAndRoute(
            rate.isZero() || rate.isNaN() || !isArray(route)
              ? [BIG_NUMBER_ZERO, []]
              : [rate, route]
          )

          //commn : get price impact
          const fromBalance = balanceRef.current[0]
          console.log("fromBalance ",fromBalance)
          const balanceInputNumber = unformatInputNumber(fromBalance)
          console.log("balanceInputNumber ",balanceInputNumber)

          if (balanceInputNumber.isNaN() || balanceInputNumber.isZero()) {
            setDisplayState('input_zero')
            return
          }

          getRoutePriceImpact(
            route,
            toWei(balanceInputNumber, token1).toString(),
            chainInfo,
            true //disableMultiRoute = true, because this is swap V1
          )
            .then(
              (
                farmResponse:
                  | callContractResponse
                  | {
                      priceImpact: string
                      priceBefore: BigNumber
                      expectedAmountOut: string
                    }
              ) => {
                // console.log('getPriceImpactPath V1 ', farmResponse)

                if (
                  'result' in farmResponse &&
                  farmResponse.result === 'ERROR'
                ) {
                  console.log('stop : error')
                  setIsLoadingPriceData(false)
                  return
                }

                let priceImpact = ''
                if ('priceImpact' in farmResponse) {
                  priceImpact = farmResponse.priceImpact
                }

                let newToBalance =
                  unformatInputNumber(fromBalance).dividedBy(rate)
                setBalances([fromBalance, newToBalance.toString()])

                const priceImpactPercent = new BigNumber(
                  priceImpact + ''
                ).multipliedBy(100)
                let priceImpactLabel = priceImpactPercent.isLessThan(0.01)
                  ? '< 0.01'
                  : priceImpactPercent.dp(2).toFormat()

                setPriceImpact(priceImpactLabel + '%')
                setPriceImpactVal(priceImpactPercent.toNumber())
                setPriceImpactIsHigh(priceImpactPercent.isGreaterThan(90))

                setIsLoadingPriceData(false)
              }
            )
            .catch((e) => {
              console.error('ERROR: on getting price impact', e)
              setDisplayState('error')
              setIsLoadingPriceData(false)
            })
        }
      )
      .catch((e) => {
        console.error('ERROR: on finding route', e)
        setIsLoadingPriceData(false)
      })
  } else {
    getBestConversionRate(token1.address, token2.address, chainInfo, true)
    .then(
      (
        rateResponse:
          | callContractResponse
          | { rate: number; route: string[] }
          | undefined
      ) => {
        console.log('tokenKeyRef', tokenKeyRef)
        if (tokenKeyRef.current !== genTokenKey(token1, token2)) {
          return
        }
        if (rateResponse === undefined) {
          return
        }
        console.log('rateResponse V1', rateResponse)

        const invertedRate =
          'rate' in rateResponse
            ? toBigNumber(rateResponse.rate, token1)
            : new BigNumber(0)
        const rate = new BigNumber(1).dividedBy(invertedRate)
        let route = 'route' in rateResponse ? rateResponse.route : []
        console.log("getBestConversionRate rate",rate)
        setRateAndRoute(
          rate.isZero() || rate.isNaN() || !isArray(route)
            ? [BIG_NUMBER_ZERO, []]
            : [rate, route]
        )

        //commn : get price impact
        const fromBalance = balanceRef.current[0]
        const balanceInputNumber = unformatInputNumber(fromBalance)

        if (balanceInputNumber.isNaN() || balanceInputNumber.isZero()) {
          setDisplayState('input_zero')
          return
        }

        getRoutePriceImpact(
          route,
          toWei(balanceInputNumber, token1).toString(),
          chainInfo,
          true //disableMultiRoute = true, because this is swap V1
        )
          .then(
            (
              farmResponse:
                | callContractResponse
                | {
                    priceImpact: string
                    priceBefore: BigNumber
                    expectedAmountOut: string
                  }
            ) => {
              // console.log('getPriceImpactPath V1 ', farmResponse)

              if (
                'result' in farmResponse &&
                farmResponse.result === 'ERROR'
              ) {
                console.log('stop : error')
                setIsLoadingPriceData(false)
                return
              }

              let priceImpact = ''
              if ('priceImpact' in farmResponse) {
                priceImpact = farmResponse.priceImpact
              }

              let newToBalance =
                unformatInputNumber(fromBalance).dividedBy(rate)
              setBalances([fromBalance, newToBalance.toString()])

              const priceImpactPercent = new BigNumber(
                priceImpact + ''
              ).multipliedBy(100)
              let priceImpactLabel = priceImpactPercent.isLessThan(0.01)
                ? '< 0.01'
                : priceImpactPercent.dp(2).toFormat()

              setPriceImpact(priceImpactLabel + '%')
              setPriceImpactVal(priceImpactPercent.toNumber())
              setPriceImpactIsHigh(priceImpactPercent.isGreaterThan(90))

              setIsLoadingPriceData(false)
            }
          )
          .catch((e) => {
            console.error('ERROR: on getting price impact', e)
            setDisplayState('error')
            setIsLoadingPriceData(false)
          })
      }
    )
    .catch((e) => {
      console.error('ERROR: on finding route', e)
      setIsLoadingPriceData(false)
    })
  }
}

export const getPriceV2 = (
  userAddress: string | null | undefined,
  token1: Token | undefined,
  token2: Token | undefined,
  balanceFrom: string,
  slippage: string,
  deadLineInMinute: number,
  amountFrom: string,
  lastAmountOut: string | null,
  timeStamp: string,
  userInputChangeTimeStampRef: React.MutableRefObject<string>,
  fetchPriceDataTimeRef: React.MutableRefObject<number>,
  balanceFromRef: React.MutableRefObject<string>,
  currentRate: React.MutableRefObject<BigNumber>,
  clearAllTimer: () => void,
  clearFetchPrice: () => void,
  getMultiRouteData: () => void,
  getMultiRouteSwapInfo: ({
    userAddress,
    tokenFrom,
    tokenTo,
    amountFrom,
    lastAmountOut,
    chainId,
    slippage,
    deadLineInMinute,
    dexOutPutReturnType,
    tokens,
  }: {
    userAddress: string | undefined | null
    tokenFrom: Token | undefined
    tokenTo: Token | undefined
    lastAmountOut: string | null
    chainId: string | null
    amountFrom: string
    slippage: string
    deadLineInMinute: number
    chainInfo: Chain | undefined
    dexOutPutReturnType: DexOutPutReturnType
    tokens: Token[]
  }) => any,
  setDisplayState: (displayState: string) => void,
  setRateV2: (value: React.SetStateAction<BigNumber>) => void,
  setStartProgress: (value: React.SetStateAction<boolean>) => void,
  setMultiRouteNode: (
    value: React.SetStateAction<RouteNodeViewModel | null | undefined>
  ) => void,
  setMultiRouteResponse: React.Dispatch<
    React.SetStateAction<MultiRouteResponse | null | undefined>
  >,
  setBalances: React.Dispatch<React.SetStateAction<string[]>>,
  setIsLoadingPriceData: (isPriceLoading: boolean) => void,
  chainInfo: Chain | undefined,
  setPriceImpact: (val: string) => void,
  setPriceImpactVal: (val: number) => void,
  setPriceImpactIsHigh: (val: boolean) => void,
  setTransactionCost: (val: string) => void,
  swapDispatch: Dispatch<any>,
  dexOutPutReturnType: DexOutPutReturnType,
  tokens: Token[]
) => {
  getMultiRouteSwapInfo({
    userAddress: userAddress,
    tokenFrom: token1,
    tokenTo: token2,
    amountFrom: amountFrom,
    lastAmountOut: lastAmountOut,
    chainId: chainInfo ? chainInfo.chainId + '' : null,
    slippage: slippage,
    deadLineInMinute: deadLineInMinute,
    chainInfo: chainInfo,
    dexOutPutReturnType: dexOutPutReturnType,
    tokens: tokens,
  }).then((result: any) => {
    if (userInputChangeTimeStampRef.current !== timeStamp) {
      //ignore result because input changed while loading
      return
    }

    let amountFrom = unformatInputNumber(balanceFrom).toNumber()
    if (amountFrom === 0) {
      clearAllTimer()
      setDisplayState('input_zero')
      setStartProgress(false)
      return
    }

    let successResponse = result?.response as MultiRouteResponse
    if (successResponse && successResponse.status === 'success') {
      //Display Price
      const route = Object(result).viewModel

      let priceImpact = successResponse.priceImpact
      let price = successResponse.price
      var newRate = new BigNumber(price)

      if (newRate.isZero() || newRate.isNaN() || !route) {
        setRateV2(BIG_NUMBER_ZERO)
        currentRate.current = BIG_NUMBER_ZERO
      } else {
        setRateV2(newRate)
        currentRate.current = newRate
      }

      setMultiRouteNode(route)
      setMultiRouteResponse(successResponse)

      //Display Price Impact
      const pct = new BigNumber(priceImpact + '')

      let label = ''
      if (pct.isLessThan(0.01)) {
        label = '< 0.01'
      } else {
        label = pct.dp(2).toFormat()
      }
      setPriceImpact(label + '%')
      setPriceImpactVal(pct.toNumber())
      setPriceImpactIsHigh(pct.isGreaterThan(90))
      setTransactionCost('' + successResponse.transactionFee)

      //Set Balance To
      let amountOut = '' + successResponse.amountOut
      setBalances([balanceFromRef.current, amountOut])
      setIsLoadingPriceData(false)
    } else {
      setDisplayState('error')

      let erroResponse: MultiRouteErrorResponse =
        result?.response as MultiRouteErrorResponse

      if (erroResponse) {
        switch (erroResponse.status) {
          case 'fail_to_solve': {
            swapDispatch({
              type: 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL',
              payload: {
                routeDisplayViewModel: null,
                routeDisplayState: 'no-route',
              },
            })
            break
          }
          default: {
            //Handler Error such as NetWork Error
            break
          }
        }
      }
    }

    //Auto Retry
    setStartProgress(true)
    clearFetchPrice()
    fetchPriceDataTimeRef.current = +setInterval(
      getMultiRouteData,
      FETCH_PRICE_DATA_DURATION
    )
  })
}

export const updateExchangeInfo = async (
  newRate: BigNumber,
  tokenFrom: Token,
  tokenTo: Token,
  conversions: {
    id: string[]
    names: string[]
    data: any[]
  },
  updateView: boolean,
  balanceFrom: string,
  myDexes: React.MutableRefObject<DexInfoDisplay[]>,
  multiRouteResponse: MultiRouteResponse | null | undefined,
  //getDexInfos: () => Promise<DexInfoDisplay[]>,
  dexs: DexInfoDisplay[],
  setDexPriceListViewModel: React.Dispatch<
    React.SetStateAction<DexPriceListViewModel[]>
  >,
  callBack: (arr: DexPriceListViewModel[]) => void
) => {
  if (!myDexes.current.length) {
    myDexes.current = dexs //await getDexInfos()
  }

  const dexMapping = _(myDexes.current).keyBy('id').value()
  let newBalanceTo = unformatInputNumber(balanceFrom)
    .dividedBy(newRate)
    .toString()

  let safeBSCAmountOut = +newBalanceTo
  var arr = [
    {
      id: '001',
      dexId: '-',
      icon: '/images/logo-vertical.png',
      name: 'Hermes Swap',
      amountOut: formatInputNumber(safeBSCAmountOut.toFixed(10)),
      diff: 'BEST',
      isLoading: true,
      dexRate: newRate,
      rawAmountOut: safeBSCAmountOut,
    },
  ]

  let index = 0
  for (let dexId of conversions.id) {
    const dexInfo = dexMapping[dexId]
    const result = conversions.data[index]
    const bigRate = _.get(result, 'rate')

    var dexRate = new BigNumber(bigRate).dividedBy(
      new BigNumber(10).pow(tokenTo.decimals)
    )
    dexRate = new BigNumber(1).dividedBy(dexRate)
    const amountOut = unformatInputNumber(balanceFrom).dividedBy(dexRate)

    arr.push({
      id: dexInfo.farmName,
      dexId: dexInfo.id,
      icon: dexInfo.icon,
      name: dexInfo.farmName,
      amountOut: formatInputNumber(amountOut.toFixed(10)),
      diff:
        (
          ((amountOut.toNumber() - safeBSCAmountOut) / safeBSCAmountOut) *
          100
        ).toFixed(2) + '%',
      isLoading: true,
      dexRate: dexRate,
      rawAmountOut: amountOut.toNumber(),
    })
    index++
  }

  let allOnDex = null
  if (
    multiRouteResponse?.selectedRoutes.length === 1 &&
    multiRouteResponse?.selectedRoutes[0].length === 1
  ) {
    let object = multiRouteResponse.dexInfos
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key]
        allOnDex = element.id
        break
      }
    }
  }

  arr = arr.sort((n1, n2) => +n2.rawAmountOut - +n1.rawAmountOut)
  arr[0].diff = 'BEST'
  let bestAmount = +arr[0].rawAmountOut
  let i = 0
  let needTOSwapIndex = false
  let indexToSwap = -1

  for (let aa of arr) {
    if (aa.id === arr[0].id) {
      i++
      continue
    }
    let tempAmountOut = +aa.rawAmountOut
    if (
      tempAmountOut === bestAmount ||
      (allOnDex && (aa.dexId === '-' || aa.dexId === allOnDex))
    ) {
      if (aa.dexId === '-' && arr[0].dexId === allOnDex) {
        arr[i].diff = 'BEST'
        arr[0].diff = 'MATCH'
        indexToSwap = i
        needTOSwapIndex = true
      } else {
        aa.diff = 'MATCH'
      }
    } else {
      aa.diff =
        (((tempAmountOut - bestAmount) / bestAmount) * 100).toFixed(2) + '%'
    }
    i++
  }
  if (needTOSwapIndex && indexToSwap !== -1) {
    let temp = arr[indexToSwap]
    arr.splice(indexToSwap, 1)
    arr = [temp].concat([arr[0]]).concat(arr.splice(1, arr.length - 1))
  }
  if (updateView) setDexPriceListViewModel(arr)
}

export const useSwapPrice = () => {
  //useSwapLabel
  const { DASH } = useSwapLabel()

  const [priceImpactIsHigh, setPriceImpactIsHigh] = React.useState(false)
  const [priceImpact, setPriceImpact] = React.useState('-')
  const [priceImpactVal, setPriceImpactVal] = React.useState(0)
  const [isLowLP, setIsLowLP] = React.useState(false)
  const [transactionCost, setTransactionCost] = React.useState('-')

  function getFormattedTransactionCost(price: string, transactionCost: string) {
    if (price === DASH || transactionCost === DASH) return DASH
    return '~' + parseFloat(transactionCost).toLocaleString() + ' USD'
  }

  return {
    priceImpactIsHigh,
    priceImpact,
    priceImpactVal,
    transactionCost,
    isLowLP,
    setPriceImpactIsHigh,
    setPriceImpact,
    setPriceImpactVal,
    setTransactionCost,
    getFormattedTransactionCost,
    setIsLowLP
  }
}
