import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SearchInput = styled.input.attrs({
  className:
    'rounded-l-lg rounded-r-lg flex-1 bg-gray-600 border focus:outline-none focus:ring-2 ring-green-400 p-2 pl-4 pr-10 border-green',
})``

const SearchInputSimple = styled.input.attrs({
  className:
    'rounded-l-lg rounded-r-lg flex-1 bg-gray-600 border focus:outline-none focus:ring-2 ring-green-400 p-2 pl-4 pr-10 border-green',
})``

// const SearchContent = styled.div.attrs({
//   className:
//     'relative flex-1 md:flex-none h-10 justify-self-end md:w-96 flex justify-seft-end items-center ',
// })``

const SearchButton = styled.button.attrs({
  className: 'absolute bottom-0 top-0 right-0 p-2 text-gray-400 mr-2',
})``

export const SearchBox = ({
  onChange,
  placeholder = '',
  isShowOnlyIcon,
  onClickIconClose,
  onClickIconSearch,
  firstResponder,
  noDelay,
  isFixedSize: isFixSize,
}: {
  onChange: (data: string) => void
  placeholder?: string
  isShowOnlyIcon?: boolean
  onClickIconClose?: () => void
  onClickIconSearch?: () => void
  firstResponder?: boolean
  noDelay?: boolean
  isFixedSize: boolean
}) => {
  const searchInput = useRef<HTMLInputElement>(null)
  const handleFocus = useCallback(() => {
    searchInput.current?.focus()
    onClickIconSearch && onClickIconSearch()
  }, [onClickIconSearch])

  useEffect(() => {
    if (firstResponder) handleFocus()
  }, [firstResponder, handleFocus])

  // temporary local input value
  const [value, setValue] = useState<string>('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>()

  // delay input change callback 400ms
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    setTypingTimeout(
      setTimeout(() => {
        onChange(event.target.value)
      }, 400)
    )
  }
  return (
    <>
      <div className={ (isFixSize ? '' : 'md:w-96 ') + 'relative flex-1 md:flex-none h-10 justify-self-end flex justify-seft-end items-center' }>

        {!isShowOnlyIcon && isFixSize && (
          <SearchInputSimple
            ref={searchInput}
            type='text'
            placeholder={placeholder}
            onFocus={handleFocus}
            onChange={
              noDelay
                ? ({ target: { value } }) => onChange(value)
                : onInputChange
            }
            value={value}
          />
        )}

        {!isShowOnlyIcon && !isFixSize && (
          <SearchInput
            ref={searchInput}
            type='text'
            placeholder={placeholder}
            onFocus={handleFocus}
            onChange={
              noDelay
                ? ({ target: { value } }) => onChange(value)
                : onInputChange
            }
            value={value}
          />
        )}

        {value.length !== 0 ? (
          <SearchButton
            onClick={() => {
              onClickIconClose && onClickIconClose()
              onChange('')
              setValue('')
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              size='1x'
              className='text-primary-500'
            />
          </SearchButton>
        ) : (
          <SearchButton onClick={handleFocus}>
            <FontAwesomeIcon
              icon={faSearch}
              size='1x'
              className='text-primary-500'
            />
          </SearchButton>
        )}
      </div>
    </>
  )
}
