import { Chain } from 'models/chain.model'
import { DexContractInfo } from '../models/swap.model'

import BEP20_ABI from './abi/bep20-abi.json'
import KAP20_ABI from './abi/kap20-abi.json'

import PANCAKE_ROUTER_V2_ABI from './abi/reduced-pancake-router-v2-abi.json'
import PANCAKE_FACTORY_V2_ABI from './abi/reduced-pancake-factory-v2-abi.json'
import PANCAKE_PAIR_V2_ABI from './abi/reduced-pancake-pair-v2-abi.json'

import DIAMOND_ROUTER_ABI from './abi/reduced-diamond-router-abi.json'
import DIAMOND_FACTORY_ABI from './abi/reduced-diamond-factory-abi.json'
import DIAMOND_PAIR_ABI from './abi/reduced-diamond-pair-abi.json'

const BSC_BASE_TOKEN_FOR_SWAP = [
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  '0x55d398326f99059ff775485246999027b3197955',
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
]

const BKC_BASE_TOKEN_FOR_SWAP = [
  '0x7d984C24d2499D840eB3b7016077164e15E5faA6',
  '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5',
]

const JBC_BASE_TOKEN_FOR_SWAP = [
  '0xfd8ef75c1cb00a594d02df48addc27414bd07f8a', //USDT
  '0x99999999990fc47611b74827486218f3398a4abd'  //WJBC
]

const JFIN_BASE_TOKEN_FOR_SWAP = [
  '0x1Cfc7EE9f50C651759a1EfFCd058D84F42E26967',
  '0x1112eb329121F5513177C07729459A3Cf601f76d',
]

const PANCAKE_V2: DexContractInfo = {
  name: 'Pancake Swap V2',
  chefAddress: '0x73feaa1ee314f8c655e354234017be2193c9e24e',
  routerAddress: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
  factoryAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
  tokenChefAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  routerABI: PANCAKE_ROUTER_V2_ABI,
  factoryABI: PANCAKE_FACTORY_V2_ABI,
  pairABI: PANCAKE_PAIR_V2_ABI,
  tokenABI: BEP20_ABI,
  baseToken: BSC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance',
  getAmountsOut: 'getAmountsOut',
}

const DIAMOND: DexContractInfo = {
  name: 'Diamond Finance',
  chefAddress: '',
  routerAddress: '0xab30a29168d792c5e6a54e4bcf1aec926a3b20fa',
  factoryAddress: '0x6e906dc4749642a456907decb323a0065dc6f26e',
  tokenChefAddress: '0x67ebd850304c70d983b2d1b93ea79c7cd6c3f6b5',
  routerABI: DIAMOND_ROUTER_ABI,
  factoryABI: DIAMOND_FACTORY_ABI,
  pairABI: DIAMOND_PAIR_ABI,
  tokenABI: KAP20_ABI,
  baseToken: BKC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance,0x7d984c24d2499d840eb3b7016077164e15e5faa6:allowances',
  getAmountsOut: 'getAmountsOut',
}

const PANCAKE_V2_TESTNET: DexContractInfo = {
  name: 'Pancake Swap V2 Testnet',
  chefAddress: '',
  routerAddress: '0x439b10b7bc1fd359c2796c839a58c9b1bd91dde7',
  factoryAddress: '',
  tokenChefAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  routerABI: PANCAKE_ROUTER_V2_ABI,
  factoryABI: PANCAKE_FACTORY_V2_ABI,
  pairABI: PANCAKE_PAIR_V2_ABI,
  tokenABI: BEP20_ABI,
  baseToken: BSC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance',
  getAmountsOut: 'getAmountsOut',
}

const DIAMOND_TESTNET: DexContractInfo = {
  name: 'Diamond Finance Testnet',
  chefAddress: '',
  routerAddress: '0xbd84f72a48a3b8412e0954e03bbdc218dd194740',
  factoryAddress: '0x6e906dc4749642a456907decb323a0065dc6f26e',
  tokenChefAddress: '0x67ebd850304c70d983b2d1b93ea79c7cd6c3f6b5',
  routerABI: DIAMOND_ROUTER_ABI,
  factoryABI: DIAMOND_FACTORY_ABI,
  pairABI: DIAMOND_PAIR_ABI,
  tokenABI: KAP20_ABI,
  baseToken: BKC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance,0x7d984c24d2499d840eb3b7016077164e15e5faa6:allowances',
  getAmountsOut: 'getAmountsOut',
}

const HERMES: DexContractInfo = {
  name: 'Hermes',
  chefAddress: '',
  routerAddress: '0x6f2427E6373781857B12Afc1f0754B21b6DC8f60',
  factoryAddress: '',
  tokenChefAddress: '',
  routerABI: DIAMOND_ROUTER_ABI,
  factoryABI: DIAMOND_FACTORY_ABI,
  pairABI: DIAMOND_PAIR_ABI,
  tokenABI: KAP20_ABI,
  baseToken: BKC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance,0x7d984c24d2499d840eb3b7016077164e15e5faa6:allowances',
  getAmountsOut: 'getAmountsOut',
}

const JBCDEX: DexContractInfo = {
  name: 'JBC Dex',
  chefAddress: '',
  routerAddress: '0x766F8C9321704DC228D43271AF9b7aAB0E529D38',
  factoryAddress: '0x4BBdA880C5A0cDcEc6510f0450c6C8bC5773D499',
  tokenChefAddress: '0x99999999990fc47611b74827486218f3398a4abd',
  routerABI: PANCAKE_ROUTER_V2_ABI,
  factoryABI: PANCAKE_FACTORY_V2_ABI,
  pairABI: PANCAKE_PAIR_V2_ABI,
  tokenABI: BEP20_ABI,
  baseToken: JBC_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance',
  getAmountsOut: 'getAmountsOut',
}

const JFINDEX: DexContractInfo = {
  name: 'JFin Dex',
  chefAddress: '',
  // routerAddress: '0xA246Cb1e5D951E1911efd0d9DDB4b5545119f13d',
  routerAddress: '0x47903bFF35Cb808ecbe545Bc4ccA1894F1ba3Be2',
  factoryAddress: '0x8fbf1c2e49eec070da062e16f13b808bfe0a3ec2',
  tokenChefAddress: '0x1112eb329121F5513177C07729459A3Cf601f76d',
  routerABI: PANCAKE_ROUTER_V2_ABI,
  factoryABI: PANCAKE_FACTORY_V2_ABI,
  pairABI: PANCAKE_PAIR_V2_ABI,
  tokenABI: BEP20_ABI,
  baseToken: JFIN_BASE_TOKEN_FOR_SWAP,
  allowance: 'allowance',
  getAmountsOut: 'getAmountsOut',
}

const DEX_CONTRACT_INFO_MAP: { [index: string]: DexContractInfo } = {
  '00071': PANCAKE_V2,
  '96001': DIAMOND,
  '970007': PANCAKE_V2_TESTNET,
  '25925001': DIAMOND_TESTNET,
  'jib_dex': JBCDEX,
  'jfin_dex': JFINDEX,
}

export const defaultDEXContractInfo = (chainInfo: Chain, disableMultiRoute: boolean) => {
  if (chainInfo.frontEndData.multiRouteEnabled && !disableMultiRoute) {
    return HERMES
  }
  return DEX_CONTRACT_INFO_MAP[chainInfo.frontEndData.defaultDEX]
}
