import * as React from 'react'
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  LanguageCode,
  ResolutionString,
  widget,
} from '../../utils/charting_library/charting_library'
import { getDataFeed } from './datafeed'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useCallback } from 'react'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { Token } from 'models/token.model'
import { useChainState } from 'contexts/ChainContext'
import { getChainById } from 'hooks/Chain/useChain'
import { PriceModel, useFarmPriceDispatch } from 'contexts/FarmPriceContext'
import { Box, Typography } from '@material-ui/core'

type RealTimeCallBackType = (() => void) | null | undefined
export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol']
  interval: ChartingLibraryWidgetOptions['interval']

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string
  libraryPath: ChartingLibraryWidgetOptions['library_path']
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']
  clientId: ChartingLibraryWidgetOptions['client_id']
  userId: ChartingLibraryWidgetOptions['user_id']
  fullscreen: ChartingLibraryWidgetOptions['fullscreen']
  autosize: ChartingLibraryWidgetOptions['autosize']
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides']
  containerId: ChartingLibraryWidgetOptions['container_id']
  data: any[]
}

export interface ChartContainerState {}

declare var location: any

const Container = styled.div`
  /* height: calc(100vh - 80px); */
  height: 400px;
`

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode)
}

export class TVChartContainer extends React.PureComponent<
  Partial<ChartContainerProps>,
  ChartContainerState
> {
  private tvWidget: IChartingLibraryWidget | null = null

  public componentDidMount(): void {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol as string,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
        this.props.datafeedUrl
      ),
      // datafeed: Datafeed,
      container: '',
      interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
      container_id: this.props
        .containerId as ChartingLibraryWidgetOptions['container_id'],
      library_path: this.props.libraryPath as string,

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
    }

    const tvWidget = new widget(widgetOptions)
    this.tvWidget = tvWidget
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove()
      this.tvWidget = null
    }
  }

  public render(): JSX.Element {
    return (
      <Container id={this.props.containerId} className={'TVChartContainer'} />
    )
  }
}

interface TradingViewChartContainerProps {
  tokenFrom: Token
  tokenTo: Token

  symbol: ChartingLibraryWidgetOptions['symbol']
  interval: ChartingLibraryWidgetOptions['interval']

  containerId?: ChartingLibraryWidgetOptions['container_id']
  libraryPath: ChartingLibraryWidgetOptions['library_path']
}

function TradingViewChartContainer({
  tokenFrom,
  tokenTo,
  containerId,
  libraryPath,
  symbol,
  interval,
}: TradingViewChartContainerProps): React.ReactElement {
  const farmDispatch = useFarmPriceDispatch()
  const { chains } = useChainState()
  const [updaetRealTimeFunction, setUpdateRealTimeFunction] =
    React.useState<{ callback: RealTimeCallBackType }>()

  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null)
  var selectedTimeFrame =
    window.sessionStorage.getItem('selectedTimeFrame') ?? '1D'

  let setSelectedTimeFrame = useCallback((timeFram: string) => {
    window.sessionStorage.setItem('selectedTimeFrame', timeFram)
  }, [])

  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const [chartElmentCount, setChartElementCount] = React.useState<{
    [index: string]: boolean
  }>({})

  const notifyLastChartPrice = useCallback(
    (priceModel: PriceModel) => {
      farmDispatch({ type: 'LAST_CHART_PRICE', payload: priceModel })
    },
    [farmDispatch]
  )

  const realTimeTimeRef = React.useRef(0)
  const startRealTimeUpdateChartTimer = useCallback(() => {
    clearInterval(realTimeTimeRef.current)
    if (updaetRealTimeFunction) {
      if (updaetRealTimeFunction.callback) updaetRealTimeFunction.callback()
      realTimeTimeRef.current = +setInterval(
        startRealTimeUpdateChartTimer,
        15000
      )
    }
  }, [updaetRealTimeFunction])

  const isHasDataChart = React.useCallback(() => {
    const key = tokenFrom.address + '_' + tokenTo.address
    return chartElmentCount[key] === true
  }, [chartElmentCount, tokenFrom.address, tokenTo.address])

  // console.log('chartElmentCount >>>', chartElmentCount)
  // console.log('isHasDataChart >>>', isHasDataChart())

  //comment: Start timer when updaetRealTimeFunction is Valid
  useEffect(() => {
    if (updaetRealTimeFunction) {
      startRealTimeUpdateChartTimer()
    }
  }, [startRealTimeUpdateChartTimer, updaetRealTimeFunction])

  const doCallUpdateRealTime = useCallback(
    (func: RealTimeCallBackType) => {
      setUpdateRealTimeFunction({ callback: func })
    },
    [setUpdateRealTimeFunction]
  )

  useEffect(() => {
    if (selectedChain?.frontEndData.displayType === 'simpleModeOnly') {
      return
    }

    if (tvWidgetRef.current) {
      return
    }

    if (realTimeTimeRef.current !== 0) {
      clearInterval(realTimeTimeRef.current)
      setUpdateRealTimeFunction({ callback: null })
      realTimeTimeRef.current = 0
    }

    // console.log('CREATE tvWidget >>>')
    const tvWidget = new widget({
      symbol, // default symbol
      interval: selectedTimeFrame, // default interval
      fullscreen: false, // displays the chart in the fullscreen mode
      autosize: true,
      container: containerId,
      datafeed: getDataFeed(
        setSelectedTimeFrame,
        selectedChain,
        tokenFrom,
        tokenTo,
        notifyLastChartPrice,
        doCallUpdateRealTime,
        (isLessThenZero: boolean) => {
          tvWidget.onChartReady(function () {
            console.log('isLessThenZero', isLessThenZero)
            if (isLessThenZero) {
              Object(tvWidget.chart().priceFormatter())._fractionalLength = 6
              Object(tvWidget.chart().priceFormatter())._priceScale = 1
              Object(tvWidget.chart().priceFormatter())._minMove = 0.000001
              Object(tvWidget.chart().priceFormatter())._precision = 6
            } else {
              Object(tvWidget.chart().priceFormatter())._fractionalLength = 2
              Object(tvWidget.chart().priceFormatter())._priceScale = 10
              Object(tvWidget.chart().priceFormatter())._minMove = 1
              Object(tvWidget.chart().priceFormatter())._precision = 2
            }
          })
        },
        (tokenFrom: Token, tokenTo: Token, date: string, count: number) => {
          const key = tokenFrom.address + '_' + tokenTo.address
          let value = chartElmentCount[key]
          let isHasData = count > 0
          if (isHasData && !value) {
            //has initial data
            const updatedChartElmentCount = { ...chartElmentCount }
            updatedChartElmentCount[key] = isHasData

            // console.log("key >>>", key)
            console.log('count >>>', count)
            console.log('updatedChartElmentCount >>>', updatedChartElmentCount)
            setChartElementCount(updatedChartElmentCount)
          }
        }
      ),
      library_path: libraryPath as string,
      clientId: 'tradingview.com',
      userId: 'public_user_id',
      theme: 'dark',
      time_frames: [
        // {
        //   text: '15m',
        //   resolution: '15',
        //   description: '15 Minutes',
        //   title: '15m',
        // },
        {
          text: '1d',
          resolution: '1D',
          description: '1 Day',
        },
        {
          text: '1Y',
          resolution: 'M',
          description: '12 Months',
        },
      ],
      disabled_features: ['header_symbol_search', 'chart_zoom'],
    } as any)
    tvWidgetRef.current = tvWidget

    return () => {
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove()
        tvWidgetRef.current = null
      }
    }
  }, [
    containerId,
    interval,
    libraryPath,
    symbol,
    selectedTimeFrame,
    setSelectedTimeFrame,
    selectedChain,
    tokenFrom,
    tokenTo,
    notifyLastChartPrice,
    doCallUpdateRealTime,
    chartElmentCount,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
      <Box
        sx={{
          border: 1,
          flex: 1,
          position: 'relative',
          opacity: isHasDataChart() ? 1 : 0.7,
        }}>
        <Container id={containerId} className={'TVChartContainer'} />
      </Box>
      {!isHasDataChart() && (
        <Box
          sx={{
            zIndex: 2,
            top: '40%',
            bottom: '50%',
            position: 'absolute',
            fontWeight: 'bold',
            color: '#FFFFFF',
            opacity: 0.5,
          }}>
          <Box
            sx={{
              padding: 16,
              border: 1,
              borderColor: 'gray',
              borderStyle: 'solid',
            }}>
            <Typography variant='h5'>NO CHART DATA</Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

TradingViewChartContainer.defaultProps = {
  containerId: 'tv_chart_containerx',
  libraryPath: '/charting_library/',
  interval: '1H' as ResolutionString,
}

export default React.memo(TradingViewChartContainer)
