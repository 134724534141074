/* eslint-disable no-eval */
import '@metamask/detect-provider'
import axios from 'axios'
import { ASSETS_SUPPORT_FARM_URL } from 'constants/url.constant'
import { callContractProgress } from '../models/contract.model'
import { MyFarmDetail, Pool, Farm } from '../models/farm.model'
import { callContractMethod } from './contract.service'
import POOL_ABI from '../constants/abi/pool-abi.json'

var _farmInfos: Farm[] | undefined = undefined
export const getFarmInfos = async (): Promise<Farm[]> =>
  _farmInfos ??
  (_farmInfos = await axios
    .get(ASSETS_SUPPORT_FARM_URL)
    .then((res) => res.data))

export type FarmFunctionType = 'Withdraw' | 'Claim' | 'SOS'
export const isFarmFunctionAvailable = (
  type: FarmFunctionType,
  farmInfo: Farm,
  lpInfo: MyFarmDetail
) => {
  //TODO: This special condition should change to a more generic way
  if (farmInfo.farmName === 'fulcrum' && type === 'Claim') {
    return false
  }
  const poolInfo = getPoolInfoWithFarmDetails(farmInfo, lpInfo)
  switch (type) {
    case 'Withdraw':
      if (poolInfo) return !!poolInfo.withdraw
      if (lpInfo.poolId === 0) return !!farmInfo.pool0Withdraw
      return !!farmInfo.withdraw
    case 'Claim':
      return poolInfo ? !!poolInfo.harvest : !!farmInfo.harvest
    case 'SOS':
      return poolInfo ? !!poolInfo.sos : !!farmInfo.sos
  }
}

export const getPoolInfoWithFarmDetails = (
  farmInfo: Farm,
  farmDetail: MyFarmDetail
): Pool | undefined => {
  if (!farmInfo.pools) return undefined
  return farmInfo.pools.find(
    (poolInfo) => poolInfo.stakingToken === farmDetail.lpAddress
  )
}

export const harvest = async (
  farmInfo: Farm,
  poolId: number,
  progressFunction?: (response: callContractProgress) => void
) => {
  if (farmInfo.pool0Withdraw && poolId === 0) {
    return callContractMethod(
      farmInfo.chefAddress,
      farmInfo.ABI,
      farmInfo.pool0Withdraw,
      { amount: '0', poolId: 0 },
      progressFunction
    )
  } else {
    return callContractMethod(
      farmInfo.chefAddress,
      farmInfo.ABI,
      farmInfo.harvest,
      { amount: '0', poolId: poolId },
      progressFunction
    )
  }
}

export const poolHarvest = async (
  poolInfo: Pool,
  poolId: number,
  progressFunction?: (response: callContractProgress) => void
) => {
  return callContractMethod(
    poolInfo.poolAddress,
    POOL_ABI,
    poolInfo.harvest,
    { amount: '0', poolId: poolId },
    progressFunction
  )
}

export const withdraw = async (
  farmInfo: Farm,
  poolId: number,
  amount: string,
  progressFunction?: (response: callContractProgress) => void
) => {
  if (farmInfo.pool0Withdraw && poolId === 0) {
    return callContractMethod(
      farmInfo.chefAddress,
      farmInfo.ABI,
      farmInfo.pool0Withdraw,
      { amount: amount, poolId: 0 },
      progressFunction
    )
  } else {
    return callContractMethod(
      farmInfo.chefAddress,
      farmInfo.ABI,
      farmInfo.withdraw,
      { amount: amount, poolId: poolId },
      progressFunction
    )
  }
}

export const poolWithdraw = async (
  poolInfo: Pool,
  poolId: number,
  amount: string,
  progressFunction?: (response: callContractProgress) => void
) => {
  return callContractMethod(
    poolInfo.poolAddress,
    POOL_ABI,
    poolInfo.withdraw,
    { amount: amount, poolId: 0 },
    progressFunction
  )
}

export const sos = async (
  farmInfo: Farm,
  poolId: number,
  progressFunction?: (response: callContractProgress) => void
) => {
  return callContractMethod(
    farmInfo.chefAddress,
    farmInfo.ABI,
    farmInfo.sos,
    { amount: '0', poolId: poolId },
    progressFunction
  )
}

export const poolSos = async (
  poolInfo: Pool,
  poolId: number,
  progressFunction?: (response: callContractProgress) => void
) => {
  return callContractMethod(
    poolInfo.poolAddress,
    POOL_ABI,
    poolInfo.sos,
    { amount: '0', poolId: poolId },
    progressFunction
  )
}
