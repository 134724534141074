import DexPriceListView from 'components/SwapExchangesView'
import { UserTradeHistory } from 'components/UserTradeHistory'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { useTranslationState } from 'contexts/TranslationContext'
import { Chain } from 'models/chain.model'
import { Token } from 'models/token.model'
import React, { FC } from 'react'
import { FooterViewType } from 'type/FooterViewSectionType'

export const FooterTabView: FC<{
  exchangeEnabled: boolean
  selectedChain: Chain | undefined
  token2: Token | null
  exchangeOnlyMode: boolean
  demo?: boolean
}> = ({
  exchangeEnabled,
  selectedChain,
  token2,
  exchangeOnlyMode,
  demo = false
}) => {
  //useTranslation
  const { tl } = useTranslationState()
  const { dexPriceListViewModel, routeDisplayViewModel } = useSwapGlobalState()
  const [viewType, setViewType] = React.useState<FooterViewType>('trade-history')

  let menuSelectedStyle =
    'pl-2 pr-2 border-b-4 border-blue-400 text-blue-200 cursor-pointer'
  const menuUnSelectedStyle = 'flex pl-2 border-b-4 border-gray-800 hover:border-blue-200 text-gray-300 pr-2 cursor-pointer'

  if(exchangeOnlyMode && viewType !== 'exchange-rate'){
    setViewType('exchange-rate')
  }
  else if (!exchangeOnlyMode &&
    selectedChain?.frontEndData.exchangeEnabled === false &&
    viewType === 'exchange-rate'
  ) {
    setViewType('lp-info')
  }

  return (
    <div className='flex flex-col'>
      <div className={'flex flex-row' + ( exchangeOnlyMode ? ' hidden' : '' ) }>
        {exchangeEnabled ? (
          <div
            className={
              (viewType === 'exchange-rate' ? menuSelectedStyle : menuUnSelectedStyle) + ' flex h-10 items-center'
            }
            onClick={() => {
              setViewType('exchange-rate')
            }}>
            {tl.exchange_rate}
          </div>
        ) : (
          <></>
        )}
        <div
          className={
            (viewType === 'trade-history' ? menuSelectedStyle : menuUnSelectedStyle) + ' flex h-10 items-center'
          }
          onClick={() => {
            setViewType('trade-history')
          }}>
          {tl.trade_history}
        </div>
      </div>
      {viewType === 'exchange-rate' && dexPriceListViewModel?.length && routeDisplayViewModel ? (
        <div className={ 'flex m-6 p-4' + ( exchangeOnlyMode? '' : ' border border-gray-600' )} >
          <DexPriceListView
            amountTitle={'Amount (' + (token2 ? token2.name : '') + ')'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* {viewType === 'lp-info' ? (
        {liquidityDemoView}
      ) : (
        <></>
      )} */}
      {viewType === 'trade-history' ? (
        <UserTradeHistory isCompact={true} isOnlyMadeByHermes={true}></UserTradeHistory>
      ) : (
        <></>
      )}
      {/* {viewType === 'trade-history' ? (
        <div className='flex p-4 border border-gray-600' style={{minHeight: 395}}>
          <div className='flex flex-1 flex-col' style={{ fontSize: '14px' }}>
            <div className='flex flex-1 border-b-2 border-gray-600 h-32 py-5'>
              <div className='flex w-32 pl-2'>{'Date & Time'}</div>
              <div className='flex w-16'>Transfer</div>
              <div className='flex flex-1 pr-2 justify-end'>Exchange Rate</div>
              <div className='flex flex-1 pr-2 justify-end'>Total Value</div>
              <div className='flex flex-1 pr-2 justify-end'>Status</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  )
}


// const liquidityDemoView = () => {
//   return (<div className='flex p-4 border border-gray-600'>
//   <div className='flex flex-1 flex-col' style={{ fontSize: '14px' }}>
//     <div className='flex flex-1 border-b-2 border-gray-600 h-32 py-5'>
//       <div className='flex w-32 pl-2'>Liquidity Pool</div>
//       <div className='flex w-16'>DEX</div>
//       <div className='flex flex-1 pr-2 justify-end'>Token Pooled</div>
//       <div className='flex flex-1 pr-2 justify-end'>Token Price</div>
//       <div className='flex flex-1 pr-2 justify-end'>
//         Total Liquidity
//       </div>
//       <div className='flex w-16 pr-4 justify-end'></div>
//     </div>
//     <div className='flex flex-1 flex-col bg-gradient-to-bl from-gray-800 to-gray-900'>
//       <div className='flex flex-1 border-b border-gray-600 h-32 py-5'>
//         <div className='flex w-32 pl-4'>BTCB/BUSD</div>
//         <div className='flex w-16'>Pancake</div>
//         <div className='flex flex-1 pr-2 justify-end'>1,058.15</div>
//         <div className='text-green-400 flex flex-1 pr-2 justify-end'>
//           $20,435.93
//         </div>
//         <div className='flex flex-1 pr-2 justify-end'>
//           $21,624,330.10
//         </div>
//         <div
//           className='cursor-pointer flex w-16 pr-4 justify-end'
//           onClick={() => {
//             window.open(
//               'https://bscscan.com/address/0x2fb64529d21ac67bfecbebffee62f63fa21bf6e7'
//             )
//           }}>
//           <svg
//             xmlns='http://www.w3.org/2000/svg'
//             className='h-6 w-6'
//             fill='none'
//             viewBox='0 0 24 24'
//             stroke='currentColor'
//             stroke-width='2'>
//             <path
//               stroke-linecap='round'
//               stroke-linejoin='round'
//               d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'
//             />
//           </svg>
//         </div>
//       </div>
//       <div className='flex flex-1 border-b border-gray-600 h-32 py-5'>
//         <div className='flex w-32 pl-4'>BTCB/WBNB</div>
//         <div className='flex w-16'>Pancake</div>
//         <div className='flex flex-1 pr-2 justify-end'>902.78</div>
//         <div className='text-green-400 flex flex-1 pr-2 justify-end'>
//           $20,433.05
//         </div>
//         <div className='flex flex-1 pr-2 justify-end'>
//           $18,446,623.41
//         </div>
//         <div
//           className='cursor-pointer flex w-16 pr-4 justify-end'
//           onClick={() => {
//             window.open(
//               'https://bscscan.com/address/0x2fb64529d21ac67bfecbebffee62f63fa21bf6e7'
//             )
//           }}>
//           <svg
//             xmlns='http://www.w3.org/2000/svg'
//             className='h-6 w-6'
//             fill='none'
//             viewBox='0 0 24 24'
//             stroke='currentColor'
//             stroke-width='2'>
//             <path
//               stroke-linecap='round'
//               stroke-linejoin='round'
//               d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'
//             />
//           </svg>
//         </div>
//       </div>
//       <div className='flex flex-1 border-b border-gray-600 h-32 py-5'>
//         <div className='flex w-32 pl-4'>BTCB/WBNB</div>
//         <div className='flex w-16'>Biswap</div>
//         <div className='flex flex-1 pr-2 justify-end'>438.92</div>
//         <div className='text-green-400 flex flex-1 pr-2 justify-end'>
//           $20,431.93
//         </div>
//         <div className='flex flex-1 pr-2 justify-end'>
//           $8,985,387.29
//         </div>
//         <div
//           className='cursor-pointer flex w-16 pr-4 justify-end'
//           onClick={() => {
//             window.open(
//               'https://bscscan.com/address/0x2fb64529d21ac67bfecbebffee62f63fa21bf6e7'
//             )
//           }}>
//           <svg
//             xmlns='http://www.w3.org/2000/svg'
//             className='h-6 w-6'
//             fill='none'
//             viewBox='0 0 24 24'
//             stroke='currentColor'
//             stroke-width='2'>
//             <path
//               stroke-linecap='round'
//               stroke-linejoin='round'
//               d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'
//             />
//           </svg>
//         </div>
//       </div>
//     </div>
//     <div className='flex flex-1 border-b border-gray-600 h-32 py-2'>
//       <div className='flex w-32 pl-4'>Total</div>
//       <div className='flex w-16'></div>
//       <div className='flex flex-1 pr-2 justify-end'>2,399.85</div>
//       <div className='text-green-400 flex flex-1 pr-2 justify-end'>
//         $20,433.63
//       </div>
//       <div className='flex flex-1 pr-2 justify-end'>$49,056,340.80</div>
//       <div className='flex w-16 pr-4 justify-end'></div>
//     </div>
//   </div>
// </div>)
// }
