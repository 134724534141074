/*
   For diplaying balance :
   maximum decimal is 12 digit
   minimum decimal is 4 digit
 */

import BigNumber from 'bignumber.js'

BigNumber.config({ EXPONENTIAL_AT: 1e9 })

export type NumberLike = BigNumber | string | number | null | undefined

export const decimalToUnsignedIntWithTokenDecimal = (
  amount: NumberLike,
  decimal: NumberLike
) => {
  if (!amount || !decimal) return new BigNumber(amount || 0)

  const bn = amount instanceof BigNumber ? amount : new BigNumber(amount)
  if (bn.isNaN()) return new BigNumber(0)
  let result = bn
    .multipliedBy(new BigNumber(10).pow(decimal))
    .dp(0, BigNumber.ROUND_DOWN)
  return result
}

export const formatBalance = (val: NumberLike, max: number = 12) => {

  // console.log("formatBalance val", val)
  if (!val) return '0'

  // console.log("max", max)
  const toStr = new BigNumber(val).toFixed(max, BigNumber.ROUND_DOWN)
  // console.log("toStr val", toStr)

  const arr = toStr.split('.')
  let newStr = ''
  if (arr.length === 2) {
    let fp = arr[1]
    if (fp.length < 4) {
      while (fp.length < 4) {
        fp = fp + '0'
      }
      newStr = new BigNumber(arr[0]).toFormat() + '.' + fp
    } else {
      newStr = new BigNumber(toStr).toFormat()
    }
  } else {
    newStr = new BigNumber(arr[0]).toFormat()
  }
  return newStr
}

export const formatRouteBalance = (val: NumberLike) => {
  if (!val) return '0'

  const toStr = new BigNumber(val).toFixed(6, BigNumber.ROUND_DOWN)
  const arr = toStr.split('.')
  let newStr = ''
  if (arr.length === 2) {
    let fp = arr[1]
    if (fp.length < 4) {
      while (fp.length < 4) {
        fp = fp + '0'
      }
      newStr = new BigNumber(arr[0]).toFormat() + '.' + fp
    } else {
      newStr = new BigNumber(toStr).toFormat()
    }
  } else {
    newStr = new BigNumber(arr[0]).toFormat()
  }
  return newStr
}

export const unformatInputNumber = (val: NumberLike): BigNumber => {
  if (!val) return new BigNumber(0)

  if (typeof val !== 'number' && !(val instanceof BigNumber)) {
    val = (val + '').replace(/([^0-9.-])/g, '').replace(/([^^])-/g, '$1')
    if (!/\d/.test(val)) val = '0'
  }
  const bn = new BigNumber(val)
  if (bn.isNaN()) return new BigNumber(0)

  return bn
}

export const formatInputNumber = (val: NumberLike): string => {
  if (typeof val !== 'number' && !(val instanceof BigNumber)) {
    val = (val + '').replace(/[^0-9.]/g, '')
    if (!val) return ''

    if (/\..*\./.test(val)) val = val.replace(/\.(?!$)/g, '')

    if (/\.\d*$/.test(val)) {
      const vals = val.split('.')
      return (
        (vals[0] ? new BigNumber('0' + vals[0]).toFormat() : '0') +
        '.' +
        (vals[1].substring(0, 12) || '')
      )
    }
  }
  return new BigNumber(val).dp(13, BigNumber.ROUND_DOWN).toFormat()
}

export const commaStrDecimal = (
  decimalStr: string,
  floatingPoint = 4,
  includeCurrency = false
): string => {
  let decimal = Number(decimalStr)
  if (isNaN(decimal)) return '-'
  return (
    (includeCurrency ? '$' : '') +
    decimal.toLocaleString(undefined, {
      minimumFractionDigits: floatingPoint,
      maximumFractionDigits: floatingPoint,
    })
  )
}

export const calPercentTokenPrice = (
  rawValue: string,
  rawChange: string,
  status: 'up' | 'down'
) => {
  const value = Number(rawValue) ?? 0
  const change = Number(rawChange) ?? 0
  if (!Number.isNaN(change) && !Number.isNaN(value) && change > 0) {
    switch (status) {
      case 'up': {
        const prevValue = value - change
        const percent = (100 - (prevValue * 100) / value).toFixed(2)
        return percent
      }
      case 'down': {
        const prevValue = value + change
        const percent = (100 - (value * 100) / prevValue).toFixed(2)
        return percent
      }
    }
  }
  return ''
}

export const currencyFormat = (
  number: number,
  decimals: number = 2
): string => {
  const format =
    Math.trunc(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
  const numberFormat = format.toFixed(decimals)
  let str = numberFormat.toString().split('.')
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return str.join('.')
}
