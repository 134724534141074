import { MenuDropDown } from 'components/MenuDropDown'
import { useChainState } from 'contexts/ChainContext'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import usedChain, { getChainById } from 'hooks/Chain/useChain'
import { Chain } from 'models/chain.model'
import React from 'react'
import { useHistory } from 'react-router-dom'

const TopBarMobile = ({
  handleShowSideBar,
}: {
  handleShowSideBar: () => void
}) => {
  const history = useHistory()
  const { tokens } = useTokenState()
  const { chains } = useChainState()
  const { setChainId } = usedChain()
  const { topBarHidden } = useSwapGlobalState()

  const { selectedChainId } = useSwapGlobalState()

  const swapDispatch = useSwapDispatch()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const getChainData = (chains: Chain[]) => {
    chains = chains.sort((n1, n2) => n1.chainId - n2.chainId)
    chains = chains.sort((n1, n2) => {
      if (
        n1.name.toLowerCase().indexOf('testnet') >= 0 &&
        n2.name.toLowerCase().indexOf('testnet') >= 0
      ) {
        return 0
      } else if (n1.name.toLowerCase().indexOf('testnet') >= 0) {
        return 1
      } else if (n2.name.toLowerCase().indexOf('testnet') >= 0) {
        return -1
      }
      return 0
    })
    return chains.map((chain) => {
      return {
        value: '' + chain.chainId,
        label: chain.name,
        icon: chain.frontEndData.icon,
      }
    })
  }

  const isMobile = true
  return (
    <div
      className={
        'relative flex text-white justify-between items-center py-2 bg-gray-800 bg-opacity-90' +
        (isMobile ? ' pr-4' : ' space-x-12 px-6') +
        (topBarHidden ? ' hidden' : '')
      }>
      <div
        className={'flex space-x-2 items-center' + (isMobile ? ' flex-1' : '')}>
        <img
          alt='hermes logo'
          className='h-10'
          src='./images/logo-horizontal.png'
          onClick={() => {
            history.push('/')
          }}
        />
      </div>

      <div className='mr-4'>
        <MenuDropDown
          isItalicFont={false}
          icon={selectedChain?.frontEndData.icon}
          tintColor={selectedChain?.frontEndData.tint}
          title={''}
          items={getChainData(chains)}
          onChange={(item) => {
            setChainId(parseInt(item), tokens, chains, (success) => {
              if (success) {
                swapDispatch({
                  type: 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL',
                  payload: {
                    routeDisplayViewModel: null,
                    routeDisplayState: 'success',
                  },
                })
              }
            })
          }}></MenuDropDown>
      </div>

      <div className='cursor-pointer' onClick={handleShowSideBar}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-6 w-6'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          strokeWidth='2'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M4 6h16M4 12h16M4 18h16'
          />
        </svg>
      </div>
    </div>
  )
}

export default TopBarMobile
