import { Token } from 'models/token.model'
import React, { createContext, useEffect, useReducer } from 'react'
import { getTokens } from 'services/token.service'

export type ActionType = 'TOKENS'
type Action =
  | { type: 'TOKENS'; payload: Token[] }
type Dispatch = (action: Action) => void
type State = {
  tokens: Token[]
}

const TokenStateContext = createContext<State | undefined>(undefined)
const TokenDispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'TOKENS': {
      return { ...state, tokens: action.payload }
    }
  }
}

const TokenProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, {
    tokens: []
  })

  useEffect(() => {
    getTokens().then((response) => {
      dispatch({
        type: 'TOKENS',
        payload: response,
      })
    })
  }, [])

  return (
    <TokenStateContext.Provider value={state}>
      <TokenDispatchContext.Provider value={dispatch}>
        {children}
      </TokenDispatchContext.Provider>
    </TokenStateContext.Provider>
  )
}

const useTokenState = (): State => {
  const context = React.useContext(TokenStateContext)
  if (context === undefined) {
    throw new Error('useTokenState must be used within a TokenProvider')
  }
  return context
}
const useTokenDispatch = (): Dispatch => {
  const context = React.useContext(TokenDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useTokenDispatch must be used within a TokenProvider'
    )
  }
  return context
}

export { TokenProvider, useTokenState, useTokenDispatch }
