export interface IAlertDialog {
  title: string
  description: string
  icon?: JSX.Element
  show: boolean
  onClose?: () => void
}

export const AlertDialog = ({
  title,
  description,
  onClose,
  icon,
}: {
  title: string
  description: any
  onClose: () => void
  icon?: JSX.Element
}) => {
  return (
    <div className='fixed inset-0 z-10 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'>
          &#8203;
        </span>
        <div
          className='inline-block w-full overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl mb-28 transform transition-all sm:my-8 sm:align-middle md:max-w-md md:w-full'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'>
          <div className='px-4 pt-5 pb-4 card sm:p-6 sm:pb-4 justify-items-center grid'>
            <div className='flex items-center justify-center flex-shrink-0 w-20 h-20 mx-auto mb-3 sm:mx-0'>
              {icon ?? (
                <svg
                  className='w-20 h-20 text-primary-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              )}
            </div>
            <div className='sm:flex sm:items-center'>
              <div className='mt-3 text-center sm:mt-0 sm:text-center'>
                <h3
                  className='text-xl font-medium text-h-color leading-6'
                  id='modal-headline'>
                  {title}
                </h3>
                <div className='mt-2'>
                  <span className='text-h-color whitespace-normal text-md'>
                    {description}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center card px-4 py-3 bg-gray-50 sm:px-6 sm:flex'>
            <button
              className='px-4 py-2 font-semibold text-h-color bg-gray-500 rounded'
              onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
