import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Title, Container } from './style'
import { Page } from '../index'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
import useWalletModal from '../WalletModal/useWalletModal'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import React from 'react'
interface Props {
  pages: Page[]
  handleHideSideBar: () => void
  showSideBar: boolean
}

const SideBar = (props: Props) => {
  const onHandleHideSideBar = () => {
    props.handleHideSideBar()
  }

  const { login, logout } = useAuth()
  const { account, active } = useWeb3React()
  const { onPresentConnectModal } = useWalletModal(login)

  const swapDispatch = useSwapDispatch()
  const { buttonClickChanged } = useSwapGlobalState()

  React.useEffect(() => {
    if (buttonClickChanged === 'userClick') {
      onPresentConnectModal()
      swapDispatch({
        type: 'CONNECT_WALLET_BUTTON_CLICK_CHANGED',
        payload: '',
      })
    }
  }, [buttonClickChanged, onPresentConnectModal, swapDispatch])

  return (
    <div className='bg-gray-200'>
      <Container isShow={props.showSideBar}>
        <div className='flex text-white h-1 items-center p-6 bg-gray-600'>
          <div className='flex flex-1 py-2'>
            <Title>Menu</Title>
          </div>
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={props.handleHideSideBar}
            className='text-green-500 ml-4 cursor-pointer'
            size='lg'
          />
        </div>
        <div className=''>
          <ul className=''>
            {!active ? (
              <div
                onClick={() => {
                  onPresentConnectModal()
                  onHandleHideSideBar()
                }}
                className='flex active:bg-gray-300 bg-gray-100 px-6 py-2 flex flex-1 justify-between border-t border-b border-gray-300'>
                <div className=''>{'Connect Wallet'}</div>
              </div>
            ) : (
              <>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(account || '')
                  }}
                  className='flex active:bg-gray-400 bg-gray-100 px-6 py-2 flex flex-1 justify-between border-t border-b border-gray-300'>
                  <div className='overflow-ellipsis overflow-hidden w-48'>
                    {account}
                  </div>
                  <img
                    className='max-h-5 ml-2'
                    src='/images/icons/icon_copy.svg'
                    alt='copy-clipboard-icon'
                  />
                </div>
                <div
                  onClick={() => {
                    logout()
                  }}
                  className='flex active:bg-gray-400 bg-gray-100 px-6 py-2 flex flex-1 justify-between border-b border-gray-300'>
                  <div className=''>{'Sign Out'}</div>
                  <img
                    className='ml-2'
                    src='/images/icons/icon_logout.svg'
                    alt='sign-out-icon'
                  />
                </div>
              </>
            )}
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default SideBar
