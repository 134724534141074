import axios from 'axios'
import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber, ethers } from 'ethers'
import { CallReturnContext, ContractCallContext } from 'ethereum-multicall'
import EthersService, { getEthersProvider } from './ethers.service'

import { Chain } from 'models/chain.model'
import { makeCallContractResponse } from './contract.service'
import { getTokensNameMap } from './token.service'
import { defaultDEXContractInfo } from 'constants/swap.constant'
import { DexInfoDisplay } from '../models/swap.model'
import { BACKEND_NODE_BASE_URL } from '../constants/url.constant'
import { alert_message_user_wallet } from 'contexts/TranslationContext'
import log_support_token from '../constants/log/supported-tokens.json'

const convertToWrapAddress = (address: string, chainInfo: Chain) => {
  return address === 'ETHER' ? chainInfo.wrapTokenAddress : address
}

export const getAmountOut = async (
  route: string[],
  amountIn: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  const provider = await getEthersProvider()
  if (!provider) return null

  let wrapCoinAddress = chainInfo.wrapTokenAddress
  if (
    route.length === 2 &&
    ((route[0].toLowerCase() === 'ETHER'.toLowerCase() && route[1].toLowerCase() === wrapCoinAddress.toLowerCase()) ||
      (route[0].toLowerCase() === wrapCoinAddress.toLowerCase() && route[1].toLowerCase() === 'ETHER'.toLowerCase()))
  ) {
    return amountIn
  }

  var contract
  try {
    contract = new ethers.Contract(
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress,
      defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI,
      provider
    )
  } catch (e) {
    console.log('Error getting contract')
    return null
  }

  let tmpRates = null
  try {
    tmpRates = await contract.getAmountsOut(amountIn, route)
  } catch (e) {
    console.log('tmpRates ERROR', e)
    return 0
  }

  let tmpRate = +tmpRates[tmpRates.length - 1]._hex
  return tmpRate
}

type ContractsCallsInput = {
  contractAddress: string
  contractAbi: any
  methodName: string[]
  arguments: any[][]
}

const generateContractsCalls = (
  inputs: ContractsCallsInput[]
): ContractCallContext<any>[] => {
  var contractCalls = []
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i]
    contractCalls.push({
      reference: 'addr_' + i,
      contractAddress: input.contractAddress,
      abi: input.contractAbi,
      calls: input.methodName.map((method, index) => ({
        reference: index + '_ofaddr_' + i,
        methodName: method,
        methodParameters: input.arguments[index],
      })),
    })
  }
  return contractCalls
}

const getAll_0_1_2_BaseToken_Routes = (
  fromToken: string,
  toToken: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  let newFromToken = convertToWrapAddress(fromToken, chainInfo)
  let newToToken = convertToWrapAddress(toToken, chainInfo)

  // revert1
  var allRoutes = [[newFromToken, newToToken]]
  let baseTokens = defaultDEXContractInfo(chainInfo, disableMultiRoute).baseToken
  console.log("baseTokens", baseTokens)

  for (let tokenAddress of baseTokens) {
    if (
      newToToken.toLowerCase() === tokenAddress.toLowerCase() ||
      newFromToken.toLowerCase() === tokenAddress.toLowerCase()
      ) {
      continue
    }
    // revert1
    allRoutes.push([newFromToken.toLowerCase(), tokenAddress.toLowerCase(), newToToken.toLowerCase()])

    for (let tokenAddress2 of baseTokens) {
      if (
        newToToken.toLowerCase() === tokenAddress2.toLowerCase() ||
        newFromToken.toLowerCase() === tokenAddress2.toLowerCase() ||
        tokenAddress.toLowerCase() === tokenAddress2.toLowerCase()
      ) {
        continue
      }
      // revert1
      allRoutes.push([newFromToken.toLowerCase(), tokenAddress.toLowerCase(), tokenAddress2.toLowerCase(), newToToken.toLowerCase()])
    }
  }
  return allRoutes
}

const loopToFindBestRoute = (
  dexAmountsOut: CallReturnContext[],
  allRoutes: string[][]
) => {
  var bestRate = 0
  var bestRoute: string[] = []

  for (let routeIndex in dexAmountsOut) {
    let { returnValues: tmpRates } = dexAmountsOut[routeIndex]

    if (tmpRates != null && tmpRates.length > 0) {
      let tmpRate = +tmpRates[tmpRates.length - 1].hex
      if (tmpRate > bestRate) {
        bestRate = tmpRate
        bestRoute = allRoutes[routeIndex]

        /*
        console.log(
          'MC found better rate using route: ',
          printRoute(bestRoute),
          ', rate: ',
          bestRate
        )
        */
      }
    }
  }
  return { bestRate: bestRate, bestRoute: bestRoute }
}

export const getConversionRateMultiCall = async (
  fromToken: string,
  fromTokenDecimal: number,
  toToken: string,
  amountIn: string = '1000000000000000000',
  chainInfos: Chain[],
  chainInfo: Chain,
  dexs: DexInfoDisplay[],
  disableMultiRoute: boolean
) => {
  var supportedDexes = dexs//await getDexInfos()
  supportedDexes = supportedDexes.filter((item) => {
    return item.id !== '0010'
  })
  var addresses = []
  var names = []
  var id = []
  if (supportedDexes) {
    for (let dex of supportedDexes) {
      if ('' !== dex.routerAddress && dex.chainId === chainInfo.chainId) {
        addresses.push(dex.routerAddress)
        names.push(dex.farmName)
        id.push(dex.id)
      }
      if (addresses.length >= 4) {
        break
      }
    }
  }

  let myreturn = await getBestRatesMultiDexMultiCall(
    addresses,
    fromToken,
    fromTokenDecimal,
    toToken,
    amountIn,
    chainInfos,
    chainInfo,
    disableMultiRoute
  )

  return { id, names, data: myreturn }
}

export const getBestConversionRateMultiCall = async (
  fromToken: string,
  fromTokenDecimal: number,
  toToken: string,
  amountIn: string = '1000000000000000000',
  chainInfos: Chain[],
  chainInfo: Chain,
  disableMultiRoute: boolean,
  dexRouterAdddress?: string
) => {
  let baseDex = defaultDEXContractInfo(chainInfo, disableMultiRoute)
  var supportedDexes = [
    {
      id: chainInfo.frontEndData.defaultDEX,
      farmName: baseDex.name,
      chefAddress: '',
      routerAddress: dexRouterAdddress ?? baseDex.routerAddress,
      factory: baseDex.factoryAddress,
    },
  ]

  var addresses = []
  var names = []
  for (let dex of supportedDexes) {
    if ('' !== dex.routerAddress) {
      addresses.push(dex.routerAddress)
      names.push(dex.farmName)
    }
    if (addresses.length >= 4) {
      break
    }
  }

  let myreturn = await getBestRatesMultiDexMultiCall(
    addresses,
    fromToken,
    fromTokenDecimal,
    toToken,
    amountIn,
    chainInfos,
    chainInfo,
    disableMultiRoute
  )

  console.log("myreturn", myreturn)
  return myreturn[0]
}

const getBestRatesMultiDexMultiCall = async (
  contractAddresses: string[],
  fromToken: string,
  fromTokenDecimal: number,
  toToken: string,
  amountIn: string = '1000000000000000000',
  chainInfos: Chain[],
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {

  // console.log("getBestRatesMultiDexMultiCall >>>", chainInfo.wrapTokenAddress)

  var contractABIs = Array(contractAddresses.length).fill(
    defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI
  )
  var contractMethods = Array(contractAddresses.length).fill(
    defaultDEXContractInfo(chainInfo, disableMultiRoute).getAmountsOut
  )

  // console.log("getBestRatesMultiDexMultiCall >>>", chainInfo.wrapTokenAddress)
  let wrapTokenAddress = chainInfo.wrapTokenAddress
  let chainToken = 'ETHER'
  //FOR getting BNB and WBNB Swap Price
  if (
    (fromToken.toLowerCase() === chainToken.toLowerCase() &&
      toToken.toLowerCase() === wrapTokenAddress.toLowerCase()) || //BNB <=> WBNB
    (fromToken.toLowerCase() === wrapTokenAddress.toLowerCase() &&
      toToken.toLowerCase() === chainToken.toLowerCase())
  ) {
    // console.log("YES!!!")
    return Array(contractAddresses.length).fill({
      rate: 10 ** 18,
      route: [fromToken, toToken],
    })
  }

  var allRoutes = getAll_0_1_2_BaseToken_Routes(fromToken, toToken, chainInfo, disableMultiRoute)
  // logRoute(allRoutes)
  // console.log("allRoutes", allRoutes)

  const etherService = new EthersService(chainInfos)

  var allParams = []
  var methods = []
  var dexParams = []

  for (let i in contractAddresses) {
    for (let route of allRoutes) {
      allParams.push([amountIn, route])
      methods.push(contractMethods[i])
    }
    dexParams.push({
      contractAddress: contractAddresses[i],
      contractAbi: contractABIs[i],
      methodName: methods,
      arguments: allParams,
    })
  }

  var multiCallAmountOutData
  // console.log("dexParams", dexParams)
  try {
    var constructorParam = generateContractsCalls(dexParams)
    multiCallAmountOutData = await etherService.multiCall(
      constructorParam,
      chainInfo
    )
    // console.log('MULTI-CALL END')
  } catch {
    return Array(contractAddresses.length).fill({
      rate: 10 ** 18,
      route: [fromToken, toToken],
    })
  }

  console.log("multiCallAmountOutData.results", multiCallAmountOutData.results)
  console.log("amountIn", amountIn)
  var results = []
  //dexamountsouts = the return of multiple 'getAmountsOut's in one dex (now only pancake) , each return is an array of amountout of each step in string
  for (let i in multiCallAmountOutData.results) {
    const { callsReturnContext: dexAmountsOut } =
      multiCallAmountOutData.results[i]

      dexAmountsOut.forEach((result)=>{
        console.log("\n\nPATH")
        // console.log(result.methodParameters[1])
        let j = 1
        logRoute([result.methodParameters[1]])
        result.returnValues.forEach((result)=>{
          // console.log(result)
          let balance = new BigNumber(result.hex)
          // console.log(balance)
          let amountOut = (balance.toNumber() / +amountIn)
          console.log(j + ". " + amountOut, " | ", 1/amountOut)
          j++
        })
      })

    const { bestRate, bestRoute } = loopToFindBestRoute(
      dexAmountsOut,
      allRoutes
    )

    results.push({
      rate: (bestRate / +amountIn) * 10 ** fromTokenDecimal,
      route: bestRoute!,
    })
  }
  console.log("results >>", results)
  return results
}

export const logRoute = (routes: string[][]) => {
  let map: { [index: string]: string} = {}
  // console.log("log_support_token", log_support_token)
  log_support_token.forEach((item)=>{
    let key = item.address+''
    map[key.toLowerCase()] = item.symbol
  })
  routes.forEach((route)=>{
    // console.log("-----------------------")
    // console.log("route", route)
    let pathDisplay = route.map((path)=>{
      return map[path.toLowerCase()]
    }).join(" -> ")
    console.log(pathDisplay)
  })
}

// example: "0x39f1014a88c8ec087ceDF1BFc7064d24f507b894", "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
export const getBestConversionRate = async (
  fromToken: string,
  toToken: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  console.log("\n\ngetBestConversionRate >>")
  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  var contract
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let routerAddress = defaultDEXContractInfo(chainInfo, disableMultiRoute).routerAddress
    let routerABI = defaultDEXContractInfo(chainInfo, disableMultiRoute).routerABI
    contract = new ethers.Contract(routerAddress, routerABI, provider)
    console.log("routerAddress", routerAddress)
    console.log("routerABI", routerABI)
    console.log("contract", contract)
  } catch (e) {
    console.log('Error getting contract')
    return
  }

  let wrapTokenAddress = chainInfo.wrapTokenAddress
  let chainToken = 'ETHER'
  if (
    (fromToken.toLowerCase() === chainToken.toLowerCase() &&
      toToken.toLowerCase() === wrapTokenAddress.toLowerCase()) || //BNB <=> WBNB
    (fromToken.toLowerCase() === wrapTokenAddress.toLowerCase() &&
      toToken.toLowerCase() === chainToken.toLowerCase())
  ) {
    return { rate: 10 ** 18, route: [fromToken, toToken] }
  }

  let newFromToken = convertToWrapAddress(fromToken, chainInfo)
  let newToToken = convertToWrapAddress(toToken, chainInfo)

  console.log('newFromToken', newFromToken)
  console.log('newToToken', newToToken)

  // revert1
  var allRoutes = [[newFromToken, newToToken]]
  let baseTokens = defaultDEXContractInfo(chainInfo, disableMultiRoute).baseToken

  for (let tokenAddress of baseTokens) {
    if (
      newToToken.toLowerCase() === tokenAddress.toLowerCase() ||
      newFromToken.toLowerCase() === tokenAddress.toLowerCase()
      ) {
      continue
    }
    // revert1
    allRoutes.push([
      newFromToken.toLowerCase(),
      tokenAddress.toLowerCase(),
      newToToken.toLowerCase()
    ])

    for (let tokenAddress2 of baseTokens) {
      if (
        newToToken.toLowerCase() === tokenAddress2.toLowerCase() ||
        newFromToken.toLowerCase() === tokenAddress2.toLowerCase() ||
        tokenAddress.toLowerCase() === tokenAddress2.toLowerCase()
      ) {
        continue
      }
      // revert1
      allRoutes.push([
        newFromToken.toLowerCase(),
        tokenAddress.toLowerCase(),
        tokenAddress2.toLowerCase(),
        newToToken.toLowerCase()
      ])
    }
  }

  // //TODO: - review in next sprint
  // let extraRoute = chainInfo.smartConstractInfo.intermediateRoute
  // if (extraRoute) {
  //   let firstRoute = allRoutes[0]
  //   for (let key in extraRoute) {
  //     let keys = key.split(':')
  //     let intermediateRoute = extraRoute[key]
  //     if (firstRoute[0] === keys[0] && firstRoute[1] === keys[1]) {
  //       firstRoute = [firstRoute[0], intermediateRoute, firstRoute[1]]
  //       allRoutes[0] = firstRoute
  //     }
  //   }
  // }

  // console.log('allRoutes', allRoutes)
  // logRoute(allRoutes)

  var bestRate = 0
  var bestRoute: string[] = []

  let startTime = Date.now()
  for (let route of allRoutes) {
    let currentTime = Date.now()
    if (currentTime - startTime > 10000) {
      break
    }

    let tmpRates = null
    await delay(200)
    try {
      console.log('route', route)
      tmpRates = await contract.getAmountsOut(10 ** 18 + '', route)
      console.log('finding better rate using route: ', printRoute(route))
    } catch (e) {
      console.log('ERROR getBestConversionRate:', e)
    }
    if (tmpRates != null) {
      let tmpRate = +tmpRates[tmpRates.length - 1]._hex
      if (tmpRate > bestRate) {
        bestRate = tmpRate
        // bestRates = tmpRates
        bestRoute = route
        console.log(
          'found better rate using route: ',
          printRoute(bestRoute),
          ', rate: ',
          bestRate
        )
      }
    }
  }

  return { rate: bestRate, route: bestRoute! }
}

export const printRoute = async (route: string[]) => {
  let tokenNameMap = await getTokensNameMap()
  var result = ''
  for (let i = 0; i < route?.length; i++) {
    let token = route[i]
    if (token in tokenNameMap) {
      result += tokenNameMap[token] + ' > '
    } else {
      result += token + ' > '
    }
  }
  return result.substr(0, result.length - 3)
}

const getPriceImpact = async (
  fromToken: string,
  toToken: string,
  inputAmount: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  const provider = await getEthersProvider()
  if (!provider)
    return makeCallContractResponse('ERROR', alert_message_user_wallet)

  let wrapCoinAddress = chainInfo.wrapTokenAddress
  if (
    (fromToken.toLowerCase() === 'ETHER'.toLowerCase() && toToken.toLowerCase() === wrapCoinAddress.toLowerCase()) ||
    (fromToken.toLowerCase() === wrapCoinAddress.toLowerCase() && toToken.toLowerCase() === 'ETHER'.toLowerCase())
  ) {
    console.log('MATCH')
    return {
      priceImpact: '1',
      priceBefore: '1',
      expectedAmountOut: inputAmount,
    }
  } else {
    fromToken = convertToWrapAddress(fromToken, chainInfo)
    toToken = convertToWrapAddress(toToken, chainInfo)
  }

  //get 'Pair Contract'
  const factoryContract = new ethers.Contract(
    defaultDEXContractInfo(chainInfo, disableMultiRoute).factoryAddress,
    defaultDEXContractInfo(chainInfo, disableMultiRoute).factoryABI,
    provider
  )
  let pairAddress = await factoryContract.getPair(fromToken, toToken)
  const pairContract = new ethers.Contract(
    pairAddress,
    defaultDEXContractInfo(chainInfo, disableMultiRoute).pairABI,
    provider
  )

  //get FromToken Reserved and ToToken Reserved
  let pairReserves = null
  let pairToken0 = null
  try {
    pairReserves = await pairContract.getReserves()
    pairToken0 = await pairContract.token0()
  } catch (e) {
    console.error(e)
  }

  // console.log("getPriceImpact >>> pairReserves:", pairReserves)
  // console.log("getPriceImpact >>> pairContract:", pairContract)

  if (!pairReserves)
    return makeCallContractResponse('ERROR', 'getReserves ERROR')

  var sameOrder = true
  if (pairToken0.toUpperCase() !== fromToken.toUpperCase()) {
    sameOrder = false
  }

  let fromTokenReserve = new BigNumber(
    EthersBigNumber.from(
      sameOrder ? pairReserves._reserve0 : pairReserves._reserve1
    ).toString()
  )
  let toTokenReserve = new BigNumber(
    EthersBigNumber.from(
      sameOrder ? pairReserves._reserve1 : pairReserves._reserve0
    ).toString()
  )
  //calculate price impact
  let poolConstantProduct = fromTokenReserve.multipliedBy(toTokenReserve)
  let priceBefore = fromTokenReserve.dividedBy(toTokenReserve) //price of t in f unit = f/t
  let amount = new BigNumber(inputAmount)
  let newFromTokenReserve = fromTokenReserve.plus(inputAmount)
  let newToTokenReserve = poolConstantProduct.dividedBy(newFromTokenReserve)

  let expectedAmountOut = toTokenReserve.minus(newToTokenReserve)

  let expectedPrice = amount.dividedBy(expectedAmountOut) //what you paid / what you got
  let priceImpact = expectedPrice.minus(priceBefore).dividedBy(expectedPrice)

  return {
    priceImpact: priceImpact.toString(),
    priceBefore: priceBefore.toString(),
    expectedAmountOut: expectedAmountOut.toString(),
  }
}

export const getRoutePriceImpact = async (
  route: string[],
  inputAmount: string,
  chainInfo: Chain,
  disableMultiRoute: boolean
) => {
  var newAmount = inputAmount
  var priceBefore = new BigNumber(1)
  for (var i = 1; i < route.length; i++) {
    let res = await getPriceImpact(route[i - 1], route[i], newAmount, chainInfo, disableMultiRoute)
    if ('message' in res) {
      return res
    }
    newAmount = res.expectedAmountOut
    priceBefore = priceBefore.multipliedBy(new BigNumber(res.priceBefore))
  }

  let expectedPrice = new BigNumber(inputAmount).dividedBy(newAmount)
  return {
    priceImpact: expectedPrice
      .minus(priceBefore)
      .dividedBy(expectedPrice)
      .toString(),
    priceBefore: priceBefore,
    expectedAmountOut: newAmount,
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getDex = async (): Promise<DexInfoDisplay[]> => {
  try {
    const result = await axios.get<DexInfoDisplay[]>(
      `${BACKEND_NODE_BASE_URL}/dex`
    )
    result.data.forEach((item) => {
      item.id = (item as any).dexId
    })
    return result.data
  } catch (e) {
    throw e
  }
}
