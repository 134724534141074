import axios from "axios"
import { BACKEND_NODE_BASE_URL } from "constants/url.constant"
import { Chain } from "models/chain.model"

export const getChains = async (): Promise<Chain[]> => {
  try {
    return (await axios.get(`${BACKEND_NODE_BASE_URL}/chain/`)).data
  } catch (e) {
    console.log('ERROR ', e)
    throw e
  }
}
