import styled from "styled-components";
import { unformatInputNumber } from "utils/formatter.utils";

export const TokenAmountInputBox = styled(
  ({
    loading,
    value,
    className,
    ...props
  }: { loading?: boolean } & React.InputHTMLAttributes<HTMLInputElement>) => (
    <input
      autoComplete='off'
      placeholder={loading ? '' : '0.0'}
      className={
        'focus:outline-none text-lg py-1 pl-4 pr-3 bg-transparent bg-opacity-0' +
        (className || '')
      }
      value={loading ? '' : value}
      {...props}
    />
  )
)`
  display: flex;
  height: 42px;
  color: ${({ value }) =>
    unformatInputNumber(value + '').isZero() ? '#cdcdcd' : 'white'};
  flex: 1;
  text-align: right;
  padding-right: 10px;
  border-radius: 4px;
  min-width: 0;
`
