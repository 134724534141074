import BigNumber from 'bignumber.js'
import { useMyWallet } from 'contexts/MyWalletContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { formatBalance } from 'utils/formatter.utils'
import { PopupContextType, usePopupContext } from '../../contexts/PopupContext'
import { SearchBox } from '../SearchBox'
import TokenSymbolImg from '../TokenSymbolImg'
import { useTranslationState } from 'contexts/TranslationContext'

const ListContainer = styled.div`
  margin-top: 10px;
  font-size: 18px;
  overflow: auto;
  max-height: 392px;
  border: 1px solid 1;
  display: flex;
  flex-direction: column;

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #40bf77;
    height: 20px;
    border-radius: 10px;
    cursor: pointer;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #40bf77;
  }
`

export type FixSizingModel = {
  width: number
  height: number
}

const TokenListPopup = memo(
  ({
    token,
    tokenSelectedCallback,
    onClose,
    lockTokens,
    size,
    limitTokens,
  }: Parameters<PopupContextType['showSwapTokenList']>['0']) => {
    //useTranslation
    const { tl } = useTranslationState()

    const [searchToken, setSearchToken] = React.useState('')
    const { hidePopup } = usePopupContext()
    const { wallets } = useMyWallet()

    const { selectedChainId } = useSwapGlobalState()
    const { tokens: tokenFromServer } = useTokenState()

    const tokens = useMemo(() => {
      return limitTokens ?? tokenFromServer
    }, [limitTokens, tokenFromServer])

    const walletMap = React.useMemo(
      () => _.mapKeys(wallets, 'tokenAddress'),
      [wallets]
    )

    const sortedTokens = React.useMemo(() => {
      const filteredTokens = tokens.filter((tk) => {
        if (
          tk.swappable &&
          (tk.chainId === undefined || tk.chainId === selectedChainId)
        ) {
          return true
        }
        return false
      })
      if (searchToken) {
        return fuzzysort.go(searchToken, filteredTokens, {
          keys: ['symbol', 'name', 'address'],
        })
      } else if (!wallets.length) {
        return filteredTokens
      } else {
        let sortedWallets = filteredTokens.sort((a, b) => {
          let balanceA = +walletMap[a.address]?.balance || 0
          let balanceB = +walletMap[b.address]?.balance || 0
          return balanceB - balanceA
        })
        return sortedWallets
      }
    }, [tokens, searchToken, wallets.length, selectedChainId, walletMap])

    const closeButton = () => {
      return (
        <button
          type='button'
          onClick={() => {
            hidePopup('tokenpopup')
            if (onClose) onClose()
          }}
          className='farm-price-favorite rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
          <span className='sr-only'>Close menu</span>
          <svg
            style={{ width: '20px', height: '20px' }}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='#40BF77'
            aria-hidden='true'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
      )
    }

    let isFixedSize = size ? true : false
    return (
      <div
        className={
          isFixedSize
            ? 'absolute inset-0 z-50'
            : 'fixed inset-0 z-50 overflow-y-auto'
        }
        style={{
          width: isFixedSize && size ? size.width : 'auto',
          height: isFixedSize && size ? size.height : 'auto',
        }}>
        <div
          className={
            isFixedSize
              ? 'w-full h-full p-4'
              : 'flex items-end justify-center min-h-screen p-0 md:px-4 md:pt-4 md:pb-20 text-center sm:block'
          }>
          <div
            className={
              isFixedSize
                ? 'bg-gray-500 opacity-75'
                : 'fixed inset-0 transition-opacity'
            }
            aria-hidden='true'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </div>
          <span
            className={
              isFixedSize
                ? 'hidden'
                : 'hidden sm:inline-block sm:align-middle sm:h-screen'
            }
            aria-hidden='true'>
            &#8203;
          </span>
          <div
            className={
              isFixedSize
                ? 'flex h-full'
                : 'absolute w-full h-full top-0 left-0'
            }>
            <div
              className={
                (isFixedSize
                  ? 'flex flex-1 flex-col m-2'
                  : 'w-full max-w-md mb-28 mt-8 sm:mt-32') +
                ' text-white border border-gray-500 bg-gradient-to-b from-gray-600 to-gray-800 inline-block pt-2 pb-6 px-6 overflow-hidden text-left rounded-lg shadow-xl transform transition-all align-middle'
              }
              role='dialog'
              aria-modal='true'
              aria-labelledby='modal-headline'>
              <div
                style={{
                  minHeight: '56px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    fontSize: '18px',
                    paddingBottom: '10px',
                    flex: '1',
                  }}>
                  {tl.select_token}
                </div>
               { closeButton() }
              </div>
              <SearchBox
                isFixedSize={isFixedSize}
                onChange={setSearchToken}
                firstResponder
              />
              <ListContainer className=''>
                {sortedTokens.map((data, index) => {
                  const obj = 'obj' in data ? data.obj : data
                  const sortResults = Array.isArray(data) ? data : null

                  const disabled = obj.address === token

                  let decimal = +walletMap[obj.address]?.decimals
                  let balance = new BigNumber(
                    walletMap[obj.address]?.rawBalance || 0
                  ).dividedBy(Math.pow(10, decimal || 0))

                  return (
                    <div
                      key={index}
                      onClick={() => {
                        if (disabled) return
                        tokenSelectedCallback(obj)

                        hidePopup('tokenpopup')
                        if (onClose) onClose()
                      }}
                      className={`flex items-center pl-3 py-2 rounded-lg hover:bg-gray-800 cursor-pointer ${
                        disabled ? 'opacity-30' : ''
                      }`}>
                      <TokenSymbolImg className='mr-3' token={obj} />
                      <div className='flex'>
                        <div>
                          {sortResults ? (
                            <>
                              <div
                                className='text-md'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    fuzzysort.highlight(
                                      sortResults[0],
                                      '<b style="color:#40BF77">',
                                      '</b>'
                                    ) || obj.symbol,
                                }}
                              />
                              <div
                                className='text-xs text-gray-700'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    fuzzysort.highlight(
                                      sortResults[1],
                                      '<b style="color:#40BF77">',
                                      '</b>'
                                    ) || obj.name,
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <div className='text-md'>{obj.symbol}</div>
                              <div className='text-xs text-gray-400'>
                                {obj.name}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='text-safegreen text-sm ml-auto mr-4'>
                        {formatBalance(balance)}
                      </div>
                    </div>
                  )
                })}
              </ListContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default TokenListPopup
