import ChartHeader from 'components/ChartHeader'
import {
  useFarmPriceState,
} from 'contexts/FarmPriceContext'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Token, TokenPrice } from 'models/token.model'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { getTokenPrice } from 'services/token.service'
import { Chain } from 'models/chain.model'
import { useChainState } from 'contexts/ChainContext'
import { getChainById } from 'hooks/Chain/useChain'

export const GroupFarmPriceContent = styled.div.attrs({
  className: 'flex flex-1 flex-col',
})``

type Props = {
  isChartVisible: boolean
}

const ChartHeaderView = ({ isChartVisible }: Props): React.ReactElement => {
  const [init, setInit] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const { token, token2, lastChartPrice } = useFarmPriceState()
  const [tokenPrices, setTokenPrices] = useState<TokenPrice[]>([])

  const swapDispatch = useSwapDispatch()

  useEffect(() => {
    if (init && !loaded) {
      setLoaded(true)
    }
  }, [init, loaded, swapDispatch])

  const { chains } = useChainState()
  const { selectedChainId } = useSwapGlobalState()
  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  if (!init) {
    setInit(true)
  }

  const tokenPricesRef = React.useRef<TokenPrice[]>([])
  useEffect(()=>{
    tokenPricesRef.current = tokenPrices
  }, [tokenPrices])

  //Update Token Price on Header when chart get real-time date.
  useEffect(()=>{
    if(lastChartPrice && (tokenPricesRef && tokenPricesRef.current.length)){
      if(lastChartPrice.close === 0 && lastChartPrice.high === 0 && lastChartPrice.low === 0) {
        return
      }
      let tokenPrice = tokenPricesRef.current[0]
      tokenPrice.high = ''+lastChartPrice.high
      tokenPrice.low = ''+lastChartPrice.low
      tokenPrice.value = ''+lastChartPrice.close

      setTokenPrices(tokenPricesRef.current.concat())
    }
  }, [isChartVisible, lastChartPrice])

  const getTokenStatsHandler = useCallback(
    (fromToken: Token, toToken: Token) => {
      if (selectedChain?.frontEndData.displayType === 'simpleModeOnly') return
      if (fromToken && toToken) {
        let fromTokenAddress = fromToken?.address
        if (fromTokenAddress === 'ETHER' && selectedChain) {
          fromTokenAddress = selectedChain.wrapTokenAddress
        }
        let toTokenAddress = toToken?.address
        if (toTokenAddress === 'ETHER' && selectedChain) {
          toTokenAddress = selectedChain.wrapTokenAddress
        }
        getTokenPrice(fromTokenAddress, toTokenAddress)
          .then(({ data }) => {
            data.symbol = fromToken.symbol
            if (!isChartVisible || (tokenPrices.length === 0 || tokenPrices[0].symbol !== data.symbol)){
              //If Chart is invisible, update date by itself
              setTokenPrices([data])
            }
          })
          .catch((error?: string | Error) => {
            console.log('ERROR: no price stats')
          })
      }
    },
    [selectedChain, tokenPrices, isChartVisible]
  )

  const refreshPriceStatsTimerRef = React.useRef(0)
  const lastTokenFromAddress = React.useRef<Token | null>(null)
  const lastTokenToAddress = React.useRef<Token | null>(null)

  function isValidChain(token1: Token, token2: Token, chain: Chain) {
    if (token1.chainId === chain.chainId && token2.chainId === chain.chainId) {
      return true
    }
    return false
  }

  const getPriceStatusSameCoin = useCallback(() => {
    if (lastTokenFromAddress.current && lastTokenToAddress.current) {
      getTokenStatsHandler(
        lastTokenFromAddress.current,
        lastTokenToAddress.current
      )
      clearInterval(refreshPriceStatsTimerRef.current)
      refreshPriceStatsTimerRef.current = 0
      refreshPriceStatsTimerRef.current = +setTimeout(
        getPriceStatusSameCoin,
        15000
      )
    }
  }, [getTokenStatsHandler])

  useEffect(() => {
    let tokenChange = false
    const getPriceStatus = () => {
      if (lastTokenFromAddress.current?.address !== token?.address) {
        tokenChange = true
      }
      if (lastTokenToAddress.current?.address !== token2?.address) {
        tokenChange = true
      }
      if (
        token &&
        token2 &&
        selectedChain &&
        isValidChain(token, token2, selectedChain)
      ) {
        if (tokenChange) {
          clearInterval(refreshPriceStatsTimerRef.current)
          refreshPriceStatsTimerRef.current = 0

          getTokenStatsHandler(token, token2)
          lastTokenFromAddress.current = token
          lastTokenToAddress.current = token2
          refreshPriceStatsTimerRef.current = +setTimeout(
            getPriceStatusSameCoin,
            15000
          )
        }
      }
    }
    getPriceStatus()
  }, [token, token2, getTokenStatsHandler, selectedChain, getPriceStatusSameCoin])

  return (
    <div className={'flex flex-1 px-4 py-2 items-center min-w-min'}>
      <div className='flex-1'>
        {token && token2 && <ChartHeader token={token} token2={token2} tokenPrices={tokenPrices}/>}
      </div>
    </div>
  )
}
export default React.memo(ChartHeaderView)
