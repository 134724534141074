import React from 'react'
import { Spinner, Rect1, Rect2, Rect3, Rect4 } from './style'

type Props = {
  loading: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const LoadingComponent: React.FC<Props> = ({ loading, ...props }) => {
  return (
    <>
      {loading && (
        <Spinner {...props}>
          <Rect1 />
          <Rect2 />
          <Rect3 />
          <Rect4 />
        </Spinner>
      )}
    </>
  )
}
