import React from 'react'

export const Tooltip: React.FC<{
  visible: boolean
  label?: string
  isMouseDown?: boolean
  mouseDownLabel?: string
  left?: string
  right?: string
  top?: string
  bottom?: string
  width?: string
}> = ({
  visible,
  label = '',
  isMouseDown = false,
  mouseDownLabel = '',
  left = '0px',
  right = '0px',
  top = '0px',
  bottom = '0px',
  width = '100px',
}) => {
  return (
    <div>
      <div
        style={{
          display: visible ? 'flex' : 'none',
          left,
          right,
          top,
          bottom,
          zIndex: 1000,
          borderRadius: '4px',
          border: '1px solid #40BF77',
          background: '#FFFFFF',
          position: 'absolute',
          height: '30px',
          width: width,
          margin: '2px',
        }}>
        <div
          className='text-xs p-1'
          style={{
            margin: 'auto',
            color: isMouseDown ? '#40BF77' : '#000000',
          }}>
          {isMouseDown ? mouseDownLabel : label}
        </div>
      </div>
    </div>
  )
}
