import { useChainState } from 'contexts/ChainContext'
import { useFarmPriceState } from 'contexts/FarmPriceContext'
import { usePopupContext } from 'contexts/PopupContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  approveSpending,
  // getTokens,
  getTokensWithPrice,
  isSpendingApproved,
} from 'services/token.service'
import { callContractResponseType } from '../models/contract.model'
import { Token } from '../models/token.model'
import { getChainById } from './Chain/useChain'
import { Chain } from 'models/chain.model'

type ApproveData = {
  tokenAddress: string
  allowanceAmount: number
}

const useApproveToken = (token?: Token | null) => {
  const { chains } = useChainState()
  const address = token?.address

  const { token: token1 } = useFarmPriceState()
  const { showTransactionStatePopup } = usePopupContext()
  const [approvedAmount, setApprovedAmount] = useState<ApproveData>({
    tokenAddress: '',
    allowanceAmount: 0,
  })
  const [disableMultiRoute, setDisableMultiRoute] = useState(false)
  const { selectedChainId } = useSwapGlobalState()

  const selectedChain = React.useMemo(() => {
    return getChainById(chains, selectedChainId)
  }, [chains, selectedChainId])

  const loadApproval = useCallback((address: string, selectedChain: Chain, disableMultiRoute: boolean) => {
    isSpendingApproved(address, selectedChain, disableMultiRoute).then(
      (
        response:
          | {
              result: callContractResponseType
              message: string
            }
          | {
              approved: boolean
              token: string
              allowanceAmount: number
            }
      ) => {
        let sameToken = address === token1?.address
        if ('result' in response) {
          if (sameToken) {
            setApprovedAmount({ tokenAddress: address, allowanceAmount: -1 })
          }
          return
        }
        console.log('isSpendingApproved response', response)
        let isApproved = response.approved
        if (sameToken) {
          if (typeof isApproved === 'boolean') {
            setApprovedAmount({
              tokenAddress: response.token,
              allowanceAmount: response.allowanceAmount,
            })
          } else {
            console.error(isApproved)
          }
        }
      }
    )
  }, [token1?.address])

  useEffect(() => {
    if (!address || !selectedChain) return
    loadApproval(address, selectedChain, disableMultiRoute)
  }, [address, disableMultiRoute, loadApproval, selectedChain])

  const approveToken = useCallback(() => {
    if (!address || !selectedChain) return
    let bscHash = ''

    approveSpending(address, selectedChain, disableMultiRoute, (response) => {
      if (response.type === 'WAITING') {
        showTransactionStatePopup('processing', {
          transactionMessage: 'Approving',
        })
      } else if (response.type === 'SUBMITTING') {
        if (response.data && 'hash' in response.data) {
          bscHash = response.data.hash
        }
        showTransactionStatePopup('processing', {
          transactionTitle: 'Transaction Submitting',
          transactionMessage: '',
        })
      } else if (response.type === 'SUBMITED') {
        /*
           if (response.data && 'hash' in response.data) {
           bscHash = response.data.hash
           }
           showTransactionStatePopup('submitted', { bscAddress: bscHash })
         */
      }
    })
      .then((response) => {
        if (response.result === 'ERROR') {
          showTransactionStatePopup('error', {
            errorMessage: response.message,
          })
        } else if (response.result === 'FAIL') {
          showTransactionStatePopup('fail')
        } else if (response.result === 'SUCCESS') {
          showTransactionStatePopup('successful', {
            bscAddress: bscHash,
          })
          // setApprovedAmount({true})
          loadApproval(address, selectedChain, disableMultiRoute)
        }
      })
      .catch((e) => {
        showTransactionStatePopup('error', {
          errorMessage: e.message,
        })
      })
  }, [address, disableMultiRoute, loadApproval, selectedChain, showTransactionStatePopup])

  return {
    // approved: !!(address && approvedAmount),
    approvedAmount,
    approveToken,
    setDisableMultiRoute,
  }
}

const useToken = () => {
  return useMemo(
    () => ({
      // tokens,
      getTokenBySymbol: (tokens: Token[], symbol: string) =>
        tokens.find((token) => token.symbol === symbol),
      getTokenByAddress: (
        tokens: Token[],
        address: string,
        chainId: number
      ) => {
        return tokens.find((token) => {
          return (
            token.address.toLowerCase() === address?.toLowerCase() &&
            token.chainId === chainId
          )
        })
      },
      approve: useApproveToken,
    }),
    []
  )
}

export default useToken

export const useTokenWithPrice = (allowTokens: string[], chainId: number) => {
  const [tokens, setTokens] = useState<Token[]>([])

  useEffect(() => {
    getTokensWithPrice(allowTokens, chainId).then(setTokens)
  }, [allowTokens, chainId])

  return useMemo(
    () => ({
      tokens,
      getTokenBySymbol: (symbol: string) =>
        tokens.find((token) => token.symbol === symbol),
      getTokenByAddress: (address: string) => {
        return tokens.find((token) => {
          return token.address.toLowerCase() === address?.toLowerCase()
        })
      },
      approve: useApproveToken,
    }),
    [tokens]
  )
}
