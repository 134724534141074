/* eslint-disable react-hooks/rules-of-hooks */
import { useFarmPriceDispatch } from 'contexts/FarmPriceContext'
import { useSwapDispatch } from 'contexts/SwapContext'
import useAuth from 'hooks/useAuth'
import { useSessionState } from 'hooks/useStickyState'
import useToken from 'hooks/useToken'
import { Chain } from 'models/chain.model'
import { Token } from 'models/token.model'

export const getChainById = (chains: Chain[], id: number) => {
  return chains.find((chain) => chain.chainId === id)
}

export const DEFAULT_CHAIN = 8899 //JIBCHAIN L1

const usedChain = () => {

  const [selectedChain, setSelectedChain] = useSessionState<number>(
    //56,
    DEFAULT_CHAIN,
    'config-chainid'
  )
  //useState
  const { setupNetwork } = useAuth()
  const { getTokenByAddress } = useToken()

  const swapDispatch = useSwapDispatch()
  const dispatch = useFarmPriceDispatch()

  const setChainId = (
    chainId: number,
    tokens: Token[],
    chains: Chain[],
    callback?: (success: boolean) => void
    ) => {
    console.log('setChainId', chainId)
    setupNetwork(chainId, chains, function (success) {
      if (success) {
        console.log('setChainId success')
        swapDispatch({
          type: 'CHAIN_CHANGED',
          payload: chainId,
        })
        setSelectedChain(chainId)
        let chainInfo = getChainById(chains, chainId)
        let detaultTokens = chainInfo?.frontEndData.defaultTokens.split(',')
        if(detaultTokens){
          let selectedDefaultTokens: (Token | undefined)[] = detaultTokens.map((token)=>{
            return getTokenByAddress(tokens, token, chainId)
          })
          dispatch({
            type: 'SWAP_TOKEN',
            payload: selectedDefaultTokens,
          })
        }
      }
      if(callback){
        callback(success)
      }
    })
  }

  return { selectedChain, setSelectedChain, setChainId }
}

export default usedChain
