import { LoadingComponent } from 'components/LoadingComponent'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { useTranslationState } from 'contexts/TranslationContext'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  amountTitle: string
}

const Loading = styled(LoadingComponent).attrs((props) => ({
  ...props,
  loading: true,
}))`
  transform: scale(0.4);
  width: 82px;
`
const DexPriceListView: FC<Props> = ({ amountTitle: pairName }) => {
  //useTranslation
  const { tl } = useTranslationState()
  const { dexPriceListViewModel, priceLoading } = useSwapGlobalState()
  const list = dexPriceListViewModel ?? []

  function makePriceDiffView(diff: string) {
    switch (diff) {
      case 'BEST':
        return (
          <div className='border border-green-600 rounded-xl pb-1 pt-1 text-center'>
            {diff}
          </div>
        )
      case 'MATCH':
        return <div className='pb-1 pt-1 text-center'>{diff}</div>
    }
    return <div className='pb-1 pt-1 text-center'>{diff}</div>
  }

  function generateTable() {
    let titles = [tl.exchange_title, pairName, tl.exchange_diff]
    return (
      <div className={'exchange-section flex-1'}>
        <div className='flex flex-1 border-b-2 border-gray-600'>
          {titles.map(function (item, i) {
            let className = 'text-white py-4 sm:text-sm text-xs'
            if (i === 0) {
              className += ' px-4 md:px-8 text-left flex-1'
            } else if (i === 1) {
              className += ' text-center w-28 md:w-64'
            } else if (i === 2) {
              className += ' text-center w-28 md:w-56'
            }
            return (
              <div key={'header' + i} className={className}>
                {item}
              </div>
            )
          })}
        </div>
        <div className='bg-gradient-to-bl from-gray-800 to-gray-900'>
          {list.map(function (item, i) {
            let amountOut =
              priceLoading && item.id === '001' ? (
                <div className='flex h-4 items-center'>
                  <Loading
                    className='ml-auto'
                    style={{ position: 'relative', right: '-18px' }}
                  />
                </div>
              ) : (
                item.amountOut
              )
            return (
              <div key={i} className='flex flex-1 flex-row sm:text-sm text-xs'>
                <div className='flex flex-1 border-b border-gray-600 pl-4 md:pl-8 py-4'>
                  <div className='flex items-center'>
                    <img
                      draggable='false'
                      className='flex'
                      style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '10px',
                      }}
                      src={process.env.PUBLIC_URL + item.icon}
                      alt=''></img>
                    {item.name}
                  </div>
                </div>
                <div className='flex justify-end border-b border-gray-600 py-5 pr-2 md:pr-16 w-28 md:w-64'>
                  {amountOut}
                </div>
                <div
                  className='flex justify-center border-b border-gray-600 py-4 text-center w-28 md:w-56'
                  style={{
                    color:
                      item.diff === 'BEST'
                        ? '#40BF77'
                        : item.diff === 'MATCH'
                        ? '#57B1E4'
                        : '#F9384F',
                  }}>
                  <div style={{ width: '100px' }}>
                    {makePriceDiffView(item.diff)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return <div className={'flex flex-1 justify-center'}>{generateTable()}</div>
}

export default DexPriceListView
