import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import TokenSymbolImg from 'components/TokenSymbolImg'
import { useChainState } from 'contexts/ChainContext'
import { useSwapGlobalState } from 'contexts/SwapContext'
import { getChainById } from 'hooks/Chain/useChain'
import useMultiRouteSwap from 'hooks/Swap/useMultiRouteSwap'
import { useTokenWithPrice } from 'hooks/useToken'
import { MultiRouteResponse } from 'models/swap-multiroute.model'
import { Token } from 'models/token.model'
import { useEffect, useMemo, useState } from 'react'
import { getBestConversionRateMultiCall } from 'services/swap.service'
import { toBigNumber } from 'services/token.service'
import {
  decimalToUnsignedIntWithTokenDecimal,
  unformatInputNumber,
} from 'utils/formatter.utils'
type SwapResultInfo = {
  amountOut: number
  transactionFee?: number
  data?: any
}

const width0 = 'w-28'
const width1 = 'w-48'
const width2 = 'w-72'

const amountsInUSD = [20, 1000, 10000, 100000]
const allowTokens = [
  'BTCB',
  'BUSD',
  'ETH',
  // "USDC",
  // "USDT",
  'WBNB',
]
// const amountsInUSD = [20, 1000]
// const allowTokens = ['BTCB', 'BUSD']
const competitors = [
  { name: 'Pancake', address: '0x10ed43c718714eb63d5aa57b78b54704e256024e' },
  { name: 'Biswap', address: '0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8' },
  { name: 'MDEX', address: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8' },
  { name: '1Inch', url: 'https://api.1inch.io/v4.0/' },
]

const HeaderCell = ({
  label,
  color,
  size,
}: {
  label: string
  color: string
  size: string
}) => {
  return (
    <div
      className={`flex item-center justify-center ${size} border-r border-white`}
      style={{ background: color }}>
      {label}
    </div>
  )
}

const TokenContentCell = ({ token }: { token: Token }) => {
  return (
    <div
      className={`flex ${width0} flex flex-row items-center justify-center p-2`}>
      <TokenSymbolImg className='mr-3' token={token} />
      {token.name}
    </div>
  )
}

const formatNumber = (
  number: number,
  pos: number = 5,
  addSign: boolean = false
) => {
  if (number % 1 === 0) {
    return (addSign && number > 0 ? '+' : '') + number
  } else {
    return (addSign && number > 0 ? '+' : '') + number.toFixed(pos)
  }
}

const getHermesKey = (
  tokenFrom: Token,
  tokenTo: Token,
  amountIn: string | number
) => 'Hermes' + tokenFrom.symbol + tokenTo.symbol + amountIn

const getCompatitorKey = (
  competitor: typeof competitors[number],
  tokenFrom: Token,
  tokenTo: Token,
  amountIn: string | number
) => competitor.name + tokenFrom.symbol + tokenTo.symbol + amountIn

const ResultRow = ({
  tokenFrom,
  tokenTo,
  dataStore,
  amountsInUSD,
}: {
  tokenFrom: Token
  tokenTo: Token
  amountsInUSD: number
  dataStore: Record<string, SwapResultInfo>
}) => {
  const hermesKey = getHermesKey(tokenFrom, tokenTo, amountsInUSD)
  return (
    <div className='flex flex-row items-center justify-center'>
      <TokenContentCell token={tokenTo} />
      <AmountOutContentCell
        amount={dataStore[hermesKey]?.amountOut}
        fee={dataStore[hermesKey]?.transactionFee}
        width={width1}
      />
      {competitors.map((competitor) => {
        const key = getCompatitorKey(
          competitor,
          tokenFrom,
          tokenTo,
          amountsInUSD
        )
        return (
          <AmountOutContentCell
            key={key}
            amount={dataStore[key]?.amountOut}
            fee={dataStore[key]?.transactionFee}
            compareTo={dataStore[hermesKey]?.amountOut}
            compareToPrice={tokenTo.price}
            width={width2}
          />
        )
      })}
    </div>
  )
}

const AmountOutContentCell = ({
  amount,
  fee,
  compareTo,
  compareToPrice,
  width,
}: {
  amount: number
  fee?: number
  compareTo?: number
  compareToPrice?: number
  width: string
}) => {
  let color
  let label = isNaN(amount) || !amount ? '...' : '' + formatNumber(amount)
  let moreInfo = ''
  if (compareTo && compareToPrice && !isNaN(compareTo) && !isNaN(amount)) {
    color = compareTo > amount ? ' bg-green' : ' bg-red'
    const diff = compareTo - amount
    const diffUSD = formatNumber(diff * compareToPrice, 2, true)
    const diffPercent = formatNumber((diff / amount) * 100, 2, true)
    moreInfo = diffUSD + '$, ' + diffPercent + '%'
  }
  const feeMsg = fee ? `${(moreInfo ? ', ' : '') + formatNumber(fee, 2)}$` : ''
  return (
    <div className={'flex ' + width}>
      {moreInfo ? <div className={'flex w-2 h-2 ' + color} /> : null}
      <div className='flex-row flex-1'>
        {label + (moreInfo || feeMsg ? ` [${moreInfo}${feeMsg}]` : '')}
      </div>
    </div>
  )
}

const useSelectedChainTokens = () => {
  const { selectedChainId } = useSwapGlobalState()
  const { tokens } = useTokenWithPrice(allowTokens, selectedChainId)
  return useMemo(
    () =>
      tokens.filter(
        ({ chainId, symbol }) =>
          chainId === selectedChainId && allowTokens.includes(symbol)
      ),
    [tokens, selectedChainId]
  )
}

const useFetchDataStore = (tokens: Token[]) => {
  const { selectedChainId } = useSwapGlobalState()
  const [dataStore, setDataStore] = useState<Record<string, SwapResultInfo>>({})

  const { getMultiRouteSwapInfo } = useMultiRouteSwap()
  const { chains } = useChainState()

  const { account } = useWeb3React()

  useEffect(() => {
    if (!chains || !tokens) return

    const selectedChain = getChainById(chains, selectedChainId)

    const fetchHermesAmountOut = async (
      tokenFrom: Token,
      tokenTo: Token,
      amountIn: number
    ) => {
      const result = await getMultiRouteSwapInfo({
        userAddress: account,
        tokenFrom: tokenFrom,
        tokenTo: tokenTo,
        amountFrom: amountIn + '',
        chainId: selectedChainId + '',
        chainInfo: selectedChain,
        tokens: tokens,
      })
      const resp = result?.response as MultiRouteResponse
      if (resp === null || resp.status !== 'success') return
      const amountOut = +resp.amountOut
      return { amountOut, data: resp, transactionFee: +resp.transactionFee }
    }

    const fetchCompetitorAmountOut = (
      tokenFrom: Token,
      tokenTo: Token,
      amountIn: number,
      competitor: typeof competitors[number]
    ) => {
      const convertedAmountIn = decimalToUnsignedIntWithTokenDecimal(
        unformatInputNumber(amountIn),
        parseFloat(tokenFrom.decimals)
      ).toString()

      if (selectedChain && competitor.address) {
        return getBestConversionRateMultiCall(
          tokenFrom.address,
          +tokenFrom.decimals,
          tokenTo.address,
          convertedAmountIn,
          chains,
          selectedChain,
          false, //disableMultiRoute is false, it means we will follow mutliRouteEnabled in selectedChain
          competitor.address
        ).then((resp) => {
          if (resp === undefined) return null
          const amountOut = unformatInputNumber(amountIn)
            .multipliedBy(toBigNumber(resp.rate, tokenFrom))
            .toNumber()
          return { amountOut }
        })
      }

      return (
        competitor.url &&
        selectedChain &&
        axios
          .get(
            `${competitor.url}${selectedChain.chainId}/swap?fromTokenAddress=${tokenFrom.address}&toTokenAddress=${tokenTo.address}&amount=${convertedAmountIn}&fromAddress=0xCfD1eBcdd555cE3795851Fd6f3b33eb4C5729e8a&slippage=1&disableEstimate=true&burnChi=true&allowPartialFill=true`
          )
          .then((data) => ({
            amountOut:
              +data.data.toTokenAmount / 10 ** parseFloat(tokenTo.decimals),
            transactionFee: data.data.tx.gasPrice / 10 ** 10,
            data,
          }))
      )
    }

    const permuted = tokens
      .map((tokenFrom) =>
        tokens
          .filter(({ id }) => tokenFrom.id !== id)
          .map((tokenTo) =>
            amountsInUSD.map((amountInUSD) => ({
              tokenFrom,
              tokenTo,
              amountInUSD,
            }))
          )
      )
      .flat(3)

    const getInitialDataStore = () => {
      const mapping: Record<string, SwapResultInfo> = {}
      permuted.forEach(({ tokenFrom, tokenTo, amountInUSD }) => {
        mapping[getHermesKey(tokenFrom, tokenTo, amountInUSD)] = {
          amountOut: 0,
        }
        competitors.forEach((competitor) => {
          mapping[
            getCompatitorKey(competitor, tokenFrom, tokenTo, amountInUSD)
          ] = {
            amountOut: 0,
          }
        })
      })
      return mapping
    }

    setDataStore(getInitialDataStore())

    permuted.forEach(async ({ tokenFrom, tokenTo, amountInUSD }) => {
      const tokenFromAmount = amountInUSD / (tokenFrom.price ?? 0)
      fetchHermesAmountOut(tokenFrom, tokenTo, tokenFromAmount).then(
        (result) => {
          const key = getHermesKey(tokenFrom, tokenTo, tokenFromAmount)
          setDataStore((prevMap) => ({
            ...prevMap,
            [key]: { ...prevMap[key], ...result },
          }))
        }
      )
      competitors.forEach((competitor) => {
        const prom = fetchCompetitorAmountOut(
          tokenFrom,
          tokenTo,
          tokenFromAmount,
          competitor
        )
        prom &&
          prom.then((result) => {
            if (!result) return

            const key = getCompatitorKey(
              competitor,
              tokenFrom,
              tokenTo,
              tokenFromAmount
            )
            setDataStore((prevMap) => ({
              ...prevMap,
              [key]: { ...prevMap[key], ...result },
            }))
          })
      })
    })
  }, [chains, tokens, getMultiRouteSwapInfo, selectedChainId, account])

  return dataStore
}

const ContentsView = () => {
  const tokens = useSelectedChainTokens()
  const dataStore = useFetchDataStore(tokens)

  return (
    <div className='flex flex-1 text-sm flex-col border border-gray-600 bg-gray-700 rounded-xl'>
      {tokens.map((tokenFrom) => (
        <div
          key={tokenFrom.symbol}
          className='flex flex-1 text-lg text-white flex-row border border-gray-400'>
          <TokenContentCell token={tokenFrom} />
          <div className='flex-row flex-1'>
            {amountsInUSD
              .map((amountIn) => amountIn / (tokenFrom.price ?? 0))
              .map((tokenFromAmount, i) => (
                <div
                  key={i}
                  className='flex flex-row flex-1 border border-gray-600'>
                  <div className={'flex-row ' + width0 + ' pl-4 pt-2'}>
                    {formatNumber(tokenFromAmount)}
                  </div>
                  <div className='flex-row'>
                    {tokens
                      .filter((tokenTo) => tokenFrom.id !== tokenTo.id)
                      .map((tokenTo, idx) => (
                        <ResultRow
                          key={idx}
                          tokenFrom={tokenFrom}
                          tokenTo={tokenTo}
                          dataStore={dataStore}
                          amountsInUSD={tokenFromAmount}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const HeaderView = () => (
  <div className='flex text-white text-lg border border-white'>
    <HeaderCell label='Token From' color='#777777' size={width0} />
    <HeaderCell label='Amount In' color='#777777' size={width0} />
    <HeaderCell label='Token To' color='#777777' size={width0} />
    <HeaderCell label='Hermes' color='#777777' size={width1} />
    {competitors.map((competitor) => (
      <HeaderCell
        key={competitor.name}
        label={competitor.name}
        color='#7c4dcf'
        size={width2}
      />
    ))}
  </div>
)

export const SwapResult = () => (
  <div className='flex flex-col w-full h-full p-6 bg-gray-800'>
    <HeaderView />
    <ContentsView />
  </div>
)
