import BigNumber from 'bignumber.js'
import { useMyWallet } from 'contexts/MyWalletContext'
import { usePopupContext } from 'contexts/PopupContext'
import useMultiRouteSwap from 'hooks/Swap/useMultiRouteSwap'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import useToken from 'hooks/useToken'
import { DexInfoDisplay, DexPriceListViewModel } from 'models/swap.model'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  decimalToUnsignedIntWithTokenDecimal,
  formatBalance,
  formatInputNumber,
  unformatInputNumber,
} from 'utils/formatter.utils'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Token } from '../../models/token.model'
import useSwap from 'hooks/Swap/useSwap'
import { TokenAmountInputBox } from './Components/TokenAmountInputBox'
import { TokenSymbolComboBox } from './Components/TokenSymbolComboBox'
import { SwapLoading } from './Components/SwapLoading'
import { useSwapConfig } from 'hooks/Swap/useSwapConfig'
import {
  BIG_NUMBER_ZERO,
  getPriceV1,
  getPriceV2,
  updateExchangeInfo,
  useSwapPrice,
} from 'hooks/Swap/useSwapPrice'
import { useSwapViewInput } from 'hooks/Swap/useSwapViewInput'
import { useSwapLabel } from 'hooks/Swap/useSwapLabel'
import { CircularProgressWithLabel } from './Components/CircularProgressWithLabel'
import { SimpleModeRouteInfoView } from './Components/SimpleModeRouteInfoView'
import { SwapButton } from './Components/SwapButton'
import { Box, Tooltip, Typography } from '@material-ui/core'
import {
  getAmountOut,
  getConversionRateMultiCall,
} from '../../services/swap.service'
import { log } from 'hooks/useLog'
import { MultiRouteResponse } from 'models/swap-multiroute.model'
import { MinimizeGasToggleButton } from 'components/MinimizeGasToggleButton'
import { callContractProgressType } from 'models/contract.model'
import { FixSizingModel } from 'components/SwapConfirmDialog'
import { useTokenState } from 'contexts/TokenContext'
import { useChainState } from 'contexts/ChainContext'
import { getChainById } from 'hooks/Chain/useChain'
import { useWeb3React } from '@web3-react/core'
import { useTranslationState } from 'contexts/TranslationContext'
import { ASSETS_BASE_URL } from 'constants/url.constant'

export const FETCH_PRICE_DATA_DURATION = 10000

export const MultiRouteSwapTokenCard = ({
  compactMode,
  simpleMode,
  setTokens,
  tokens: preSelectToken,
  size,
  compact,
  chartVisible,
  setChartVisible,
  emebeddedStyle,
}: {
  compactMode?: boolean
  simpleMode?: boolean
  setTokens: (tokens: (Token | undefined)[]) => void
  tokens: (Token | undefined)[]
  size?: FixSizingModel
  compact: boolean
  chartVisible: boolean
  setChartVisible: React.Dispatch<React.SetStateAction<boolean>>
  emebeddedStyle: boolean
}) => {
  //useTranslation
  const { tl } = useTranslationState()

  //useTranslation
  const { addTransaction, reloadTransaction } = useMyWallet()

  //useSwapLabel
  const { DASH } = useSwapLabel()

  //useMyWallet
  const { wallets } = useMyWallet()

  //useSwapDispatch ( Notification )
  const swapDispatch = useSwapDispatch()

  //useSwap V1
  const { rateV1, routeV1, setRateAndRoute, swapTokens } = useSwap()

  //useSwap V2 ( multiroute )
  const {
    rateV2,
    multiRouteNode,
    multiRouteResponse,
    dexPriceListViewModel,
    setRateV2,
    getMultiRouteSwapInfo,
    setMultiRouteNode,
    setMultiRouteResponse,
    setDexPriceListViewModel,
    swapUsingDexAggregator,
  } = useMultiRouteSwap()

  //useSwapConfig
  const {
    slipPage,
    deadLine,
    isManualInput,
    maxReturn,
    setSlipPage,
    setDeadLine,
    setIsManualInput,
    setMaxReturn,
  } = useSwapConfig()

  //useSwapPrice
  const {
    priceImpactIsHigh,
    priceImpact,
    priceImpactVal,
    transactionCost,
    isLowLP,
    setPriceImpactIsHigh,
    setPriceImpact,
    setPriceImpactVal,
    getFormattedTransactionCost,
    setTransactionCost,
    setIsLowLP,
  } = useSwapPrice()

  // console.log("priceImpactIsHigh", priceImpactIsHigh)
  // console.log("priceImpactVal", priceImpactVal)
  // console.log("priceImpact", priceImpact)

  //usePopupContext
  const { showSwapConfigPopup, showSwapTokenList } = usePopupContext()

  //usedChain
  const { chains, dexs } = useChainState()
  const { tokens: allTokens } = useTokenState()

  const [token1, token2] = preSelectToken
  const { account } = useWeb3React()

  //useToken
  const { getTokenByAddress } = useToken()
  const { approvedAmount, approveToken, setDisableMultiRoute } =
    useToken().approve(token1)

  //useSwapGlobalState
  const {
    priceLoading,
    selectedChainId,
    tourInfoChanged,
    viewMode,
    networkChanged,
  } = useSwapGlobalState()

  useEffect(() => {
    console.log('networkChanged', networkChanged)
  }, [networkChanged])

  const selectedChain = React.useMemo(() => {
    let chain = getChainById(chains, selectedChainId)
    return chain
  }, [chains, selectedChainId])

  const walletBalances = React.useMemo(() => {
    return preSelectToken.map((token) => {
      if (!token) return 0
      let wallet = wallets.find(
        (wallet) => wallet.tokenAddress === token.address
      )
      if (!wallet) return 0
      let decimal = +wallet.decimals
      return new BigNumber(wallet.rawBalance || 0).dividedBy(
        Math.pow(10, decimal || 0)
      )
    })
  }, [preSelectToken, wallets])

  const maxExchangeFrom = new BigNumber(walletBalances[0] || 0)

  //useSwapViewInput
  const { balances, setBalances, setBalanceFrom } = useSwapViewInput()

  let [balanceFrom, balanceTo] = balances
  const balanceRef = React.useRef(balances)
  balanceRef.current[0] = balanceFrom
  balanceRef.current[1] = balanceTo

  //Display State
  const [submitButtonVisible, setSubmitButtonVisible] = React.useState(false)
  const [, setLastUserInput] = React.useState(0)
  const [startProgress, setStartProgress] = React.useState(false)

  const CHAIN_COIN = 'ETHER'
  const isWrapTokens = useMemo(() => {
    let _isWrapTokens = false
    if (token1 && token2 && selectedChain) {
      _isWrapTokens =
        (token1.address.toLowerCase() === CHAIN_COIN.toLowerCase() &&
          token2.address.toLowerCase() ===
            selectedChain.wrapTokenAddress.toLowerCase()) ||
        (token1.address.toLowerCase() ===
          selectedChain.wrapTokenAddress.toLowerCase() &&
          token2.address.toLowerCase() === CHAIN_COIN.toLowerCase())
    }
    setDisableMultiRoute(_isWrapTokens)
    return _isWrapTokens
  }, [token1, token2, selectedChain, setDisableMultiRoute])

  const useSwapV1 = useMemo(() => {
    if (selectedChain)
      return !selectedChain.frontEndData.multiRouteEnabled || isWrapTokens
    return false
  }, [isWrapTokens, selectedChain])

  const [is2TokenSelected, covertedPrice] = React.useMemo(() => {
    const selected = token1 && token2
    let price = ''
    if (token1 && token2) {
      if (!useSwapV1) {
        price =
          selected && !rateV2.isZero() && !rateV2.isNaN()
            ? `${formatBalance(rateV2, 5)} ${token1.symbol}`
            : DASH
      } else {
        // console.log('useSwapV1 rateV1 >>>', rateV1)
        // console.log('useSwapV1.toNumber rateV1 >>>', rateV1.toNumber())
        // console.log('selected rateV1 >>>', selected)
        // console.log('rateV1.isZero() rateV1 >>>', rateV1.isZero())
        // console.log('rateV1.isNaN() rateV1 >>>', rateV1.isNaN())
        price = DASH
        if (selected && !rateV1.isZero() && !rateV1.isNaN()) {
          if (rateV1.toNumber() < 0.00001) {
            price = `${formatBalance(rateV1, 14)} ${token1.symbol}`
          } else {
            price = `${formatBalance(rateV1, 5)} ${token1.symbol}`
          }
        }
      }
    }

    return [selected, price]
  }, [token1, token2, useSwapV1, rateV2, DASH, rateV1])

  // console.log('covertedPrice >>', covertedPrice)

  const setIsLoadingPriceData = useCallback(
    (isPriceLoading: boolean) => {
      swapDispatch({
        type: 'NOTIFY_PRICE_LOADING',
        payload: isPriceLoading,
      })
    },
    [swapDispatch]
  )

  React.useEffect(() => {
    //console.log('useEffect CASE 1')
    swapDispatch({
      type: 'NOTIFY_MULTIROUTE_SUCCESS',
      payload: multiRouteResponse ?? null,
    })
  }, [multiRouteResponse, swapDispatch])

  React.useEffect(() => {
    swapDispatch({
      type: 'NOTIFY_ROUTE_DISPLAY_VIEW_MODEL',
      payload: {
        routeDisplayViewModel: multiRouteNode ?? null,
        routeDisplayState: 'success',
      },
    })
  }, [multiRouteNode, swapDispatch])

  React.useEffect(() => {
    //console.log('useEffect CASE 3')
    swapDispatch({
      type: 'NOTIFY_BALANCE_CHANGED',
      payload: balances,
    })
  }, [balances, swapDispatch])

  React.useEffect(() => {
    //console.log('useEffect CASE 4')
    swapDispatch({
      type: 'NOTIFY_EXCHANGE_VIEW_MODEL',
      payload: dexPriceListViewModel ?? [],
    })
  }, [dexPriceListViewModel, swapDispatch])

  const isTokenFromApproved = useMemo(() => {
    let amountFrom = unformatInputNumber(balanceFrom).toString()
    if (
      token1 &&
      approvedAmount.tokenAddress.toLowerCase() ===
        token1.address.toLowerCase() &&
      approvedAmount.allowanceAmount / Math.pow(10, +token1.decimals) >=
        +amountFrom
    ) {
      return true
    }
    return false
  }, [
    approvedAmount.allowanceAmount,
    approvedAmount.tokenAddress,
    balanceFrom,
    token1,
  ])

  React.useEffect(() => {
    console.log('useEffect CASE 5')
    if (isTokenFromApproved) {
      setSubmitButtonVisible(true)
    } else {
      setSubmitButtonVisible(false)
    }
  }, [isTokenFromApproved])

  const setDisplayState = useCallback(
    (displayState: string) => {
      if (displayState === 'error' || displayState === 'input_zero') {
        setPriceImpact('-')
        setPriceImpactVal(0)
        setPriceImpactIsHigh(false)

        //clear loading state
        setIsLoadingPriceData(false)

        //clear balance to
        setBalances([balanceFromRef.current, '0'])

        //clear route display and exchange view
        setMultiRouteNode(null)

        //Update Rate
        setRateV2(BIG_NUMBER_ZERO)
        currentRate.current = BIG_NUMBER_ZERO
      }
    },
    [
      setPriceImpact,
      setPriceImpactVal,
      setPriceImpactIsHigh,
      setIsLoadingPriceData,
      setBalances,
      setMultiRouteNode,
      setRateV2,
    ]
  )

  const balanceFromRef = React.useRef(balanceFrom)
  balanceFromRef.current = balanceFrom
  const fetchPriceDataTimeRef = React.useRef(0)
  const userInputDelayTimeRef = React.useRef(0)
  const exchangeTokenInfosRef = React.useRef<DexPriceListViewModel[]>([])
  const userInputChangeTimeStampRef = React.useRef('')

  const conversionsRef = React.useRef<{
    id: string[]
    names: string[]
    data: any[]
  }>()

  const currentRate = React.useRef<BigNumber>(BIG_NUMBER_ZERO)
  const myDexes = React.useRef<DexInfoDisplay[]>([])
  const lastMultiRouteParam = React.useRef<{
    tokenFrom: Token | undefined
    tokenTo: Token | undefined
    amountFrom: string
  }>({
    tokenFrom: undefined,
    tokenTo: undefined,
    amountFrom: '',
  })

  const tokenKeyRef = React.useRef('')
  const lastAmountOutRef = React.useRef('')

  const lastMutliRouteResponse = React.useRef<MultiRouteResponse>()
  React.useEffect(() => {
    if (token1 && token2 && selectedChain) {
      var clearFetchPrice = () => {
        if (fetchPriceDataTimeRef.current !== 0)
          clearInterval(fetchPriceDataTimeRef.current)
        fetchPriceDataTimeRef.current = 0
      }
      var clearUserInputDelay = () => {
        if (userInputDelayTimeRef.current !== 0)
          clearInterval(userInputDelayTimeRef.current)
        userInputDelayTimeRef.current = 0
      }
      var clearAllTimer = () => {
        clearFetchPrice()
        clearUserInputDelay()
      }

      let amountFrom = unformatInputNumber(balanceFrom).toNumber()
      if (amountFrom === 0) {
        clearAllTimer()
        setDisplayState('input_zero')
        setStartProgress(false)
        setMultiRouteNode(null)
        return
      }

      const getExchangeRates = async function (
        newRate: BigNumber,
        tokenFrom: Token,
        tokenTo: Token,
        callBack: (arr: DexPriceListViewModel[]) => void
      ) {
        let tokenFromDecimal = parseFloat(tokenFrom?.decimals ?? '0')
        let amountFrom = unformatInputNumber(balanceFrom).toString()
        let convertedAmountToken1 = decimalToUnsignedIntWithTokenDecimal(
          unformatInputNumber(amountFrom),
          tokenFromDecimal
        )

        let conversions = await getConversionRateMultiCall(
          tokenFrom.address,
          tokenFromDecimal,
          tokenTo.address,
          convertedAmountToken1.toString(),
          chains,
          selectedChain,
          dexs,
          isWrapTokens
        )
        conversionsRef.current = conversions
      }

      //define function
      const getMultiRouteData = async () => {
        log(
          '[MULTI_ROUTE] getBestRate from ' +
            token1.symbol +
            ' to ' +
            token2.symbol
        )

        clearFetchPrice()
        setStartProgress(false)

        if (useSwapV1) {
          //getPrice V1
          getPriceV1(
            token1,
            token2,
            balanceFrom,
            chains,
            setRateAndRoute,
            setIsLoadingPriceData,
            setDisplayState,
            setBalances,
            tokenKeyRef,
            balanceRef,
            selectedChain,
            setPriceImpact,
            setPriceImpactVal,
            setPriceImpactIsHigh,
            setIsLowLP
          )
        } else {
          //getPrice V2
          let timeStamp = userInputChangeTimeStampRef.current
          await getExchangeRates(
            currentRate.current,
            token1,
            token2,
            (item: DexPriceListViewModel[]) => {
              if (userInputChangeTimeStampRef.current === timeStamp) {
                exchangeTokenInfosRef.current = item
              }
            }
          )

          let lastAmountOut: string | null = null
          let amountFrom = unformatInputNumber(balanceFrom).toString()
          if (
            lastMultiRouteParam.current.tokenFrom &&
            lastMultiRouteParam.current.tokenFrom.address.toLowerCase() ===
              token1.address.toLowerCase() &&
            lastMultiRouteParam.current.tokenTo &&
            lastMultiRouteParam.current.tokenTo.address.toLowerCase() ===
              token2.address.toLowerCase() &&
            lastMultiRouteParam.current.amountFrom === amountFrom
          ) {
            lastAmountOut = lastAmountOutRef.current
          }
          lastMultiRouteParam.current.tokenFrom = token1
          lastMultiRouteParam.current.tokenTo = token2
          lastMultiRouteParam.current.amountFrom = amountFrom

          getPriceV2(
            account,
            token1,
            token2,
            balanceFrom,
            slipPage,
            deadLine,
            amountFrom,
            lastAmountOut,
            timeStamp,
            userInputChangeTimeStampRef,
            fetchPriceDataTimeRef,
            balanceFromRef,
            currentRate,
            clearAllTimer,
            clearFetchPrice,
            () => {
              getMultiRouteData()
            },
            getMultiRouteSwapInfo,
            setDisplayState,
            setRateV2,
            setStartProgress,
            setMultiRouteNode,
            setMultiRouteResponse,
            setBalances,
            setIsLoadingPriceData,
            selectedChain,
            setPriceImpact,
            setPriceImpactVal,
            setPriceImpactIsHigh,
            setTransactionCost,
            swapDispatch,
            maxReturn,
            allTokens
          )
        }
      }

      let timeStamp = Date.now().toString()
      userInputChangeTimeStampRef.current = timeStamp

      //update exchnageInfo current price
      if (lastMutliRouteResponse && conversionsRef.current) {
        updateExchangeInfo(
          currentRate.current,
          token1,
          token2,
          conversionsRef.current,
          true,
          balanceFrom,
          myDexes,
          lastMutliRouteResponse.current,
          dexs,
          setDexPriceListViewModel,
          () => {}
        )
      }

      let delay = 1000
      clearAllTimer()
      userInputDelayTimeRef.current = +setTimeout(getMultiRouteData, delay)

      let needToShowProgress = false
      if (balanceRef.current[1] === 'Infinity') {
        needToShowProgress = true
      } else {
        needToShowProgress = true
      }
      setIsLoadingPriceData(needToShowProgress)
      setStartProgress(false)
    } else {
      setIsLoadingPriceData(false)
    }
    return () => {
      if (fetchPriceDataTimeRef.current !== 0)
        clearInterval(fetchPriceDataTimeRef.current)
      if (userInputDelayTimeRef.current !== 0)
        clearInterval(userInputDelayTimeRef.current)
      fetchPriceDataTimeRef.current = 0
      userInputDelayTimeRef.current = 0
    }
  }, [
    account,
    maxReturn,
    balanceFrom,
    deadLine,
    slipPage,
    token1,
    token2,
    selectedChainId,
    selectedChain,
    chains,
    setIsLoadingPriceData,
    setRateAndRoute,
    setBalances,
    getMultiRouteSwapInfo,
    swapDispatch,
    setRateV2,
    setMultiRouteNode,
    setMultiRouteResponse,
    setDexPriceListViewModel,
    setDisplayState,
    setPriceImpact,
    setPriceImpactVal,
    setPriceImpactIsHigh,
    setTransactionCost,
    allTokens,
    dexs,
    useSwapV1,
    isWrapTokens,
    setIsLowLP,
  ])

  React.useEffect(() => {
    if (multiRouteResponse !== null)
      lastMutliRouteResponse.current = multiRouteResponse

    if (multiRouteResponse)
      lastAmountOutRef.current = '' + multiRouteResponse.amountOut

    if (conversionsRef.current && token1 && token2) {
      updateExchangeInfo(
        currentRate.current,
        token1,
        token2,
        conversionsRef.current,
        true,
        balanceFrom,
        myDexes,
        multiRouteResponse,
        dexs,
        setDexPriceListViewModel,
        () => {}
      )
    }
  }, [
    balanceFrom,
    dexs,
    multiRouteResponse,
    setDexPriceListViewModel,
    token1,
    token2,
  ])

  function switchToken() {
    setTokens(preSelectToken.concat().reverse())
    setBalances([balanceTo, balanceFrom])
    setMultiRouteNode(null)
  }

  async function swap(
    addTx: (fromAddress: string, hash: string, chainId: number) => void,
    reloadTransaction: () => void
  ) {
    if (selectedChain) {
      if (!useSwapV1) {
        console.log('SWAP V2')
        // SWAP V2 MUltiRoute
        if (token1 && token2 && multiRouteResponse && multiRouteNode) {
          swapUsingDexAggregator(
            selectedChain,
            token1,
            token2,
            multiRouteResponse,
            balances,
            multiRouteNode,
            (progressType: callContractProgressType) => {
              if (progressType === 'SUBMITTING') {
                setBalances(['0', '0'])
              }
            },
            dexs
          )
        }
      } else {
        console.log('SWAP V1')
        // SWAP V1
        let amountFrom = unformatInputNumber(balanceFrom).toString()
        let covertAmountFrom = decimalToUnsignedIntWithTokenDecimal(
          unformatInputNumber(amountFrom),
          parseFloat(token1?.decimals ?? '0')
        )

        console.log('covertAmountFrom', covertAmountFrom)

        let amountTo = await getAmountOut(
          routeV1,
          covertAmountFrom.toString(),
          selectedChain,
          true //disableMultiRoute = true, because this is swap V1
        )
        console.log('amountTo', amountTo)

        if (!amountTo) return
        var amountToBigNum = new BigNumber(amountTo).dividedBy(
          Math.pow(10, +(token2?.decimals || 0))
        )

        console.log('amountToBigNum', amountToBigNum)

        swapTokens({
          tokenFrom: token1,
          tokenTo: token2,
          amountFrom: amountFrom,
          amountTo: amountToBigNum.toString(),
          path: routeV1,
          slipPage,
          deadLineInMinute: deadLine * 60,
          chainInfo: selectedChain,
          transactionCommit: async (hash, chain) => {
            if (account && chain) {
              addTx(account, hash, chain.chainId)
            }
          },
          complete: () => {
            reloadTransaction()
          },
        })
      }
    }
  }

  React.useEffect(() => {
    if (tourInfoChanged === 'balance') {
      swapDispatch({
        type: 'TOUR_INFO_CHANGED',
        payload: '',
      })
      let value = '0.001'
      setBalanceFrom(value, rateV1, rateV2)
      setLastUserInput(unformatInputNumber(value).toNumber())
    }
  }, [rateV1, rateV2, setBalanceFrom, swapDispatch, tourInfoChanged])

  const getLimitTokenList = useCallback(
    (tokenIndex: number) => {
      const CHAIN_COIN_ADDRESS = 'ETHER'
      if (
        selectedChain &&
        ((tokenIndex === 0 && token2?.address === CHAIN_COIN_ADDRESS) ||
          (tokenIndex === 1 && token1?.address === CHAIN_COIN_ADDRESS))
      ) {
        let token = getTokenByAddress(
          allTokens,
          selectedChain.wrapTokenAddress,
          selectedChain.chainId
        )
        return token ? [token] : undefined
      }
      return undefined
    },
    [
      selectedChain,
      token2?.address,
      token1?.address,
      getTokenByAddress,
      allTokens,
    ]
  )

  const NO_ICON_SOURCE = `${ASSETS_BASE_URL}/assets/images/noimg.png`
  const pathView = useCallback(() => {
    // console.log('routeV1', routeV1)
    let path: any[] = []
    if (routeV1 && selectedChain) {
      routeV1.forEach((address: string) => {
        let isVisible = address !== routeV1[routeV1.length-1]
        let tokenIn = getTokenByAddress(
          allTokens,
          address,
          selectedChain.chainId
        )
        // console.log('tokenIn', tokenIn)
        if (tokenIn) {
          path.push(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={tokenIn.name}>
                <img
                  className='w-6 h-6'
                  src={
                    tokenIn?.icon
                      ? ASSETS_BASE_URL + tokenIn.icon
                      : NO_ICON_SOURCE
                  }
                  alt={tokenIn.name}
                />
              </Tooltip>
              {isVisible && <KeyboardArrowRightIcon></KeyboardArrowRightIcon>}
            </Box>
          )
        }
      })
    }
    return (
      <Box
        sx={{
          border: 1,
          height: 20,
          marginX: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {path}
      </Box>
    )
  }, [NO_ICON_SOURCE, allTokens, getTokenByAddress, routeV1, selectedChain])

  let darkTheme = simpleMode
    ? ''
    : 'text-white border border-gray-900 bg-gray-700 rounded-xl'
  return (
    <div className={simpleMode ? 'swap-page' : 'swap-page p-6 ' + darkTheme}>
      <div>
        <form
          className='swap-section flex flex-col'
          onSubmit={async (e) => {
            e.preventDefault()
            swap(addTransaction, reloadTransaction)
          }}>
          <div className='flex flex-col'>
            <div className={simpleMode ? 'hidden' : 'flex flex-col'}>
              <div className='flex flex-row items-center'>
                <div
                  className='flex items-center'
                  style={{ paddingRight: '12px' }}>
                  <div className='flex text-lg'>{tl.title_exchange}</div>
                </div>
                <div className='flex items-center' style={{ flex: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      fontStyle: 'italic',
                      fontSize: '14px',
                      color: '#94D1AE',
                    }}>
                    {/* Trade token in an instant */}
                  </div>
                </div>

                {!emebeddedStyle && viewMode === 'advance' && (
                  <Tooltip
                    title={
                      chartVisible ? tl.swap_hide_chart : tl.swap_show_chart
                    }>
                    <button
                      type='button'
                      className={
                        'mr-2 ' + (chartVisible ? ' ' : ' text-gray-300')
                      }
                      onClick={() => {
                        setChartVisible(!chartVisible)
                      }}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className={'h-5 w-5'}
                        viewBox='0 0 20 20'
                        fill={chartVisible ? HERMES_GREEN_COLOR : ''}>
                        <path d='M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z' />
                      </svg>
                    </button>
                  </Tooltip>
                )}

                <div>
                  <Tooltip title={tl.swap_tooltip_auto_refresh}>
                    <div
                      className='flex mr-2 relative'
                      color='#cdcdcd'
                      style={{
                        color: HERMES_GREEN_COLOR,
                      }}>
                      <CircularProgressWithLabel
                        isLoading={startProgress}></CircularProgressWithLabel>
                    </div>
                  </Tooltip>
                </div>

                <Tooltip title={tl.swap_open_setting}>
                  <button
                    type='button'
                    onClick={() => {
                      showSwapConfigPopup(
                        isManualInput,
                        slipPage,
                        deadLine,
                        (
                          newSlipPage: string,
                          newDeadLine: number,
                          isManualInput: boolean
                        ) => {
                          setSlipPage(newSlipPage)
                          setDeadLine(newDeadLine)
                          setIsManualInput(isManualInput)
                        }
                      )
                    }}>
                    <img
                      draggable='false'
                      className='flex'
                      style={{ width: '18px', height: '19px' }}
                      src={
                        process.env.PUBLIC_URL +
                        'images/swaptoken/config_button.png'
                      }
                      alt=''></img>
                  </button>
                </Tooltip>
              </div>
            </div>

            {selectedChain?.frontEndData.swapEnabled === false &&
            !simpleMode ? (
              <div
                className={
                  'rounded-sm text-sm text-white bg-gradient-to-r from-cyan-500 to-blue-500'
                }
                style={{
                  display: 'block',
                  width: '80px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  fontSize: '10px',
                }}>
                BETA VERSION
              </div>
            ) : (
              <div></div>
            )}

            <div
              className={
                compactMode
                  ? 'flex w-full justify-evenly'
                  : 'flex flex-col w-full'
              }
              style={{
                paddingTop: simpleMode ? '0px' : '32px',
                paddingBottom: compactMode ? '0px' : '22px',
                paddingLeft: compactMode ? '6px' : '0px',
                paddingRight: compactMode ? '6px' : '0px',
              }}>
              <div className='flex-1'>
                <div className='flex'>
                  <div className='flex flex-1 pb-2'>
                    <div
                      style={{
                        fontSize: '12px',
                      }}>
                      {tl.swap_from}
                    </div>
                  </div>
                  <div
                    className={'text-h-color'}
                    style={{
                      fontSize: '12px',
                    }}>
                    {tl.balance + ' :'}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: HERMES_GREEN_COLOR,
                    }}>
                    &nbsp;{formatBalance(walletBalances[0])}
                  </div>
                </div>

                <div className='amount-in-section flex items-center mb-1'>
                  <div className='relative flex flex-row items-center flex-1 pr-1 bg-gray-800 border border-safegreen rounded'>
                    <TokenSymbolComboBox
                      token={token1}
                      enabled={true}
                      onClick={() => {
                        showSwapTokenList({
                          token: token1?.address || '',
                          tokenSelectedCallback: (token) => {
                            if (token.address === token2?.address) {
                              setTokens([token, token1])
                            } else {
                              setTokens([token, token2])
                            }
                            setMultiRouteNode(null)
                          },
                          lockTokens: [],
                          size: size,
                          limitTokens: getLimitTokenList(0),
                        })
                      }}
                    />
                    <TokenAmountInputBox
                      value={formatInputNumber(balanceFrom)}
                      onChange={({ target: { value } }) => {
                        setBalanceFrom(value, rateV1, rateV2)
                        setLastUserInput(unformatInputNumber(value).toNumber())
                      }}
                    />
                  </div>
                </div>

                <div className={(simpleMode ? 'hidden' : '') + ' pb-4'}>
                  <>
                    {amountSelectionBar((value) => {
                      if (!rateV2) return

                      let newBalance = ''
                      if (value === 100) {
                        newBalance = maxExchangeFrom
                          .dp(13, BigNumber.ROUND_DOWN)
                          .toString()
                      } else {
                        newBalance = maxExchangeFrom
                          .multipliedBy(value / 100)
                          .toString()
                      }
                      setBalanceFrom(newBalance, rateV1, rateV2)
                    }, tl.max_button)}
                  </>
                </div>
              </div>

              {/*  Swap Icon */}
              <div
                className={
                  (simpleMode ? 'hidden' : 'flex items-center justify-center') +
                  ' pb-2'
                }>
                <button className='mx-4' type='button' onClick={switchToken}>
                  <img
                    className='w-5 h-5'
                    draggable='false'
                    src={process.env.PUBLIC_URL + 'images/swaptoken/switch.png'}
                    alt='Switch token'></img>
                </button>
              </div>

              {/*  To ===> Balance */}
              <div className={'flex-1'}>
                <div className='flex'>
                  <div className='flex flex-1 pb-2'>
                    <div
                      style={{
                        fontSize: '12px',
                      }}>
                      {tl.swap_to}
                    </div>
                  </div>
                  <div
                    className={'text-h-color'}
                    style={{
                      fontSize: '12px',
                    }}>
                    {tl.balance + ' :'}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: HERMES_GREEN_COLOR,
                    }}>
                    &nbsp;{formatBalance(walletBalances[1])}
                  </div>
                </div>
                <div className='amount-out-section flex items-center mb-1'>
                  <div
                    className={
                      'relative flex flex-row items-center flex-1 pr-1 bg-gray-800 border rounded' +
                      (simpleMode ? ' border-gray-500' : ' border-safegreen')
                    }>
                    <TokenSymbolComboBox
                      token={token2}
                      enabled={!simpleMode}
                      onClick={() => {
                        if (simpleMode) return
                        showSwapTokenList({
                          token: token2?.address || '',
                          tokenSelectedCallback: (token) => {
                            if (token.address === token1?.address) {
                              setTokens([token2, token])
                            } else {
                              setTokens([token1, token])
                            }
                            setMultiRouteNode(null)
                          },
                          lockTokens: [],
                          limitTokens: getLimitTokenList(1),
                        })
                      }}
                    />
                    <TokenAmountInputBox
                      loading={priceLoading}
                      value={formatInputNumber(balanceTo)}
                      onChange={({ target: { value } }) =>
                        log('require onChange')
                      }
                    />
                    {priceLoading ? (
                      <div className='absolute right-0 flex h-4 items-center'>
                        <SwapLoading
                          className='ml-auto'
                          style={{ position: 'relative', right: '-18px' }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* {!simpleMode && !compact && (
              <SimpleModeRouteInfoView token2={token2} />
            )} */}

            {!simpleMode && !useSwapV1 ? (
              <div className='pb-4'>
                <MinimizeGasToggleButton
                  maxReturn={maxReturn}
                  setMaxReturn={setMaxReturn}></MinimizeGasToggleButton>
              </div>
            ) : (
              <></>
            )}

            {/*  Price | Price Impact | Route */}
            <div
              className='flex flex-col'
              style={{ display: compactMode ? 'none' : 'flex' }}>
              <div className='flex flex-col pb-3'>
                <div className='flex flex-row items-center text-xs'>
                  <div
                    className='flex flex-1 items-center'
                    style={{ paddingRight: '12px' }}>
                    <div className='flex'>
                      {/* Price */}
                      {token2 ? '1 ' + token2.symbol + ' ' + tl.swap_price : ''}
                    </div>
                  </div>
                  <div className='flex h-4 items-center'>
                    {priceLoading ? (
                      <SwapLoading
                        style={{ position: 'relative', right: '-16px' }}
                      />
                    ) : (
                      <div className='flex'>{covertedPrice}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className={'flex flex-col pb-3'}>
                <div className='flex flex-row items-center text-xs'>
                  <div
                    className='flex flex-1 items-center'
                    style={{ paddingRight: '12px' }}>
                    <div className='flex'>{tl.swap_price_impact}</div>
                  </div>
                  <div className='flex h-4 items-center'>
                    {priceLoading ? (
                      <SwapLoading
                        style={{ position: 'relative', right: '-16px' }}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          color:
                            priceImpact === DASH
                              ? 'inherit'
                              : priceImpactVal > 10
                              ? HERMES_RED_TEXT_COLOR
                              : HERMES_GREEN_COLOR,
                        }}>
                        {priceImpact}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={'flex flex-col pb-3' + (useSwapV1 ? ' pb-8' : '')}>
                <div className='flex flex-row items-center text-xs'>
                  <div
                    className='flex flex-1 items-center'
                    style={{ paddingRight: '12px' }}>
                    <div className='flex'>{tl.swap_route}</div>
                  </div>
                  <div className='flex h-4 items-center'>
                    {priceLoading ? (
                      <SwapLoading
                        style={{ position: 'relative', right: '-16px' }}
                      />
                    ) : (
                      <>{ pathView() }</>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={
                  (simpleMode ? '' : 'flex flex-col pb-8') +
                  (useSwapV1 ? ' hidden' : '')
                }
                style={{ paddingBottom: simpleMode ? '16px' : '32px' }}>
                <div className='pt-w'>
                  {txCost(
                    priceLoading,
                    getFormattedTransactionCost(covertedPrice, transactionCost),
                    tl.swap_tx_cost
                  )}
                </div>
                <div className='pt-2'>
                  {hermesFeeSection(priceLoading, tl.swap_fee)}
                </div>
                {!simpleMode && !compact && (
                  <SimpleModeRouteInfoView token2={token2} />
                )}
              </div>
            </div>
            {
              <SwapButton
                token1={token1}
                token2={token2}
                approved={isTokenFromApproved}
                approveToken={approveToken}
                is2TokenSelected={is2TokenSelected}
                balanceFrom={balanceFrom}
                maxExchangeFrom={maxExchangeFrom}
                priceImpactIsHigh={priceImpactIsHigh}
                submitButtonVisible={submitButtonVisible}
                chainInfo={selectedChain}
                isLowLP={isLowLP}
              />
            }
          </div>

          {emebeddedStyle && (
            <div
              className='flex justify-center pt-4 flex-row cursor-pointer'
              onClick={() => {
                window.open('https://hermesswap.com/swap')
              }}>
              <div className='flex justify-center pr-2'>
                <img
                  alt='hermes logo'
                  style={{ width: 20, height: 20 }}
                  className=''
                  src={process.env.PUBLIC_URL + '/images/logo-hermes.png'}
                />
              </div>
              <div className='hover:text-blue-300 text-gray-400 text-center'>
                Powered by Hermes
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const txCost = (priceLoading: boolean, value: string, label: string) => {
  return (
    <div className='flex flex-row items-center'>
      <div
        className='flex items-center'
        style={{ paddingRight: '12px', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            fontSize: '12px',
          }}>
          {label}
        </div>
      </div>
      <div className='flex h-4 items-center'>
        {priceLoading ? (
          <SwapLoading style={{ position: 'relative', right: '-16px' }} />
        ) : (
          <div
            style={{
              display: 'flex',
              fontSize: '12px',
            }}>
            {value}
          </div>
        )}
      </div>
    </div>
  )
}

const hermesFeeSection = (priceLoading: boolean, label: string) => {
  return (
    <div className='flex flex-row items-center'>
      <div
        className='flex items-center'
        style={{ paddingRight: '12px', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            fontSize: '12px',
            color: HERMES_GREEN_COLOR,
          }}>
          {label}
        </div>
      </div>
      <div className='flex h-4 items-center'>
        {priceLoading ? (
          <SwapLoading style={{ position: 'relative', right: '-16px' }} />
        ) : (
          <div
            style={{
              display: 'flex',
              fontSize: '12px',
              color: HERMES_GREEN_COLOR,
            }}>
            {'0'}
          </div>
        )}
      </div>
    </div>
  )
}

const amountSelectionBar = (
  onClick: (value: number) => void,
  label: string
) => {
  let data = [
    { id: '25', value: '25%' },
    { id: '50', value: '50%' },
    { id: '75', value: '75%' },
    { id: '100', value: label },
  ]
  return (
    <div className='flex w-full flex-1 space-x-1'>
      {data.map((item, index) => {
        return (
          <button
            key={'button_' + index}
            onClick={() => {
              onClick(+item.id)
            }}
            type='button'
            style={{
              borderColor: HERMES_GREEN_COLOR,
              color: HERMES_GREEN_COLOR,
            }}
            className='flex text-sm opacity-60 hover:bg-gray-600 hover:opacity-100 flex-1 border justify-center rounded-sm cursor-pointer'>
            {item.value}
          </button>
        )
      })}
    </div>
  )
}

const HERMES_RED_TEXT_COLOR = '#FB5971'
const HERMES_GREEN_COLOR = '#40BF77'
