import { DropdownModel } from 'components/Dropdown'
import React, { useState } from 'react'

interface Props {
  title: string
  items: (DropdownModel | null)[]
  onChange: (item: string) => void
  arrowHidden?: boolean
  tintColor?: string
  icon?: string
  isItalicFont?: boolean
  fontSize?: number
  hidden?: boolean
  width?: number
}

export const MenuDropDown: React.FC<Props> = ({
  items,
  title,
  onChange,
  arrowHidden,
  tintColor,
  icon,
  isItalicFont,
  fontSize,
  hidden,
  width
}) => {
  const [open, setOpen] = useState(false)

  const windowMouseUpHandler = () => {
    setOpen(false)
  }

  let fontStyle = ' ' + (fontSize ? fontSize : 'text-sm')
  return (
    <div className={'flex text-gray-300' + (hidden === true? ' hidden': '')}>
      <div
        className={'flex text-sm cursor-pointer items-center hover:bg-gray-600 py-2 rounded-lg px-4' + (isItalicFont === false ? '': ' italic') + fontStyle }
        style={{
          width: width ? width : 'auto',
          border: tintColor? '1px solid ' + tintColor : '', //selectedChain?.frontEndData.tint,
          fontSize: fontSize? fontSize : 'auto'
        }}
        onClick={() => {
          setOpen(!open)
          window.addEventListener('mouseup', windowMouseUpHandler)
        }}>
        {icon && (
          <div
            style={{ width: '20px', marginRight: '10px' }}
            className='flex items-center justify-center'>
            <img
              draggable='false'
              className='flex rounded-full'
              style={{
                width: '20px',
                height: '20px',
              }}
              src={process.env.PUBLIC_URL + icon}
              alt=''></img>
          </div>
        )}
        {title}
        {!arrowHidden && (
          <svg
            className='ml-2 h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'>
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        )}
      </div>

      <div
        className={'z-50 absolute bg-gray-500' + (open ? '' : ' hidden')}
        style={{
          top: '40px',
        }}>
        {items.map((item) => {
          if (item == null) {
            return <></>
          }
          return (
            <div
              key={item.value}
              onClick={() => {
                setOpen(false)
                onChange(item.value)
              }}
              className='flex cursor-pointer border-b border-gray-700 hover:bg-gray-600 px-4 py-2'>
              {item.icon && (
                <div
                  style={{ width: '20px', marginRight: '10px' }}
                  className='flex items-center justify-center'>
                  <img
                    draggable='false'
                    className='flex rounded-full'
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                    src={process.env.PUBLIC_URL + item.icon}
                    alt=''></img>
                </div>
              )}
              {item.label}
            </div>
          )
        })}
      </div>
    </div>
  )
}
