import { Tooltip } from '@material-ui/core'
import { MenuDropDown } from 'components/MenuDropDown'
import { useChainState } from 'contexts/ChainContext'
import { useSwapDispatch, useSwapGlobalState } from 'contexts/SwapContext'
import { useTokenState } from 'contexts/TokenContext'
import { get } from 'lodash'
import { Token } from 'models/token.model'
import { WebStats } from 'models/user.model'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getChartStats, getStats } from 'services/stats.service'

export const HomePage: FC<{}> = () => {
  const { dexs: dexInfo } = useChainState()

  const [windowDimension, setWindowDimension] = useState(0)
  const [init, setInit] = useState(false)
  const isMobile = windowDimension <= 990

  const [amountInSelection, setAmountInSelection] = useState<string[]>([])
  const [chartResponse, setChartResponse] = useState<any[]>([])
  const [amountIn, setAmountIn] = useState('1')
  const [sinceDate, setSinceDate] = useState<Date>(new Date())
  const [minGraph, setMinGraph] = useState<number>(0)
  const [tokenList] = useState<string[]>(['ETH', 'WBNB', 'BTCB'])
  const [dex, setDex] = useState<string[]>([])
  // const [dexInfo, setDexInfo] = useState<DexInfoDisplay[]>([])
  const [selectedToken, setSelectedToken] = useState<string>('BTCB')
  const token0 = 'USDT'

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLandingStats] = useState<WebStats>({
    totalLiquidity: 0,
    totalVolume: 0,
    totalWallet: 0,
    totalTrades: 0,
  })
  const [landingChart, setLandingChart] = useState<any[][]>([])
  const [totalBenefit, setTotalBenefit] = useState(0)

  const { topBarHidden } = useSwapGlobalState()

  const swapDispatch = useSwapDispatch()
  const { tokens } = useTokenState()
  // const { tokens } = useToken()
  const amountCount = 2

  const tokenMapping = React.useRef<{ [index: string]: Token }>({})

  useEffect(() => {
    if (init) {
      tokens.forEach((element) => {
        if (!('chainId' in element)) {
          console.log('NOT CHAINID = ', get(element, 'name'))
        }
        tokenMapping.current[element.address.toLowerCase()] = element
      })
      getStats().then((response) => {
        console.log('setLandingStats', response)
        setLandingStats(Object(response))
      })
      getChartStats().then((response) => {
        if (!response) {
          console.log('!ERROR: getChartStats response is NULL')
          return
        }

        // response = filterForDemo(response)
        let amountIn: string[] = []
        for (let index = 0; index < amountCount; index++) {
          let element = response[index]
          amountIn.push(element.amountIn)
        }

        setAmountInSelection(amountIn)
        setChartResponse(response)
        setAmountIn(amountIn[amountIn.length - 1])
      })
    }
  }, [init, tokens])

  // const filterForDemo = (response: any): any => {
  //   return response.filter((item: any) => {
  //     return item.amountIn <= 10000
  //   })
  // }

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    if (chartResponse !== undefined) {
      if (!dexInfo.length) {
        return
      }

      let startIndex = 0
      let index = amountInSelection.indexOf(amountIn)
      let selectedDataIndex = startIndex + index
      let data = chartResponse[selectedDataIndex]

      if (data) {
        let dexNames = []
        for (let index = 0; index < data.dex.length; index++) {
          let dexName = data.dex[index].dexName
          dexNames.push(dexName)
        }
        //console.log("dexName", dexName)
        setDex(['Amount In'].concat(dexNames))
        let totalDiff = 0.0
        let allData: any[] = []

        let index =
          tokenList.indexOf(selectedToken) * amountCount +
          amountInSelection.indexOf(amountIn)

        let chatData = chartResponse[index]
        if (chatData) {
          let amountOut = chatData.amountOut
          allData = allData.concat([[data.price1[0]].concat(amountOut)])

          let clone = amountOut.concat()
          clone = clone.sort((n1: number, n2: number) => n1 - n2)

          totalDiff += clone[clone.length - 1] - clone[clone.length - 2]
          let calMinGraph = clone[0] - (clone[0] * 0.1) / 100
          setMinGraph(calMinGraph)

          if (!isNaN(totalDiff)) {
            setTotalBenefit(totalDiff)
          } else {
            setTotalBenefit(0)
          }

          setLandingChart(allData)
          setSinceDate(new Date(data.startTime))
        }
      }
    }
  }, [
    amountIn,
    amountInSelection,
    chartResponse,
    dexInfo,
    selectedToken,
    tokenList,
  ])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  let google = Object(window).google
  google.charts.load('current', { packages: ['corechart'] })
  google.charts.setOnLoadCallback(drawChart)

  function drawChart() {
    if (landingChart.length === 0) {
      return
    }

    let chartData = [dex].concat(landingChart)
    var data = google.visualization.arrayToDataTable(chartData)

    var options = {
      chartArea: { left: isMobile ? 20 : 60, right: 20 },
      // bar: { groupWidth: "90%" },
      // chartArea: { top: 100, right: 10, left: 10, width: 200 },
      title: token0, //'BTC-BUSD ( Swap 0.01BTC on everydays )',
      backgroundColor: { fill: 'transparent' },
      hAxis: {
        title: amountIn + ' USDT to ' + selectedToken,
        baselineColor: 'green',
        gridlineColor: 'none',
        titleTextStyle: { color: '#ffffff' },
        textStyle: {
          color: '#ffffff',
        },
      },
      vAxis: {
        textPosition: isMobile ? 'none' : '',
        baselineColor: '#ccc',
        gridlineColor: 'none',
        minValue: minGraph,
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        position: 'top',
        maxLines: 3,
        alignment: 'start',
        textStyle: {
          color: '#ffffff',
        },
      },
      titleTextStyle: {
        color: '#ffffff',
      },
      colors: ['#1b9e77', '#7570b3', '#52A6D0', '#FEB300'],
      is3D: true,
    }

    var chart = new google.visualization.ColumnChart(
      document.getElementById('chart_div')
    )
    chart.draw(data, options)
  }

  if (!topBarHidden) {
    swapDispatch({
      type: 'TOPBAR_STATE_CHANGED',
      payload: true,
    })
  }

  if (!init) setInit(true)
  const history = useHistory()

  // var SI_SYMBOL = ['', 'K', 'M', 'B', 'T']
  // function formatCurrency(value: number, includeCurrency: boolean = false) {
  //   // what tier? (determines SI symbol)
  //   var tier = (Math.log10(Math.abs(value)) / 3) | 0

  //   // if zero, we don't need a suffix
  //   if (tier === 0) return value

  //   // get suffix and determine scale
  //   var suffix = SI_SYMBOL[tier]
  //   var scale = Math.pow(10, tier * 3)

  //   // scale the number
  //   var scaled = value / scale

  //   // format number and add suffix
  //   return scaled.toFixed(1) + suffix + (value > 100 ? '+' : '')
  // }

  function DateToYYYYMMDD(Date: Date): string {
    let DS: string =
      Date.getFullYear() +
      '/' +
      ('0' + (Date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + Date.getDate()).slice(-2)
    return DS
  }

  function makeTokensTabbar() {
    let items = tokenList.map((token) => {
      let isSelected = selectedToken === token
      return (
        <div
          className={
            'flex flex-1 items-center justify-center cursor-pointer px-4 w-16 rounded-lg' +
            (isSelected
              ? '  bg-white text-gray-700 border-white'
              : '  text-white hover:bg-gray-500')
          }
          onClick={() => {
            setSelectedToken(token + '')
          }}>
          {token}
        </div>
      )
    })
    return <div className='flex flex-1'>{items}</div>
  }

  // const DESKTOP_CHART_WIDTH = 550
  const columnWidth = () => {
    // let chartWidth = Math.min(windowDimension, DESKTOP_CHART_WIDTH)
    if (isMobile) {
      return 43
    }
    return 50
  }

  // function validateNumber(val: number) {
  //   if (isNaN(val)) {
  //     return 0
  //   }
  //   return val
  // }

  const getImageWithTooltip = (title: string, imageURL: string) => {
    return (
      <>
        <Tooltip
          arrow
          className='flex'
          title={
            <div className='text-lg' style={{ color: 'lightblue' }}>
              {title}
            </div>
          }>
          <img className='flex md:h-8 h-6' src={imageURL} alt={title}></img>
        </Tooltip>
      </>
    )
  }

  const dexBanner = () => {
    return [
      getImageWithTooltip(
        'PancakeSwap V1 and V2',
        '/assets/icons/dex/pancakeswap_color.svg'
      ),
      // getImageWithTooltip('ApeSwap', '/assets/icons/dex/apeswap_color.svg'),
      getImageWithTooltip('WardenSwap', '/assets/icons/dex/warden_color.svg'),
      getImageWithTooltip(
        'BISWAP Finance',
        '/assets/icons/dex/biswap_color.svg'
      ),
      getImageWithTooltip(
        'Gambit Finance',
        '/assets/icons/dex/gambit_color.svg'
      ),
      getImageWithTooltip(
        'BakerySwap',
        '/assets/icons/dex/bakeryswap_color.svg'
      ),
      // getImageWithTooltip(
      //   'DDOD V1, DSP and DPP',
      //   '/assets/icons/dex/dodo_color.svg'
      // ),
      getImageWithTooltip('LatteSwap', '/assets/icons/dex/latteswap_color.png'),
    ]
  }

  return (
    <div className='flex flex-1 flex-col h-full bg-cyan-800'>
      {/* <div
        style={{
          background: 'url("./home_bg2.png")',
          backgroundSize: 'cover',
        }}
        className='absolute flex flex-1 opacity-40 w-full h-full'></div> */}

      {header(isMobile, history)}

      <div className='flex flex-1 relative items-center justify-center bg-gradient-to-r from-cyan-600 to-blue-600'>
        <div
          className={
            'relative flex justify-center' +
            (isMobile ? ' flex-col items-center pt-8 space-y-8' : ' space-x-6')
          }
          // style={{ maxWidth: isMobile ? 360 : '' }}
        >
          <div className='text-gray-200 flex flex-1 justify-center items-center flex-col space-y-10'>
            <div
              className={
                'flex font-semibold' +
                (isMobile ? ' m-auto text-2xl' : ' text-4xl ')
              }>
              {' '}
              Get Best Rates
              <br /> using Optimization Model
              <br />
              on Hermes Swap
            </div>

            <div className='flex justify-center'>
              <img
                alt='hermes logo'
                className=''
                src={process.env.PUBLIC_URL + '/home_page_coin.png'}
              />
            </div>

            <div className='flex justify-center'>
              <div
                onClick={() => {
                  history.push('/swap')
                }}
                className='flex text-base font-bold text-blue-900 cursor-pointer items-center justify-center h-8 bg-white hover:bg-gray-400 px-8 text-white rounded-lg'>
                Launch App
              </div>
            </div>

            <div
              className={
                'text-white flex-1 flex space-y-8 flex-wrap font-semibold px-4 flex-col' +
                (isMobile ? ' m-auto text-base' : ' text-xl')
              }
              style={{ maxWidth: 'fit-content' }}>
              <div
                className='flex text-center'
                style={{ maxWidth: isMobile ? '100%' : 400 }}>
                Enjoy zero fee swap and get the best crypto exchange rates on
                Hermes Swap with multiple dexes.
              </div>
              <div className='flex flex-1 text-base flex-col justify-center items-center'>
                <div className='flex'>List of supported dexes</div>
                <div
                  className={
                    'flex flex-wrap space-x-2 items-center justify-center pt-8'
                  }>
                  {dexBanner()}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              'flex flex-col items-center justify-center bg-gray-800 bg-opacity-80 border border-gray-700' +
              (isMobile ? ' w-full' : ' rounded-xl')
            }>
            <div className='flex md:flex-row flex-col items-center md:text-xl md:space-x-4 items-center justify-center m-4'>
              <div className='text-white'>Total benefit</div>
              <div
                className={
                  'text-green-400'
                }>
                {totalBenefit.toLocaleString() + 'USD'}
              </div>
              <div className='text-white'>
                {'Start from ' + DateToYYYYMMDD(sinceDate)}
              </div>
            </div>

            <div className='relative flex md:flex-row flex-col space-x-2 py-2 px-8 items-center justify-center'>
              <div className='flex text-white'>{'When swap'}</div>
              <MenuDropDown
                width={140}
                title={(+amountIn).toLocaleString() + ' USDT'}
                items={amountInSelection
                  .map((element) => {
                    return {
                      value: element,
                      label: (+element).toLocaleString(),
                    }
                  })
                  .filter((item) => {
                    return item !== null
                  })}
                onChange={(item) => {
                  setAmountIn(item)
                }}></MenuDropDown>
              <div className='flex text-white items-center justify-center'>
                to
              </div>
              {makeTokensTabbar()}
            </div>

            <div className='flex flex-1 relative'>
              <div
                id='chart_div'
                style={{
                  width: isMobile ? '320px' : 'auto',
                  // minWidth: isMobile ? windowDimension : 'auto',
                  // maxWidth: isMobile ? '320px' : '550px',
                  height: '500px',
                }}></div>
              {chartIcon(
                chartResponse,
                columnWidth(),
                windowDimension,
                isMobile
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const header = (isMobile: boolean, history: any) => {
  return (
    <div
      className={
        'relative flex justify-between items-center pt-1 pb-2 bg-gray-800 bg-opacity-90' +
        (isMobile ? ' pr-4' : ' space-x-16 pl-12 pr-20')
      }>
      <div
        className={'flex space-x-2 items-center' + (isMobile ? ' flex-1' : '')}>
        <div className='flex flex-col relative items-center'>
          <img
            alt='hermes logo cursor-pointer'
            className='md:h-14 h-10'
            src='./images/logo-horizontal.png'
            onClick={() => {
              history.push('/')
            }}
          />
          <div
            style={{
              fontSize: 12,
              textAlign: 'center',
            }}
            className='flex border text-white px-2 rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500'>
            With 0% trading fees
          </div>
        </div>
      </div>

      <div
        className={
          'flex flex-row flex-1 space-x-10 items-center mt-2' +
          (isMobile ? ' hidden' : '')
        }></div>

      <div className='flex flex-row space-x-4'>
        <div className={'flex items-center' + (isMobile ? ' hidden' : '')}>
          <div
            onClick={() => {
              history.push('/swap')
            }}
            className='flex text-base font-bold text-blue-900 cursor-pointer items-center justify-center h-8 bg-gray-200 hover:bg-gray-400 px-8 rounded-lg'>
            Launch App
          </div>
        </div>
      </div>
    </div>
  )
}

export const chartIcon = (
  chartResponse: any,
  columnWidth: number,
  screenWidth: number,
  isMobile: boolean
) => {
  return (
    <div
      className='absolute'
      style={{
        bottom: '100px',
        //left: isMobile ? (screenWidth/2-175/2) - 3 : (400/2-200/2) + 40/2,
        right: isMobile ? columnWidth * 2 - 12 : columnWidth * 2 - 21,
        display: chartResponse ? 'block' : 'none',
      }}>
      <div className='flex flex-1 flex-row'>
        <div
          style={{ width: columnWidth + 'px' }}
          className='flex items-center justify-center'>
          {' '}
          <img
            draggable='false'
            className='flex rounded-full'
            style={{
              width: '28px',
              height: '28px',
            }}
            src={process.env.PUBLIC_URL + '/images/logo-vertical.png'}
            alt=''></img>
        </div>
        <div
          style={{ width: columnWidth + 'px' }}
          className='flex items-center justify-center'>
          {' '}
          <img
            draggable='false'
            className='flex rounded-full'
            style={{
              width: '28px',
              height: '28px',
            }}
            src={
              process.env.PUBLIC_URL + '/images/swaptoken/dex/pancakeswap.svg'
            }
            alt=''></img>
        </div>
        <div
          style={{ width: columnWidth + 'px' }}
          className='flex items-center justify-center'>
          {' '}
          <img
            draggable='false'
            className='flex rounded-full'
            style={{
              width: '28px',
              height: '28px',
            }}
            src={process.env.PUBLIC_URL + '/images/swaptoken/dex/mdex.svg'}
            alt=''></img>
        </div>
        <div
          style={{ width: columnWidth + 'px' }}
          className='flex items-center justify-center'>
          {' '}
          <img
            draggable='false'
            className='flex rounded-full'
            style={{
              width: '28px',
              height: '28px',
            }}
            src={process.env.PUBLIC_URL + '/images/swaptoken/dex/apeswap.svg'}
            alt=''></img>
        </div>
      </div>
    </div>
  )
}

export const status = (
  isMobile: boolean,
  windowDimension: number,
  formatCurrency: any,
  validateNumber: any,
  landingStats: any
) => {
  ;<div
    className={
      'flex hidden flex-row bg-gray-900 items-center bg-opacity-60 border-2 border-dotted border-gray-600 rounded-xl justify-evenly' +
      (isMobile ? ' px-4' : ' px-16')
    }
    style={{
      width: isMobile ? windowDimension - 10 + 'px' : '',
      maxWidth: isMobile ? '360px' : 'none',
      minHeight: '120px',
      paddingTop: '10px',
    }}>
    <div className='flex flex-col flex-1 justify-center items-center'>
      <div
        className={
          'flex text-green-400 font-semibold text-gray-300 italic' +
          (isMobile ? ' text-xl' : ' text-2xl')
        }>
        {'$' + formatCurrency(validateNumber(landingStats.totalLiquidity))}
      </div>
      <div className='h-12 flex text-sm text-gray-300 italic text-center'>
        Available liquidity
      </div>
    </div>

    {/* <div className='flex flex-col flex-1  justify-center items-center'>
                <div
                  className={
                    'flex text-green-400 font-semibold text-gray-300 italic' +
                    (isMobile ? ' text-xl' : ' text-2xl')
                  }>
                  {'$' + formatCurrency(landingStats.totalVolume)}
                </div>
                <div className='h-12 flex text-sm text-gray-300 italic'>
                  Total volume
                </div>
              </div>

              <div className='flex flex-col flex-1  justify-center items-center'>
                <div
                  className={
                    'flex text-green-400 font-semibold text-gray-300 italic' +
                    (isMobile ? ' text-xl' : ' text-2xl')
                  }>
                  {formatCurrency(landingStats.totalWallet)}
                </div>
                <div className='h-12 flex text-sm text-gray-300 italic'>
                  Total wallets
                </div>
              </div>

              <div className='flex flex-col flex-1  justify-center items-center'>
                <div
                  className={
                    'flex text-green-400 font-semibold text-gray-300 italic' +
                    (isMobile ? ' text-xl' : ' text-2xl')
                  }>
                  {formatCurrency(landingStats.totalTrades)}
                </div>
                <div className='h-12 flex text-sm text-gray-300 italic'>
                  Total trades
                </div>
              </div> */}
  </div>
}
