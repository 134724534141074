import { useChainState } from "contexts/ChainContext"
import { usePopupContext } from "contexts/PopupContext"
import { useSwapGlobalState } from "contexts/SwapContext"
import { useTranslationState } from "contexts/TranslationContext"
import { getChainById } from "hooks/Chain/useChain"
import useMultiRouteSwap from "hooks/Swap/useMultiRouteSwap"
import { Token } from "models/token.model"
import { FC } from "react"

export const SimpleModeRouteInfoView: FC<{
  token2: Token | undefined
}> = ({ token2 }) => {
  //useTranslation
  const { tl } = useTranslationState()

  const { getRouteText } = useMultiRouteSwap()
  const { chains } = useChainState()
  const { viewMode, routeDisplayViewModel, selectedChainId } = useSwapGlobalState()
  const { showRouteView } = usePopupContext()

  let chainInfo = getChainById(chains, selectedChainId)
  let showRouteInfoForSimpleMode = chainInfo?.frontEndData.multiRouteEnabled && viewMode === 'simple'
  if(!showRouteInfoForSimpleMode){
    return <></>
  }

  let disabled = routeDisplayViewModel ? false : true
  let exchangeDisabled = !((routeDisplayViewModel && chainInfo?.frontEndData.exchangeEnabled) ? true : false)
  let disabledStyle = disabled
    ? ' space-y-2 opacity-30 pointer-events-none'
    : ' space-y-4'

  return (
    <div
      className={
        // 'flex flex-col justify-center text-xs border-b border-gray-500 mb-4 py-4' +
        'flex flex-col justify-center text-xs py-2' +
        disabledStyle
      }>

      {exchangeDisabled ? (
        <></>
      ) : (
        <>
          {/* <div>Swap Comparision</div>
          <SwapSimpleExchangesView
            amountTitle={'Amount (' + (token2 ? token2.name : '') + ')'}
          /> */}
        </>
      )}
      <div className='route-simple-section flex'>
        <div className='flex flex-1'>{tl.swap_route}</div>
        <div className='text-green-500 mr-2'>
          {getRouteText(routeDisplayViewModel)}
        </div>
        <div
          style={{height: 18}}
          onClick={() => {
            showRouteView()
          }}
          className='flex justify-center text-gray-300 bg-gray-900 hover:bg-gray-800 px-2 items-center rounded-lg cursor-pointer'>
          {tl.swap_show_more}
        </div>
      </div>
    </div>
  )
}
