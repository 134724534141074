import { ASSETS_TOKEN_URL } from 'constants/url.constant'
import styled from 'styled-components'

interface TokenImgProps {
  tokenSymbol: string
  width?: string
  height?: string
}

interface TokenImgProps {
  tokenSymbol: string
  width?: string
  height?: string
}


const ImgToken = styled.img.attrs((props) => ({
  width: props.width || '32px',
  height: props.height || '32px',
}))``

export const TokenImg = (props: TokenImgProps) => {
  return (
    <ImgToken
      width={props.width}
      height={props.height}
      src={ASSETS_TOKEN_URL + `token/${props.tokenSymbol?.toLowerCase()}.png`}
    />
  )
}

export const TokenImgWithIcon = (props: TokenImgProps) => {
  return (
    <ImgToken
      width={props.width}
      height={props.height}
      src={props.tokenSymbol ? props.tokenSymbol : 'assets/icons/token/noimg.png' }
    />
  )
}
