import { LANG } from "constants/translation";

export const LANG_TH: LANG = {
  title_exchange: "แลกเปลี่ยน",
  swap_from: 'แลกจาก',
  swap_to: "แลกไปเป็น",
  swap_price: "ราคา",
  swap_price_impact: "การเปลี่ยนแปลงของราคา",
  swap_tx_cost: "ค่าธรรมเนียม",
  swap_fee: "ค่าธรรมเนียม จาก HERMES",
  swap_route: "เส้นทาง",
  swap_step_suffix: "ขั้นลำดับในเส้นทาง",
  swap_tooltip_auto_refresh: "นับถอยหลังอัพเดทราคา",
  swap_open_setting: "เปิดการตั้งค่า",
  swap_show_more: "แสดงเพิ่ม",
  swap_transaction_setting: "การตั้งค่าธุรกรรม",
  swap_slippage_tolerance: "ความแตกต่างของราคา (%)",
  swap_manual_input: "กำหนดความแตกต่างของราคาด้วยตนเอง",
  swap_what_is_slippage_title: "อะไรคือ ความแตกต่างของราคา",
  swap_what_is_slippage_messge: "การตั้งค่าความคลาดเคลื่อนที่ยอมรับได้สูงสามารถช่วยให้ธุรกรรมประสบความสำเร็จ แต่คุณอาจไม่ได้ราคาที่ดีเช่นนี้ ใช้ด้วยความระมัดระวัง",
  swap_is_slippage: "-",
  swap_save_button: "บันทึก",
  swap_show_chart: "แสดงกราฟ",
  swap_hide_chart: "ซ่อนกราฟ",
  button_insufficient: "ยอดคงเหลือไม่เพียงพอ",
  button_insufficient_lp: "",
  button_swap: "ยืนยันแลก",
  button_wrap: "ยืนยันแลก",
  button_unwrap: "ยืนยันแลก",
  button_approve: "อนุญาต",
  button_connect_wallat: "เชื่อมต่อกระเป๋า",
  button_connect_network: "เปลี่ยนเครือข่าย",
  max_return: "ผลลัพท์สูงสุด",
  lowest_gas: "ค่าแก๊สน้อยสุด",
  balance: "ยอดคงเหลือ",
  menu_trade: "ซื้อ-ขาย",
  menu_trade_simple_swap: "ซื้อขายอย่างง่าย",
  menu_trade_advance_swap: "ซื้อขาย",
  menu_more: "อื่นๆ",
  menu_more_market_price: "ราคาตลาด",
  menu_more_history: "การซื้อขายย้อนหล้ง",
  logo_description: "ค่าธรรมเนียม 0%",
  exchange_rate: "อัตราแลกเปลี่ยน",
  trade_history: "ธุรกรรมย้อนหลัง",
  routing: "เส้นทาง",
  sign_out: "ออกจากระบบ",
  select_token: "เลือกโทเคน",
  routing_view: "เส้นทาง",
  exchange_title: "ชื่อ",
  exchange_amount: "จำนวน",
  exchange_diff: "ความแตกต่าง",
  max_button: "สูงสุด",
  connect_to_wallet_title: "เชื่อมต่อกระเป๋า"
}

