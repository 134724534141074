import useEagerConnect from 'hooks/useEagerConnect'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.scss'
import NavLayout from './components/NavLayout'
import { ApplicationRoutes } from './routes'

function App() {
  useEagerConnect()
  return (
    <>
      <BrowserRouter>
        <NavLayout>
          <Switch>
            <Route
              exact
              path={ApplicationRoutes.HOME.path}
              component={ApplicationRoutes.HOME.component}
            />
            <Route
              exact
              path={ApplicationRoutes.HomePage.path}
              component={ApplicationRoutes.HomePage.component}
            />
            <Route
              exact
              path={ApplicationRoutes.TradeHistory.path}
              component={ApplicationRoutes.TradeHistory.component}
            />
             <Route
              exact
              path={ApplicationRoutes.SwapResult.path}
              component={ApplicationRoutes.SwapResult.component}
            />
            <Route
              exact
              path={ApplicationRoutes.AboutPage.path}
              component={ApplicationRoutes.AboutPage.component}
            />
            <Route
              exact
              path={ApplicationRoutes.SwapEmbedded.path}
              component={ApplicationRoutes.SwapEmbedded.component}
            />
             <Route
              exact
              path={ApplicationRoutes.SimpleSwap.path}
              component={ApplicationRoutes.SimpleSwap.component}
            />
               <Route
              exact
              path={ApplicationRoutes.MarketPrice.path}
              component={ApplicationRoutes.MarketPrice.component}
            />
            <Route
              exact
              path={ApplicationRoutes.DemoPage.path}
              component={ApplicationRoutes.DemoPage.component}
            />
          </Switch>
        </NavLayout>
      </BrowserRouter>
    </>
  )
}

export default App
