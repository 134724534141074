import { ContractInterface, ethers } from 'ethers'
import { Multicall, ContractCallContext } from 'ethereum-multicall'
import { RateLimiter } from 'limiter'
import { Chain } from 'models/chain.model'
import { ConnectorNames } from 'hooks/useAuth'
import { connectorsByName } from 'utils/connector.utils'

const multiCallLimiter = new RateLimiter({
  tokensPerInterval: 4,
  interval: 'second',
})
class EthersService {
  provider: {
    [chainId: number]: ethers.providers.JsonRpcProvider | ethers.Signer
  }
  rpcProvider: { [chainId: number]: ethers.providers.JsonRpcProvider }
  chainInfos: Chain[]

  constructor(chainInfos: Chain[], signer?: ethers.Signer) {
    this.chainInfos = chainInfos
    this.rpcProvider = {}
    chainInfos.forEach ((chain) => {
      this.rpcProvider[chain.chainId] = new ethers.providers.JsonRpcProvider(chain.rpcUrls[0])
    })
    this.provider = signer ? [signer] : this.rpcProvider
  }

  getContract = (addr: string, abi: ContractInterface, chainId: number) => {
    return new ethers.Contract(addr, abi, Object(this.provider[chainId]))
  }

  multiCall = (
    props: ContractCallContext<any>[],
    chainInfo: Chain
    ) => {

    const multicall = new Multicall({
      ethersProvider: Object(this.rpcProvider[chainInfo.chainId]),
      multicallCustomContractAddress: chainInfo?.multiCallContractAddress,
      tryAggregate: true,
    })

    return multiCallLimiter.removeTokens(1).then(() => multicall.call(props))
  }
}

const POLLING_INTERVAL = 12000
export const getEthersProvider = async () => {
  const connectorID = window.localStorage.getItem("loginType")
  let provider = null

  if (connectorID === ConnectorNames.Injected)  {

    provider = window.ethereum
      ? new ethers.providers.Web3Provider(window.ethereum)
      : null
    if (provider) provider.pollingInterval = POLLING_INTERVAL
  } else if (connectorID === ConnectorNames.BSC) {

    const bscConnector = connectorsByName[ConnectorNames.BSC]
    await bscConnector.activate()
    let bscProvider = await bscConnector.getProvider()
    provider = new ethers.providers.Web3Provider(bscProvider);

  } else if (connectorID === ConnectorNames.WalletConnect) {

  }
  return provider
}

export default EthersService
