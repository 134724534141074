import { useTranslationState } from "contexts/TranslationContext"
import { DexOutPutReturnType } from "hooks/Swap/DexOutPutReturnType"
import { FC } from 'react'

export const MinimizeGasToggleButton: FC<{
  maxReturn: 'max-return' | 'lowest-gas',
  setMaxReturn: React.Dispatch<React.SetStateAction<DexOutPutReturnType>>,
}> = ({ maxReturn, setMaxReturn }) => {
  let selectedStyle = ' bg-gray-700 border border-gray-900 text-white'
  let unSelectedStyle = ' text-gray-600'
  let style = 'flex flex-1 justify-center item-center rounded-lg pt-2 pb-2 cursor-pointer'
  let isMaxReturn = (maxReturn === 'max-return')
  //useTranslation
  const { tl: currentLanguage } = useTranslationState()

  return (
    <div
    className='flex flex-row bg-gray-800 rounded-lg'
    style={{padding: '3px'}}
    >
      <div onClick={()=>{
        setMaxReturn('max-return')
      }} className={style + (isMaxReturn ? selectedStyle : unSelectedStyle)}>
        {currentLanguage.max_return}
      </div>
      <div onClick={()=>{
        setMaxReturn('lowest-gas')
      }} className={style + (isMaxReturn ? unSelectedStyle : selectedStyle)}>
        {currentLanguage.lowest_gas}
      </div>
    </div>
  )
}
